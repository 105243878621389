import { Spinner } from 'components/Spinner';
import { FC } from 'react';

import 'assets/styles/Preloader.scss';

export const Preloader: FC = () => {
	return (
		<div className="preloader">
			<Spinner />
		</div>
	);
};
