import { EngineOption, SelectEngineForHome } from 'components/selects';
import { Section } from 'components/Section';
import { App, Button, Progress, Result } from 'antd';
import { ipc, isDesktop } from 'desktop';
import style from 'assets/styles/homePage.module.scss';
import selectEngineStyle from 'assets/styles/selectEngine.module.scss';
import { Cond } from 'utils/Cond';
import { FC, Dispatch, SetStateAction, MouseEvent, useState } from 'react';
import { downloadAndInstall, cancelDownload } from 'desktop/install';
import Modal from 'antd/es/modal/Modal';
import { Spinner } from 'components/Spinner';
import { McButton, McMoreMenu } from 'components/mc';
import { useLinkClickHandler } from 'react-router-dom';
import { IconClose } from 'assets/icons/svg';
import { ModalState, modalStateFromProgress } from './downloadAndInstall';
import enginesService from 'services/EnginesService';
import { useQueryClient } from '@tanstack/react-query';

export const EnginesSection: FC<{
	selectedEngine?: EngineOption;
	setSelectedEngine: Dispatch<SetStateAction<EngineOption | undefined>>;
}> = (props) => {
	const { selectedEngine, setSelectedEngine } = props;
	const queryClient = useQueryClient();

	const { notification } = App.useApp();

	const [modalState, setModalState] = useState<ModalState>({
		title: '',
		details: '',
		open: false,
		done: false,
	});

	const detailsClickHandler = useLinkClickHandler(
		`/engines/${selectedEngine?.online?.engineId}`
	);

	const downloadOnClick = async (e: MouseEvent) => {
		const engineId = selectedEngine?.online?.engineId;
		if (!engineId) {
			e.preventDefault();
			return;
		}

		const downloadTicket = await enginesService
			.getEngineFile(engineId)
			.then((r) => r.data);

		if (isDesktop) {
			downloadAndInstall(downloadTicket, (progress) => {
				setModalState(modalStateFromProgress(progress));
				if (progress.step === 'Done') {
					queryClient.invalidateQueries({
						queryKey: ['engines', 'home', 'installed'],
					});
				}
			});
		} else {
			const link = document.createElement('a');
			link.href = downloadTicket.url!;
			link.download = downloadTicket.fileName;
			link.click();
		}
	};
	const closeModal = () => setModalState((s) => ({ ...s, open: false }));

	const desktopDownloadText =
		!!selectedEngine && !!selectedEngine.installed && !!selectedEngine.online
			? 'Reinstall'
			: 'Install';

	const isUpgrade = !!selectedEngine && selectedEngine.upgradeTag;

	return (
		<Section title="Engines">
			<Modal
				maskClosable={modalState.done}
				closeIcon={<IconClose />}
				afterClose={() => setModalState((s) => ({ ...s, open: false }))}
				title={modalState.title}
				open={modalState.open}
				footer={[
					modalState.percent ? (
						<Button
							key="cancel"
							disabled={!modalState.percent}
							onClick={() => modalState.id && cancelDownload(modalState.id)}
						>
							Cancel
						</Button>
					) : (
						<Button
							key="submit"
							type="primary"
							disabled={!modalState.done}
							onClick={closeModal}
						>
							OK
						</Button>
					),
				]}
			>
				{modalState.done ? (
					modalState.title === 'Failed' ? (
						<Result
							status="error"
							title="Failed to install engine!"
							subTitle={modalState.details ?? ''}
						/>
					) : modalState.title === 'Done' ? (
						<Result
							status="success"
							title="Successfully installed engine!"
							subTitle={modalState.details ?? ''}
						/>
					) : (
						<Result status="info" title={modalState.details ?? ''} />
					)
				) : modalState.percent ? (
					<Progress
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
						type="circle"
						percent={modalState.percent}
						showInfo
						format={(p) => `${p?.toFixed(1)}%`}
					/>
				) : (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Spinner />
					</div>
				)}
			</Modal>
			<div className={style.enginesContainer}>
				<SelectEngineForHome
					setSelectedEngine={setSelectedEngine}
					selectedEngine={selectedEngine}
				/>
				{isDesktop ? (
					<McButton
						centered
						nowrap
						onClick={downloadOnClick}
						disabled={!selectedEngine?.online}
						className={isUpgrade ? selectEngineStyle.upgradeTagColor : ''}
					>
						{isUpgrade ? 'Upgrade' : desktopDownloadText}
					</McButton>
				) : (
					<McButton
						centered
						onClick={downloadOnClick}
						disabled={!selectedEngine?.online}
					>
						Download
					</McButton>
				)}
				<McMoreMenu placement="bottomRight">
					<>
						<button
							className="section"
							disabled={!selectedEngine?.online}
							onClick={(e) => detailsClickHandler(e as any)}
						>
							Show detail page
						</button>
						<Cond if={isDesktop}>
							<button
								className="section"
								disabled={!selectedEngine?.installed}
								onClick={() =>
									ipc.showDirectoryInShell(
										selectedEngine?.installed?.installPath!
									)
								}
							>
								<p>Show file in explorer</p>
							</button>
							<button
								className="section"
								onClick={(e) => {
									if (!!selectedEngine && !!selectedEngine.installed) {
										const engineName = selectedEngine.installed.name;
										ipc
											.uninstallEngine(selectedEngine.installed.name)
											.then((res) => {
												if (res === 0) {
													notification.success({
														message: 'Success!',
														description: `Engine ${engineName} was successfully uninstalled!`,
													});
													queryClient.invalidateQueries({
														queryKey: ['engines', 'home', 'installed'],
													});
												}
											})
											.catch((err) =>
												notification.error({
													message: 'Failure!',
													description: `Engine ${engineName} failed to uninstall!`,
												})
											);
									}
								}}
								disabled={!selectedEngine?.installed}
							>
								<p>Uninstall</p>
							</button>
						</Cond>
					</>
				</McMoreMenu>
			</div>
		</Section>
	);
};
