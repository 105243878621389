import { SvgIconProps } from 'assets/icons/SvgIcon';
import {
	CalAppIcon,
	ControlSuiteAppIcon,
	T2DAppIcon,
	T2DDICAppIcon,
	T3DAppIcon,
	T3DDICAppIcon,
	T6DAppIcon,
	TemaGenericAppIcon,
} from 'assets/icons/apps';
import { FC } from 'react';

export interface LocalApplication {
	name: string;
	code: string;
	icon: FC<SvgIconProps>;
	requiredModuleProductCodes: number[];
}

/**
 * Referenced by engines included applications when in offline mode.
 */
export const localApplications: Record<string, LocalApplication> = {
	'2D': {
		name: 'TEMA 2D',
		code: '2D',
		icon: T2DAppIcon,
		requiredModuleProductCodes: [1000],
	},
	'3D': {
		name: 'TEMA 3D',
		code: '3D',
		icon: T3DAppIcon,
		requiredModuleProductCodes: [1000, 1010],
	},
	'6D': {
		name: 'TEMA 6D',
		code: '6D',
		icon: T6DAppIcon,
		requiredModuleProductCodes: [1000, 1015],
	},
	'DIC 2D': {
		name: 'TEMA DIC 2D',
		code: 'DIC 2D',
		icon: T2DDICAppIcon,
		requiredModuleProductCodes: [1000, 1005],
	},
	'DIC 3D': {
		name: 'TEMA DIC 3D',
		code: 'DIC 3D',
		icon: T3DDICAppIcon,
		requiredModuleProductCodes: [1000, 1010, 1005],
	},
	CS: {
		name: 'TEMA Control Suite',
		code: 'CS',
		icon: ControlSuiteAppIcon,
		requiredModuleProductCodes: [1000, 1020],
	},
	calib: {
		name: 'TEMA Calibration',
		code: 'calib',
		icon: CalAppIcon,
		requiredModuleProductCodes: [1000],
	},
} satisfies Record<string, LocalApplication>;

export const getLocalApplication = (code: string) => {
	return (
		localApplications[code] ??
		({
			name: 'unknown',
			code: '??',
			icon: TemaGenericAppIcon,
			requiredModuleProductCodes: [],
		} as LocalApplication)
	);
};
