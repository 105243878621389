import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
	AddOrganizationDto,
	ErrorDTO,
	OrganizationDto,
	WebCrmOrganizationDto,
} from 'api';

import { RootState } from 'store';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import organizationService from 'services/OrganizationService';
import webCrmService from 'services/WebCrmService';

export interface OrganizationState {
	organization: OrganizationDto;
	organizations: OrganizationDto[];
	webCrmOrganization: WebCrmOrganizationDto;
	webCrmOrganizations: WebCrmOrganizationDto[];
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: OrganizationState = {
	organization: {} as OrganizationDto,
	organizations: [],
	webCrmOrganization: {} as WebCrmOrganizationDto,
	webCrmOrganizations: [],
	loading: true,
	error: null,
};

export const addOrganization = createAppAsyncThunk(
	'organizations/addOrganization',
	(addOrganizationDto: AddOrganizationDto, { rejectWithValue }) =>
		organizationService
			.addOrganization(addOrganizationDto)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const removeOrganization = createAppAsyncThunk(
	'organizations/removeOrganization',
	(id: number, { rejectWithValue }) =>
		organizationService
			.removeOrganization(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const updateOrganization = createAppAsyncThunk(
	'organizations/updateOrganization',
	(organizationDto: OrganizationDto, { rejectWithValue }) =>
		organizationService
			.updateOrganization(organizationDto)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getAmountOfOrganizations = createAppAsyncThunk(
	'organizations/getAmountOfOrganizations',
	(_, { rejectWithValue }) =>
		organizationService
			.getAmountOfOrganizations()
			.catch(getErrorMessageAnd(rejectWithValue))
);

type GetOrganizationsParams = Parameters<
	typeof organizationService.getOrganizations
>;
export const getOrganizations = createAppAsyncThunk(
	'organizations/getOrganizations',
	(params: GetOrganizationsParams, { rejectWithValue }) =>
		organizationService
			.getOrganizations(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOrganizationById = createAppAsyncThunk(
	'organizations/getOrganizationById',
	(id: number, { rejectWithValue }) =>
		organizationService
			.getOrganizationById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type AddUserToOrganizationParams = Parameters<
	typeof organizationService.addUserToOrganization
>;
export const addUserToOrganization = createAppAsyncThunk(
	'organizations/addUserToOrganization',
	(params: AddUserToOrganizationParams, { rejectWithValue }) =>
		organizationService
			.addUserToOrganization(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type RemoveUserFromOrganizationParams = Parameters<
	typeof organizationService.removeUserFromOrganization
>;
export const removeUserFromOrganization = createAppAsyncThunk(
	'organizations/removeUserFromOrganization',
	(params: RemoveUserFromOrganizationParams, { rejectWithValue }) =>
		organizationService
			.removeUserFromOrganization(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type GetUsersInOrganizationParams = Parameters<
	typeof organizationService.getUsersInOrganization
>;
export const getUsersInOrganization = createAppAsyncThunk(
	'organizations/getUsersInOrganization',
	(params: GetUsersInOrganizationParams, { rejectWithValue }) =>
		organizationService
			.getUsersInOrganization(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getAmountOfUsersInOrganization = createAppAsyncThunk(
	'organizations/getAmountOfUsersInOrganization',
	(id: number, { rejectWithValue }) =>
		organizationService
			.getAmountOfUsersInOrganization(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type GetWebCrmOrganizationsParams = Parameters<
	typeof webCrmService.getWebCrmOrganizations
>;
export const getWebCrmOrganizations = createAppAsyncThunk(
	'organizations/getWebCrmOrganizations',
	(params: GetWebCrmOrganizationsParams, { rejectWithValue }) =>
		webCrmService
			.getWebCrmOrganizations(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getWebCrmOrganizationById = createAppAsyncThunk(
	'organizations/getWebCrmOrganizationById',
	(id: number, { rejectWithValue }) =>
		webCrmService
			.getWebCrmOrganizationById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

const organizationsSlice = createSlice({
	name: 'organizations',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addOrganization.pending, setLoadingState)
			.addCase(addOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(removeOrganization.pending, setLoadingState)
			.addCase(removeOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(updateOrganization.pending, setLoadingState)
			.addCase(updateOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getAmountOfOrganizations.pending, setLoadingState)
			.addCase(getAmountOfOrganizations.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getOrganizations.pending, setLoadingState)
			.addCase(getOrganizations.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.error = null;
				state.organizations = payload.data.content;
			})

			.addCase(getOrganizationById.pending, setLoadingState)
			.addCase(getOrganizationById.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.error = null;
				state.organization = payload.data;
			})

			.addCase(addUserToOrganization.pending, setLoadingState)
			.addCase(addUserToOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(removeUserFromOrganization.pending, setLoadingState)
			.addCase(removeUserFromOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getUsersInOrganization.pending, setLoadingState)
			.addCase(getUsersInOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getAmountOfUsersInOrganization.pending, setLoadingState)
			.addCase(getAmountOfUsersInOrganization.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getWebCrmOrganizations.pending, setLoadingState)
			.addCase(getWebCrmOrganizations.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.error = null;
				state.webCrmOrganizations = payload.data.content ?? [];
			})

			.addCase(getWebCrmOrganizationById.pending, setLoadingState)
			.addCase(getWebCrmOrganizationById.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.error = null;
				state.webCrmOrganization = payload.data;
			})

			.addMatcher(
				isAnyOf(
					addOrganization.rejected,
					removeOrganization.rejected,
					updateOrganization.rejected,
					getAmountOfOrganizations.rejected,
					getOrganizations.rejected,
					getOrganizationById.rejected,
					addUserToOrganization.rejected,
					removeUserFromOrganization.rejected,
					getUsersInOrganization.rejected,
					getAmountOfUsersInOrganization.rejected,
					getWebCrmOrganizations.rejected,
					getWebCrmOrganizationById.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const organizationsState = (state: RootState) => state.organizations;

export default organizationsSlice.reducer;
