import { invoke } from '@tauri-apps/api';
import { FC, useEffect, useState } from 'react';
import { CMLicense } from 'types/codemeter';

export const Licenses: FC<{}> = () => {
	const [status, setStatus] = useState('loading');
	const [licenses, setLicenses] = useState<CMLicense[]>([]);

	useEffect(() => {
		invoke('get_codemeter_licenses', {
			firmCode: 6000157,
		}).then(
			(l) => {
				const licenses = l as CMLicense[];
				setLicenses(licenses);
				setStatus(`found ${licenses.length} licenses`);
			},
			(e) => {
				if ('error' in e && typeof e.error === 'string') {
					setStatus(`${e.error.replaceAll('\x00', '')}`);
				} else {
					setStatus(`error: ${e}`);
				}
			}
		);
	}, []);

	return (
		<>
			<div>
				<code>{status}</code>
			</div>
			<hr />
			<div
				style={{
					maxHeight: '400px',
					overflow: 'auto',
					background: '#131719',
					padding: '.5rem',
				}}
			>
				{licenses.map((l, i) => (
					<div key={i}>
						<pre
							style={{
								background: 'rgb(34, 34, 34)',
								color: 'rgb(224, 224, 224)',
								fontFamily: 'monospace',
								fontWeight: 'normal',
								padding: '.5rem',
								margin: '0 0 1rem 0',
							}}
						>
							{JSON.stringify(l, null, 2)}
						</pre>
					</div>
				))}
			</div>
		</>
	);
};
