import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO } from 'api';
import { AxiosResponse } from 'axios';
import { newsService } from 'services/NewsService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface NewsState {
	news: News | null;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: NewsState = {
	news: {} as News | null,
	loading: true,
	error: null,
};

export const getOnePublishedNews = createAppAsyncThunk<AxiosResponse>(
	'onePublishedNews/getOnePublishedNews',
	(_, { rejectWithValue }) =>
		newsService
			.getNewestPublishedNewItem()
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const onePublishedNewsSlice = createSlice({
	name: 'onePublishedNews',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getOnePublishedNews.pending, setLoadingState)
			.addCase(getOnePublishedNews.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.news = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(isAnyOf(getOnePublishedNews.rejected), (state, action) => {
				state.error = action.payload ?? rejectionError;
				state.loading = false;
			});
	},
});

export const onePublishedNewsState = (state: RootState) =>
	state.onePublishedNews;

export default onePublishedNewsSlice.reducer;
