import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CloudIcon: React.FC<SvgIconProps> = ({
	size = '20',
	zoomResize,
}) => (
	<SvgIcon size={size} actualSize={20} zoomResize={zoomResize}>
		<path
			d="M15 6.33203H13.95C13.6382 5.12453 12.994 4.02869 12.0905 3.16903C11.1871 2.30937 10.0606 1.72036 8.83911 1.46893C7.61762 1.2175 6.35009 1.31373 5.18056 1.74669C4.01102 2.17965 2.98638 2.93198 2.22305 3.91818C1.45973 4.90439 0.988336 6.08493 0.862433 7.32566C0.736531 8.56639 0.96117 9.81756 1.51082 10.937C2.06048 12.0564 2.91311 12.9993 3.97183 13.6583C5.03055 14.3174 6.25291 14.6663 7.50001 14.6654H15C16.1051 14.6654 17.1649 14.2264 17.9463 13.445C18.7277 12.6636 19.1667 11.6038 19.1667 10.4987C19.1667 9.39363 18.7277 8.33382 17.9463 7.55242C17.1649 6.77102 16.1051 6.33203 15 6.33203Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
