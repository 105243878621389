import { invoke } from '@tauri-apps/api/tauri';
import { appWindow } from '@tauri-apps/api/window';
import { ipc } from './ipc';
import { DownloadTicketDTO } from 'api';

export type DownloadAndInstallProgress =
	| { step: 'Connecting' }
	| { step: 'Downloading'; done: number; total: number; id: number }
	| { step: 'Installing' }
	| { step: 'Failed'; details: string }
	| { step: 'Canceled' }
	| { step: 'Done' };

export const downloadAndInstall = async (
	ticket: DownloadTicketDTO,
	progress: (progress: DownloadAndInstallProgress) => void
) => {
	try {
		progress({ step: 'Connecting' });
		let downloaded = 0;
		const [, install_file] = await download_engine(ticket.url, (p) => {
			downloaded += p.progress;
			progress({
				step: 'Downloading',
				done: downloaded,
				total: p.total,
				id: p.id,
			});
		});
		console.log('Install file: %o', install_file);
		progress({ step: 'Installing' });
		await ipc
			.installEngine(install_file)
			.then((statusCode) =>
				statusCode === 0
					? progress({ step: 'Done' })
					: progress({
							step: 'Failed',
							details: `Exited with status ${statusCode}`,
					  })
			)
			.catch((error) => {
				console.log('Error: %o', error);
				progress({ step: 'Failed', details: `${error.error}` });
			});
	} catch (x) {
		console.log('Error: %o', x);
		const details = x instanceof Error ? x.message : x;
		if (details === 'download canceled') {
			progress({ step: 'Canceled' });
		} else {
			progress({ step: 'Failed', details: `${details}` });
		}
	}
};

export const cancelDownload = (id: number) => {
	invoke('cancel_download_engine', { id });
};

async function download_engine(
	url: string,
	progressHandler?: ProgressHandler
): Promise<[number, string]> {
	const ids = new Uint32Array(1);
	window.crypto.getRandomValues(ids);
	const id = ids[0];

	if (progressHandler != null) {
		handlers.set(id, progressHandler);
	}

	await listenToEventIfNeeded('download://progress');

	return await invoke('download_engine', {
		id,
		url,
	});
}

interface ProgressPayload {
	id: number;
	progress: number;
	total: number;
}

type ProgressHandler = (progress: ProgressPayload) => void;
const handlers: Map<number, ProgressHandler> = new Map();
let listening = false;

async function listenToEventIfNeeded(event: string): Promise<void> {
	if (listening) {
		return await Promise.resolve();
	}
	return await appWindow
		.listen<ProgressPayload>(event, ({ payload }) => {
			const handler = handlers.get(payload.id);
			if (handler != null) {
				handler(payload);
			}
		})
		.then(() => {
			listening = true;
		});
}
