import {
	LicensesApiFp,
	LicensesApiAxiosParamCreator,
} from 'api/client/licenses-api';
import { apiAxios, apiConfig } from './ApiService';
import { BASE_PATH } from 'api/base';

export const licensesService = LicensesApiFp(apiAxios, apiConfig);
export default licensesService;

type ParamCreator = ReturnType<typeof LicensesApiAxiosParamCreator>;

export const getLicenseURL = async <K extends keyof ParamCreator>(
	key: K,
	params: Parameters<ParamCreator[K]>
) =>
	LicensesApiAxiosParamCreator()
		/* @ts-ignore */
		[key](...params)
		.then((ra) => BASE_PATH + ra.url);
