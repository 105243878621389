import { FC } from 'react';
import { T2DAppIcon } from './T2DAppIcon';
import { SvgIconProps } from '../SvgIcon';
import { T3DAppIcon } from './T3DAppIcon';
import { T2DDICAppIcon } from './T2DDICAppIcon';
import { T3DDICAppIcon } from './T3DDICAppIcon';
import { T6DAppIcon } from './T6DAppIcon';
import { ControlSuiteAppIcon } from './ControlSuiteAppIcon';
import { TemaGenericAppIcon } from './TemaGenericAppIcon';

const appIcons: Record<string, React.FC<SvgIconProps>> = {
	'2D': T2DAppIcon,
	'3D': T3DAppIcon,
	'6D': T6DAppIcon,
	'DIC 2D': T2DDICAppIcon,
	'DIC 3D': T3DDICAppIcon,
	'Control Suite': ControlSuiteAppIcon,
};

export const AppIcon: FC<{ app: string; size: number }> = ({ app, size }) => {
	const AppIcon = appIcons[app] ?? TemaGenericAppIcon;
	return <AppIcon size={size} />;
};
