import { FC } from 'react';
import { Button, ButtonProps } from 'antd';

import 'assets/styles/button.scss';

declare const ButtonTypes: ['primary', 'ghost', 'default'];
export type ButtonType = (typeof ButtonTypes)[number];
declare const ButtonShapes: ['default', 'circle', 'round'];
export type ButtonShape = (typeof ButtonShapes)[number];
export type SizeType = 'small' | 'middle' | 'large' | undefined;
declare const ButtonHTMLTypes: ['submit', 'button', 'reset'];
export type ButtonHTMLType = (typeof ButtonHTMLTypes)[number];

interface Props extends ButtonProps {
	width?: string;
	height?: string;
	fontWeight?: string;
}

export const MotionButton: FC<Props> = (props) => {
	const { type = 'primary', width, height, fontWeight } = props;
	return (
		<Button
			{...props}
			className="motionbutton"
			type={type}
			style={{
				minWidth: `${width}`,
				height: `${height}`,
				fontWeight: `${fontWeight}`,
			}}
		/>
	);
};
