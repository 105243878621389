import { Dispatch, useEffect, useMemo, useState } from 'react';
import style from 'assets/styles/selectField.module.scss';
import organizationService from 'services/OrganizationService';
import { OrganizationDto } from 'api';
import { useDebounce } from 'hooks/useDebounce';
import SelectEntity, { Option } from './SelectEntity';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { App } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';

interface Props {
	selectedId: number | undefined;
	setSelectedId: Dispatch<number | undefined>;
	tabIndex?: number;
}

const SelectOrganization = ({ selectedId, setSelectedId, tabIndex }: Props) => {
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce(searchText, 700);
	const [initialId, setInitialId] = useState<number | undefined>(undefined);
	const PAGE_SIZE = 300;
	const { notification } = App.useApp();

	useEffect(() => {
		if (
			selectedId !== undefined &&
			selectedId !== null &&
			initialId === undefined
		)
			setInitialId(selectedId);
	}, [initialId, selectedId]);

	const {
		data: initialOrganization,
		isLoading: isLoadingInitialOrganization,
		error: getInitialOrganizationError,
	} = useQuery({
		queryKey: ['organizations', initialId],
		queryFn: () =>
			organizationService
				.getOrganizationById(initialId!)
				.then((res) => res.data),
		enabled: initialId !== undefined,
	});

	const {
		data: organizationPages,
		error: getOrganizationsError,
		isLoading: isLoadingOrganizations,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['organizations', 'infinite', debouncedSearch],
		queryFn: ({ pageParam: pageNr }) =>
			organizationService
				.getOrganizations(pageNr, PAGE_SIZE, debouncedSearch)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const organizationOptions = useMemo(() => {
		const mapOption = (org: OrganizationDto) => {
			return {
				label: (
					<div className={style.dropdownItem}>
						<div className={style.labelIconName}>
							<div>{org.name}</div>
						</div>
					</div>
				),
				value: org.id,
				searchValue: (org.name! + org.email! + org.id).replace(/ /g, ''),
			};
		};

		const organizations = !!organizationPages
			? organizationPages.pages.reduce(
					(acc, page) => [...acc, ...page.content.map((org) => mapOption(org))],
					[] as Option[]
			  )
			: [];

		if (
			initialOrganization !== undefined &&
			!organizations.find((org) => org.value === initialOrganization.id)
		)
			return [mapOption(initialOrganization), ...organizations];

		return organizations;
	}, [initialOrganization, organizationPages]);

	useEffect(() => {
		if (!getInitialOrganizationError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getInitialOrganizationError,
				'fetch',
				'initial organization'
			)
		);
	}, [getInitialOrganizationError, notification]);

	useEffect(() => {
		if (!getOrganizationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getOrganizationsError,
				'fetch',
				'organization list for select field'
			)
		);
	}, [getOrganizationsError, notification]);

	const loading = isLoadingOrganizations || isLoadingInitialOrganization;

	return (
		<SelectEntity
			setSelectedId={setSelectedId}
			selectedId={selectedId}
			loading={loading}
			setSearchText={setSearchText}
			label="Organization"
			entityName="organization"
			includeEmptyOption={true}
			placeholder="Select an organization"
			tabIndex={tabIndex}
			fetchMoreEntities={fetchNextPage}
			options={organizationOptions}
		/>
	);
};

export default SelectOrganization;
