/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddNewsDto } from '../model';
import { EntityStatus } from '../model';
import { ErrorDTO } from '../model';
import { NewsCategory } from '../model';
import { NewsDto } from '../model';
import { PageDtoNewsDto } from '../model';
/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add news item
         * @param {AddNewsDto} [newsDto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewItemForm: async (newsDto?: AddNewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (newsDto !== undefined) {
                const newsDtoValue = typeof newsDto === 'object' && !(newsDto instanceof Blob) ? JSON.stringify(newsDto) : newsDto;
                localVarFormParams.append('newsDto', newsDtoValue as any);
            }

            if (imageFullSize !== undefined) {
                const imageFullSizeValue = typeof imageFullSize === 'object' && !(imageFullSize instanceof Blob) ? JSON.stringify(imageFullSize) : imageFullSize;
                localVarFormParams.append('imageFullSize', imageFullSizeValue as any);
            }

            if (imageSmallSize !== undefined) {
                const imageSmallSizeValue = typeof imageSmallSize === 'object' && !(imageSmallSize instanceof Blob) ? JSON.stringify(imageSmallSize) : imageSmallSize;
                localVarFormParams.append('imageSmallSize', imageSmallSizeValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete news item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsItemById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteNewsItemById.');
            }
            const localVarPath = `/api/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete news item picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewsItemPicture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteNewsItemPicture.');
            }
            const localVarPath = `/api/news/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get newest published news item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewestPublishedNewItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/news/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get news item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsItemById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNewsItemById.');
            }
            const localVarPath = `/api/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of news with pagination and filtering by key value, status and category
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {EntityStatus} [entityStatus] 
         * @param {NewsCategory} [category] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsList: async (page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, category?: NewsCategory, sortOrder?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getNewsList.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getNewsList.');
            }
            const localVarPath = `/api/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (entityStatus !== undefined) {
                localVarQueryParameter['entityStatus'] = entityStatus;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update news item by id
         * @param {number} id 
         * @param {NewsDto} [dto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNewsItemForm: async (id: number, dto?: NewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNewsItemForm.');
            }
            const localVarPath = `/api/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (dto !== undefined) {
                const dtoValue = typeof dto === 'object' && !(dto instanceof Blob) ? JSON.stringify(dto) : dto;
                localVarFormParams.append('dto', dtoValue as any);
            }

            if (imageFullSize !== undefined) {
                const imageFullSizeValue = typeof imageFullSize === 'object' && !(imageFullSize instanceof Blob) ? JSON.stringify(imageFullSize) : imageFullSize;
                localVarFormParams.append('imageFullSize', imageFullSizeValue as any);
            }

            if (imageSmallSize !== undefined) {
                const imageSmallSizeValue = typeof imageSmallSize === 'object' && !(imageSmallSize instanceof Blob) ? JSON.stringify(imageSmallSize) : imageSmallSize;
                localVarFormParams.append('imageSmallSize', imageSmallSizeValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add news item
         * @param {AddNewsDto} [newsDto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewItemForm(newsDto?: AddNewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).addNewItemForm(newsDto, imageFullSize, imageSmallSize, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete news item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).deleteNewsItemById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete news item picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsItemPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).deleteNewsItemPicture(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get newest published news item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewestPublishedNewItem(options?: AxiosRequestConfig): Promise<AxiosResponse<NewsDto>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).getNewestPublishedNewItem(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get news item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<NewsDto>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).getNewsItemById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of news with pagination and filtering by key value, status and category
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {EntityStatus} [entityStatus] 
         * @param {NewsCategory} [category] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsList(page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, category?: NewsCategory, sortOrder?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoNewsDto>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).getNewsList(page, size, searchedValue, entityStatus, category, sortOrder, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update news item by id
         * @param {number} id 
         * @param {NewsDto} [dto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNewsItemForm(id: number, dto?: NewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration).updateNewsItemForm(id, dto, imageFullSize, imageSmallSize, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add news item
         * @param {AddNewsDto} [newsDto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewItemForm(newsDto?: AddNewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NewsApiFp(configuration).addNewItemForm(newsDto, imageFullSize, imageSmallSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete news item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NewsApiFp(configuration).deleteNewsItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete news item picture by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNewsItemPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NewsApiFp(configuration).deleteNewsItemPicture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get newest published news item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewestPublishedNewItem(options?: AxiosRequestConfig): Promise<AxiosResponse<NewsDto>> {
            return NewsApiFp(configuration).getNewestPublishedNewItem(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get news item by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsItemById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<NewsDto>> {
            return NewsApiFp(configuration).getNewsItemById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of news with pagination and filtering by key value, status and category
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {EntityStatus} [entityStatus] 
         * @param {NewsCategory} [category] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsList(page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, category?: NewsCategory, sortOrder?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoNewsDto>> {
            return NewsApiFp(configuration).getNewsList(page, size, searchedValue, entityStatus, category, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update news item by id
         * @param {number} id 
         * @param {NewsDto} [dto] 
         * @param {Blob} [imageFullSize] 
         * @param {Blob} [imageSmallSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNewsItemForm(id: number, dto?: NewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NewsApiFp(configuration).updateNewsItemForm(id, dto, imageFullSize, imageSmallSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @summary Add news item
     * @param {AddNewsDto} [newsDto] 
     * @param {Blob} [imageFullSize] 
     * @param {Blob} [imageSmallSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async addNewItemForm(newsDto?: AddNewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NewsApiFp(this.configuration).addNewItemForm(newsDto, imageFullSize, imageSmallSize, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete news item by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async deleteNewsItemById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NewsApiFp(this.configuration).deleteNewsItemById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete news item picture by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async deleteNewsItemPicture(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NewsApiFp(this.configuration).deleteNewsItemPicture(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get newest published news item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async getNewestPublishedNewItem(options?: AxiosRequestConfig) : Promise<AxiosResponse<NewsDto>> {
        return NewsApiFp(this.configuration).getNewestPublishedNewItem(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get news item by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async getNewsItemById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<NewsDto>> {
        return NewsApiFp(this.configuration).getNewsItemById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of news with pagination and filtering by key value, status and category
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {EntityStatus} [entityStatus] 
     * @param {NewsCategory} [category] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async getNewsList(page: number, size: number, searchedValue?: string, entityStatus?: EntityStatus, category?: NewsCategory, sortOrder?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoNewsDto>> {
        return NewsApiFp(this.configuration).getNewsList(page, size, searchedValue, entityStatus, category, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update news item by id
     * @param {number} id 
     * @param {NewsDto} [dto] 
     * @param {Blob} [imageFullSize] 
     * @param {Blob} [imageSmallSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public async updateNewsItemForm(id: number, dto?: NewsDto, imageFullSize?: Blob, imageSmallSize?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NewsApiFp(this.configuration).updateNewsItemForm(id, dto, imageFullSize, imageSmallSize, options).then((request) => request(this.axios, this.basePath));
    }
}
