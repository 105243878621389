import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface CodemeterInterface {
	importLifModalDismissed: boolean;
}

const initialState: CodemeterInterface = {
	importLifModalDismissed: false,
};

const codemeterSlice = createSlice({
	name: 'codemeter',
	initialState: initialState,
	reducers: {
		setImportLifModalDismissed: (state, action) => {
			state.importLifModalDismissed = action.payload;
		},
	},
});

export const codemeterState = (state: RootState) => state.codemeter;

export const { setImportLifModalDismissed } = codemeterSlice.actions;

export default codemeterSlice.reducer;
