import { InputHTMLAttributes, forwardRef, useId } from 'react';

type InputElement = InputHTMLAttributes<HTMLInputElement>;

interface Props extends InputElement {
	label?: string;
	error?: string;
}

export const McTextBox = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const inputProps = {
		...props,
		label: undefined,
		error: undefined,
	};

	// prettier-ignore
	const cssClasses = [
        'mc-formField',
        props.error ? 'mc-hasError' : ''
    ].join(' ');

	const uniqueId = `mctx-${useId()}`;
	const id = props.id ? props.id : uniqueId;

	return (
		<div className={cssClasses}>
			<label htmlFor={id} className="mc-fieldLabel">
				{props.label}
			</label>
			<input className="mc-textBox" {...inputProps} id={id} ref={ref} />
			<div className="mc-fieldError">{props.error}</div>
		</div>
	);
});
