import { IconEmptyCube } from 'assets/icons/IconEmptyCube';
import { FC, ReactNode } from 'react';
import { FileSource, ipc } from 'desktop';
import { EngineOption } from 'components/selects';
import { App, Tooltip } from 'antd';
import { errorNotification } from 'utils/Notifications';
import McCard from './McCard';
import { IconTickDown } from 'assets/icons/svg';
import style from 'assets/styles/RecentFileCard.module.scss';

interface CardProps {
	selectedEngine: EngineOption | undefined;
	recentFile: {
		image: ReactNode;
		name: string;
		timeStamp: string;
		fileSource: FileSource;
	};
}

export const RecentFileCard: FC<CardProps> = ({
	recentFile,
	selectedEngine,
}) => {
	const { notification } = App.useApp();
	const isDisabled = !selectedEngine || !selectedEngine.installed;

	const fileName =
		recentFile.name.length > 20
			? `${recentFile.name.substring(0, 20)}...`
			: recentFile.name;

	return (
		<McCard
			hoverable={!isDisabled}
			bordered={!isDisabled}
			size="small"
			extra={
				<div
					style={{ display: 'inline-flex' }}
					className={`rotate180 ${isDisabled ? style.disabled : ''}`}
				>
					<IconTickDown />
				</div>
			}
			className={isDisabled ? style.disabled : ''}
			onClick={() => {
				if (!!selectedEngine && !!selectedEngine.installed) {
					ipc
						.openSessionFile(
							selectedEngine.installed.name,
							recentFile.name,
							recentFile.fileSource
						)
						.catch((err) => {
							console.error('Session file failed to launch: %o', err);
							notification.error(
								errorNotification('Failed to launch session file!')
							);
						});
				}
			}}
			title={
				<div className={style.cardTitle}>
					<div
						style={{
							display: 'inline-flex',
							opacity: isDisabled ? '0.5' : '1.0',
						}}
					>
						{recentFile.image === null ? (
							<IconEmptyCube size={24} />
						) : (
							recentFile.image
						)}
					</div>
					<Tooltip title={recentFile.name} mouseEnterDelay={1}>
						<div
							style={{
								overflowX: 'hidden',
							}}
							className={isDisabled ? style.disabled : ''}
						>
							{fileName}
						</div>
					</Tooltip>
				</div>
			}
		>
			<p style={{ fontSize: '11px' }}>{recentFile.timeStamp}</p>
		</McCard>
	);
};
