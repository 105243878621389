import { useId } from 'react';
import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const TemaGenericAppIcon: React.FC<SvgIconProps> = ({ size = '24' }) => {
	const clipId = useId();
	return (
		<SvgIcon size={size} actualSize={24}>
			<path
				d="M0.000244141 5.35759C0.000244141 2.39867 2.39892 0 5.35783 0H18.6353C21.5942 0 23.9929 2.39867 23.9929 5.35759V18.6351C23.9929 21.594 21.5942 23.9927 18.6353 23.9927H5.35783C2.39892 23.9927 0.000244141 21.594 0.000244141 18.6351V5.35759Z"
				fill="#13ADC8"
			/>
			<path
				d="M0.640869 5.71219C0.640869 2.91128 2.91146 0.640686 5.71237 0.640686H18.2809C21.0818 0.640686 23.3524 2.91127 23.3524 5.71219V18.2807C23.3524 21.0816 21.0818 23.3522 18.2809 23.3522H5.71237C2.91146 23.3522 0.640869 21.0816 0.640869 18.2807V5.71219Z"
				fill="#252B2E"
			/>
			<path
				d="M11.997 22.1437C11.7422 22.1432 11.4876 22.1263 11.235 22.0931C11.0826 22.0738 10.9344 22.0541 10.7904 22.0342L15.7002 13.5936L18.9807 19.3411C18.0852 20.1884 17.0472 20.8712 15.9145 21.3581C14.6766 21.8865 13.343 22.154 11.997 22.1437ZM9.12108 21.7184C7.65288 21.3055 6.30098 20.5564 5.17242 19.5305C4.0757 18.5518 3.22016 17.3327 2.67269 15.9685H12.4277L9.12108 21.7184ZM15.2207 9.62148H21.8537C21.9368 9.97596 22.0048 10.3397 22.0564 10.7051C22.2723 12.287 22.0894 13.8977 21.5244 15.3908C21.1663 16.3362 20.6884 17.2317 20.1023 18.0553L15.2207 9.62148ZM2.14054 14.3716C2.05739 14.0176 1.98981 13.6538 1.93888 13.288C1.87916 12.8601 1.84938 12.4285 1.84976 11.9964C1.8433 10.8385 2.04053 9.68852 2.43245 8.59898C2.78125 7.64152 3.27165 6.74178 3.8873 5.92976L8.77333 14.3716H2.14054ZM5.01324 4.65164C5.90918 3.80428 6.94749 3.1215 8.08051 2.63464C9.31809 2.10634 10.6514 1.83905 11.9969 1.84948C12.2159 1.84948 12.471 1.8621 12.7554 1.88694C12.9211 1.90169 13.0785 1.91782 13.2278 1.93536L8.29417 10.3991L5.01324 4.65164ZM11.5434 8.02467L14.898 2.27381C16.3632 2.68535 17.7116 3.43472 18.8348 4.46175C19.9254 5.44353 20.7761 6.66243 21.3215 8.02465L11.5434 8.02467Z"
				fill="#33393C"
			/>
			<g clipPath={`url(#${clipId})`}>
				<path
					d="M11.9124 5.3576V7.47829H16.5444V9.79177H11.9124V15.9611C11.9124 16.1731 12.0272 16.2714 12.1121 16.3148C12.1691 16.3437 12.2337 16.3466 12.2974 16.3466H13.2634V18.6601H11.9124C10.9329 18.6601 10.4803 18.4124 10.0895 17.889C9.69771 17.3655 9.59638 16.8296 9.59638 15.9611V9.79177H7.47339V7.47829H9.59638V5.3576H11.9124Z"
					fill="#13ADC8"
				/>
			</g>
			<defs>
				<clipPath id={clipId}>
					<rect
						width="9.10172"
						height="13.3025"
						fill="white"
						transform="translate(7.45435 5.3576)"
					/>
				</clipPath>
			</defs>
		</SvgIcon>
	);
};
