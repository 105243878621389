import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CrossedAvatarIcon: React.FC<SvgIconProps> = ({ size = '32' }) => (
	<SvgIcon actualSize={32} size={size}>
		<g clipPath="url(#clip0_8900_9815)">
			<circle
				cx="16"
				cy="16"
				r="15.1"
				stroke="var(--text-subtle)"
				strokeWidth="1.8"
			/>
			<mask id="path-2-inside-1_8900_9815" fill="white">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4 26.5827V25.9993C4 22.0093 11.995 19.9993 16 19.9993C20.005 19.9993 28 22.0093 28 25.9993V26.5826C25.0682 29.9043 20.7788 31.9994 16 31.9994C11.2212 31.9994 6.93178 29.9043 4 26.5827ZM16 6.66602C12.685 6.66602 10 9.35102 10 12.666C10 15.981 12.685 18.666 16 18.666C19.315 18.666 22 15.981 22 12.666C22 9.35102 19.315 6.66602 16 6.66602Z"
				/>
			</mask>
			<path
				d="M4 26.5827H2V27.339L2.50052 27.9061L4 26.5827ZM28 26.5826L29.4995 27.9061L30 27.339V26.5826H28ZM6 26.5827V25.9993H2V26.5827H6ZM6 25.9993C6 25.7364 6.11583 25.3581 6.66825 24.8483C7.23426 24.3259 8.11424 23.8114 9.24187 23.3597C11.5003 22.4549 14.2475 21.9993 16 21.9993V17.9993C13.7475 17.9993 10.4947 18.5488 7.75438 19.6465C6.38264 20.1961 5.01356 20.9322 3.95534 21.9089C2.88354 22.8981 2 24.2673 2 25.9993H6ZM16 21.9993C17.7525 21.9993 20.4997 22.4549 22.7581 23.3597C23.8858 23.8114 24.7657 24.3259 25.3317 24.8483C25.8842 25.3581 26 25.7364 26 25.9993H30C30 24.2673 29.1165 22.8981 28.0447 21.9089C26.9864 20.9322 25.6174 20.1961 24.2456 19.6465C21.5053 18.5488 18.2525 17.9993 16 17.9993V21.9993ZM26 25.9993V26.5826H30V25.9993H26ZM26.5005 25.2592C23.9323 28.169 20.1811 29.9994 16 29.9994V33.9994C21.3764 33.9994 26.2042 31.6397 29.4995 27.9061L26.5005 25.2592ZM16 29.9994C11.8189 29.9994 8.06771 28.169 5.49948 25.2592L2.50052 27.9061C5.79585 31.6397 10.6236 33.9994 16 33.9994V29.9994ZM12 12.666C12 10.4556 13.7896 8.66602 16 8.66602V4.66602C11.5804 4.66602 8 8.24645 8 12.666H12ZM16 16.666C13.7896 16.666 12 14.8764 12 12.666H8C8 17.0856 11.5804 20.666 16 20.666V16.666ZM20 12.666C20 14.8764 18.2104 16.666 16 16.666V20.666C20.4196 20.666 24 17.0856 24 12.666H20ZM16 8.66602C18.2104 8.66602 20 10.4556 20 12.666H24C24 8.24645 20.4196 4.66602 16 4.66602V8.66602Z"
				fill="var(--text-subtle)"
				mask="url(#path-2-inside-1_8900_9815)"
			/>
			<line
				y1="-1.25"
				x2="41.7253"
				y2="-1.25"
				transform="matrix(-0.695022 -0.718988 -0.718988 0.695022 30 32)"
				stroke="var(--text-subtle)"
				strokeWidth="2.5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8900_9815">
				<rect width="32" height="32" fill="white" />
			</clipPath>
		</defs>
	</SvgIcon>
);
