import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO } from 'api';
import { templatesService } from 'services/TemplatesService';

import { RootState } from 'store';
import { TemplatesResponse } from 'types/response/TemplatesResponse';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface PublishedTemplatesState {
	templates: TemplatesResponse;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: PublishedTemplatesState = {
	templates: {} as TemplatesResponse,
	loading: true,
	error: null,
};

interface PubTemplatesParams {
	templatesCriteria?: string;
	enginesId?: number;
	page: number;
	size: number;
}

export const getPublishedTemplates = createAppAsyncThunk(
	'publishedTemplates/getTemplates',
	(
		{ templatesCriteria, enginesId, page, size }: PubTemplatesParams,
		{ rejectWithValue }
	) =>
		templatesService
			.getPublishedTemplatesList(page, size, templatesCriteria, enginesId)
			.catch(getErrorMessageAnd(rejectWithValue))
);

const publishedTemplatesSlice = createSlice({
	name: 'publishedTemplates',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPublishedTemplates.pending, setLoadingState)
			.addCase(getPublishedTemplates.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.templates = payload.data;
			})

			.addMatcher(isAnyOf(getPublishedTemplates.rejected), (state, action) => {
				state.error = action.payload ?? rejectionError;
				state.loading = false;
			});
	},
});

export const publishedTemplatesState = (state: RootState) =>
	state.publishedTemplates;

export default publishedTemplatesSlice.reducer;
