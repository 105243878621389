import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { tagService } from './../../services/TagsService';
import { RootState } from 'store';
import { ErrorDTO, TagDto } from 'api';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface TagslistState {
	list?: Array<TagDto>;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: TagslistState = {
	list: undefined,
	loading: false,
	error: null,
};

export const getTags = createAppAsyncThunk(
	'tags/getTags',
	(params, { rejectWithValue }) =>
		tagService.getTagsList().catch(getErrorMessageAnd(rejectWithValue))
);

type CreateTagParams = Parameters<typeof tagService.createTag>;
export const addTag = createAppAsyncThunk(
	'tags/addTag',
	(params: CreateTagParams, { rejectWithValue }) =>
		tagService.createTag(...params).catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getTags.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			})
			.addCase(addTag.pending, setLoadingState)
			.addCase(addTag.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})
			.addMatcher(isAnyOf(getTags.pending, addTag.pending), setLoadingState)
			.addMatcher(
				isAnyOf(getTags.rejected, addTag.rejected),
				(state, action) => {
					console.error('[Tags] %o error: %o', action.type, action.payload);
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const tagsState = (state: RootState) => state.tags;

export default tagsSlice.reducer;
