import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import modulesService from 'services/ModulesService';
import { ModulesResponse } from 'types/response/ModulesResponse';

import { RootState } from 'store';
import { getRequestError, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';
import { ErrorDTO } from 'api';

export interface ModulesState {
	modules: ModulesResponse;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: ModulesState = {
	modules: {} as ModulesResponse,
	loading: true,
	error: null,
};

interface GetModulesParams {
	page: number;
	searchedValue: string;
	size: number;
}

export const getModules = createAppAsyncThunk(
	'modules/getModules',
	({ page, searchedValue, size }: GetModulesParams, { rejectWithValue }) =>
		modulesService
			.getModulesList(page, size, searchedValue)
			.catch((e) => rejectWithValue(getRequestError(e)))
);

export const resetState = createAction('RESET');

const modulesSlice = createSlice({
	name: 'modules',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getModules.pending, (state) => {
				state.loading = true;
				state.error = null;
			})

			.addCase(getModules.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.modules = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(isAnyOf(getModules.rejected), (state, action) => {
				state.error = action.payload ?? rejectionError;
				state.loading = false;
			});
	},
});

export const modulesState = (state: RootState) => state.modules;

export default modulesSlice.reducer;
