import { Dispatch, useMemo, useState } from 'react';
import pageStyle from './ActivationWizardPages.module.scss';
import { McButton } from 'components/mc';
import { App, Result, Tooltip } from 'antd';
import { InputDate, InputField, TagField, dateFormat } from 'components/fields';
import { SupportIcon2, UploadIcon } from 'assets/icons/svg';
import { CmContainer, ipc, isDesktop } from 'desktop';
import {
	ActivationWizardState,
	OfflineActivationOption,
} from '../ActivationWizard';
import {
	ContainerType,
	FullDataLicenseDto,
	LicenseRequestDto,
	LicenseRequestProgressDto,
	RequestType,
	SubscriptionDto,
} from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import { errorNotification } from 'utils/Notifications';
import McSpinner from 'components/MotionCloudSpinner';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { capitalize } from 'utils';
import { getDetailedErrorMessage } from '../ActivationWizardError';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
	container: CmContainer;
	changeDisplayPage: Dispatch<ActivationWizardState>;
	licenses: FullDataLicenseDto[];
	subscription: SubscriptionDto;
	setDisplayActivationWizard: Dispatch<boolean>;
	setLicenseRequestProgressDto: Dispatch<LicenseRequestProgressDto | undefined>;
	requestType: RequestType;
	deselectAllLicenses?: () => void;
	offlineActivationOption: OfflineActivationOption | undefined;
	importedContextFile: Blob | undefined;
}

type ActivationState = 'idle' | 'pending' | 'success' | 'error';

const DefineActivationPage = ({
	container,
	changeDisplayPage,
	licenses,
	subscription,
	setDisplayActivationWizard,
	requestType,
	deselectAllLicenses,
	offlineActivationOption,
	importedContextFile,
	setLicenseRequestProgressDto,
}: Props) => {
	const [date, setDate] = useState<string | undefined>(undefined);
	const { modal, notification } = App.useApp();

	const queryClient = useQueryClient();

	const [activationState, setActivationState] =
		useState<ActivationState>('idle');

	const [activationProgressText, setActivationProgressText] = useState<
		string | undefined
	>(undefined);

	const [activationErrorMessage, setActivationErrorMessage] = useState<
		string | undefined
	>(undefined);

	const isManualMode = !!offlineActivationOption && !!importedContextFile;

	const [downloadableUpdate, setDownloadableUpdate] = useState<
		Blob | undefined
	>(undefined);

	const [showContinue, setShowContinue] = useState<boolean>(false);

	const handleActivateLicensesAutomatically = async ({
		subscription,
		container,
		licenses,
		expiration,
		requestType,
	}: {
		subscription: SubscriptionDto;
		container: CmContainer;
		licenses: FullDataLicenseDto[];
		expiration: string;
		requestType: RequestType;
	}) => {
		try {
			setActivationState('pending');
			setActivationErrorMessage(undefined);
			setActivationProgressText(
				`Starting license ${licenseActionDescription.action}...`
			);
			const context = await createContext(container);

			const containerType: ContainerType =
				container.container_type === 'Act'
					? 'SOFTWARE'
					: container.container_type === 'Dongle'
					? 'DONGLE'
					: 'NA';

			if (containerType === 'NA') {
				notification.error(
					errorNotification(
						'Unsupported container type: ' + container.container_type
					)
				);
				return;
			}

			const requestDto: LicenseRequestDto = {
				licenseIds: licenses.map((license) => license.id),
				containerSerial: container.identifier, // OBS! explain why not container.serial here!
				expiryDate: expiration,
				requestType: requestType,
				containerType: containerType,
			};

			const progressDto = await activateLicenses({
				subscription: subscription,
				requestDto: requestDto,
				context: context,
			});
			setLicenseRequestProgressDto(progressDto);

			const updateText = Uint8ArrayToString(
				base64ToByteArray(progressDto.base64EncodedData)
			);

			await importUpdate({ container: container, update: updateText });

			const receipt = await createReceipt(container);

			await completeRequest({
				subscriptionId: subscription.id,
				licenseRequestId: progressDto.licenseRequestId,
				receipt: receipt,
			});

			setActivationState('success');
			queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
		} catch (err) {
			setActivationState('error');
		}
	};

	const handleActivateLicensesManually = async ({
		subscription,
		container,
		licenses,
		expiration,
		requestType,
		context,
	}: {
		subscription: SubscriptionDto;
		container: CmContainer;
		licenses: FullDataLicenseDto[];
		expiration: string;
		requestType: RequestType;
		context: Blob;
	}) => {
		try {
			setActivationState('pending');
			setActivationErrorMessage(undefined);
			setActivationProgressText(
				`Starting license ${licenseActionDescription.action}...`
			);
			const containerType: ContainerType =
				container.container_type === 'Act'
					? 'SOFTWARE'
					: container.container_type === 'Dongle'
					? 'DONGLE'
					: 'NA';

			if (containerType === 'NA') {
				notification.error(
					errorNotification(
						'Unsupported container type: ' + container.container_type
					)
				);
				setActivationErrorMessage(
					'Unsupported container type: ' + container.container_type
				);
				setActivationState('error');
				return;
			}

			const requestDto: LicenseRequestDto = {
				licenseIds: licenses.map((license) => license.id),
				containerSerial: container.identifier,
				expiryDate: expiration,
				requestType: requestType,
				containerType: containerType,
			};

			const progressDto = await activateLicenses({
				subscription: subscription,
				requestDto: requestDto,
				context: context,
			});

			setLicenseRequestProgressDto(progressDto);

			const updateText = Uint8ArrayToString(
				base64ToByteArray(progressDto.base64EncodedData)
			);
			setDownloadableUpdate(new Blob([updateText]));
			setActivationState('success');
			queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
		} catch (err) {
			setActivationState('error');
		}
	};

	const { mutateAsync: createContext } = useMutation({
		mutationFn: (container: CmContainer) =>
			ipc
				.createContextFile(container.mask, container.serial)
				.then((res) => new Blob([String.fromCharCode.apply(String, res)])),
		onMutate: () => setActivationProgressText('Creating context file...'),
		onError: (err: any) =>
			setActivationErrorMessage('Failed to create context file!'),
	});

	const { mutateAsync: importUpdate } = useMutation({
		mutationFn: ({
			container,
			update,
		}: {
			container: CmContainer;
			update: string;
		}) => ipc.importUpdateFile(container.mask, container.serial, update),
		onMutate: () => setActivationProgressText('Importing update file...'),
		onError: (err: any) =>
			setActivationErrorMessage('Failed to import update file!'),
	});

	const { mutateAsync: createReceipt } = useMutation({
		mutationFn: (container: CmContainer) =>
			ipc
				.createContextFile(container.mask, container.serial)
				.then((res) => new Blob([String.fromCharCode.apply(String, res)])),
		onMutate: () => setActivationProgressText('Creating receipt file...'),
		onError: (err: any) =>
			setActivationErrorMessage('Failed to create receipt file!'),
	});

	const { mutateAsync: activateLicenses } = useMutation({
		mutationFn: ({
			subscription,
			requestDto,
			context,
		}: {
			subscription: SubscriptionDto;
			requestDto: LicenseRequestDto;
			context: Blob;
		}) =>
			subscriptionsService
				.activateLicensesForm(subscription.id, requestDto, context)
				.then((res) => res.data),
		onError: (err: unknown) =>
			setActivationErrorMessage(getDetailedErrorMessage(err)),
		onMutate: () => setActivationProgressText('Sending activation request...'),
	});

	const { mutateAsync: completeRequest } = useMutation({
		mutationFn: ({
			subscriptionId,
			licenseRequestId,
			receipt,
		}: {
			subscriptionId: number;
			licenseRequestId: number;
			receipt: Blob;
		}) =>
			subscriptionsService.completeRequestForm(
				subscriptionId,
				licenseRequestId,
				receipt
			),
		onMutate: () => setActivationProgressText('Sending receipt...'),
		onError: (err: unknown) =>
			setActivationErrorMessage(getDetailedErrorMessage(err)),
	});

	const base64ToByteArray = (base64String: string) => {
		const binaryString = window.atob(base64String); // Decode Base64 string to binary string
		const byteArray = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++) {
			byteArray[i] = binaryString.charCodeAt(i);
		}
		return byteArray;
	};

	const Uint8ArrayToString = (uint8Array: Uint8Array) => {
		return new TextDecoder().decode(uint8Array);
	};

	const minimumSelectableExtensionExpiry: string = useMemo(
		() =>
			dayjs(
				licenses
					.map((license) => license.expiryDate)
					.filter((expiry) => !!expiry && expiry.length > 0)
					.map((expiry) => dayjs(expiry))
					.reduce((a, b) => {
						return b.isAfter(dayjs(a)) ? b.format(dateFormat) : a;
					}, licenses.at(0)?.expiryDate as string)
			)
				.add(1, 'day') // Add 1 day to the license with the highest expiry date so all the licenses gets expired to something greater than their current value
				.format(dateFormat),
		[licenses]
	);

	const licenseActionDescription: {
		past: string;
		present: string;
		future: string;
		action: string;
	} = useMemo(() => {
		if (requestType === 'DEACTIVATION') {
			return {
				past: 'deactivated',
				present: 'deactivating',
				future: 'deactivate',
				action: 'deactivation',
			};
		} else if (requestType === 'EXTEND_ACTIVATION') {
			return {
				past: 'extended',
				present: 'extending',
				future: 'extend',
				action: 'extension',
			};
		} else {
			return {
				past: 'activated',
				present: 'activating',
				future: 'activate',
				action: 'activation',
			};
		}
	}, [requestType]);

	const exportUpdateFile = async (update: Blob, container: CmContainer) => {
		try {
			const blobText = await update.text();
			const fileName: string = `Cm-${container.container_type}-${container.mask}-${container.serial}.WibuCmRaU`;
			var url = window.URL.createObjectURL(update);
			const tempLink = document.createElement('a');
			tempLink.href = url;
			tempLink.setAttribute('download', fileName);
			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(url);
			setShowContinue(true);
			if (isDesktop) {
				await ipc.saveFile(fileName, blobText);
			}
		} catch (err) {
			notification.error(errorNotification('Failed to export file!'));
		}
	};

	return (
		<div className={pageStyle.pageContainer}>
			{activationState === 'idle' && (
				<>
					<div className={pageStyle.pageContent}>
						<div className={pageStyle.pageTitle}>
							Define {licenseActionDescription.action}
						</div>
						<div
							style={{
								marginBottom: '8px',
								marginTop: '12px',
							}}
							className={pageStyle.divider}
						/>
						<div
							style={{
								width: '100%',
								marginBottom: '24px',
							}}
							className={pageStyle.pageText}
						>
							Verify your requested activation, and set the requested expiry
							date (not required while deactivating), when the license will be
							automatically free and available for new activations. By pressing
							the "{licenseActionDescription.future}" button the licenses will
							be {licenseActionDescription.past}.
							{requestType === 'ACTIVATION' ||
							requestType === 'EXTEND_ACTIVATION'
								? 'To release an activated license before the expiry date, a "Deactivation" request must be processed.'
								: ''}
						</div>
						<InputField
							value={container.identifier}
							readOnly
							label={'Container'}
						/>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								gap: '12px',
								alignItems: 'center',
							}}
						>
							<TagField
								values={licenses.map((license) => license.moduleName)}
								label="Modules"
							/>
							<Tooltip
								title={`These are the modules you are about to ${licenseActionDescription.future} for the selected container!`}
							>
								<div className={pageStyle.supportIcon}>
									<SupportIcon2 />
								</div>
							</Tooltip>
						</div>
						{requestType !== 'DEACTIVATION' && (
							<InputDate
								label={`Expiration date (UTC)`}
								value={date}
								setDate={setDate}
								isUtc
								max={subscription.renewalDate}
								min={
									requestType === 'EXTEND_ACTIVATION' && licenses.length > 0
										? minimumSelectableExtensionExpiry
										: undefined
								}
								minToday={requestType === 'ACTIVATION' ? true : false}
								timeRemainingSuffix
								timeRemainingIncludeTarget
							/>
						)}
					</div>
					<div
						style={{
							marginTop: '12px',
							justifyContent: 'space-between',
						}}
						className={pageStyle.buttonBar}
					>
						<McButton
							onClick={() => {
								changeDisplayPage(
									isManualMode ? 'ImportContext' : 'SelectContainer'
								);
							}}
						>
							Back
						</McButton>
						<McButton
							primary
							disabled={
								(requestType !== 'DEACTIVATION' && !date) || // FIXME: add subscription expiration as one of the alternatives here aswell
								!licenses ||
								(!!licenses && licenses.length === 0) ||
								!container
							}
							onClick={() =>
								modal.confirm({
									title: `Are you sure you want to ${licenseActionDescription.future} the selected licenses?`,
									centered: true,
									onOk: () => {
										if (isManualMode) {
											if (!importedContextFile) return;
											handleActivateLicensesManually({
												subscription: subscription,
												container: container,
												licenses: licenses,
												expiration: date!,
												requestType: requestType,
												context: importedContextFile,
											});
										} else {
											handleActivateLicensesAutomatically({
												subscription: subscription,
												container: container,
												licenses: licenses,
												expiration: date!,
												requestType: requestType,
											});
										}
									},
								})
							}
						>
							{capitalize(licenseActionDescription.action)}
						</McButton>
					</div>
				</>
			)}
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					width: '100%',
					verticalAlign: 'middle',
					justifyContent: 'center',
					height: '100%',
					flex: 1,
				}}
			>
				{activationState === 'error' && (
					<Result
						status="error"
						title={activationErrorMessage ?? 'Uknown error!'}
						extra={
							<div
								style={{
									width: '100%',
									justifyContent: 'center',
									display: 'flex',
								}}
							>
								<McButton onClick={() => setActivationState('idle')}>
									Return
								</McButton>
							</div>
						}
					/>
				)}
				{activationState === 'pending' && (
					<Result
						icon={<McSpinner />}
						status="info"
						title="Pending..."
						subTitle={activationProgressText}
					/>
				)}
				{activationState === 'success' && !isManualMode && (
					<Result
						status="success"
						title={`Selected licenses ${licenseActionDescription.past} successfully!`}
						extra={
							<div
								style={{
									width: '100%',
									justifyContent: 'center',
									display: 'flex',
								}}
							>
								<McButton
									primary
									onClick={() => {
										deselectAllLicenses?.();
										setDisplayActivationWizard(false);
									}}
								>
									Return
								</McButton>
							</div>
						}
					/>
				)}
				{activationState === 'success' && isManualMode && (
					<Result
						status="success"
						title={`Selected licenses ${licenseActionDescription.past} successfully!`}
						subTitle={
							showContinue
								? `Once exported you can press ${
										requestType === 'DEACTIVATION' ? 'Continue' : 'Finish'
								  }`
								: requestType === 'DEACTIVATION'
								? 'Proceed to export the update file to the offline computer'
								: ''
						}
						extra={
							<div
								style={{
									width: '100%',
									justifyContent: 'center',
									display: 'flex',
									gap: '12px',
								}}
							>
								<McButton
									primary
									onClick={() => {
										exportUpdateFile(downloadableUpdate!, container);
									}}
								>
									<UploadIcon />
									Export update file
								</McButton>
								{showContinue && (
									<McButton
										onClick={() => {
											if (requestType === 'DEACTIVATION') {
												changeDisplayPage('SendReceipt');
											} else {
												queryClient.invalidateQueries({
													queryKey: ['subscriptions'],
												});
												setDisplayActivationWizard(false);
											}
										}}
									>
										{requestType === 'DEACTIVATION' ? 'Continue' : 'Finish'}
									</McButton>
								)}
							</div>
						}
					/>
				)}
			</div>
		</div>
	);
};

export default DefineActivationPage;
