import React, { forwardRef, useMemo, useEffect } from 'react';
import type { BaseSelectRef } from 'rc-select';
import style from 'assets/styles/components.module.scss';
import { Select, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { getTags, tagsState } from 'store/slices/tags';

interface OptionType {
	label: string;
	value: string;
}

interface SelectTagsProps {
	label?: string;
	value: string[];
	tabIndex?: number;
	onChange?:
		| ((value: string[], option: OptionType | OptionType[]) => void)
		| undefined;
	error?: boolean;
	errorMessage?: string;
}

export type SelectTagsRef = React.Ref<BaseSelectRef> | undefined | null;

export const SelectTags: React.FC<SelectTagsProps> = forwardRef<
	SelectTagsRef,
	SelectTagsProps
>((props, ref) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getTags());
	}, [dispatch]);

	const { list, loading } = useAppSelector(tagsState);

	const options: OptionType[] = useMemo(
		() =>
			loading || !list
				? []
				: list.map((tag) => ({
						label: tag.name,
						value: `${tag.id}`,
				  })),
		[loading, list]
	);

	const notFound = (
		<div
			style={{
				color: 'var(--add-red)',
				fontWeight: '600',
			}}
		>
			No such tag
		</div>
	);

	return (
		<>
			<div
				className={style.inputFieldWrapper}
				style={{ marginBottom: '1.5rem' }}
			>
				<span className={style.inputFieldLabel}>{props.label}</span>
				<Select
					mode="tags"
					tabIndex={props.tabIndex}
					value={props.value}
					tagRender={(props) => (
						<Tag
							closable={props.closable}
							onClose={props.onClose}
							className={style.tagMultiSelectField}
						>
							{props.label}
						</Tag>
					)}
					notFoundContent={notFound}
					getPopupContainer={(trigger) => trigger.parentNode}
					placeholder={'Please select'}
					onChange={props.onChange}
					options={options}
					allowClear={true}
					className={style.multiSelectField}
					style={{
						border: props.error ? '1.5px solid var(--add-red)' : '',
						marginBottom: '-24px',
					}}
					// ref={ref}
				/>
			</div>
			<div className={style.errorMessage}>{props.errorMessage}</div>
		</>
	);
});
