import React, { FC } from 'react';

const McSpinner: FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="200"
			height="200"
			fill="none"
			style={{ maxWidth: '100%', maxHeight: '100%' }}
		>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,362.13494531250007,109.39493139648431"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M159.8 85.6c-6.5-23-27.7-39.9-52.8-39.9-18 0-34 8.7-44.1 22.1-13.3-4.4-26.6 6.6-29.3 19.6-13.5 5-23.3 17.3-23.3 32.1 0 19.2 16.3 34.7 36.2 34.7h107c19.9 0 36.2-15.4 36.2-34.7 0-16.9-14.4-31.9-29.1-33.8-.2 0-.5 0-.8-.1z"
				clipRule="evenodd"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,362.13494531250007,109.39493139648431; 0,109.39493139648431,362.13494531250007,0; 362.13494531250007,109.39493139648431,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,39.21830273437501,11.847195617675775"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M112.1 115.8l9.8-11.5L145.3 77"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,39.21830273437501,11.847195617675775; 0,11.847195617675775,39.21830273437501,0; 39.21830273437501,11.847195617675775,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,39.54670898437501,11.946401672363274"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M121.9 104.3L116.8 90l-12.2-34.2"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,39.54670898437501,11.946401672363274; 0,11.946401672363274,39.54670898437501,0; 39.54670898437501,11.946401672363274,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,39.77343750000001,12.014892578124993"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M116.8 90l-15-2.6-36-6.4"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,39.77343750000001,12.014892578124993; 0,12.014892578124993,39.77343750000001,0; 39.77343750000001,12.014892578124993,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,39.14164453125001,11.82403845214843"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M147.5 122l-35.4-6.2-14.8-2.6"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,39.14164453125001,11.82403845214843; 0,11.82403845214843,39.14164453125001,0; 39.14164453125001,11.82403845214843,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,39.443534179687504,11.915234283447258"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M68.5 126.5L92 98.8l9.8-11.4"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,39.443534179687504,11.915234283447258; 0,11.915234283447258,39.443534179687504,0; 39.443534179687504,11.915234283447258,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,39.461162109375,11.920559387207025"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M109.8 147l-12.5-33.8L92 98.8"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,39.461162109375,11.920559387207025; 0,11.920559387207025,39.461162109375,0; 39.461162109375,11.920559387207025,0,0"
				></animate>
			</path>
			<path
				stroke="#13ADC8"
				strokeDasharray="0,0,220.55540625000003,66.62611230468747"
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M106.7 147.2c25.239 0 45.7-20.461 45.7-45.7 0-25.24-20.461-45.7-45.7-45.7-25.24 0-45.7 20.46-45.7 45.7 0 25.239 20.46 45.7 45.7 45.7z"
			>
				<animate
					attributeName="stroke-dasharray"
					attributeType="XML"
					dur="2.272727272727273s"
					keyTimes="0; 0.23199999999999987; 1"
					repeatCount="indefinite"
					values="0,0,220.55540625000003,66.62611230468747; 0,66.62611230468747,220.55540625000003,0; 220.55540625000003,66.62611230468747,0,0"
				></animate>
			</path>
		</svg>
	);
};

export default McSpinner;
