import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ApplicationReleaseDto, ErrorDTO } from 'api';
import { templatesService } from 'services/TemplatesService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';

export interface AllAppsShortInfoState {
	allAppReleasesShortInfo: ApplicationReleaseDto[];
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: AllAppsShortInfoState = {
	allAppReleasesShortInfo: [] as ApplicationReleaseDto[],
	loading: true,
	error: null,
};

export const getAllAppReleasesShortInfo = createAppAsyncThunk(
	'allAppsShortInfo/getAllAppsShortInfo',
	(_, { rejectWithValue }) =>
		templatesService
			.getAllApplicationsForTemplate()
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const allAppReleasesShortInfoSlice = createSlice({
	name: 'allAppsShortInfo',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getAllAppReleasesShortInfo.pending, (state) => {
				state.loading = true;
				state.error = null;
			})

			.addCase(getAllAppReleasesShortInfo.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.allAppReleasesShortInfo = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(getAllAppReleasesShortInfo.rejected),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const allAppReleasesShortInfoState = (state: RootState) =>
	state.allAppReleasesShortInfo;

export default allAppReleasesShortInfoSlice.reducer;
