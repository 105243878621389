/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ErrorDTO } from '../model';
import { NotificationCategory } from '../model';
import { PageDtoNotificationDto } from '../model';
import { UpdateNotificationStatusDto } from '../model';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all notifications for the current user
         * @param {NotificationCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllUserNotifications: async (category?: NotificationCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the notification with the corresponding notification id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationById: async (notificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling deleteNotificationById.');
            }
            const localVarPath = `/api/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get count of unread notifications for specified user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsCounter: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/users/{userId}/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of Notifications with pagination , filtering by notification category
         * @param {number} page 
         * @param {number} size 
         * @param {NotificationCategory} [category] 
         * @param {boolean} [onlyUnread] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNotifications: async (page: number, size: number, category?: NotificationCategory, onlyUnread?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling listNotifications.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling listNotifications.');
            }
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (onlyUnread !== undefined) {
                localVarQueryParameter['onlyUnread'] = onlyUnread;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark all notifications user owns as read
         * @param {NotificationCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllRead: async (category?: NotificationCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/markAllRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification status for user
         * @param {UpdateNotificationStatusDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationStatus: async (body: UpdateNotificationStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNotificationStatus.');
            }
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes all notifications for the current user
         * @param {NotificationCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllUserNotifications(category?: NotificationCategory, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).deleteAllUserNotifications(category, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Deletes the notification with the corresponding notification id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotificationById(notificationId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).deleteNotificationById(notificationId, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get count of unread notifications for specified user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationsCounter(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).getUnreadNotificationsCounter(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of Notifications with pagination , filtering by notification category
         * @param {number} page 
         * @param {number} size 
         * @param {NotificationCategory} [category] 
         * @param {boolean} [onlyUnread] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(page: number, size: number, category?: NotificationCategory, onlyUnread?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoNotificationDto>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).listNotifications(page, size, category, onlyUnread, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Mark all notifications user owns as read
         * @param {NotificationCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAllRead(category?: NotificationCategory, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).markAllRead(category, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update notification status for user
         * @param {UpdateNotificationStatusDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationStatus(body: UpdateNotificationStatusDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await NotificationsApiAxiosParamCreator(configuration).updateNotificationStatus(body, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Deletes all notifications for the current user
         * @param {NotificationCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllUserNotifications(category?: NotificationCategory, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NotificationsApiFp(configuration).deleteAllUserNotifications(category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the notification with the corresponding notification id
         * @param {number} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotificationById(notificationId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NotificationsApiFp(configuration).deleteNotificationById(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get count of unread notifications for specified user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationsCounter(options?: AxiosRequestConfig): Promise<AxiosResponse<number>> {
            return NotificationsApiFp(configuration).getUnreadNotificationsCounter(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of Notifications with pagination , filtering by notification category
         * @param {number} page 
         * @param {number} size 
         * @param {NotificationCategory} [category] 
         * @param {boolean} [onlyUnread] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNotifications(page: number, size: number, category?: NotificationCategory, onlyUnread?: boolean, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoNotificationDto>> {
            return NotificationsApiFp(configuration).listNotifications(page, size, category, onlyUnread, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark all notifications user owns as read
         * @param {NotificationCategory} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAllRead(category?: NotificationCategory, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NotificationsApiFp(configuration).markAllRead(category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification status for user
         * @param {UpdateNotificationStatusDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationStatus(body: UpdateNotificationStatusDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return NotificationsApiFp(configuration).updateNotificationStatus(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Deletes all notifications for the current user
     * @param {NotificationCategory} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public async deleteAllUserNotifications(category?: NotificationCategory, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NotificationsApiFp(this.configuration).deleteAllUserNotifications(category, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes the notification with the corresponding notification id
     * @param {number} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public async deleteNotificationById(notificationId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NotificationsApiFp(this.configuration).deleteNotificationById(notificationId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get count of unread notifications for specified user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public async getUnreadNotificationsCounter(options?: AxiosRequestConfig) : Promise<AxiosResponse<number>> {
        return NotificationsApiFp(this.configuration).getUnreadNotificationsCounter(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of Notifications with pagination , filtering by notification category
     * @param {number} page 
     * @param {number} size 
     * @param {NotificationCategory} [category] 
     * @param {boolean} [onlyUnread] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public async listNotifications(page: number, size: number, category?: NotificationCategory, onlyUnread?: boolean, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoNotificationDto>> {
        return NotificationsApiFp(this.configuration).listNotifications(page, size, category, onlyUnread, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Mark all notifications user owns as read
     * @param {NotificationCategory} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public async markAllRead(category?: NotificationCategory, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NotificationsApiFp(this.configuration).markAllRead(category, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update notification status for user
     * @param {UpdateNotificationStatusDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public async updateNotificationStatus(body: UpdateNotificationStatusDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return NotificationsApiFp(this.configuration).updateNotificationStatus(body, options).then((request) => request(this.axios, this.basePath));
    }
}
