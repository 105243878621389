import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import appsService from 'services/ApplicationsService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { PagedRequestParams } from 'types';
import { ErrorDTO, PageDtoApplicationDto } from 'api';
import { createAppAsyncThunk } from 'utils/rtk';

export interface AppsState {
	apps: PageDtoApplicationDto | undefined;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: AppsState = {
	apps: undefined,
	loading: true,
	error: null,
};

export const getApps = createAppAsyncThunk(
	'applications/getApps',
	({ page, searchedValue, size }: PagedRequestParams, { rejectWithValue }) =>
		appsService
			.getApplicationsList(page, size, searchedValue)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getAllApps = createAppAsyncThunk(
	'licensing/getAllApplications',
	(_, { dispatch }) => dispatch(getApps({ page: 0, size: 99 }))
);

export const resetState = createAction('RESET');

const appsSlice = createSlice({
	name: 'applications',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getApps.pending, (state) => {
				state.loading = true;
				state.error = null;
			})

			.addCase(getApps.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.apps = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(isAnyOf(getApps.rejected), (state, action) => {
				state.error = action.payload ?? rejectionError;
				state.loading = false;
			});
	},
});

export const appsState = (state: RootState) => state.applications;

export default appsSlice.reducer;
