import React from 'react';
import { SearchIcon } from 'assets/icons/svg';
import style from 'assets/styles/components.module.scss';

interface SearchFieldProps {
	placeholder: string;
	value?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(
	({ placeholder, value, onChange, onFocus, onBlur }, ref) => {
		return (
			<div className={style.searchField}>
				<SearchIcon zoomResize />
				<input
					className={style.inputSearchField}
					type="search"
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					ref={ref}
				/>
			</div>
		);
	}
);
