import { SvgIcon, SvgIconProps } from './SvgIcon';

export const SearchIcon: React.FC<SvgIconProps> = ({
	size = '24',
	zoomResize,
}) => (
	<SvgIcon actualSize={24} size={size} zoomResize={zoomResize}>
		<path
			d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
			stroke="#89969F"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.9999 21.0004L16.6499 16.6504"
			stroke="#89969F"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
