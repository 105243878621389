import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	FC,
	MouseEvent,
	ReactNode,
} from 'react';

interface Props {
	icon: ReactNode;
	marked?: boolean;
}
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, Props {}

const clearProps: { [K in keyof Props]-?: any } = {
	icon: undefined,
	marked: undefined,
};

const McIconButtonComponent: FC<ButtonProps> = (props) => {
	const buttonProps = { ...props, ...clearProps };

	const cssClasses = ['mc-iconButton', props.marked ? 'mc-marked' : ''].join(
		' '
	);

	const onClick = props.onClick
		? (e: MouseEvent<HTMLButtonElement>) => {
				e.stopPropagation();
				props.onClick!(e);
		  }
		: undefined;

	return (
		<button {...buttonProps} onClick={onClick} className={cssClasses}>
			{props.icon ?? props.children}
		</button>
	);
};

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement>, Props {
	disabled?: boolean;
}
export const McIconLink: FC<LinkProps> = (props) => {
	const cssClasses = [
		'mc-iconButton',
		props.disabled ? 'mc-disabled' : '',
		props.marked ? 'mc-marked' : '',
	].join(' ');
	const anchorProps = { ...props, ...clearProps, disabled: undefined };

	return (
		<a {...anchorProps} className={cssClasses}>
			{props.icon ?? props.children}
		</a>
	);
};

export const McIconButton =
	McIconButtonComponent as typeof McIconButtonComponent & {
		Link: typeof McIconLink;
	};
McIconButton.Link = McIconLink;
