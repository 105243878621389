import { useEffect, useState } from 'react';

interface Opts {
	skipWhenClear?: boolean;
}
export const useDebounce = <V>(value: V, delay: number, opts?: Opts) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		if (opts?.skipWhenClear && !value) {
			setDebouncedValue(value);
			return () => {};
		}
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay, opts]);
	return debouncedValue;
};
