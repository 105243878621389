import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO, ModuleSaveDto } from 'api';
import { modulesService } from 'services/ModulesService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface ModuleState {
	module: Module;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: ModuleState = {
	module: {} as Module,
	loading: true,
	error: null,
};

export const deleteModule = createAppAsyncThunk(
	'oneModule/deleteModule',
	async ({ id }: { id: number }, { rejectWithValue }) =>
		modulesService
			.deleteModuleItemById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const addModule = createAppAsyncThunk(
	'oneModule/addModule',
	async (dto: ModuleSaveDto, { rejectWithValue }) =>
		modulesService.createModule(dto).catch(getErrorMessageAnd(rejectWithValue))
);

export const updateModule = createAppAsyncThunk(
	'oneModule/updateModule',
	({ id, dto }: { id: number; dto: ModuleSaveDto }, { rejectWithValue }) =>
		modulesService
			.updateModuleItem(dto, id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOneModule = createAppAsyncThunk(
	'oneModule/getOneModule',
	async ({ id }: { id: number }, { rejectWithValue }) =>
		modulesService.getModuleById(id).catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const oneModuleSlice = createSlice({
	name: 'oneModule',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addModule.pending, setLoadingState)
			.addCase(addModule.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(updateModule.pending, setLoadingState)
			.addCase(updateModule.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteModule.pending, setLoadingState)
			.addCase(deleteModule.fulfilled, (state) => {
				state.loading = false;
				state.module = {} as Module;
				state.error = null;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(
					addModule.rejected,
					updateModule.rejected,
					deleteModule.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const oneModuleState = (state: RootState) => state.oneModule;

export default oneModuleSlice.reducer;
