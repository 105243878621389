import { useInfiniteQuery } from '@tanstack/react-query';
import { App, Spin } from 'antd';
import { FeatureFlagDto } from 'api';
import { AlertCircle } from 'assets/icons/AlertCircle';
import style from 'assets/styles/manageElements.module.scss';
import { AddButton } from 'components/buttons';
import { SearchField } from 'components/fields/SearchField';
import { McButton } from 'components/mc';
import { useDebounce } from 'hooks/useDebounce';
import EditFeatureFlagForm from 'modules/ManageFeatureFlags/EditFeatureFlagForm';
import ManageOneFeatureFlag from 'modules/ManageFeatureFlags/ManageOneFeatureFlag';
import { useEffect, useMemo, useState } from 'react';
import featureFlagService from 'services/FeatureFlagService';
import { mcErrorNotification } from 'utils/Notifications';

const ManageFeatureFlags = () => {
	const [showAddFeatureFlagForm, setShowAddFeatureFlagForm] =
		useState<boolean>(false);
	const [searchedValue, setSearchedValue] = useState('');
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;
	const { notification } = App.useApp();
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);

	const {
		data: featureFlagPages,
		error: getFeatureFlagPagesError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['feature-flags', 'infinite', debouncedSearchTerm],
		queryFn: ({ pageParam: pageNr }) =>
			featureFlagService
				.getPaginatedFeatureFlags(pageNr, PAGE_SIZE, debouncedSearchTerm)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const featureFlags: FeatureFlagDto[] = useMemo(
		() =>
			!!featureFlagPages
				? featureFlagPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as FeatureFlagDto[]
				  )
				: [],
		[featureFlagPages]
	);

	const featureFlagsCount =
		!!featureFlagPages && featureFlagPages.pages.length > 0
			? featureFlagPages.pages[0].allElements
			: 0;

	useEffect(() => {
		if (!getFeatureFlagPagesError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				getFeatureFlagPagesError,
				'fetch',
				'feature flags'
			)
		);
	}, [getFeatureFlagPagesError, notification]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Feature Flags</h1>
				<>
					<div className={style.amountInfo}>
						{`Displaying ${featureFlags.length} out of ${featureFlagsCount} feature flags`}
					</div>
					<div className={style.searchAddFields}>
						<div style={{ flex: 'auto' }} className={style.searchFieldWrapper}>
							<SearchField
								placeholder={'Search'}
								value={searchedValue}
								onChange={(e) => {
									setSearchedValue(e.target.value);
								}}
							/>
						</div>
						<AddButton
							onClick={() => setShowAddFeatureFlagForm((prev) => !prev)}
						/>
					</div>
				</>

				<div style={{ marginTop: '2rem' }}>
					{showAddFeatureFlagForm && (
						<EditFeatureFlagForm
							hideForm={() => setShowAddFeatureFlagForm(false)}
							featureFlag={'new'}
						/>
					)}
				</div>
				{featureFlagsCount !== 0 && (
					<h3 className={style.listTitle}>Feature flag toggles</h3>
				)}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading && featureFlagsCount === 0 && searchedValue !== '' && (
							<div className={style.noElementsInfo}>
								There are no feature flags to show here
								<AlertCircle />
							</div>
						)}
						{featureFlagsCount !== 0 && (
							<div className={style.itemsContainer}>
								{featureFlags.map((featureFlag) => {
									return (
										<div key={featureFlag.id}>
											<ManageOneFeatureFlag
												featureFlag={featureFlag}
												selectedToUpdate={selectedToUpdate}
												setSelectedToUpdate={setSelectedToUpdate}
											/>
										</div>
									);
								})}
							</div>
						)}
					</Spin>
				</div>
				{featureFlagsCount !== 0 && hasNextPage && (
					<div
						className={style.paginationContainer}
						onClick={() => fetchNextPage()}
					>
						<McButton disabled={isLoading || isFetching}>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};

export default ManageFeatureFlags;
