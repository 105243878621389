import { useEffect, useState } from 'react';
import { IconClose } from 'assets/icons/svg';
import { App, Switch } from 'antd';
import { McButton } from './mc';
import { enable, disable, isEnabled } from 'tauri-plugin-autostart-api';
import { errorNotification } from 'utils/Notifications';

export const PreferencesPopoverContent = ({
	setVisible,
}: {
	setVisible: (value: boolean) => void;
}) => {
	const handleClose = () => {
		setVisible(false);
	};

	const [autostart, setAutostart] = useState<boolean>(false);
	const { notification } = App.useApp();

	useEffect(() => {
		isEnabled()
			.then((res) => setAutostart(res))
			.catch(() =>
				console.error(
					'Something went wrong while trying to read autostart value'
				)
			);
	}, []);

	const onChange = () => {
		autostart
			? disable()
					.then(() => setAutostart(false))
					.catch(() =>
						notification.error(
							errorNotification('Failed to disable autostart!')
						)
					)
			: enable()
					.then(() => setAutostart(true))
					.catch(() =>
						notification.error(errorNotification('Failed to enable autostart!'))
					);
	};

	return (
		<div className="PreferencesPopover popoverSettings-content">
			<div className="closePopover" onClick={handleClose}>
				<IconClose />
			</div>
			<div className="preferences-wrapper flex">
				<div className="flex-col w-full">
					<h4 className="title">Login settings</h4>
					<div className="mc-switch">
						<Switch checked={autostart} onChange={onChange} />
						<p>Launch Motion Cloud on login</p>
					</div>
					<McButton type="submit" primary>
						Save
					</McButton>
				</div>
			</div>
		</div>
	);
};
