import { FC, ReactNode } from 'react';
import 'assets/styles/ThinCard.scss';

interface ThinCardProps {
	buttons?: ReactNode;
	children?: ReactNode;
}

export const ThinCard: FC<ThinCardProps> = (props) => {
	return (
		<div className="thinCard-container">
			<div className="title-container">{props.children}</div>
			<div className="buttons-container">{props.buttons}</div>
		</div>
	);
};
