import React, { Dispatch, SetStateAction } from 'react';

import { EditUserForm } from './EditUserForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import {
	IconAvatar,
	IconEdit,
	IconTrash,
	MoreVertical,
} from 'assets/icons/svg';
import { UserDto } from 'api/model';
import { capitalize } from 'utils';
import { getPublicImageURL } from 'services/ApiService';
import { getRequestError } from 'utils/errors';
import { App, Tooltip } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import usersService from 'services/UsersService';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface ManageOneUserProps {
	user: UserDto;
	isCurAdmin: boolean;
	setSelectedToUpdate: Dispatch<SetStateAction<string | null>>;
	selectedToUpdate: string | null;
}

export const ManageOneUser: React.FC<ManageOneUserProps> = ({
	user,
	isCurAdmin,
	setSelectedToUpdate,
	selectedToUpdate,
}) => {
	const queryClient = useQueryClient();
	const { modal, notification } = App.useApp();

	const styles =
		selectedToUpdate === user.email
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
					fontSize: '0.875rem',
			  }
			: {
					color: 'var(--text-normal)',
					fontSize: '0.875rem',
			  };

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();
		if (!user.email) return;

		if (selectedToUpdate === null) {
			setSelectedToUpdate(user.email);
		} else {
			if (selectedToUpdate === user.email) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(user.email);
			}
		}
	};

	const deleteUserHandler = useMutation({
		mutationFn: (email: string) => usersService.deleteUserByEmail(email),
		onSuccess: () => {
			notification.success(
				deleteSuccessNotification(user.firstName + ' ' + user.lastName)
			);
			queryClient.invalidateQueries({
				queryKey: ['users'],
			});
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			notification.error(
				mcErrorNotification(
					'Error',
					error,
					'delete',
					`user${
						errorDto.code === 'ENTITY_OWNS_RESOURCES' &&
						errorDto.target === 'subscription'
							? '! The user is still connected to a subscription'
							: ''
					}`
				)
			);
		},
	});

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.profileItem} style={{ width: '25%' }}>
					<Tooltip title={'user id'}>
						<div style={{ color: 'var(--text-disabled)' }}>{user.id}</div>
					</Tooltip>
					<div style={{ transform: 'scale(0.85)' }}>
						{!user.avatarPath ? (
							<IconAvatar />
						) : (
							<img
								className={style.userAvatar}
								alt=""
								src={getPublicImageURL(user.avatarPath)}
							/>
						)}
					</div>
					<div className={style.userNameItem}>
						<span>{user.firstName}</span>
						<span>{user.lastName}</span>
					</div>
				</div>
				<div className={style.textItem} style={{ width: '25%' }}>
					{user.email}
				</div>
				<div className={style.textItem} style={{ width: '5%' }}>
					{capitalize(user.role!)}
				</div>
				<div className={style.textItem} style={{ width: '20%' }}>
					<div>{user.company}</div>
				</div>
				<div className={style.statusItem} style={{ width: '10%' }}>
					<div
						className={style.statusCircle}
						style={{
							background:
								user.status === 'VERIFIED'
									? 'var(--add-green)'
									: user.status === 'ACTIVE'
									? 'var(--primary)'
									: user.status === 'INACTIVE'
									? 'var(--add-red)'
									: user.status === 'PENDING'
									? 'var(--add-orange)'
									: 'var(--add-orange)', // A fallback color if status doesn't match any of the above
						}}
					></div>
					{capitalize(user.status!)}
				</div>

				<div className={style.buttonsItem} style={{ width: '12%' }}>
					{!isCurAdmin ? (
						<div style={{ cursor: 'pointer' }} onClick={clickEditBtnHandler}>
							<IconEdit />
						</div>
					) : (
						<div style={{ opacity: '0' }}>
							<IconEdit />
						</div>
					)}

					{!isCurAdmin ? (
						<div
							style={{ cursor: 'pointer' }}
							onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
								e.preventDefault();
								modal.confirm(
									deleteItemModal(
										() => deleteUserHandler.mutate(user.email),
										user.firstName + ' ' + user.lastName
									)
								);
							}}
						>
							<IconTrash />
						</div>
					) : (
						<div style={{ opacity: '0' }}>
							<IconTrash />
						</div>
					)}
					<div style={{ cursor: 'auto' }}>
						<MoreVertical />
					</div>
				</div>
			</ItemContainer>
			{selectedToUpdate === user.email && (
				<EditUserForm user={user} onHide={() => setSelectedToUpdate(null)} />
			)}
		</div>
	);
};
