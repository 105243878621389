import * as yup from 'yup';

export const signupSchema = yup.object().shape({
	firstName: yup.string().required('This field is required'),
	lastName: yup.string().required('This field is required'),
	email: yup
		.string()
		.required('This field is required')
		.email('Please enter a valid email address'),
	company: yup.string().required('This field is required'),
	department: yup.string(),
	passkey: yup.string().trim().required('This field is required'),
});

export const loginSchema = yup.object().shape({
	email: yup
		.string()
		.required('This field is required')
		.email('Please enter a valid email address'),
});

export const resetPassSchema = yup.object().shape({
	email: yup
		.string()
		.required('This field is required')
		.email('Please enter a valid email address'),
});

export const setPassSchema = yup.object().shape({
	password: yup
		.string()
		.required('This field is required')
		.min(8, 'Password must be 8 characters long')
		.matches(/[0-9]/, 'Password requires a number')
		.matches(/[a-z]/, 'Password requires a lowercase letter')
		.matches(/[A-Z]/, 'Password requires an uppercase letter'),
	confirmPassword: yup
		.string()
		.required('This field is required')
		.min(8, 'Password must be 8 characters long')
		.matches(/[0-9]/, 'Password requires a number')
		.matches(/[a-z]/, 'Password requires a lowercase letter')
		.matches(/[A-Z]/, 'Password requires an uppercase letter')
		.oneOf([yup.ref('password'), null], 'Passwords do not match'),
});

export const appReleaseSchema = yup.object().shape({
	version: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	applicationId: yup.number().required('Picking an application is required!'),
	description: yup
		.string()
		.trim()
		.notRequired()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.max(1000, 'Max 1000 characters'),
});

export const featureFlagSchema = yup.object().shape({
	key: yup
		.string()
		.trim()
		.required('This field is required')
		.max(100, 'Max 100 characters'),
	description: yup.string().trim().max(1000, 'Max 1000 characters'),
});

export const engineSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	releaseDate: yup.string().required('This field is required'),
	description: yup
		.string()
		.trim()
		.notRequired()
		.max(2000, 'Max 2000 characters'),
	releaseNotes: yup
		.string()
		.trim()
		.notRequired()
		.max(2000, 'Max 2000 characters'),
	downloadLink: yup
		.string()
		.trim()
		.notRequired()
		.max(255, 'Max 255 characters'),

	version: yup.string().trim().notRequired().max(255, 'Max 255 characters'),
});

// Both for editing and adding
export const editUserSchema = yup.object().shape({
	firstName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	lastName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	email: yup
		.string()
		.required('This field is required')
		.max(255)
		.email('Please enter a valid email address'),
	company: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	// prettier-ignore
	department: yup
		.string()
		.trim()
		.max(255, 'Max 255 characters'),
});

export const newsSchema = yup.object().shape({
	title: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	link: yup.string().trim().notRequired().max(350, 'Max 350 characters'),
	description: yup
		.string()
		.trim()
		.nullable()
		.required('This field is required')
		.transform((curr, orig) => (orig === '' ? null : curr))
		.max(1500, 'Max 1500 characters'),
});

export const addTemplateSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.required('This field is required')
		.max(50, 'Max 50 characters'),
	description: yup
		.string()
		.trim()
		.nullable()
		.required('This field is required')
		.transform((curr, orig) => (orig === '' ? null : curr))
		.max(400, 'Max 400 characters'),
	application: yup.number().nullable(),
});

export const editTemplateSchema = yup.object().shape({
	editName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(50, 'Max 50 characters'),
	editDescription: yup
		.string()
		.trim()
		.nullable()
		.required('This field is required')
		.transform((curr, orig) => (orig === '' ? null : curr))
		.max(400, 'Max 400 characters'),
	editApplication: yup.number().nullable(),
});

export const editProfileSchema = yup.object().shape({
	firstName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(40, 'Max 40 characters'),
	lastName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(40, 'Max 40 characters'),
});

export const editModuleSchema = yup.object().shape({
	editName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(30, 'Max 30 characters'),
	editProductCode: yup.number().integer().required('This field is required'),
});

export const addApplicationSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.required('This field is required')
		.max(30, 'Max 30 characters'),
	code: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	modules: yup.array().required('This field is required'),
});

export const editApplicationSchema = yup.object().shape({
	editName: yup
		.string()
		.trim()
		.required('This field is required')
		.max(30, 'Max 30 characters'),
	editCode: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	editModules: yup.array().required('This field is required'),
});

export const addSubscriptionSchema = yup.object().shape({
	subscriptionId: yup
		.string()
		.trim()
		.required('This field is required')
		.max(30, 'Max 30 characters'),
	renewalDate: yup.string().required('This field is required'),
});
export const editSubscriptionSchema = yup.object().shape({
	subscriptionId: yup
		.string()
		.trim()
		.required('This field is required')
		.max(30, 'Max 30 characters'),
	renewalDate: yup.string().required('This field is required'),
	adminNotes: yup.string().trim().max(1000, 'Max 1000 characters'),
});

export const containerSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.required('This field is required')
		.max(30, 'Max 30 characters'),
});

export const customerActivationRequestSchema = yup.object().shape({
	requestFile: yup.string().trim().required('This field is required'),
	activationUntilDate: yup.string().required('This field is required'),
});

export const customerExtendActivationRequestSchema = yup.object().shape({
	requestFile: yup.string().trim().required('This field is required'),
	activationUntilDate: yup.string().required('This field is required'),
});

export const customerDeactivationRequestSchema = yup.object().shape({
	requestFile: yup.string().trim().required('This field is required'),
});

export const supportItemSchema = yup.object().shape({
	question: yup
		.string()
		.trim()
		.required('This field is required')
		.max(100, 'Max 100 characters'),
	answer: yup.string().trim().notRequired().max(2000, 'Max 2000 characters'),
	itemType: yup.string().typeError('Item Type is required'),
});

export const editOrganizationSchema = yup.object().shape({
	name: yup
		.string()
		.trim()
		.required('This field is required')
		.max(255, 'Max 255 characters'),
	email: yup
		.string()
		.trim()
		.max(255, 'Max 255 characters')
		.email('Please enter a valid email address'),
});
