import { ThemeConfig } from 'antd/es/config-provider/context';

export type ThemeColors = Record<
	| 'primary'
	| 'primaryDark'
	| 'surface'
	| 'textNormal'
	| 'textSubtitle'
	| 'textPrimary'
	| 'textDisabled'
	| 'bg'
	| 'blue'
	| 'red'
	| 'orange'
	| 'green'
	| 'borderPopup'
	| 'borderInput'
	| 'bgHover',
	string
>;

const rem = 16;

export const createTheme = (
	algorithm: ThemeConfig['algorithm'],
	colors: ThemeColors
): ThemeConfig => ({
	algorithm,
	token: {
		colorPrimary: colors.primary,
		colorBgBase: colors.surface,
		colorTextBase: colors.textNormal,
		colorBgContainer: colors.bg,
		colorSuccess: colors.green,
		colorInfo: colors.blue,
		colorError: colors.red,
		colorWarning: colors.orange,
		fontSize: 16,
		borderRadius: 16,
		wireframe: false,
		fontFamily: 'Inter, sans-serif',
		colorIcon: colors.textNormal,
	},
	components: {
		Card: {
			colorTextHeading: colors.textNormal,
			colorTextDescription: colors.textNormal,
		},
		Menu: {
			fontSizeIcon: 24,
			borderRadiusOuter: 0,
			borderRadius: 0,
			borderRadiusLG: 0,
			borderRadiusSM: 0,
			itemSelectedBg: 'transparent',
			radiusItem: 0,
			horizontalItemSelectedBg: 'transparent',
		},
		Select: {
			colorBorder: colors.borderInput,
			controlHeight: 48,
			controlHeightLG: 48,
			paddingContentHorizontalLG: 8,
			boxShadowSecondary: 'rgba(0, 0, 0, 0.6) 0px 2px 30px 0px',
			colorBgElevated: colors.bg,
			controlItemBgHover: colors.bgHover,
			colorFillContentHover: colors.textSubtitle,
		},
		Input: {
			borderRadiusLG: 50,
			borderRadius: 50,
			colorBorder: colors.borderInput,
			fontSizeLG: 16,
			controlHeight: 48,
			controlHeightLG: 48,
			paddingInlineLG: 20,
			colorIcon: colors.textNormal,
		},
		Button: {
			defaultBorderColor: colors.borderInput,
			defaultColor: colors.textNormal,
			colorIcon: colors.textNormal,
			controlHeightLG: 48,
			controlHeight: 48,
		},
		Popover: {
			borderRadiusLG: 1.5 * rem,
			colorBgElevated: colors.bg,
			colorBorder: colors.borderPopup,
		},
	},
});
