import { Dispatch } from 'react';
import pageStyle from './ActivationWizardPages.module.scss';
import { McButton } from 'components/mc';
import {
	ActivationWizardState,
	OfflineActivationOption,
} from '../ActivationWizard';
import { OfflineIcon, OnlineIcon } from 'assets/icons/svg';
import { CmContainer, isDesktop } from 'desktop';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { App } from 'antd';
import { infoNotification } from 'utils/Notifications';

interface Props {
	container?: CmContainer;
	changeDisplayPage: Dispatch<ActivationWizardState>;
	offlineActivationOption: OfflineActivationOption | undefined;
	setOfflineActivationOption: Dispatch<OfflineActivationOption | undefined>;
}

const OfflineActivationSelect = ({
	changeDisplayPage,
	offlineActivationOption,
	setOfflineActivationOption,
	container,
}: Props) => {
	const { user } = useAppSelector(authState);
	const isGuest = user.role === 'GUEST';
	const { notification } = App.useApp();
	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Offline Activation</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					Select if this computer is the:
					<ul style={{ marginTop: 0, marginBottom: '24px' }}>
						<li>
							Online computer activating the license against the license server
						</li>
						<li>
							Offline computer, which should receive the activated software.
						</li>
					</ul>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: '24px',
						marginBottom: '24px',
					}}
				>
					<div
						style={{
							height: '72px',
							width: '100%',
							backgroundColor: 'var(--bg)',
							borderRadius: '8px',
							display: 'flex',
							flexDirection: 'row', // TODO: refactor this and div below to separate stylesheet
							padding: '12px 16px',
							alignItems: 'center',
							cursor: isDesktop && isGuest ? 'default' : 'pointer',
							gap: '12px',
							userSelect: 'none',
							border:
								offlineActivationOption === 'OnlineComputer'
									? '2px #01B2D0 solid'
									: '2px var(--bg) solid',
							color:
								offlineActivationOption === 'OnlineComputer' ? '#01B2D0' : '',
							opacity: isDesktop && isGuest ? '0.4' : 'unset',
						}}
						onClick={() => {
							if (isDesktop && isGuest) return;
							setOfflineActivationOption(
								offlineActivationOption === 'OnlineComputer'
									? undefined
									: 'OnlineComputer'
							);
						}}
					>
						<OnlineIcon />
						<div>Online</div>
					</div>
					<div
						style={{
							height: '72px',
							width: '100%',
							backgroundColor: 'var(--bg)',
							borderRadius: '8px',
							display: 'flex',
							flexDirection: 'row',
							padding: '12px 16px',
							alignItems: 'center',
							cursor: 'pointer',
							gap: '12px',
							userSelect: 'none',
							border:
								offlineActivationOption === 'OfflineComputer'
									? '2px #01B2D0 solid'
									: '2px var(--bg) solid',
							color:
								offlineActivationOption === 'OfflineComputer' ? '#01B2D0' : '',
						}}
						onClick={() => {
							if (!container) {
								notification.info(
									infoNotification(
										'You must pick a container before you can select this option.' // FIXME: change this notification to a tooltip on click (or maybe hover)!
									)
								);
							} else {
								setOfflineActivationOption(
									offlineActivationOption === 'OfflineComputer'
										? undefined
										: 'OfflineComputer'
								);
							}
						}}
					>
						<OfflineIcon />
						<div>Offline</div>
					</div>
				</div>
			</div>
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton onClick={() => changeDisplayPage('SelectContainer')}>
					Back
				</McButton>
				<McButton
					primary
					onClick={() => {
						changeDisplayPage(
							offlineActivationOption === 'OnlineComputer'
								? 'ImportContext'
								: 'ExportContext'
						);
					}}
					disabled={offlineActivationOption === undefined}
				>
					Next
				</McButton>
			</div>
		</div>
	);
};

export default OfflineActivationSelect;
