import { useEffect, useRef, useState } from 'react';
import { SearchResults } from './SearchResults';
import { SearchField } from 'components/fields';

export const GlobalSearch = () => {
	const [query, setQuery] = useState('');
	const [focused, setFocused] = useState(false);
	const searchFieldRef = useRef<HTMLInputElement>(null);

	// Added because onMouseUp on search result items only sets the focused state, but not the actual focus on the input.
	useEffect(() => {
		focused ? searchFieldRef.current?.focus() : searchFieldRef.current?.blur();
	}, [focused]);

	return (
		<SearchResults
			query={query}
			fieldFocused={focused}
			setSearchBarFocus={setFocused}
		>
			<div className={`header-search ${focused ? 'headerClicked' : ''}`}>
				<SearchField
					placeholder={'Search'}
					value={query}
					onChange={(e) => setQuery(e.target.value)}
					onFocus={() => setFocused(true)}
					onBlur={() => {
						setFocused(false);
						setQuery('');
					}}
					ref={searchFieldRef}
				/>
			</div>
		</SearchResults>
	);
};
