import React, { useState } from 'react';

import { SaveButton } from 'components/buttons';
import { CancelButton } from 'components/buttons';
import style from 'assets/styles/editAddElementForm.module.scss';
import { PopUpWindow } from 'components/PopUpWindow';
import { InputField } from 'components/fields';
import { addTag } from 'store/slices/tags';
import { PostTagDto } from 'api';
import { useDispatch } from 'react-redux';
import {
	DisplayError,
	generateDisplayErrorMessage,
	getRequestError,
} from 'utils/errors';

interface AdTagProps {
	setShowAddTagsWindow: Dispatcher;
}

export const AddTagForm: React.FC<AdTagProps> = ({ setShowAddTagsWindow }) => {
	const [value, setValue] = useState<string>('');
	const [displayError, setDisplayError] = useState<DisplayError>();

	const dispatch = useDispatch();
	const addLicModulesHandler = () => {
		if (!value) {
			setDisplayError('This field is required!');
			return;
		}
		const tagDto: PostTagDto = {
			name: value,
		};
		dispatch(addTag([tagDto]) as any)
			.unwrap()
			.then(() => {
				setShowAddTagsWindow(false);
			})
			.catch((error: unknown) => {
				const errorDto = getRequestError(error);
				setDisplayError(generateDisplayErrorMessage(errorDto));
			});
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setShowAddTagsWindow(false);
	};

	return (
		<PopUpWindow title={'Add a Tag'}>
			<form className={style.addFormWrapper} onSubmit={addLicModulesHandler}>
				<div>
					<InputField
						placeholder={'Name'}
						tabIndex={1}
						label={'Name'}
						error={!!displayError}
						errorMessage={displayError}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setValue(e.target.value)
						}
						type="text"
						value={value}
					/>
				</div>
				<div className={style.buttonsWrapper}>
					<CancelButton tabIndex={30} onClickCancel={cancel} />
					<SaveButton tabIndex={20} />
				</div>
			</form>
		</PopUpWindow>
	);
};
