import React from 'react';
import style from 'assets/styles/components.module.scss';

interface ButtonProps {
	tabIndex?: number;
	onClickCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CancelButton: React.FC<ButtonProps> = ({
	tabIndex,
	onClickCancel,
}) => {
	return (
		<>
			<button
				type="button"
				className={style.cancelButton}
				tabIndex={tabIndex}
				onClick={onClickCancel}
			>
				Cancel
			</button>
		</>
	);
};
