import { useState, useEffect, FC, useMemo } from 'react';
import { ManageOneApplication } from 'modules/ManageApplications/ManageOneApplication';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import style from 'assets/styles/manageElements.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { McButton } from 'components/mc';
import { useInfiniteQuery } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';
import { ApplicationDto } from 'api';
import { mcErrorNotification } from 'utils/Notifications';
import { EditAppForm } from 'modules/ManageApplications/EditAppForm';

export const ManageApplications: FC = () => {
	const [showAddAppForm, setShowAddTypeForm] = useState(false);
	const [searchedValue, setSearchedValue] = useState('');
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;
	const { notification } = App.useApp();

	const {
		data: applicationPages,
		error: getApplicationsError,
		hasNextPage,
		isLoading,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['applications', 'infinite', debouncedSearchTerm],
		queryFn: ({ pageParam: pageNr }) =>
			applicationsService
				.getApplicationsList(pageNr, PAGE_SIZE, debouncedSearchTerm)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const applications = useMemo(
		() =>
			!applicationPages
				? []
				: applicationPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as ApplicationDto[]
				  ),
		[applicationPages]
	);

	const totalAppsCount =
		!applicationPages ||
		!applicationPages.pages ||
		applicationPages.pages.length < 1
			? 0
			: applicationPages.pages[0].allElements;

	useEffect(() => {
		if (!getApplicationsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getApplicationsError,
				'fetch',
				'application list'
			)
		);
	}, [getApplicationsError, notification]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Applications</h1>
				{!isLoading && searchedValue === '' && totalAppsCount === 0 ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							No Application found
							<AlertCircle />
						</div>
						<AddButton onClick={() => setShowAddTypeForm((prev) => !prev)} />
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{`Displaying ${applications.length} out of ${totalAppsCount} applications`}
						</div>
						<div className={style.searchAddFields}>
							<div
								style={{ flex: 'auto' }}
								className={style.searchFieldWrapper}
							>
								<SearchField
									placeholder={'Search'}
									value={searchedValue}
									onChange={(e) => {
										setSearchedValue(e.target.value);
									}}
								/>
							</div>
							<AddButton onClick={() => setShowAddTypeForm((prev) => !prev)} />
						</div>
					</>
				)}

				<div style={{ marginTop: '2rem' }}>
					{showAddAppForm && (
						<EditAppForm
							application={'new'}
							setShowAddApplicationForm={setShowAddTypeForm}
						/>
					)}
				</div>
				{totalAppsCount !== 0 && (
					<h3 className={style.listTitle}>Application List</h3>
				)}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading && totalAppsCount === 0 && searchedValue !== '' && (
							<div className={style.noElementsInfo}>
								No application found
								<AlertCircle />
							</div>
						)}
						<div className={style.itemsContainer}>
							{applications.map((application) => {
								return (
									<div key={application.id}>
										<ManageOneApplication
											application={application}
											setSelectedToUpdate={setSelectedToUpdate}
											selectedToUpdate={selectedToUpdate}
										/>
									</div>
								);
							})}
						</div>
					</Spin>
				</div>
				{totalAppsCount !== 0 && hasNextPage && (
					<div
						className={style.paginationContainer}
						onClick={() => fetchNextPage()}
					>
						<McButton>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};
