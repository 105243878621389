import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MotionButton } from 'components/MotionButton';

import style from 'assets/styles/notFoundPage.module.scss';

export const NotFound: FC = () => {
	return (
		<div className={style.container}>
			<div className={style.content}>
				<div className={style.image}></div>
				<p className={style.mainText}>That page doesn't exist</p>
				<p className={style.subtleText}>
					This is not the web page your are looking for
				</p>
				<Link to="/login" style={{ textDecoration: 'none' }}>
					<MotionButton
						shape="round"
						size="large"
						htmlType="submit"
						width="10rem"
						height="2.375rem"
						fontWeight="700"
					>
						Back home
					</MotionButton>
				</Link>
			</div>
		</div>
	);
};
