import React, {
	Dispatch,
	SetStateAction,
	ChangeEvent,
	useState,
	useEffect,
} from 'react';

import { EditLicensedModulesForm } from './EditLicensedModulesForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import { AlertTriangle, BranchIcon, IconTrash } from 'assets/icons/svg';
import { FullDataLicenseDto, SubscriptionDto } from 'api';
import { McCheckBox } from 'components/mc';
import { App, Tooltip } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	infoNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import licensesService from 'services/LicenseService';
import subscriptionsService from 'services/SubscriptionsService';

interface ManageOneLicenseProps {
	subscription: SubscriptionDto;
	license: FullDataLicenseDto;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	selectedToUpdate: number | null;
	showEditLicModulesWindow: boolean;
	setShowEditLicModulesWindow: Dispatcher;
}

export const ManageOneLicense: React.FC<ManageOneLicenseProps> = (props) => {
	const {
		subscription,
		license,
		setSelectedToUpdate,
		selectedToUpdate,
		showEditLicModulesWindow,
		setShowEditLicModulesWindow,
	} = props;

	const { notification, modal } = App.useApp();
	const queryClient = useQueryClient();

	const clickEditBtnHandler = (e: ChangeEvent<HTMLInputElement>) => {
		if (selectedToUpdate === null) {
			setSelectedToUpdate(license.id);
		} else {
			if (selectedToUpdate === license.id) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(license.id);
			}
		}
	};

	const { mutate: updateEnableStatus } = useMutation({
		mutationFn: (newEnableStatus: boolean) =>
			subscriptionsService.updateEnableLicense(license.id, newEnableStatus),
		onSuccess: (_, newEnableStatus: boolean) => {
			notification.success(
				infoNotification(
					`You successfully ${
						newEnableStatus ? 'enabled' : 'disabled'
					} the license!`
				)
			);
			queryClient.invalidateQueries({
				queryKey: ['subscriptions', subscription.id, 'licenses'],
			});
		},
		onError: (err: unknown, newEnableStatus: boolean) =>
			notification.error(
				mcErrorNotification(
					'Error',
					err,
					newEnableStatus ? 'enabled' : 'disabled',
					'license'
				)
			),
	});

	const { mutate: deleteLicense } = useMutation({
		mutationFn: () => licensesService.deleteLicenseItemById(license.id),
		onSuccess: () => {
			notification.success(deleteSuccessNotification());
			queryClient.invalidateQueries({
				queryKey: ['subscriptions', subscription.id, 'licenses'],
			});
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'delete', 'license')
			),
	});

	const [isExpired, setIsExpired] = useState<boolean>(false);

	useEffect(() => {
		if (!!license.expiryDate) {
			const expiryDate = new Date(license.expiryDate + 'T23:59:59.999Z');
			const now = new Date();

			setIsExpired(now > expiryDate);
		} else {
			setIsExpired(false);
		}
	}, [license.expiryDate]);

	const expiredColor: React.CSSProperties = isExpired
		? {
				color: 'var(--add-red)',
		  }
		: {};

	return (
		<div>
			<ItemContainer subscription={true}>
				<>
					<div className={style.textItem} style={{ width: '40%', gap: '8px' }}>
						<div>{license.moduleName}</div>
						<div
							style={{ color: 'var(--text-disabled)' }}
						>{`(id: ${license.id})`}</div>
						<div style={{ color: 'var(--add-red)' }}>
							{isExpired ? `(Expired)` : ''}
						</div>
						<div style={{ color: 'var(--add-red)' }}>
							{!license.enabled ? `(Disabled)` : ''}
						</div>
					</div>
					<div
						className={style.textItem}
						style={{
							width: '20%',
							...expiredColor,
						}}
					>
						{license.containerName ?? (
							<i style={{ opacity: 0.5 }}>No container</i>
						)}
					</div>
					<div
						className={style.textItem}
						style={{
							width: '30%',
							...expiredColor,
						}}
					>
						{(license.expiryDate ?? '') === '' ? (
							<i style={{ opacity: 0.5 }}>Not activated</i>
						) : (
							<>Activated until: {license.expiryDate}</>
						)}
					</div>
					<div className={style.buttonsItem} style={{ width: '10%' }}>
						<McCheckBox
							checked={selectedToUpdate === license.id}
							onChange={clickEditBtnHandler}
						/>
						{!!license.enabled ? (
							<Tooltip title={'Disable license'}>
								<div
									className={style.warningIcon}
									onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
										e.preventDefault();
										modal.confirm({
											title: 'Disable license item?',
											content: (
												<>
													Any attempts to start any <u>new</u> license requests
													that includes the following license will be rejected.
													Disabled licenses are <u>not</u> shown to customers.
													<br />
													<br />
													Customers can still complete already existing{' '}
													<i>pending</i> license requests that includes this
													license item!
												</>
											),
											onOk: () => {
												updateEnableStatus(false);
											},
											okType: 'danger',
											okText: 'Disable',
											maskClosable: true,
											centered: true,
										});
									}}
								>
									<AlertTriangle />
								</div>
							</Tooltip>
						) : (
							<Tooltip title={'Enable license'}>
								<div
									className={style.approveIcon}
									onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
										e.preventDefault();
										modal.confirm({
											title: 'Enable license item?',
											content: (
												<>
													The customer who owns this subscription will
													immediately gain access to this license again and,
													will be able to use it in new license requests.
												</>
											),
											onOk: () => {
												updateEnableStatus(true);
											},
											okType: 'danger',
											okText: 'Enable',
											maskClosable: true,
											centered: true,
										});
									}}
								>
									<BranchIcon />
								</div>
							</Tooltip>
						)}
						<Tooltip title={'Delete license'}>
							<div
								style={{ cursor: 'pointer' }}
								className={style.dangerIcon}
								onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
									e.preventDefault();
									modal.confirm(
										deleteItemModal(() => {
											deleteLicense();
										})
									);
								}}
							>
								<IconTrash />
							</div>
						</Tooltip>
					</div>
				</>
			</ItemContainer>
			{showEditLicModulesWindow && selectedToUpdate === license.id && (
				<EditLicensedModulesForm
					subscription={subscription}
					license={license}
					setSelectedToUpdate={setSelectedToUpdate}
					setShowEditLicModulesWindow={setShowEditLicModulesWindow}
				/>
			)}
		</div>
	);
};
