/* tslint:disable */
/* eslint-disable */
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export const UserStatusVals = {
    REVIEWING: 'REVIEWING',
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
} as const;

export type UserStatus = typeof UserStatusVals[keyof typeof UserStatusVals];

