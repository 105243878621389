import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { enginesService } from 'services/EnginesService';
import { PublishedEngineForTemplates } from 'types/response/EnginesResponse';

import { RootState } from 'store';
import { HomeEngine } from 'types';
import { InstalledEngine, ipc } from 'desktop';
import templatesService from 'services/TemplatesService';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';
import { ErrorDTO, EngineDto } from 'api';

export interface EnginesState {
	engines: EngineDto;
	homeEngines: HomeEngine[];
	installedEnginesForHome: InstalledEngine[];
	publishedEnginesForHome: EngineDto[];
	publishedEnginesForTemplates: PublishedEngineForTemplates[];
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: EnginesState = {
	engines: {} as EngineDto,
	publishedEnginesForHome: [],
	installedEnginesForHome: [],
	homeEngines: [],
	publishedEnginesForTemplates: [],
	loading: false,
	error: null,
};

export const getInstalledEngines = createAppAsyncThunk<InstalledEngine[]>(
	'engines/getInstalledEngines',
	(_, { rejectWithValue }) =>
		ipc.getInstalledEngines().catch(getErrorMessageAnd(rejectWithValue))
);

export const getPublishedEnginesForHome = createAppAsyncThunk<AxiosResponse>(
	'engines/getPublishedEnginesForHome',
	async (_, { rejectWithValue }) =>
		await enginesService
			.getPublishedEngines()
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getPublishedEnginesForTemplates =
	createAppAsyncThunk<AxiosResponse>(
		'engines/getPublishedEnginesForTemplates',
		(_, { rejectWithValue }) =>
			templatesService
				.getAllPublishedEnginesForTemplate()
				.catch(getErrorMessageAnd(rejectWithValue))
	);

const enginesSlice = createSlice({
	name: 'engines',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPublishedEnginesForHome.pending, setLoadingState)
			.addCase(getPublishedEnginesForHome.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.publishedEnginesForHome = payload.data;
				state.homeEngines = getHomeEngines(
					state.installedEnginesForHome,
					state.publishedEnginesForHome
				);
			})

			.addCase(getInstalledEngines.pending, setLoadingState)
			.addCase(getInstalledEngines.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.installedEnginesForHome = [...payload];
				state.installedEnginesForHome.sort(
					(a, b) => 0 - a.name.localeCompare(b.name)
				);
				state.homeEngines = getHomeEngines(
					state.installedEnginesForHome,
					state.publishedEnginesForHome
				);
			})

			.addCase(getPublishedEnginesForTemplates.pending, setLoadingState)
			.addCase(
				getPublishedEnginesForTemplates.fulfilled,
				(state, { payload }) => {
					state.loading = false;
					state.publishedEnginesForTemplates = payload.data;
				}
			)

			.addMatcher(
				isAnyOf(
					getPublishedEnginesForHome.rejected,
					getInstalledEngines.rejected,
					getPublishedEnginesForTemplates.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const enginesState = (state: RootState) => state.engines;

export default enginesSlice.reducer;

const getHomeEngines = (
	installed: InstalledEngine[],
	published: EngineDto[]
): HomeEngine[] => [
	...published.map((o) => {
		const installedEngine = installed.find((i) => i.name === o.name);
		return {
			...o,
			installed: installedEngine?.install_path,
			published: o.version,
			apps: installedEngine?.apps,
		};
	}),
	...installed
		.filter((l) => !published.some((o) => o.name === l.name))
		.map((l) => ({
			name: l.name,
			version: l.version,
			installed: l.install_path,
			apps: l.apps,
		})),
];

const setLoadingState = (state: EnginesState) => {
	state.loading = true;
	state.error = null;
};
