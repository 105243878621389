import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const T2DAppIcon: React.FC<SvgIconProps> = ({ size = '24' }) => (
	<SvgIcon size={size} actualSize={24}>
		<path
			d="M0 5.35759C0 2.39867 2.39867 0 5.35759 0H18.6351C21.594 0 23.9927 2.39867 23.9927 5.35759V18.6351C23.9927 21.594 21.594 23.9927 18.6351 23.9927H5.35759C2.39867 23.9927 0 21.594 0 18.6351V5.35759Z"
			fill="#00BF8C"
		/>
		<path
			d="M0.640625 5.71219C0.640625 2.91128 2.91121 0.640686 5.71213 0.640686H18.2806C21.0815 0.640686 23.3521 2.91127 23.3521 5.71219V18.2807C23.3521 21.0816 21.0815 23.3522 18.2806 23.3522H5.71213C2.91121 23.3522 0.640625 21.0816 0.640625 18.2807V5.71219Z"
			fill="#252B2E"
		/>
		<path
			d="M11.9965 22.1437C11.7417 22.1432 11.4871 22.1263 11.2345 22.0931C11.0821 22.0738 10.9339 22.0541 10.7899 22.0342L15.6997 13.5936L18.9802 19.3411C18.0847 20.1884 17.0467 20.8712 15.914 21.3581C14.6761 21.8865 13.3425 22.154 11.9965 22.1437ZM9.12059 21.7184C7.65239 21.3055 6.30049 20.5564 5.17193 19.5305C4.07521 18.5518 3.21968 17.3327 2.6722 15.9685H12.4272L9.12059 21.7184ZM15.2202 9.62148H21.8532C21.9363 9.97596 22.0043 10.3397 22.0559 10.7051C22.2718 12.287 22.0889 13.8977 21.524 15.3908C21.1659 16.3362 20.6879 17.2317 20.1018 18.0553L15.2202 9.62148ZM2.14005 14.3716C2.0569 14.0176 1.98932 13.6538 1.93839 13.288C1.87867 12.8601 1.84889 12.4285 1.84927 11.9964C1.84281 10.8385 2.04005 9.68852 2.43196 8.59898C2.78076 7.64152 3.27116 6.74178 3.88682 5.92976L8.77284 14.3716H2.14005ZM5.01275 4.65164C5.90869 3.80428 6.94701 3.1215 8.08002 2.63464C9.3176 2.10634 10.6509 1.83905 11.9965 1.84948C12.2154 1.84948 12.4705 1.8621 12.7549 1.88694C12.9206 1.90169 13.078 1.91782 13.2273 1.93536L8.29369 10.3991L5.01275 4.65164ZM11.5429 8.02467L14.8975 2.27381C16.3628 2.68535 17.7111 3.43472 18.8343 4.46175C19.9249 5.44353 20.7756 6.66243 21.321 8.02465L11.5429 8.02467Z"
			fill="#33393C"
		/>
		<path
			d="M6.04004 15.3146H10.5975V16.8338H3.47127V15.6322L7.31061 11.9863C8.24973 11.0886 8.41546 10.5223 8.41546 9.98372C8.41546 9.09984 7.80779 8.58885 6.62008 8.58885C5.68096 8.58885 4.89376 8.9065 4.35515 9.59702L3.09839 8.63028C3.84416 7.62211 5.18378 7.02826 6.772 7.02826C8.8712 7.02826 10.2246 8.09167 10.2246 9.80418C10.2246 10.7433 9.96224 11.5996 8.62261 12.8563L6.04004 15.3146Z"
			fill="#00BF8C"
		/>
		<path
			d="M12.1334 17.0088L12.9648 15.4639L13.8146 11.3964V8.72267L12.1334 7.1778H16.0907C19.0263 7.1778 21.0309 9.144 21.0309 12.0933C21.0309 15.0426 19.0263 17.0088 16.0907 17.0088H12.1334ZM12.9648 15.4639H16.0131C18.0305 15.4639 19.3367 14.1297 19.3367 12.0933C19.3367 10.0569 18.0305 8.72267 16.0131 8.72267H13.8146V11.3964L12.9648 15.4639Z"
			fill="#E4E4E4"
		/>
		<path
			d="M13.8146 8.72267L12.1334 7.1778L12.0896 11.3964H13.8146V8.72267Z"
			fill="#E4E4E4"
		/>
	</SvgIcon>
);
