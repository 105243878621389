import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const T2DDICAppIcon: React.FC<SvgIconProps> = ({ size = '24' }) => (
	<SvgIcon size={size} actualSize={24}>
		<path
			d="M0 5.35759C0 2.39867 2.39867 0 5.35759 0H18.6351C21.594 0 23.9927 2.39867 23.9927 5.35759V18.6351C23.9927 21.594 21.594 23.9927 18.6351 23.9927H5.35759C2.39867 23.9927 0 21.594 0 18.6351V5.35759Z"
			fill="#FF7E16"
		/>
		<path
			d="M0.640625 5.71219C0.640625 2.91128 2.91121 0.640686 5.71213 0.640686H18.2806C21.0815 0.640686 23.3521 2.91127 23.3521 5.71219V18.2807C23.3521 21.0816 21.0815 23.3522 18.2806 23.3522H5.71213C2.91121 23.3522 0.640625 21.0816 0.640625 18.2807V5.71219Z"
			fill="#252B2E"
		/>
		<path
			d="M11.9967 22.1437C11.7419 22.1433 11.4874 22.1264 11.2347 22.0932C11.0823 22.0739 10.9341 22.0542 10.7901 22.0343L15.6999 13.5937L18.9805 19.3411C18.0849 20.1885 17.0469 20.8713 15.9142 21.3581C14.6763 21.8866 13.3427 22.154 11.9967 22.1437ZM9.12084 21.7185C7.65264 21.3056 6.30074 20.5565 5.17218 19.5306C4.07545 18.5519 3.21992 17.3328 2.67244 15.9686H12.4274L9.12084 21.7185ZM15.2204 9.62154H21.8534C21.9366 9.97602 22.0046 10.3398 22.0561 10.7052C22.272 12.287 22.0892 13.8977 21.5242 15.3909C21.1661 16.3362 20.6882 17.2317 20.1021 18.0554L15.2204 9.62154ZM2.14029 14.3717C2.05714 14.0176 1.98956 13.6539 1.93863 13.2881C1.87891 12.8601 1.84914 12.4285 1.84952 11.9964C1.84306 10.8386 2.04029 9.68858 2.4322 8.59904C2.781 7.64158 3.27141 6.74184 3.88706 5.92982L8.77308 14.3717H2.14029ZM5.013 4.6517C5.90894 3.80434 6.94725 3.12156 8.08027 2.6347C9.31784 2.1064 10.6511 1.83911 11.9967 1.84954C12.2156 1.84954 12.4707 1.86216 12.7552 1.887C12.9208 1.90175 13.0783 1.91789 13.2275 1.93542L8.29393 10.3992L5.013 4.6517ZM11.5431 8.02473L14.8977 2.27387C16.363 2.68542 17.7114 3.43478 18.8346 4.46181C19.9251 5.44359 20.7758 6.66249 21.3213 8.02471L11.5431 8.02473Z"
			fill="#33393C"
		/>
		<path
			d="M5.01904 13.9174H7.67394V14.8023H3.52264V14.1024L5.7592 11.9785C6.30627 11.4555 6.40281 11.1257 6.40281 10.8119C6.40281 10.297 6.04882 9.99937 5.35694 9.99937C4.80987 9.99937 4.35129 10.1844 4.03753 10.5867L3.30542 10.0235C3.73986 9.43621 4.52024 9.09027 5.44543 9.09027C6.6683 9.09027 7.45673 9.70975 7.45673 10.7073C7.45673 11.2544 7.30387 11.7532 6.52349 12.4853L5.01904 13.9174Z"
			fill="#FF7E16"
		/>
		<path
			d="M8.19663 14.8992L8.68097 13.9992L9.17603 11.6297V10.0721L8.19663 9.17212H10.502C12.2122 9.17212 13.3799 10.3175 13.3799 12.0357C13.3799 13.7538 12.2122 14.8992 10.502 14.8992H8.19663ZM8.68097 13.9992H10.4568C11.6321 13.9992 12.393 13.222 12.393 12.0357C12.393 10.8493 11.6321 10.0721 10.4568 10.0721H9.17603V11.6297L8.68097 13.9992Z"
			fill="#E4E4E4"
		/>
		<path
			d="M9.17603 10.0721L8.19663 9.17212L8.17114 11.6297H9.17603V10.0721Z"
			fill="#E4E4E4"
		/>
		<path
			d="M13.9473 14.8194V9.25166H14.9813V14.8194H13.9473Z"
			fill="#FF7E16"
		/>
		<path
			d="M18.5557 14.899C16.8535 14.899 15.5809 13.6979 15.5809 12.0355C15.5809 10.3732 16.8535 9.17212 18.5637 9.17212C19.4704 9.17212 20.2499 9.49823 20.7669 10.1027L20.0988 10.7311C19.6931 10.2936 19.192 10.0789 18.6114 10.0789C17.458 10.0789 16.6229 10.8902 16.6229 12.0355C16.6229 13.1809 17.458 13.9922 18.6114 13.9922C19.192 13.9922 19.6931 13.7775 20.0988 13.332L20.7669 13.9684C20.2499 14.5729 19.4704 14.899 18.5557 14.899Z"
			fill="#FF7E16"
		/>
	</SvgIcon>
);
