import { Dispatch, useState } from 'react';
import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';
import { UploadIcon } from 'assets/icons/svg';
import { CmContainer, ipc } from 'desktop';
import { ActivationWizardState } from '../ActivationWizard';
import { useMutation } from '@tanstack/react-query';
import { App, Spin } from 'antd';

interface Props {
	changeDisplayPage: Dispatch<ActivationWizardState>;
	selectedContainer: CmContainer;
	setDisplayActivationWizard: Dispatch<boolean>;
}

const ExportReceiptPage = ({
	changeDisplayPage,
	selectedContainer,
	setDisplayActivationWizard,
}: Props) => {
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);

	const [isExporting, setIsExporting] = useState<boolean>(false);
	const { modal } = App.useApp();

	const exportReceiptFile = async (container: CmContainer) => {
		setErrorMessage(undefined);
		setIsExporting(true);
		try {
			const receipt = await createReceipt(container);
			const blobText = await receipt.text();
			const fileName: string = `Cm-${container.container_type}-${container.mask}-${container.serial}.WibuCmRaR`;
			var url = window.URL.createObjectURL(receipt);
			const tempLink = document.createElement('a');
			tempLink.href = url;
			tempLink.setAttribute('download', fileName);
			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(url);
			await ipc.saveFile(fileName, blobText);
		} catch (err) {
			setErrorMessage('Failed to export generated receipt file!');
		} finally {
			setIsExporting(false);
		}
	};

	const { mutateAsync: createReceipt, isPending } = useMutation({
		mutationFn: (container: CmContainer) =>
			ipc
				.createContextFile(container.mask, container.serial) // FIXME: changed to context instead of receipt!
				.then((res) => new Blob([String.fromCharCode.apply(String, res)])),
		onError: (err: any) => {
			setErrorMessage(
				'Failed to generate a receipt for the selected container!'
			);
		},
	});

	const disableButtons: boolean = isPending || isExporting;

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Export Receipt</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						marginBottom: '12px',
					}}
					className={pageStyle.pageSubTitle}
				>
					*If the request type was an activation or extension you can skip this
					part!*
				</div>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					In case the license request was a deactivation, you must first export
					a receipt file of the current container to the online computer for
					verification against motion cloud servers.
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '24px',
					gap: '12px',
					alignItems: 'center',
				}}
			>
				<McButton
					primary
					disabled={disableButtons}
					onClick={() => {
						if (!disableButtons) exportReceiptFile(selectedContainer);
					}}
				>
					<UploadIcon />
					Export receipt file
				</McButton>
				{disableButtons && <Spin spinning={disableButtons} />}
				{!!errorMessage && (
					<div
						style={{
							color: 'var(--add-red)',
							fontSize: '13px',
							fontWeight: '500',
						}}
					>
						{errorMessage}
					</div>
				)}
			</div>
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton
					onClick={() => {
						changeDisplayPage('ImportUpdate');
					}}
					disabled={disableButtons}
				>
					Back
				</McButton>

				<McButton
					primary
					onClick={() => {
						modal.confirm({
							centered: true,
							title:
								'If your request is a deactivation request, make sure the receipt file has been successfully exported first!',
							onOk: () => {
								setDisplayActivationWizard(false);
							},
							okText: 'Finish',
						});
					}}
					disabled={disableButtons}
				>
					Finish
				</McButton>
			</div>
		</div>
	);
};

export default ExportReceiptPage;
