import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const T3DDICAppIcon: React.FC<SvgIconProps> = ({ size = '24' }) => (
	<SvgIcon size={size} actualSize={24}>
		<path
			d="M0 5.35759C0 2.39867 2.39867 0 5.35759 0H18.6351C21.594 0 23.9927 2.39867 23.9927 5.35759V18.6351C23.9927 21.594 21.594 23.9927 18.6351 23.9927H5.35759C2.39867 23.9927 0 21.594 0 18.6351V5.35759Z"
			fill="#FFC114"
		/>
		<path
			d="M0.640625 5.71219C0.640625 2.91128 2.91121 0.640686 5.71213 0.640686H18.2806C21.0815 0.640686 23.3521 2.91127 23.3521 5.71219V18.2807C23.3521 21.0816 21.0815 23.3522 18.2806 23.3522H5.71213C2.91121 23.3522 0.640625 21.0816 0.640625 18.2807V5.71219Z"
			fill="#252B2E"
		/>
		<path
			d="M11.9965 22.1437C11.7417 22.1432 11.4871 22.1263 11.2345 22.0931C11.0821 22.0738 10.9339 22.0541 10.7899 22.0342L15.6997 13.5936L18.9802 19.3411C18.0847 20.1884 17.0467 20.8712 15.914 21.3581C14.6761 21.8865 13.3425 22.154 11.9965 22.1437ZM9.12059 21.7184C7.65239 21.3055 6.30049 20.5564 5.17193 19.5305C4.07521 18.5518 3.21968 17.3327 2.6722 15.9685H12.4272L9.12059 21.7184ZM15.2202 9.62148H21.8532C21.9363 9.97596 22.0043 10.3397 22.0559 10.7051C22.2718 12.287 22.0889 13.8977 21.524 15.3908C21.1659 16.3362 20.6879 17.2317 20.1018 18.0553L15.2202 9.62148ZM2.14005 14.3716C2.0569 14.0176 1.98932 13.6538 1.93839 13.288C1.87867 12.8601 1.84889 12.4285 1.84927 11.9964C1.84281 10.8385 2.04005 9.68852 2.43196 8.59898C2.78076 7.64152 3.27116 6.74178 3.88682 5.92976L8.77284 14.3716H2.14005ZM5.01275 4.65164C5.90869 3.80428 6.94701 3.1215 8.08002 2.63464C9.3176 2.10634 10.6509 1.83905 11.9965 1.84948C12.2154 1.84948 12.4705 1.8621 12.7549 1.88694C12.9206 1.90169 13.078 1.91782 13.2273 1.93536L8.29369 10.3991L5.01275 4.65164ZM11.5429 8.02467L14.8975 2.27381C16.3628 2.68535 17.7111 3.43472 18.8343 4.46175C19.9249 5.44353 20.7756 6.66243 21.321 8.02465L11.5429 8.02467Z"
			fill="#33393C"
		/>
		<path
			d="M6.05248 11.4665C7.11445 11.6033 7.65347 12.2389 7.65347 13.0836C7.65347 14.0329 6.93745 14.8535 5.42496 14.8535C4.62849 14.8535 3.83202 14.6122 3.30908 14.2019L3.75961 13.3893C4.16187 13.7272 4.7733 13.9444 5.40887 13.9444C6.15707 13.9444 6.59955 13.6146 6.59955 13.0917C6.59955 12.5848 6.22948 12.255 5.37669 12.255H4.8618V11.5389L6.14098 10.0184H3.58262V9.14148H7.42016V9.84141L6.05248 11.4665Z"
			fill="#FFC114"
		/>
		<path
			d="M8.13999 14.87L8.62433 13.97L9.11939 11.6004V10.0429L8.13999 9.14288H10.4453C12.1555 9.14288 13.3233 10.2883 13.3233 12.0064C13.3233 13.7245 12.1555 14.87 10.4453 14.87H8.13999ZM8.62433 13.97H10.4001C11.5754 13.97 12.3363 13.1927 12.3363 12.0064C12.3363 10.8201 11.5754 10.0429 10.4001 10.0429H9.11939V11.6004L8.62433 13.97Z"
			fill="#E4E4E4"
		/>
		<path
			d="M9.11939 10.0429L8.13999 9.14288L8.1145 11.6004H9.11939V10.0429Z"
			fill="#E4E4E4"
		/>
		<path
			d="M13.8906 14.7902V9.22242H14.9246V14.7902H13.8906Z"
			fill="#FFC114"
		/>
		<path
			d="M18.4991 14.8697C16.7969 14.8697 15.5243 13.6687 15.5243 12.0063C15.5243 10.3439 16.7969 9.14288 18.507 9.14288C19.4138 9.14288 20.1933 9.469 20.7103 10.0735L20.0421 10.7019C19.6365 10.2644 19.1354 10.0496 18.5547 10.0496C17.4014 10.0496 16.5662 10.8609 16.5662 12.0063C16.5662 13.1517 17.4014 13.963 18.5547 13.963C19.1354 13.963 19.6365 13.7482 20.0421 13.3028L20.7103 13.9391C20.1933 14.5436 19.4138 14.8697 18.4991 14.8697Z"
			fill="#FFC114"
		/>
	</SvgIcon>
);
