import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO } from 'api';
import applicationsService from 'services/ApplicationsService';
import { homeService } from 'services/HomeService';

import { RootState } from 'store';
import { HomeEngine } from 'types';
import { getRequestError, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface SelectedApplicationsState {
	applicationReleases: AppReleases;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: SelectedApplicationsState = {
	applicationReleases: [],
	loading: true,
	error: null,
};

interface SelectedAppsParams {
	engine?: HomeEngine;
	isGuest: boolean;
}

export const getSelectedApplications = createAppAsyncThunk(
	'selectedApplications/getApps',
	async ({ engine, isGuest }: SelectedAppsParams, { rejectWithValue }) => {
		try {
			if (!engine) return [];

			const publishedAppReleaes = isGuest
				? []
				: await homeService
						.getPublishedApplicationReleases(0, 100, engine.id)
						.then((r) => r.data.content);

			const allApplications = await applicationsService
				.getApplicationsList(0, 50)
				.then((res) => res.data.content);

			const appReleaseNames = new Set([
				...(engine.apps ?? []),
				...publishedAppReleaes.map(
					(rel) =>
						allApplications.find(
							(application) => application.id === rel.applicationId
						)?.name ?? ''
				),
			]);

			return Array.from(
				appReleaseNames,
				(name) =>
					publishedAppReleaes.find(
						(rel) =>
							allApplications.find(
								(application) => application.id === rel.applicationId
							)?.name === name
					) ??
					({
						id: -1,
						description: name,
						version: '',
						entityStatus: undefined,
					} as LocalOnlyApplication)
			);
		} catch (error) {
			return rejectWithValue(getRequestError(error));
		}
	}
);

const selectedApplicationsSlice = createSlice({
	name: 'selectedApplications',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSelectedApplications.pending, setLoadingState)
			.addCase(getSelectedApplications.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.applicationReleases = payload;
			})

			.addMatcher(
				isAnyOf(getSelectedApplications.rejected),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const selectedApplicationsState = (state: RootState) =>
	state.selectedApplications;

export default selectedApplicationsSlice.reducer;
