import {
	useState,
	Dispatch,
	SetStateAction,
	ChangeEvent,
	FC,
	useRef,
} from 'react';
import style from 'assets/styles/editAddElementForm.module.scss';
import { IconBigAvatar, IconCamera, IconWhiteTrash } from 'assets/icons/svg';
import Compressor from 'compressorjs';
import { getPublicImageURL } from 'services/ApiService';
import { App } from 'antd';
import { infoNotification } from 'utils/Notifications';

interface PictureProps {
	size?: string;
	selectedImage: Blob | undefined;
	setSelectedImage: Dispatch<SetStateAction<Blob | undefined>>;
	setCompressedFile?: Dispatch<SetStateAction<Blob | undefined>>;
	needToCompress?: boolean;
	existingImagePath?: string;
	isImageDeleted: boolean;
	setIsImageDeleted: Dispatch<SetStateAction<boolean>>;
	isAvatar: boolean;
	isAppForm?: boolean;
	onlyToDeleteAvatar?: boolean;
}

export const PictureField: FC<PictureProps> = ({
	selectedImage,
	setSelectedImage,
	setCompressedFile,
	needToCompress,
	existingImagePath,
	isImageDeleted,
	setIsImageDeleted,
	isAvatar,
	isAppForm,
	onlyToDeleteAvatar,
}) => {
	const [showEditPictureButtons, setShowEditPictureButtons] = useState(false);
	const { notification } = App.useApp();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleCompressedUpload = (image: File) => {
		new Compressor(image, {
			quality: 0.6,
			convertTypes: 'image/png',
			convertSize: 50000,
			success: (compressedResult) => {
				if (setCompressedFile) setCompressedFile(compressedResult);
			},
		});
	};

	const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const imgFile = event.target.files![0];

		if (!imgFile) return;

		if (isAvatar) {
			if (imgFile.size > 100_000) {
				notification.info(
					infoNotification(
						isAvatar
							? 'Image must be smaller than 100KB!'
							: 'Image must be smaller than 2MB!'
					)
				);
			} else if (
				imgFile.type !== 'image/jpeg' &&
				imgFile.type !== 'image/jpg' &&
				imgFile.type !== 'image/png'
			) {
				notification.info(
					infoNotification('You can only upload JPG/PNG file!')
				);
			} else {
				setSelectedImage(imgFile);
			}
		} else {
			if (imgFile.size / 1024 / 1024 > 2) {
				notification.info(
					infoNotification(
						isAvatar
							? 'Image must be smaller than 100KB!'
							: 'Image must be smaller than 2MB!'
					)
				);
			} else {
				if (
					imgFile.type !== 'image/jpeg' &&
					imgFile.type !== 'image/jpg' &&
					imgFile.type !== 'image/png'
				) {
					notification.info(
						infoNotification('You can only upload JPG/PNG file!')
					);
				} else {
					setSelectedImage(imgFile);
					if (needToCompress) {
						handleCompressedUpload(imgFile);
					} else if (setCompressedFile) {
						setCompressedFile(undefined);
					}
				}
			}
		}
	};

	return (
		<div>
			<div
				className={
					isAvatar
						? isAppForm
							? style.avatarApp
							: style.avatar
						: style.frontImage
				}
				onMouseOver={(e) => {
					e.preventDefault();
					setShowEditPictureButtons(true);
				}}
				onMouseOut={(e) => {
					e.preventDefault();
					setShowEditPictureButtons(false);
				}}
			>
				{selectedImage ? (
					<img
						alt=""
						className={isAvatar ? style.avatarImage : ''}
						style={
							isAvatar ? { borderRadius: isAppForm ? '0.625rem' : '50%' } : {}
						}
						src={URL.createObjectURL(selectedImage)}
					/>
				) : existingImagePath && !isImageDeleted ? (
					<img
						alt=""
						className={isAvatar ? style.avatarImage : ''}
						style={
							isAvatar ? { borderRadius: isAppForm ? '0.625rem' : '50%' } : {}
						}
						src={getPublicImageURL(existingImagePath)}
					/>
				) : isAvatar ? (
					isAppForm ? (
						<div
							className={style.avatarApp}
							style={{ background: 'var(--border-popup)' }}
						>
							App Icon
						</div>
					) : (
						<IconBigAvatar />
					)
				) : (
					<div>Image</div>
				)}

				<div
					className={
						isAvatar
							? isAppForm
								? style.editAvatarApp
								: style.editAvatar
							: style.editFrontImage
					}
					style={{ visibility: showEditPictureButtons ? 'visible' : 'hidden' }}
				>
					{(!isAvatar || (isAvatar && !onlyToDeleteAvatar)) && (
						<label style={{ cursor: 'pointer' }}>
							<input
								type="file"
								onChange={(event) => {
									setIsImageDeleted(false);
									onChangeHandler(event);
								}}
								ref={inputRef}
							/>
							<IconCamera />
						</label>
					)}
					{selectedImage || (existingImagePath && !isImageDeleted) ? (
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setIsImageDeleted(true);
								setSelectedImage(undefined);
								// Reset the input as well, so we can load another file with the same name
								if (inputRef.current) {
									inputRef.current.value = '';
								}
								if (setCompressedFile) setCompressedFile(undefined);
							}}
						>
							<IconWhiteTrash />
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};
