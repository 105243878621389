import { theme } from 'antd';
import { ThemeColors, createTheme } from './common';

export const nimbusColors: ThemeColors = {
	primary: '#0daec9',
	primaryDark: '#068298',
	surface: '#181d1f',
	textNormal: '#89969f',
	textSubtitle: '#e7e7e7',
	textPrimary: '#01b2d0',
	textDisabled: '#4b5559',
	bg: '#252b2e',
	green: '#4bf083',
	blue: '#01b2d0',
	red: '#f04b4b',
	orange: '#e88224',
	borderPopup: '#33393c',
	borderInput: '#89969f',
	bgHover: ' #203f46',
};
export const nimbusTheme = createTheme(theme.darkAlgorithm, nimbusColors);
