import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const WinCloseIcon: React.FC<SvgIconProps> = ({ size = '10' }) => (
	<SvgIcon size={size} actualSize={10}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.736339 0L0 0.736421L4.26477 5.00389L0.0066637 9.26247L0.744114 10L5.00222 5.74142L9.257 9.99445L9.99445 9.25803L5.73967 5.00389L10 0.741975L9.26255 0.00444296L5.00222 4.26524L0.738561 0H0.736339Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
