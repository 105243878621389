import { enableHackyZoomSupport } from 'desktop/hackyZoom';

export const initApp = () => {
	// Until we have native zoom, this implements pseudo-zooming by changing the REM unit and putting `zoom` on images.
	// Remove as soon as https://github.com/tauri-apps/tauri/issues/3310 if completed.
	enableHackyZoomSupport();
	if (process.env.NODE_ENV === 'production') {
		window.addEventListener('contextmenu', (e) => e.preventDefault());
	}

	import('@tauri-apps/api/app').then((app) => {
		Promise.all([
			app.getName(),
			app.getVersion(),
			import('@tauri-apps/api/window'),
		]).then(([name, version, win]) =>
			win.getCurrent().setTitle(`${name} v${version}`)
		);
	});
};
