export type StatusSelection = undefined | 'DRAFT' | 'PUBLISHED';

export const statusSelectionValues: StatusSelection[] = [
	undefined,
	'DRAFT',
	'PUBLISHED',
];
export const statusSelectionLabels = ['All', 'Drafts', 'Published'];

export const statusSelectionOptions: {
	label: string;
	value: Exclude<StatusSelection, undefined>;
}[] = [
	{ label: 'Published', value: 'PUBLISHED' },
	{ label: 'Draft', value: 'DRAFT' },
];
