import { FC } from 'react';
import style from 'assets/styles/components.module.scss';
import { Tag } from 'antd';

type Props = (PropsWithValues | PropsWithChildren) & {
	label?: string;
};
interface PropsWithChildren extends ChildrenProps {
	values?: undefined;
}
interface PropsWithValues {
	values: string[];
	children?: undefined;
}

export const TagField: FC<Props> = (props) => {
	return (
		<div className={style.inputFieldWrapper} style={{ marginBottom: '1.5rem' }}>
			<span className={style.inputFieldLabel}>{props.label}</span>
			<div className={style.tagWrapper}>
				{props.children ??
					props.values!.map((v) => (
						<Tag color="var(--primary-dark)" key={v}>
							{v}
						</Tag>
					))}
			</div>
		</div>
	);
};
