import {
	keepPreviousData,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import {
	App,
	Input,
	InputRef,
	Modal,
	Spin,
	Table,
	TableProps,
	Tag,
	Tooltip,
} from 'antd';
import {
	AdminLicenseRequestDto,
	LicensingRequestStatus,
	SubscriptionDto,
} from 'api';
import {
	CSSProperties,
	FormEvent,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import subscriptionsService from 'services/SubscriptionsService';
import { RequestStatuses } from 'types/api';
import { capitalize } from 'utils';
import {
	errorNotification,
	infoNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import licensingStyle from 'assets/styles/licensingPage.module.scss';
import { ipc, isDesktop } from 'desktop';
import {
	CheckIcon,
	DownloadIcon,
	FileTextIcon,
	IconEdit,
	IconTrash,
	UploadIcon,
} from 'assets/icons/svg';
import { McIconButton } from 'components/mc';
import { defaultFileName, fileTypes } from 'desktop/licensing';
import { getDetailedErrorMessage } from './ActivationWizard/ActivationWizardError';

interface Props {
	subscription: SubscriptionDto;
}

export const getStatusColor = (
	status?: LicensingRequestStatus
): CSSProperties['background'] => {
	switch (status) {
		case RequestStatuses.DONE:
			return 'var(--add-green)';
		case RequestStatuses.ABANDONED:
			return 'var(--add-orange)';
		case RequestStatuses.PENDING:
			return 'var(--add-blue)';
		default:
			return 'var(--add-grey)';
	}
};

export const TAG_COLOR = (moduleName: String) => {
	switch (moduleName.toLowerCase()) {
		case 'tema 2d':
			return 'green';
		case '3d':
			return 'yellow';
		case 'dic':
			return 'orange';
		case '6d':
			return 'cyan';
		case 'cs':
			return 'teal';
		default:
			return 'purple';
	}
};

export const ADD_0_PREFIX = (value: number) => {
	return value < 10 ? '0' + value.toString() : value.toString();
};

export const formatDateForUTC = (apiDate?: string | Date): string => {
	try {
		if (!apiDate) return '';
		const date =
			apiDate instanceof Date
				? apiDate
				: new Date(
						apiDate.toString().substring(0, apiDate.toString().indexOf('.')) +
							'Z'
				  );

		return (
			date.getUTCFullYear() +
			'-' +
			ADD_0_PREFIX(date.getUTCMonth() + 1) + // 0 indexed
			'-' +
			ADD_0_PREFIX(date.getUTCDate()) +
			' (' +
			ADD_0_PREFIX(date.getUTCHours()) +
			':' +
			ADD_0_PREFIX(date.getUTCMinutes()) +
			':' +
			ADD_0_PREFIX(date.getUTCSeconds()) +
			')'
		);
	} catch (err) {
		return '';
	}
};

const AdminLicenseRequestTable = ({ subscription }: Props) => {
	const { notification, modal } = App.useApp();

	const PAGE_SIZE = 5;

	const [pageNr, setPageNr] = useState<number>(1);

	const [currentTicketRequestId, setCurrentTicketRequestId] = useState<
		number | undefined
	>(undefined);

	const [currentTicketIdentifier, setCurrentTicketIdentifier] = useState<
		string | undefined
	>(undefined);

	const currentTicketRef = useRef<InputRef>(null);

	const {
		data: requestPage,
		isLoading,
		error: getRequestsError,
		isFetching,
	} = useQuery({
		queryKey: [
			'subscriptions',
			subscription.id,
			'requests',
			'paginated',
			pageNr - 1, // -1 due to different indexing in the antd table
		],
		queryFn: () =>
			subscriptionsService
				.getAllAdminLicensingRequest(subscription.id, pageNr - 1, PAGE_SIZE)
				.then((res) => res.data),
		enabled: !!subscription,
		placeholderData: keepPreviousData,
	});

	const requests = useMemo(
		() =>
			!!requestPage
				? requestPage.content.map((request) => {
						return { ...request, key: request.id };
				  })
				: [],
		[requestPage]
	);

	useEffect(() => {
		if (!getRequestsError) return;
		notification.warning(
			mcErrorNotification('Warning', getRequestsError, 'fetch', 'requests')
		);
	}, [getRequestsError, notification]);

	const queryClient = useQueryClient();

	const {
		mutateAsync: completeRequest,
		isPending: isUploadingReceipt,
		variables: uploadingReceiptVariables,
	} = useMutation({
		mutationFn: ({
			subscriptionId,
			licenseRequestId,
			receipt,
		}: {
			subscriptionId: number;
			licenseRequestId: number;
			receipt: Blob;
		}) =>
			subscriptionsService
				.completeRequestForm(subscriptionId, licenseRequestId, receipt)
				.then((res) => res.data),
		onSuccess: () => {
			notification.success({
				message: 'Success!',
				description: `You successfully completed the license request!`,
			});
			queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
		},
		onError: (err: unknown) =>
			notification.error(errorNotification(getDetailedErrorMessage(err))),
	});

	const { mutateAsync: downloadResponseFile } = useMutation({
		mutationFn: ({
			subscription,
			request,
		}: {
			subscription: SubscriptionDto;
			request: AdminLicenseRequestDto;
		}) => {
			const promise = subscriptionsService.downloadRequestFile(
				subscription.id,
				request.id,
				'RESPONSE',
				{ responseType: 'arraybuffer' }
			);
			let fileType = fileTypes['RESPONSE'];
			return ipc.downloadFileToDesktop(
				promise,
				fileType.name,
				fileType.extensions,
				defaultFileName(
					'RESPONSE',
					request.containerSerial ?? `request-${request.id}`
				)
			);
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'download', 'response file')
			),
	});

	const {
		mutate: abandonRequest,
		isPending: isAbandoningRequest,
		variables: abandoningRequestVariables,
	} = useMutation({
		mutationFn: ({
			subscription,
			request,
		}: {
			subscription: SubscriptionDto;
			request: AdminLicenseRequestDto;
		}) =>
			subscriptionsService
				.abandonRequest(subscription.id, request.id)
				.then((res) => res.data),
		onSuccess: () => {
			notification.success({
				message: 'Request abandoned!',
				description: 'You successfully abandoned the request!',
			});
			queryClient.invalidateQueries({
				queryKey: ['subscriptions'],
			});
		},
		onError: (err: unknown) => {
			notification.error(
				mcErrorNotification('Error', err, 'abandon', 'request')
			);
		},
	});

	const {
		mutate: updateTicketIdentifier,
		isPending: isUpdatingTicketIdentifier,
	} = useMutation({
		mutationFn: ({
			subscriptionId,
			requestId,
			ticketIdentifier,
		}: {
			subscriptionId: number;
			requestId: number;
			ticketIdentifier: string;
		}) =>
			subscriptionsService
				.updateTicketIdentifier(subscriptionId, requestId, ticketIdentifier)
				.then((res) => res.data),
		onSuccess: () => {
			notification.success({
				message: 'Ticket Identifier updated!',
				description: 'You successfully updated the ticket identifier!',
			});
			queryClient.invalidateQueries({
				queryKey: ['subscriptions'],
			});
			setCurrentTicketIdentifier(undefined);
			setCurrentTicketRequestId(undefined);
		},
		onError: (err: unknown) => {
			notification.error(
				mcErrorNotification('Error', err, 'update', 'ticket identifier')
			);
		},
	});

	const { mutateAsync: uploadUpdateFile, isPending: isUploadingUpdateFile } =
		useMutation({
			mutationFn: ({
				subscriptionId,
				licenseRequestId,
				updateFile,
			}: {
				subscriptionId: number;
				licenseRequestId: number;
				updateFile: Blob;
			}) =>
				subscriptionsService
					.uploadRequestFileForm(
						subscriptionId,
						licenseRequestId,
						'RESPONSE',
						updateFile
					)
					.then((res) => res.data),
			onSuccess: () => {
				notification.success({
					message: 'Success!',
					description: `You successfully uploaded the update file!`,
				});
				queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
			},
			onError: (err: unknown) =>
				notification.error(errorNotification(getDetailedErrorMessage(err))),
		});

	const columns: TableProps<AdminLicenseRequestDto>['columns'] = useMemo(() => {
		return [
			{
				title: 'Created (UTC)',
				key: 'creationDate',
				dataIndex: 'creationDate',
				render: (_, { creationDate }) => formatDateForUTC(creationDate),
			},
			{
				title: 'Type',
				key: 'requestType',
				dataIndex: 'requestType',
				render: (_, { requestType }) =>
					(capitalize(requestType) ?? '').replaceAll('_', ' '),
			},
			{
				title: 'Modules',
				key: 'selectedModuleNames',
				dataIndex: 'selectedModuleNames',
				render: (_, record) => (
					<>
						{record.selectedModuleNames.map((moduleName) => (
							<Tag color={TAG_COLOR(moduleName)} key={moduleName}>
								{moduleName.toUpperCase()}
							</Tag>
						))}
					</>
				),
			},
			{
				title: 'Expiry (UTC)',
				key: 'activationExpiryDate',
				dataIndex: 'activationExpiryDate',
			},
			{
				title: 'Container ID',
				key: 'containerSerial',
				dataIndex: 'containerSerial',
			},
			{
				title: 'Ticket Identifier',
				key: 'ticketIdentifier',
				dataIndex: 'ticketIdentifier',
				render: (_, record) => (
					<div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
						<Tooltip title={'Change ticket identifier'}>
							<div
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setCurrentTicketIdentifier(record.ticketIdentifier ?? '');
									setCurrentTicketRequestId(record.id);
								}}
							>
								<IconEdit />
							</div>
						</Tooltip>
						<div>{record.ticketIdentifier}</div>
					</div>
				),
			},
			{
				title: 'Status',
				key: 'action',
				render: (_, record) => {
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Tooltip title={capitalize(record.licensingRequestStatus ?? '')}>
								<figure
									className={licensingStyle.statusRequestCircle}
									style={{
										background: getStatusColor(record.licensingRequestStatus),
									}}
								/>
							</Tooltip>
							<Tooltip title="Download response file">
								<McIconButton
									icon={<DownloadIcon />}
									disabled={!record.haveResponseFile}
									onClick={async (event) => {
										if (isDesktop) {
											event.preventDefault();
											event.stopPropagation();
											await downloadResponseFile({
												subscription: subscription,
												request: record,
											});
											return;
										} else {
											subscriptionsService
												.downloadRequestFile(
													subscription.id,
													record.id,
													'RESPONSE'
												)
												.then((res) => {
													var data = new Blob([res.data]);
													var requestURL = window.URL.createObjectURL(data);
													const tempLink = document.createElement('a');
													tempLink.href = requestURL;
													tempLink.setAttribute(
														'download',
														`${record.containerSerial}.WibuCmRaU`
													);
													tempLink.click();
												})
												.catch((err) =>
													notification.error(
														mcErrorNotification(
															'Error',
															err,
															'download',
															'response file'
														)
													)
												);
										}
									}}
								/>
							</Tooltip>
							{isUploadingUpdateFile ? (
								<div>
									<Spin spinning size={'small'} />
								</div>
							) : !!record && record.licensingRequestStatus !== 'ABANDONED' ? (
								<form
									onChange={async (event: FormEvent) => {
										const target = event.target as HTMLInputElement;
										if (
											target.files?.length &&
											!isUploadingUpdateFile &&
											!!subscription
										) {
											try {
												const updateFile: Blob = target.files[0];
												await uploadUpdateFile({
													subscriptionId: subscription.id,
													licenseRequestId: record.id,
													updateFile: updateFile,
												});
											} catch (err: any) {
												console.error('Failed to start upload!');
											}
										}
									}}
								>
									<input type="hidden" name="requestId" value={record.id} />
									<label>
										<input type="file" name="file" />
										<Tooltip title="Upload response file">
											<div className="mc-iconButton">
												<UploadIcon />
											</div>
										</Tooltip>
									</label>
								</form>
							) : (
								<McIconButton disabled icon={<UploadIcon />} />
							)}
							{!!uploadingReceiptVariables &&
							isUploadingReceipt &&
							uploadingReceiptVariables.licenseRequestId === record.id ? (
								<div>
									<Spin spinning size={'small'} />
								</div>
							) : record.haveReceiptFile ? (
								<Tooltip title="Receipted">
									<McIconButton disabled icon={<CheckIcon />} />
								</Tooltip>
							) : record.haveResponseFile ? (
								<form
									onChange={async (event: FormEvent) => {
										const target = event.target as HTMLInputElement;
										if (
											target.files?.length &&
											!isUploadingReceipt &&
											!!subscription &&
											!!record
										) {
											try {
												const receipt: Blob = target.files[0];
												await completeRequest({
													subscriptionId: subscription.id,
													licenseRequestId: record.id,
													receipt: receipt,
												});
											} catch (err: any) {
												console.error('Failed request!');
											}
										}
									}}
								>
									<input type="hidden" name="requestId" value={record.id} />
									<label>
										<input type="file" name="file" />
										<Tooltip title="Upload receipt file">
											<div className="mc-iconButton">
												<FileTextIcon />
											</div>
										</Tooltip>
									</label>
								</form>
							) : (
								<McIconButton disabled icon={<FileTextIcon />} />
							)}
							{!!abandoningRequestVariables &&
							isAbandoningRequest &&
							abandoningRequestVariables.request.id === record.id ? (
								<Spin spinning size={'small'} />
							) : (
								<Tooltip title="Abandon request">
									<McIconButton
										disabled={record.licensingRequestStatus !== 'PENDING'}
										icon={<IconTrash size={24} />}
										onClick={() => {
											modal.confirm({
												title: 'Abandon Request?',
												content:
													'Are you sure you want to abandon this request? This action is irreversible!',
												maskClosable: true,
												centered: true,
												okText: 'Abandon',
												onOk: () => {
													if (
														!isAbandoningRequest &&
														!!subscription &&
														!!record
													) {
														try {
															abandonRequest({
																subscription: subscription,
																request: record,
															});
														} catch (err: any) {
															notification.error(
																errorNotification('Failed to abandon request!')
															);
														}
													}
												},
											});
										}}
									/>
								</Tooltip>
							)}
							{/*
								// TODO: Perhaps implement an ordinary unlocking feature that isn't abandon?
								<Tooltip title="Unlock request">
									<McIconButton
										icon={<BranchIcon />}
										disabled={record.licensingRequestStatus !== 'PENDING'}
									/>
								</Tooltip>
								*/}
						</div>
					);
				},
			},
		];
	}, [
		abandonRequest,
		abandoningRequestVariables,
		completeRequest,
		downloadResponseFile,
		isAbandoningRequest,
		isUploadingReceipt,
		isUploadingUpdateFile,
		modal,
		notification,
		subscription,
		uploadUpdateFile,
		uploadingReceiptVariables,
	]);
	return (
		<>
			<Modal
				centered
				maskClosable
				open={!!currentTicketRequestId}
				onCancel={() => {
					setCurrentTicketRequestId(undefined);
					setCurrentTicketIdentifier(undefined);
				}}
				afterClose={() => {
					setCurrentTicketRequestId(undefined);
					setCurrentTicketIdentifier(undefined);
				}}
				destroyOnClose
				okText={'Update'}
				title={'Update ticket identifier'}
				onOk={() => {
					try {
						if (
							!currentTicketRequestId ||
							currentTicketIdentifier === undefined
						) {
							return;
						}

						if (
							currentTicketRef.current?.input?.value === currentTicketIdentifier
						) {
							notification.info(
								infoNotification('Ticket identifier unchanged!')
							);
							setCurrentTicketIdentifier(undefined);
							setCurrentTicketRequestId(undefined);
							return;
						}

						updateTicketIdentifier({
							subscriptionId: subscription.id,
							requestId: currentTicketRequestId,
							ticketIdentifier:
								currentTicketRef.current?.input?.value.trim() ?? '',
						});
					} catch (e) {
						notification.error(
							errorNotification('Error while reading ticket identifier')
						);
					}
				}}
			>
				{isUpdatingTicketIdentifier ? (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Spin spinning={isUpdatingTicketIdentifier} size="large" />
					</div>
				) : (
					<Input
						ref={currentTicketRef}
						type="text"
						defaultValue={currentTicketIdentifier}
					/>
				)}
			</Modal>
			<Table
				columns={columns}
				dataSource={requests}
				pagination={{
					position: ['bottomCenter'],
					defaultCurrent: 1,
					total: !!requestPage ? requestPage.allElements : 0,
					size: 'small',
					defaultPageSize: PAGE_SIZE,
					current: pageNr,
					hideOnSinglePage: true,
					onChange: (page, _) => {
						setPageNr(page);
					},
				}}
				loading={isLoading || isFetching}
				size={'small'}
			/>
		</>
	);
};

export default AdminLicenseRequestTable;
