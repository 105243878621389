import React, { Dispatch, SetStateAction } from 'react';

import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash, StarIcon } from 'assets/icons/svg';
import { deleteNews } from 'store/slices/oneNews';
import { updateNews } from 'store/slices/oneNews';
import { useAppDispatch } from 'hooks/hooks';
import { getOneNews } from 'store/slices/oneNews';
import { NewsStatuses } from 'types/api';
import { getPublicImageURL } from 'services/ApiService';
import { App } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import defaultImage from 'assets/images/top.jpg';
import { McMarkdown } from 'components/mc';

interface ManageOneNewsProps {
	news: News;
	setRefresh: Dispatcher;
	selectedToUpdate: number | null;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	setIndexNewsForEdit: Dispatch<SetStateAction<number | null>>;
	indexNews: number;
}

export const ManageOneNews: React.FC<ManageOneNewsProps> = ({
	news,
	setRefresh,
	selectedToUpdate,
	setSelectedToUpdate,
	setIndexNewsForEdit,
	indexNews,
}) => {
	const dispatch = useAppDispatch();
	const { modal, notification } = App.useApp();

	const styles =
		selectedToUpdate === news.id ||
		(news.extraNew === true && news.entityStatus === 'PUBLISHED')
			? {
					border: '2px solid var(--bg-active)',
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const stylesDescription =
		(news.title ?? '').length > 50
			? `${style.descriptionLessRows}`
			: `${style.description}`;

	const deleteNewsHandler = () => {
		dispatch(deleteNews({ id: news.id as number }))
			.unwrap()
			.then(() => {
				setRefresh((prev) => !prev);
				notification.success(deleteSuccessNotification(news.title));
			})
			.catch((error: unknown) => {
				console.error('Delete error: %o', error);
				notification.error(
					mcErrorNotification('Error', error, 'delete', 'selected news item')
				);
			});
	};

	const setAsFeatured = () => {
		if (news.entityStatus === 'PUBLISHED' && !news.extraNew) {
			dispatch(
				updateNews([
					news.id as number,
					{ extraNew: true },
					undefined,
					undefined,
				])
			)
				.unwrap()
				.then(
					() => {
						setRefresh((prev) => !prev);
					},
					(error: unknown) => {
						console.error('Update error: %o', error);
						notification.error(
							mcErrorNotification('Error', error, 'set', 'featured news item')
						);
					}
				);
		}
	};

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();
		if (!news.id) return;

		if (selectedToUpdate === null) {
			dispatch(getOneNews(news.id));
			setSelectedToUpdate(news.id);
			setIndexNewsForEdit(indexNews);
		} else {
			if (selectedToUpdate === news.id) {
				setSelectedToUpdate(null);
				setIndexNewsForEdit(null);
			} else {
				dispatch(getOneNews(news.id));
				setSelectedToUpdate(news.id);
				setIndexNewsForEdit(indexNews);
			}
		}
	};

	return (
		<div className={style.cardContainer} style={styles}>
			<div className={style.cardImgContainer}>
				<img
					alt="News thumbnail"
					className={style.cardImage}
					src={
						news.thumbnailImagePath
							? getPublicImageURL(news.thumbnailImagePath)
							: defaultImage
					}
					onError={(e) => {
						e.currentTarget.src = defaultImage;
					}}
				/>
			</div>
			<div className={style.cardDescriptContainer}>
				<h4 className={style.cardDescriptTitle}>{news.title}</h4>
				<div className={stylesDescription}>
					<McMarkdown content={news.description} />
				</div>
			</div>
			<div className={style.cardBtnContainer}>
				<div style={{ cursor: 'auto' }}>
					{news.entityStatus === 'PUBLISHED'
						? 'Published'
						: news.entityStatus === 'DRAFT'
						? 'Draft'
						: ''}
				</div>

				<div style={{ marginLeft: 'auto' }} onClick={clickEditBtnHandler}>
					<IconEdit />
				</div>
				<div
					onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
						e.preventDefault();
						modal.confirm(deleteItemModal(deleteNewsHandler, news.title));
					}}
				>
					<IconTrash />
				</div>
				<div
					className={
						news.extraNew && news.entityStatus === NewsStatuses.PUBLISHED
							? style.extraNewsIcon
							: style.starIcon
					}
					onClick={setAsFeatured}
				>
					<StarIcon />
				</div>
			</div>
		</div>
	);
};
