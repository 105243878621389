import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { EngineDto, EntityStatus } from 'api';
import { ApplicationReleaseSortOrder } from 'api';
import {
	ApplicationReleaseDto,
	ErrorDTO,
	PageDtoApplicationReleaseDto,
} from 'api';
import { appReleasesService } from 'services/AppReleasesService';
import enginesService from 'services/EnginesService';
import { RootState } from 'store';
import {
	getErrorMessageAnd,
	getRequestError,
	rejectionError,
} from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface ApplicationsState {
	releases: PageDtoApplicationReleaseDto;
	selectedAppRelease?: ApplicationReleaseDto;
	selectedAppReleaseEngine?: EngineDto;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: ApplicationsState = {
	releases: {} as PageDtoApplicationReleaseDto,
	loading: true,
	error: null,
};

interface GetAppsParams {
	sortOrder?: ApplicationReleaseSortOrder;
	searchedValue?: string;
	enginesId?: number;
	page: number;
	size: number;
	entityStatus?: EntityStatus;
	applicationsId?: number;
}

export const getReleases = createAppAsyncThunk(
	'releases/getReleases',
	async (p: GetAppsParams, { rejectWithValue }) =>
		await appReleasesService
			.getApplicationReleasesList(
				p.page,
				p.size,
				p.searchedValue,
				p.enginesId,
				p.sortOrder,
				p.entityStatus,
				p.applicationsId
			)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOneRelease = createAppAsyncThunk(
	'releases/getOneRelease',
	({ id }: { id: number }, { rejectWithValue }) =>
		appReleasesService
			.getApplicationReleaseById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type CreateApplicationParams = Parameters<
	typeof appReleasesService.createApplicationReleaseForm
>;
export const addRelease = createAppAsyncThunk(
	'releases/addRelease',
	(params: CreateApplicationParams, { rejectWithValue }) =>
		appReleasesService
			.createApplicationReleaseForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type UpdateApplicationParams = Parameters<
	typeof appReleasesService.updateApplicationReleaseForm
>;
export const updateRelease = createAppAsyncThunk(
	'releases/updateRelease',
	(
		[id, avatarPic, basicPic, dto]: UpdateApplicationParams,
		{ rejectWithValue }
	) =>
		appReleasesService
			.updateApplicationReleaseForm(id, avatarPic, basicPic, dto)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteRelease = createAppAsyncThunk(
	'releases/deleteRelease',
	({ id }: { id: number }, { rejectWithValue }) =>
		appReleasesService
			.deleteApplicationReleaseById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deletePicture = createAppAsyncThunk(
	'releases/deletePicture',
	async ({ id }: { id: number }, { rejectWithValue }) =>
		await appReleasesService
			.deleteApplicationReleasesBasicPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteAvatar = createAppAsyncThunk(
	'releases/deleteAvatar',
	async ({ id }: { id: number }, { rejectWithValue }) =>
		appReleasesService
			.deleteApplicationReleasesIconPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getReleaseWithEngine = createAppAsyncThunk(
	'releases/getReleaseWithEngine',
	async (
		{ engineId, appId }: { engineId?: number; appId: number },
		{ rejectWithValue }
	) => {
		try {
			const engine = await (engineId
				? enginesService.getEngineById(engineId).then((r) => r.data)
				: enginesService.getFeaturedEngineItem().then((r) => r.data));
			const releases = await appReleasesService.getApplicationReleasesList(
				0,
				99,
				undefined,
				engine.id,
				undefined,
				undefined,
				appId
			);
			const release = releases.data.content.shift();
			return [engine, release] as [
				EngineDto,
				ApplicationReleaseDto | undefined
			];
		} catch (x) {
			return rejectWithValue(getRequestError(x));
		}
	}
);

export const resetState = createAction('RESET');
export const resetOneRelease = createAction('RESET-ONE-RELEASE');

const releasesSlice = createSlice({
	name: 'releases',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(getReleases.pending, setLoadingState)
			.addCase(getReleases.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.releases = payload.data;
			})

			.addCase(getOneRelease.pending, setLoadingState)
			.addCase(getOneRelease.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.selectedAppRelease = payload.data;
			})

			.addCase(addRelease.pending, setLoadingState)
			.addCase(addRelease.fulfilled, (state) => {
				state.loading = false;
			})

			.addCase(updateRelease.pending, setLoadingState)
			.addCase(updateRelease.fulfilled, (state) => {
				state.loading = false;
			})

			.addCase(deleteRelease.pending, setLoadingState)
			.addCase(deleteRelease.fulfilled, (state) => {
				state.loading = false;
			})

			.addCase(deletePicture.pending, setLoadingState)
			.addCase(deletePicture.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteAvatar.pending, setLoadingState)
			.addCase(deleteAvatar.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getReleaseWithEngine.pending, setLoadingState)
			.addCase(
				getReleaseWithEngine.fulfilled,
				(state, { payload: [engine, release] }) => {
					state.loading = false;
					state.selectedAppRelease = release;
					state.selectedAppReleaseEngine = engine;
				}
			)
			.addCase(resetState, () => {
				return initialState;
			})

			.addCase(resetOneRelease, (state) => {
				return {
					...state,
					selectedAppRelease: undefined,
					selectedAppReleaseEngine: undefined,
				};
			})

			.addMatcher(
				isAnyOf(
					getReleases.rejected,
					getOneRelease.rejected,
					addRelease.rejected,
					updateRelease.rejected,
					deleteRelease.rejected,
					deletePicture.rejected,
					deleteAvatar.rejected,
					getReleaseWithEngine.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			),
});

export const appReleasesState = (state: RootState) => state.appReleases;

export default releasesSlice.reducer;
