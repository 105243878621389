import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorDTO } from 'api';
import { rejectionError } from './errors';

// NOTE: This is a hack, because something goes wrong when actaully calling `createAsyncThunk.withTypes`
export const createAppAsyncThunk = createAsyncThunk as ReturnType<
	typeof createAsyncThunk.withTypes<{
		rejectValue: ErrorDTO;
	}>
>;

export interface LoadingState {
	loading?: boolean;
	error?: ErrorDTO;
}

export interface StateWithLoading<L extends string> {
	loadingStates: Record<L, LoadingState>;
}

export const getLoadingStateReducers = <L extends string>(loadable: L) => ({
	pending: (state: StateWithLoading<L>) => {
		state.loadingStates[loadable] = { loading: true };
	},
	rejected: (
		state: StateWithLoading<L>,
		{ payload }: { payload: ErrorDTO | undefined }
	) => {
		state.loadingStates[loadable] = {
			loading: false,
			error: payload ?? rejectionError,
		};
	},
	fulfilled: (state: StateWithLoading<L>) => {
		state.loadingStates[loadable] = { loading: false };
	},
});

export const setLoadingStateReducer =
	<L extends string>(loadable: L) =>
	(state: StateWithLoading<L>) => {
		state.loadingStates[loadable] = { loading: true };
	};

export const setLoadingStateResult =
	<L extends string>(
		state: StateWithLoading<L>,
		loadable: L,
		error?: ErrorDTO
	) =>
	() => {
		state.loadingStates[loadable] = { loading: false, error };
	};

export const setLoadingState = <
	T extends { loading: boolean | null; error: ErrorDTO | null }
>(
	state: T
) => {
	state.loading = true;
	state.error = null;
};

export const setUploadingState = <
	T extends { uploading: boolean | null; error: ErrorDTO | null }
>(
	state: T
) => {
	state.uploading = true;
	state.error = null;
};
