import React, {
	useEffect,
	Dispatch,
	SetStateAction,
	useMemo,
	useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import style from 'assets/styles/selectField.module.scss';
import { SelectField } from './SelectField';
import {
	allAppReleasesShortInfoState,
	getAllAppReleasesShortInfo,
} from 'store/slices/allAppReleasesShortInfo';
import { ApplicationDto } from 'api';
import applicationsService from 'services/ApplicationsService';
import { App } from 'antd';
import { warningNotification } from 'utils/Notifications';
import { getPublicImageURL } from 'services/ApiService';
import { TemaGenericAppIcon } from 'assets/icons/apps';

interface Props {
	appReleaseId: number | undefined;
	setAppReleaseId: Dispatch<SetStateAction<number | undefined>>;
	tabIndex?: number;
	label?: string;
	error?: boolean;
	errorMessage?: string;
}

export const SelectAppRelease: React.FC<Props> = (props) => {
	const dispatch = useAppDispatch();
	const [applications, setApplications] = useState<ApplicationDto[]>([]);
	const { notification } = App.useApp();

	useEffect(() => {
		dispatch(getAllAppReleasesShortInfo());
	}, [dispatch]);

	const { allAppReleasesShortInfo: applicationReleases, loading } =
		useAppSelector(allAppReleasesShortInfoState);

	useEffect(() => {
		applicationsService
			.getApplicationsList(0, 50)
			.then((res) => setApplications(res.data.content))
			.catch(() =>
				notification.warning(
					warningNotification('Could not fetch applications!')
				)
			);
	}, [notification]);

	const options = useMemo(
		() =>
			(applicationReleases ?? []).map((appRelease) => {
				const application = applications.find(
					(application) => application.id === appRelease.applicationId
				);

				return {
					value: appRelease.id,
					label: (
						<>
							<div
								className={style.labelIconName}
								style={{
									opacity: appRelease.entityStatus === 'DRAFT' ? '0.6' : '',
								}}
							>
								{application && application.iconImagePath ? (
									<img
										alt=""
										src={getPublicImageURL(application.iconImagePath)}
										width={24}
										height={24}
									/>
								) : (
									<TemaGenericAppIcon size={24} />
								)}
								<div>{application ? application.name : 'loading...'}</div>
								<div>-</div>
								<div> {appRelease.version}</div>
							</div>
						</>
					),
				};
			}),
		[applicationReleases, applications]
	);

	return (
		<SelectField
			label={props.label}
			placeholder="Select an application"
			value={props.appReleaseId}
			options={options}
			onChange={props.setAppReleaseId}
			loading={loading}
		/>
	);
};
