import { CheckboxCheckedIcon, CheckboxDefaultIcon } from 'assets/icons/ui';
import { InputHTMLAttributes, forwardRef, useId } from 'react';

type InputElement = InputHTMLAttributes<HTMLInputElement>;

interface Props extends InputElement {}

export const McCheckBox = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const inputProps = {
		...props,
	};

	const uniqueId = `mccb-${useId()}`;
	const id = props.id ?? uniqueId;

	return (
		<div className="mc-checkBox">
			<input
				type="checkbox"
				{...inputProps}
				checked={props.checked}
				id={id}
				ref={ref}
			/>
			<label htmlFor={id}>
				{props.checked ? (
					<CheckboxCheckedIcon disabled={props.disabled} />
				) : (
					<CheckboxDefaultIcon disabled={props.disabled} />
				)}
			</label>
		</div>
	);
});
