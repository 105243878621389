import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { transformLinkTarget } from 'utils/urls';

interface Props {
	content: string | undefined;
}
export const McMarkdown: FC<Props> = (props) => {
	return (
		<div className="mc-markdown">
			<ReactMarkdown linkTarget={transformLinkTarget}>
				{props.content ?? ''}
			</ReactMarkdown>
		</div>
	);
};
