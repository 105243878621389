import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { CancelButton, SaveButton } from 'components/buttons';
import {
	InputField,
	InputTextarea,
	PictureField,
	UploadFileField,
} from 'components/fields';

import {
	deleteTemplateFile,
	deleteTemplatePicture,
	oneTemplateState,
	updateTemplate,
} from 'store/slices/oneTemplate';

import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { DisabledScreen } from 'components/DisabledScreen';
import {} from 'components/fields';
import { SelectAppRelease, SelectField, SelectOpts } from 'components/selects';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { editTemplateSchema } from 'validations/FormValidation';
import { TemplateDto } from 'api';
import { EntityStatus, EntityStatuses } from 'types/api';
import { TooltipBubble } from 'components/TooltipBubble';
import { FILE_ERROR, generateDisplayErrorMessage } from 'utils/errors';
import { App } from 'antd';
import { errorModal, saveSuccessNotification } from 'utils/Notifications';

interface EditTemplateFormProps {
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	setRefresh: Dispatcher;
}

interface FormValues {
	editName: string;
	editDescription: string;
	editApplication: number | undefined;
}

export const EditTemplateForm: React.FC<EditTemplateFormProps> = ({
	setSelectedToUpdate,
	setRefresh,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(editTemplateSchema),
	});

	const dispatch = useAppDispatch();

	const { template: templateWithFullSizeImg, uploading } =
		useAppSelector(oneTemplateState);
	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [compressedFile, setCompressedFile] = useState<Blob | undefined>();
	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const [status, setStatus] = useState<EntityStatus>();
	const [appReleaseId, setAppReleaseId] = useState<number | undefined>(
		templateWithFullSizeImg.applicationReleaseId
	);
	const [fileUploadName, setFileUploadName] = useState<string | undefined>(
		templateWithFullSizeImg.downloadLink
	);
	const [fileUpload, setFileUpload] = useState<Blob>();
	const [showLoader, setShowLoader] = useState(uploading);
	const [startUploading, setStartUploading] = useState(false);
	const { modal, notification } = App.useApp();

	useEffect(() => {
		setShowLoader(uploading && startUploading);
	}, [uploading, startUploading]);

	useEffect(() => {
		if (appReleaseId === null || appReleaseId === undefined) {
			setStatus(EntityStatuses.DRAFT);
		}
	}, [appReleaseId, setAppReleaseId, status, setStatus]);

	useEffect(() => {
		if (templateWithFullSizeImg) {
			setValue('editName', templateWithFullSizeImg.title ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue('editDescription', templateWithFullSizeImg.description ?? '', {
				shouldValidate: true,
				shouldDirty: true,
			});
			setValue(
				'editApplication',
				templateWithFullSizeImg.applicationReleaseId ?? undefined,
				{
					shouldValidate: Boolean(
						templateWithFullSizeImg.applicationReleaseId ?? undefined
					),
					shouldDirty: true,
				}
			);

			setAppReleaseId(
				templateWithFullSizeImg.applicationReleaseId ?? undefined
			);
			setStatus(templateWithFullSizeImg.entityStatus as string as EntityStatus);
			setFileUploadName(templateWithFullSizeImg.downloadLink ?? '');
		}
	}, [setValue, templateWithFullSizeImg]);

	const updateTemplateHandler: SubmitHandler<FormValues> = (data) => {
		setStartUploading(true);
		if (
			data.editName === templateWithFullSizeImg.title &&
			data.editDescription === templateWithFullSizeImg.description &&
			!(selectedImage instanceof File) &&
			(status as string) === (templateWithFullSizeImg.entityStatus as string) &&
			!isImageDeleted &&
			appReleaseId === templateWithFullSizeImg.applicationReleaseId &&
			((!fileUploadName && !templateWithFullSizeImg.downloadLink) ||
				(fileUploadName === templateWithFullSizeImg.downloadLink &&
					!fileUpload))
		) {
			setSelectedToUpdate(null);
			return;
		} else {
			const templateDto: TemplateDto = {
				id: templateWithFullSizeImg.id,
				title:
					data.editName.trim() === templateWithFullSizeImg.title
						? undefined
						: data.editName.trim(),
				description:
					data.editDescription.trim() === templateWithFullSizeImg.description
						? undefined
						: data.editDescription.trim(),
				applicationReleaseId:
					appReleaseId === templateWithFullSizeImg.applicationReleaseId
						? undefined
						: appReleaseId,
				entityStatus: ((status as string) ===
				(templateWithFullSizeImg.entityStatus as string)
					? undefined
					: status) as EntityStatus,
			};

			dispatch(
				updateTemplate([
					templateWithFullSizeImg.id,
					templateDto,
					selectedImage,
					compressedFile,
					fileUpload,
					!!fileUpload,
				])
			)
				.unwrap()
				.then(() => {
					setSelectedToUpdate(null);

					if (isImageDeleted) {
						dispatch(deleteTemplatePicture(templateWithFullSizeImg.id))
							.then(() => setRefresh((prev) => !prev))
							.finally(() => setIsImageDeleted(false));
					}

					if (
						!fileUploadName &&
						!fileUpload &&
						!!templateWithFullSizeImg.downloadLink
					) {
						dispatch(deleteTemplateFile(templateWithFullSizeImg.id))
							.unwrap()
							.catch(() => {
								modal.error(errorModal(FILE_ERROR));
							});
					}
					notification.success(saveSuccessNotification(data.editName.trim()));
					setRefresh((prev) => !prev);
				})
				.catch((error) => {
					if (error.code === 'ENTITY_UNIQUE_CONFLICT') {
						setFormError('editName', {
							type: 'custom',
							message:
								'Another template with such name and application already exist!',
						});
						setFormError('editApplication', {
							type: 'custom',
							message:
								'Another template with such name and application already exist!',
						});
					}
					setStartUploading(false);
					modal.error(errorModal(generateDisplayErrorMessage(error)));
				});
		}
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSelectedToUpdate(null);
		setStartUploading(false);
	};

	return (
		<>
			<form
				className={style.addFormWrapper}
				onSubmit={handleSubmit(updateTemplateHandler)}
			>
				<div className={style.coverImgContainer}>
					<PictureField
						selectedImage={selectedImage}
						setSelectedImage={setSelectedImage}
						setCompressedFile={setCompressedFile}
						needToCompress={true}
						existingImagePath={templateWithFullSizeImg.fullSizeImagePath}
						isImageDeleted={isImageDeleted}
						setIsImageDeleted={setIsImageDeleted}
						isAvatar={false}
					/>
					<div className={style.infoTextWrapper}>
						<TooltipBubble
							position="right"
							tooltiptext="Upload an image of proportion W/H 3.05, preferably of horisontal width > 1000 pixels"
						/>
					</div>
				</div>
				<div className={style.uploadImgContainer}>
					<UploadFileField
						text={'Upload template'}
						fileUploadName={fileUploadName}
						setFileUploadName={setFileUploadName}
						setFileUpload={setFileUpload}
						showLoader={showLoader}
					/>
				</div>
				<div className={style.editForm} style={{ marginTop: '1.5rem' }}>
					<div className={style.column}>
						<Controller
							name="editName"
							control={control}
							render={({ field }) => (
								<InputField
									placeholder={'Template name'}
									tabIndex={10}
									{...field}
									label={'Template Name'}
									error={!!errors.editName}
									errorMessage={errors.editName?.message}
								/>
							)}
						/>
						<Controller
							name="editDescription"
							control={control}
							render={({ field }) => (
								<InputTextarea
									{...field}
									placeholder={'Description'}
									tabIndex={20}
									label={'Description'}
									error={errors.editDescription === undefined ? false : true}
									errorMessage={errors.editDescription?.message}
								/>
							)}
						/>
					</div>
					<div className={style.column}>
						<Controller
							name="editApplication"
							control={control}
							render={() => (
								<>
									<SelectAppRelease
										appReleaseId={appReleaseId}
										setAppReleaseId={setAppReleaseId}
										tabIndex={3}
										label={'Application Release'}
										error={!!errors.editApplication}
										errorMessage={errors.editApplication?.message}
									/>
									<input type="hidden" {...register('editApplication')} />
								</>
							)}
						/>

						<SelectField
							value={status}
							options={
								[
									{ label: 'Draft', value: EntityStatuses.DRAFT },
									{ label: 'Published', value: EntityStatuses.PUBLISHED },
								] as SelectOpts<EntityStatus | undefined>
							}
							label={'Status'}
							tabIndex={40}
							setSelectedField={setStatus}
						/>
					</div>
				</div>
				<div className={style.buttonsWrapper}>
					<CancelButton tabIndex={60} onClickCancel={cancel} />
					<SaveButton tabIndex={50} />
				</div>
			</form>
			{showLoader && <DisabledScreen />}
		</>
	);
};
