import React, { useEffect, useState } from 'react';
import elementStyles from 'assets/styles/manageElements.module.scss';
import styles from '../assets/styles/releaseNotes.module.scss';
import { AlertCircle } from 'assets/icons/svg';
import { Spin } from 'antd';
import '../extra.scss';
import ReleaseNoteCard from '../components/cards/ReleaseNoteCard';
import { SearchField } from 'components/fields';

const formatDate = (dateString: string) => {
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		return { monthYear: 'Invalid Date', fullDate: 'Invalid Date' };
	}

	const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
	const day = date.getDate();
	const year = date.getFullYear();
	const monthName = date.toLocaleDateString(undefined, monthOptions);
	const monthYear = `${monthName} ${year}`;
	const fullDate = `${monthName} ${day}, ${year}`;

	return { monthYear, fullDate };
};

const importAllMarkdownFiles = (requireContext: {
	keys: () => string[];
	(key: string): any;
}) => {
	return requireContext.keys().map(requireContext);
};

const ReleaseNotes = () => {
	const [markdowns, setMarkdowns] = useState<string[]>([]);
	const [markdownNames, setMarkdownNames] = useState<string[]>([]);
	const [dates, setDates] = useState<string[]>([]);
	const [searchedValueText, setSearchedValueText] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const MAX_DISPLAY = 10;

	useEffect(() => {
		const fetchMarkdownFiles = async () => {
			setIsLoading(true);
			const markdownFiles = importAllMarkdownFiles(
				require.context('../../../release_notes/', true, /\.md$/)
			);

			const names: string[] = [];
			const extractedDates: string[] = [];
			const allContents: string[] = [];

			for (let file of markdownFiles) {
				try {
					const content = await fetch(file).then((response) => response.text());
					const match = file.match(
						/\/(\d{4}-\d{2}-\d{2})_(v\d+\.\d+\.\d+)(?:\.\w+)?\.md$/
					);

					if (match) {
						extractedDates.push(match[1]);
						names.push(match[2]);
					} else {
						extractedDates.push('Unknown Date');
						names.push('Unknown Version');
					}
					allContents.push(content);
				} catch (error) {
					console.error(`Failed to fetch ${file}:`, error);
					extractedDates.push('Fetch Error');
					names.push('Unknown Version');
					allContents.push('Could not load content');
				}
			}

			const sortedData = names
				.map((name, index) => ({
					name,
					content: allContents[index],
					date: extractedDates[index],
				}))
				.sort((a, b) => {
					const versionA = a.name.split('.').map(Number);
					const versionB = b.name.split('.').map(Number);
					return (
						versionB[0] - versionA[0] ||
						versionB[1] - versionA[1] ||
						versionB[2] - versionA[2]
					);
				});

			setMarkdownNames(
				sortedData.slice(0, MAX_DISPLAY).map((item) => item.name)
			);
			setMarkdowns(
				sortedData.slice(0, MAX_DISPLAY).map((item) => item.content)
			);
			setDates(sortedData.slice(0, MAX_DISPLAY).map((item) => item.date));
			setIsLoading(false);
		};

		fetchMarkdownFiles();
	}, []);

	const filteredItems = markdownNames
		.map((name, index) => ({
			name,
			content: markdowns[index],
			date: dates[index],
		}))
		.filter(
			({ name, content }) =>
				name.toLowerCase().includes(searchedValueText.toLowerCase()) ||
				content.toLowerCase().includes(searchedValueText.toLowerCase())
		);

	return (
		<Spin spinning={isLoading} size="large">
			<h2 className={elementStyles.title}>Latest releases</h2>

			<div className={styles.searchContainer}>
				<div className={styles.searchFieldWrapper}>
					<SearchField
						placeholder="Search"
						value={searchedValueText}
						onChange={(e) => setSearchedValueText(e.target.value)}
					/>
				</div>
			</div>

			<div className={elementStyles.container}>
				{filteredItems.length === 0 && (
					<div className={elementStyles.noElementsInfo}>
						No release notes available
						<AlertCircle />
					</div>
				)}

				{filteredItems.map(({ name, content, date }, index) => {
					const { monthYear, fullDate } = formatDate(date);

					return (
						<ReleaseNoteCard
							key={index}
							markdown={content}
							monthYear={monthYear}
							fullDate={fullDate}
							index={index}
							name={name}
						/>
					);
				})}
			</div>
		</Spin>
	);
};

export default ReleaseNotes;
