import { ErrorDTO, ErrorType } from 'api';
import axios from 'axios';

export const getRequestError = (error: unknown): ErrorDTO => {
	try {
		console.error('[API] Error:', error);

		if (axios.isAxiosError(error)) {
			const errorData = error.response?.data;

			if (typeof errorData === 'string') return serverError(errorData);
			if (isError(errorData)) return errorData;
		}

		const errorMessage = (error as Error).message;
		if (errorMessage) return serverError(errorMessage);
	} catch (err: any) {
		console.error('Failed to unwrap error!');
	}

	return serverError(`Unknown error`);
};

const serverError = (message: string): ErrorDTO => ({
	code: 'SERVER_ERROR',
	message,
});

export const rejectionError: ErrorDTO = {
	code: 'SERVER_ERROR',
	message: 'Action rejected without an error',
};

export const getErrorMessageAnd = <T>(
	action: (value: ErrorDTO) => T
): ((error: unknown) => T) => {
	return (error: unknown) => action(getRequestError(error));
};

export const isError = (subject: unknown): subject is ErrorDTO =>
	typeof subject === 'object' &&
	subject != null &&
	'code' in subject &&
	'message' in subject;

export const isErrorCode = (maybeError: unknown, code: ErrorType) =>
	isError(maybeError) && maybeError.code === code;

export const GENERIC_ERROR = 'Something went wrong. Please try again later!';

export const FILE_ERROR =
	'Something went wrong. Try reloading the page or go back to the previous page!';

export type DisplayError = string | undefined;

export const generateDisplayErrorMessage = (error: ErrorDTO) => {
	switch (error.code) {
		case 'ENTITY_NOT_FOUND':
			return 'The specified entity was not found. Please try again later!';
		case 'FAILED_TO_UPLOAD':
			return 'Failed to upload item. Please try again later!';
		case 'ENTITY_UNIQUE_CONFLICT':
			return (
				'Another entity with the parameter: ' + error.target + ' already exist!'
			);
		case 'ENTITY_FIELD_STALE':
			return 'At least one of the fields provided is considered stale. Try reloading the page!';
		case 'ENTITY_FIELD_EMPTY':
			return 'The specified fields are not allowed to be empty!';
		case 'ENTITY_OWNS_RESOURCES':
			return (
				'The entity ' +
				error.target +
				' is still connected to at least one resource!'
			);
		default:
			return GENERIC_ERROR;
	}
};
