import React, { ReactNode } from 'react';

import style from 'assets/styles/messageWindow.module.scss';

interface PopupProps {
	children: ReactNode;
	title: string;
}

export const PopUpWindow: React.FC<PopupProps> = ({ children, title }) => {
	return (
		<>
			<div className={style.window}>
				<div className={style.popUpContainer}>
					<div className={style.title}>{title}</div>
					{children}
				</div>
			</div>
		</>
	);
};
