/* tslint:disable */
/* eslint-disable */
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export const NewsCategoryVals = {
    NEWS: 'NEWS',
    ADMINNOTICE: 'ADMIN_NOTICE',
    SOFTWARERELEASE: 'SOFTWARE_RELEASE'
} as const;

export type NewsCategory = typeof NewsCategoryVals[keyof typeof NewsCategoryVals];

