import { authState } from 'store/slices/auth';

import { useAppSelector } from 'hooks/hooks';
import { Preloader } from 'modules/Preloader';
import { Navigate } from 'react-router-dom';
import { setLocalSetting } from 'settings';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
	const { isAuth, loading } = useAppSelector(authState);

	if (!loading && !isAuth) {
		setLocalSetting('redirectAfterLogin', window.location.pathname);
		return <Navigate to="/login" replace={true} />;
	}
	return loading ? <Preloader /> : children;
};
