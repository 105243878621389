import React, { Dispatch, useRef, useState } from 'react';
import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';
import { ActivationWizardState } from '../ActivationWizard';
import manualStyle from './../Components/FileButton.module.scss';
import { Spin } from 'antd';
import { CheckIcon, DownloadIcon } from 'assets/icons/svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CmContainer, ipc } from 'desktop';

interface Props {
	changeDisplayPage: Dispatch<ActivationWizardState>;
	selectedContainer: CmContainer;
}

const ImportUpdatePage = ({ changeDisplayPage, selectedContainer }: Props) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [isImported, setIsImported] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);

	const handleImport = async ({
		container,
		update,
	}: {
		container: CmContainer;
		update: Blob;
	}) => {
		setErrorMessage(undefined);
		const blobText = await update.text();
		try {
			await importUpdate({ container: container, update: blobText });
		} catch (err) {
			console.error(err);
		}
	};

	const queryClient = useQueryClient();

	const { mutateAsync: importUpdate, isPending } = useMutation({
		mutationFn: ({
			container,
			update,
		}: {
			container: CmContainer;
			update: string;
		}) =>
			ipc
				.importUpdateFile(container.mask, container.serial, update)
				.then((res) => res),
		onError: (err: any) => setErrorMessage('Failed to import update file!'),
		onSuccess: () => {
			setIsImported(true);
			queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
		},
	});

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>
					Install license updates in container
				</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					Press "Import update file" to install the the activated license into
					the container. After import: License has been successfully activated!
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '24px',
					gap: '8px',
					alignItems: 'center',
				}}
			>
				<div className={manualStyle.fileButtonContainer}>
					<input
						className={manualStyle.fileButtonInput}
						type="file"
						id="file-input"
						style={{ display: 'none' }}
						ref={fileInputRef}
						onChange={async (event) => {
							try {
								handleImport({
									container: selectedContainer,
									update: event.target.files![0],
								});
							} catch (e) {
								console.error('Failed to import file!');
							}
						}}
					/>
					<McButton
						primary
						disabled={isImported || isPending}
						onClick={() => {
							if (fileInputRef !== null && fileInputRef.current !== null)
								fileInputRef.current.click();
						}}
					>
						<DownloadIcon />
						Import update file
					</McButton>
				</div>
				{isPending && <Spin spinning={isPending} />}
				{isImported && <CheckIcon />}
				{!!errorMessage && (
					<div
						style={{
							color: 'var(--add-red)',
							fontSize: '13px',
							fontWeight: '500',
							whiteSpace: 'pre-line',
						}}
					>
						{errorMessage}
					</div>
				)}
			</div>
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton
					disabled={isPending}
					onClick={() => {
						changeDisplayPage('ExportContext');
					}}
				>
					Back
				</McButton>

				<McButton
					primary
					disabled={isPending}
					onClick={() => {
						changeDisplayPage('ExportReceipt');
					}}
				>
					Next
				</McButton>
			</div>
		</div>
	);
};

export default ImportUpdatePage;
