import { ReactNode } from 'react';
import { ThinCard } from './ThinCard';
import { getPublicImageURL } from 'services/ApiService';
import { TemaGenericAppIcon } from 'assets/icons/apps/TemaGenericAppIcon';
import Tooltip from 'antd/es/tooltip';
import style from 'assets/styles/manageElements.module.scss';

interface Props {
	title?: string;
	icon?: ReactNode;
	iconPath?: string;
	buttons?: ReactNode;
	hasLicense?: boolean;
	hasLicenseExpired?: boolean;
}

const ApplicationThinCard = ({
	buttons,
	hasLicense,
	iconPath,
	icon,
	title,
	hasLicenseExpired,
}: Props) => {
	return (
		<ThinCard buttons={buttons}>
			<>
				{hasLicense ? (
					<Tooltip title={'License available'}>
						<div
							className={style.statusCircle}
							style={{
								background: 'var(--add-green)',
							}}
						/>
					</Tooltip>
				) : (
					<Tooltip title={'Unable to find available license!'}>
						<div
							className={style.statusCircle}
							style={{
								background: 'var(--add-red)',
							}}
						/>
					</Tooltip>
				)}
				{iconPath ? (
					<img
						className="thinCard-avatar"
						alt=""
						src={getPublicImageURL(iconPath)}
					/>
				) : (
					icon ?? <TemaGenericAppIcon />
				)}
				<h4 className="title">{title}</h4>
				{!hasLicense && (
					<p style={{ color: 'var(--text-disabled)', marginLeft: 0 }}>
						({hasLicenseExpired ? 'license expired' : 'no license'})
					</p>
				)}
			</>
		</ThinCard>
	);
};

export default ApplicationThinCard;
