import { FC } from 'react';
import { InfoIcon } from 'assets/icons/svg';

interface TooltipBubbleProps {
	tooltiptext?: string;
	position?: 'left' | 'right';
}

export const TooltipBubble: FC<TooltipBubbleProps> = ({
	tooltiptext = 'Default tooltip text',
	position = 'right',
}) => {
	const bubbleClass = position === 'left' ? 'tooltipLeft' : 'tooltipRight';

	return (
		<div className="tooltip-anim">
			<span className={`mc-tooltip ${bubbleClass}`} data-tooltip={tooltiptext}>
				<InfoIcon />
			</span>
		</div>
	);
};
