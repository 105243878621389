import { useLightMode } from 'hooks/useLightMode';
import { FC } from 'react';
import { cirrusColors, cirrusTheme } from './cirrusTheme';
import { nimbusColors, nimbusTheme } from './nimbusTheme';
import { ThemeColors } from './common';

const cssKey = (key: keyof ThemeColors) => {
	switch (key) {
		case 'primaryDark':
			return '--primary-dark';
		case 'textNormal':
			return '--text-normal';
		case 'textSubtitle':
			return '--text-subtitle';
		case 'textPrimary':
			return '--text-primary';
		case 'textDisabled':
			return '--text-disabled';

		case 'green':
		case 'red':
		case 'orange':
			return `--add-${key}`;

		case 'blue':
			return `--text-primary`;

		default:
			return '--' + key;
	}
};

export const ThemePage: FC = () => {
	const [lightMode, setLightmode] = useLightMode();
	const theme = lightMode ? cirrusTheme : nimbusTheme;
	const themeColors = lightMode ? cirrusColors : nimbusColors;

	return (
		<div style={{ background: 'white', padding: '1rem' }}>
			<div>
				<h2 style={{ display: 'inline-block' }}>
					{lightMode ? 'Cirrus (light)' : 'Nimbus (dark)'}
				</h2>
				<button
					style={{ marginLeft: '1rem' }}
					onClick={() => setLightmode(!lightMode)}
				>
					{lightMode ? '☀️' : '🌙'}
				</button>
			</div>
			<div style={{ padding: '1rem' }}>
				<h3>Colors</h3>
				<div style={{ paddingTop: '.5rem' }} />
				{Object.keys(themeColors).map((k) => {
					const key = k as keyof ThemeColors;
					const color = themeColors[key];

					return (
						<div key={key} style={{ display: 'flex' }}>
							<code style={{ width: '8em' }}>{key}</code>
							<code style={{ width: '5em' }}>{color}</code>
							<div
								style={{
									marginRight: '1rem',
									display: 'inline-block',
									width: '1rem',
									height: '1rem',
									border: '1px black solid',
									background: color,
								}}
							/>
							<div
								style={{
									display: 'inline-block',
									width: '1rem',
									height: '1rem',
									border: '1px black solid',
									marginRight: '1rem',
									background: `var(${cssKey(key)})`,
								}}
							/>
							<code style={{ width: '10em' }}>{cssKey(key)}</code>
						</div>
					);
				})}
			</div>
			<hr />
			<div>
				<h3>
					<a
						href="https://ant.design/theme-editor"
						rel="noreferrer"
						target="_blank"
					>
						Ant Design Editor
					</a>{' '}
					Config
				</h3>
				<pre
					style={{
						fontFamily: 'monospace',
						fontWeight: 'normal',
						background: '#ddd',
						padding: '1rem',
					}}
				>
					{JSON.stringify(
						{ ...theme, algorithm: lightMode ? undefined : 'dark' },
						null,
						2
					)}
				</pre>
			</div>
		</div>
	);
};
