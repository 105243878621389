import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from 'assets/icons/svg';
import { Spin } from 'antd';
import { NotFoundElement } from 'components/NotFoundElement';
import style from 'assets/styles/newsSinglePage.module.scss';
import { Cond } from 'utils/Cond';
import { McButton, McMarkdown } from 'components/mc';
import { capitalize } from 'utils';
import { transformLinkTarget } from 'utils/urls';
import { getPublicImageURL } from 'services/ApiService';
import { useQuery } from '@tanstack/react-query';
import newsService from 'services/NewsService';
import defaultImage from 'assets/images/top.jpg';

export const OneNews = () => {
	let navigate = useNavigate();
	const { id: idString } = useParams();
	const id = Number(idString);

	const handleBackBtn = () => {
		navigate(-1);
	};

	function getMonthName(monthNumber: number) {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString('en-US', { month: 'long' });
	}

	const {
		data: newsItem,
		isLoading,
		error: getNewsError,
	} = useQuery({
		queryKey: ['news', id],
		queryFn: () => newsService.getNewsItemById(id).then((res) => res.data),
		initialData: undefined,
	});

	const date = useMemo(() => {
		if (!newsItem || !newsItem.date) return '';
		return [
			getMonthName(parseInt(newsItem.date.slice(5, 7))),
			newsItem.date.slice(8, 10),
			newsItem.date.slice(0, 4),
		].join(' ');
	}, [newsItem]);

	const linkTarget = !!newsItem ? transformLinkTarget(newsItem.link) : '_blank';

	return getNewsError ? (
		<NotFoundElement element={'news'} />
	) : (
		<div className={style.container}>
			<div>
				<Spin spinning={isLoading} size="large">
					<div className={style.imgContainer}>
						<img
							alt={newsItem?.title || 'News thumbnail'}
							src={
								!!newsItem && !!newsItem.thumbnailImagePath
									? getPublicImageURL(newsItem.thumbnailImagePath)
									: defaultImage
							}
							onError={(e) => {
								e.currentTarget.src = defaultImage;
							}}
						/>
					</div>
					<div
						className={`${style.intro} flex between align-start flex-col-mobile`}
					>
						<div>
							<h1 className={style.title}>{newsItem?.title}</h1>
							<h2 className={style.date}>{date}</h2>
						</div>
						<div className={style.meta}>
							<p className={style.tag}>
								{!!newsItem && capitalize(newsItem.category)}
							</p>
							<Cond if={!!newsItem && !!newsItem.link}>
								<McButton.Link
									target={linkTarget}
									href={newsItem?.link}
									rel="noreferrer"
									primary
								>
									More info
								</McButton.Link>
							</Cond>
						</div>
					</div>

					<div className={style.description}>
						<McMarkdown content={newsItem?.description} />
					</div>
					<div className={style.backButton} onClick={handleBackBtn}>
						<ArrowLeftIcon />
						<span>Back</span>
					</div>
				</Spin>
			</div>
		</div>
	);
};
