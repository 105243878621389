export interface InstalledEngine {
	name: string;
	version: string;
	install_path: string;
	apps: string[];
}

export interface SessionFile {
	name: string;
	last_modified: string;
	extension: string;
	app_id: string;
	engine: string;
}

export interface CmContainer {
	serial: number;
	mask: number;
	identifier: string;
	container_type: ContainerType;
}

export type FileSource = 'RECENT' | 'TEMPLATE';

export type LicensingFileType = 'REQUEST' | 'RESPONSE' | 'RECEIPT';

export type ContainerType = 'Dongle' | 'Act';

export const isContainerType = (value: any): value is ContainerType => {
	return value === 'Dongle' || value === 'Act';
};
