import React, { FC, useState } from 'react';
import { Checkbox, Image, Steps, App } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { McButton, McDialog } from './mc';
import { useLocalSettings } from 'hooks/useLocalSettings';
import { infoNotification } from 'utils/Notifications';
import { useAppDispatch } from 'hooks/hooks';
import { setTourOpen } from 'store/slices/tour';

const step1Img = require('assets/images/mc1.jpg');
const step2Img = require('assets/images/mc2.jpg');
const step3Img = require('assets/images/mc3.jpg');
const step4Img = require('assets/images/mc4.jpg');

interface StepsTourProps {
	current: number;
	next: () => void;
	prev: () => void;
	onStepChange: (current: number) => void;
}

const makeOpacityButton = (isDisabled: boolean) => ({
	opacity: isDisabled ? 0.4 : 1,
});

export const StepsTour: FC<StepsTourProps> = ({
	current,
	next,
	prev,
	onStepChange,
}) => {
	const steps = [
		{
			title: 'Welcome',
			content: (
				<div className="flex flex-col gap-1">
					<Image
						style={{
							borderRadius: '0.75rem',
							border: '1px solid var(--primary, #01B2D0)',
							background: 'var(--primary, #0DAEC9)',
							width: '100%',
						}}
						src={step1Img}
						alt="step 1 image"
					/>
					<div className="flex-col gap-0-5 scrollable">
						<h3 style={{ color: 'var(--text-subtitle)' }}>
							About TEMA and Motion Cloud
						</h3>
						<h4>
							TEMA is the worlds most comprehensive software tools for
							professional video tracking and analysis for test and measurement.
							Our user-friendly tools and extensive analysis libraries will help
							adding value to your test campaigns. Whether you're a beginner or
							a seasoned pro, we've got the resources you need to bring your
							analysis results to life.
							<br></br>
							<br></br>
							Motion Cloud is the starting point for your management of your
							TEMA softwares. From tutorials and templates to cutting-edge
							features, Motion Cloud is here to elevate your usage of TEMA.
						</h4>
					</div>
				</div>
			),
		},
		{
			title: 'Motion Cloud Features',
			content: (
				<div className="flex flex-col gap-1">
					<Image
						style={{
							borderRadius: '0.75rem',
							border: '1px solid var(--primary, #01B2D0)',
							background: 'var(--primary, #0DAEC9)',
						}}
						src={step2Img}
						alt="step 2 image"
					/>
					<div className="flex-col gap-0-5 scrollable">
						<h3 style={{ color: 'var(--text-subtitle)' }}>
							Motion Cloud Features
						</h3>
						<h4>
							Motion Cloud offers a streamlined software management experience,
							centered around our flagship software, TEMA. With Motion Cloud,
							users can effortlessly license, install, and manage TEMA
							installations with ease. Key features include:
						</h4>
						<ul>
							<li>Effortless Licensing: Obtain and manage TEMA licenses</li>
							<li>
								Streamlined Installation: Deploy TEMA across computers with
								automated prompts and intuitive instructions.
							</li>
							<li>
								Application and test management: Manage and start all different
								TEMA applications, as well as keeping track of recent tests and
								templates
							</li>
							<li>
								Store Page: Explore additional software offerings to enhance
								your TEMA experience.
							</li>
							<li>
								Support Material: Access comprehensive FAQs, troubleshooting
								guides, and support. Choose Motion Cloud for simplified software
								management.
							</li>
						</ul>
					</div>
				</div>
			),
		},
		{
			title: 'Desktop App',
			content: (
				<div className="flex flex-col gap-1">
					<Image
						style={{
							borderRadius: '0.75rem',
							border: '1px solid var(--primary, #01B2D0)',
							background: 'var(--primary, #0DAEC9)',
						}}
						src={step3Img}
						alt="step 3 image"
					/>
					<div className="flex-col gap-0-5 scrollable">
						<h3 style={{ color: 'var(--text-subtitle)' }}>
							Desktop App Introduction
						</h3>
						<h4>
							As part of Motion Cloud suite we also provide you with the Motion
							Cloud Desktop App! It is the desktop version of Motion Cloud, that
							helps you to manage your installations of TEMA products.
						</h4>
						<ul>
							<li>First, find the download link for the Desktop App.</li>
							<li>
								Download the file, and follow the on-screen instructions to
								install the app on your computer.
							</li>
							<li>
								After installation you can launch the app and log in with your
								Motion Cloud account credentials.
							</li>
						</ul>
						<h4>
							Once logged in, you can use the app to install, update, and manage
							all your TEMA products easily and efficiently, including direct
							license activations. It will also help you managing your ongoing
							TEMA analysis sessions.
						</h4>
					</div>
				</div>
			),
		},
		{
			title: 'Get Started with TEMA',
			content: (
				<div className="flex flex-col gap-1">
					<Image
						style={{
							borderRadius: '0.75rem',
							border: '1px solid var(--primary, #01B2D0)',
							background: 'var(--primary, #0DAEC9)',
						}}
						src={step4Img}
						alt="step 4 image"
					/>
					<div className="flex-col gap-0-5 scrollable">
						<h3 style={{ color: 'var(--text-subtitle)' }}>
							Get Started with TEMA
						</h3>
						<h4>
							Now you are only a few steps from getting started with your TEMA
							products!
						</h4>
						<ol>
							<li>Download and install a Tema Engine</li>
							<li>Activate a license</li>
							<li>Start-up your desired TEMA application!</li>
						</ol>
						<h4>
							Our TEMA platform is designed to keep you ahead of the curve with
							continuous updates, new features, and an ever-expanding library of
							resources. Stay tuned in Motion Cloud for exciting tutorials,
							fresh templates, and get guided to innovative tools that will
							enhance your projects. Use the templates to get your projects up
							and running fast, and discover the support pages to access our
							knowledge database. With Motion Cloud, the possibilities are
							endless. Keep analysing, keep innovating, and watch your results
							come to life!
						</h4>
					</div>
				</div>
			),
		},
	];

	const items = steps.map((item, index) => ({
		key: item.title,
		title: item.title,
		onClick: () => onStepChange(index),
	}));

	return (
		<div
			style={{ display: 'flex', height: '100%', flexWrap: 'wrap' }}
			className="stepsTour flex-col-mobile"
		>
			<div
				style={{
					borderRadius: '0.75rem',
					background: 'var(--bg-dark, #181D1F)',
					display: 'flex',
					padding: '24px',
					marginLeft: '16px',
					flexDirection: 'column',
					alignItems: 'flex-start',
					alignSelf: 'stretch',
					minWidth: '20%',
				}}
			>
				<h2
					style={{
						marginBottom: '24px',
						color: '--text-subtitle',
						whiteSpace: 'nowrap',
					}}
				>
					Welcome to Motion Cloud
				</h2>
				<Steps current={current} direction="vertical" items={items} />
			</div>
			<div style={{ flex: 1, padding: '0 24px' }}>{steps[current].content}</div>
		</div>
	);
};

export const TourComponent: FC = () => {
	const [current, setCurrent] = useState(0);
	const [, setTourDismissed] = useLocalSettings('dismissIntroductionTour');
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(setTourOpen(false));
	};

	const next = () => {
		if (current < 3) {
			setCurrent(current + 1);
		}
	};

	const prev = () => {
		if (current > 0) {
			setCurrent(current - 1);
		}
	};

	const onStepChange = (currentStep: number) => {
		setCurrent(currentStep);
	};

	const [checkboxTicked, setCheckboxTicked] = useState(false);

	const isAtFirstStep = current === 0;
	const isAtLastStep = current === 3;

	return (
		<>
			<McDialog
				modal={true}
				size="large"
				footer={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							width: '100%',
							borderTop: '1px solid var(--border-color, #2D3336)',
							padding: '16px 0 0',
							margin: '16px 0 0',
							gap: '1rem',
						}}
					>
						<div className="flex gap-1">
							<McButton
								style={makeOpacityButton(isAtFirstStep)}
								onClick={prev}
								disabled={isAtFirstStep}
							>
								<LeftOutlined />
							</McButton>
							<McButton
								style={makeOpacityButton(isAtLastStep)}
								onClick={next}
								disabled={isAtLastStep}
							>
								<RightOutlined />
							</McButton>
						</div>
						<div className="flex gap-1">
							<Checkbox
								onChange={() => setCheckboxTicked((prev) => !prev)}
								checked={checkboxTicked}
							>
								Don't show again
							</Checkbox>
							<McButton
								onClick={() => {
									setTourDismissed(checkboxTicked);
									notification.success(
										infoNotification('You have finished the tour successfully!')
									);
									handleClose();
								}}
							>
								Done
							</McButton>
						</div>
					</div>
				}
			>
				<StepsTour
					current={current}
					next={next}
					prev={prev}
					onStepChange={onStepChange}
				/>
			</McDialog>
		</>
	);
};
