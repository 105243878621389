import { Configuration } from 'api';
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';

const API_URL_BASE = process.env.REACT_APP_API_URI ?? '';
export const API_URL = API_URL_BASE + '/api';

const PUBLIC_STORAGE_URL_BASE = process.env.REACT_APP_PUBLIC_STORAGE_URI ?? '';

export const downloadDesktopAppUrl = `${API_URL_BASE}/download-desktop-application`;
export const getDownloadTemplateUrl = (id: number) =>
	`${API_URL}/templates/${id}/file`;
export const getDownloadEngineUrl = (id: number) =>
	`${API_URL}/engines/${id}/file`;

export const getPublicImageURL = (storagePath: string) =>
	`${PUBLIC_STORAGE_URL_BASE}/${storagePath}`;

export interface ApiService {
	axiosInstance: AxiosInstance;
}

const createAxios = (configDefaults: CreateAxiosDefaults) =>
	Promise.resolve(axios.create({ ...configDefaults }));

export const apiAxios = createAxios({ withCredentials: true });

export const apiConfig = new Configuration();
