import { Skeleton } from 'antd';
import { FC } from 'react';

interface Props extends ChildrenProps {
	headers: string[];
	loading?: boolean;
	emptyText?: string;
	items?: any[];
	placeholderValues?: string[];
	skeletonCount?: number;
}

type SubComponents = {
	Cell: typeof Cell;
	Row: typeof Row;
};

const McTableComponent: FC<Props> = (props) => {
	return (
		<table className="mc-table" style={{ display: 'block', width: '100%' }}>
			<thead style={{ display: 'block', width: '100%' }}>
				<tr>
					<th />
					{props.headers.map((header) => (
						<th key={header}>{header}</th>
					))}
					<th />
				</tr>
			</thead>
			<tbody
				style={{
					display: 'inline-block',
					overflowY: 'scroll',
					height: '200px',
					width: '100%',
				}}
			>
				{props.loading ? (
					<SkeletonRows
						placeholderValues={props.placeholderValues ?? props.headers}
						skeletonCount={props.skeletonCount ?? 6}
					/>
				) : props.items?.length ?? 1 ? (
					props.children
				) : (
					<tr>
						<td />
						<td colSpan={props.headers.length}>{props.emptyText} </td>
						<td />
					</tr>
				)}
			</tbody>
		</table>
	);
};

const SkeletonRows: FC<{
	placeholderValues: string[];
	skeletonCount: number;
}> = ({ placeholderValues, skeletonCount }) => (
	<>
		{Array.from(new Array(skeletonCount), (_, i) => (
			<tr key={i}>
				<td />
				{placeholderValues.map((value) => (
					<td key={value}>
						<Skeleton
							paragraph={false}
							title={{ width: value.length + 'ch' }}
							active
						/>
					</td>
				))}
				<td />
			</tr>
		))}
	</>
);

interface RowProps extends ChildrenProps {}
const Row: FC<RowProps> = (props) => {
	return (
		<tr>
			<td />
			{props.children}
			<td />
		</tr>
	);
};

interface CellProps extends ChildrenProps {
	type?: 'text' | 'actions';
}
const Cell: FC<CellProps> = (props) => {
	return (
		<td className={`mc-${props.type ?? 'text'}Cell`}>
			<div className="mc-cellWrapper">{props.children}</div>
		</td>
	);
};

export const McTable = McTableComponent as typeof McTableComponent &
	SubComponents;

McTable.Cell = Cell;
McTable.Row = Row;
