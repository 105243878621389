import { FC, ReactNode } from 'react';
import style from 'assets/styles/components.module.scss';

interface Props {
	children: ReactNode;
	styles?: {};
	subscription?: boolean;
}

export const ItemContainer: FC<Props> = ({
	children,
	styles,
	subscription,
}) => {
	return (
		<div
			className={subscription ? style.subscriptionItem : style.itemContainer}
			style={styles}
		>
			{children}
		</div>
	);
};
