export type ServerEventType = 'NOTIFICATION' | 'ENGINE' | 'SUBSCRIPTION';

export const SSE_URI = process.env.REACT_APP_API_URI + '/api/updates';
const cookieAccessToken = 'AccessToken';

//export const sseURI = (type: ServerEventType) => SSE_URI ;

export const setAuthenticationCookie = (accessToken: string) => {
	document.cookie = `${cookieAccessToken}=${accessToken};path=/api`;
};
