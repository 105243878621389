import { FC, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { LogoLeft } from 'assets/icons/svg';
import style from 'assets/styles/sign.module.scss';
import { useAppDispatch } from 'hooks/hooks';
import { loginAsGuest } from 'store/slices/auth';
import { isDesktop } from 'desktop';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import McSpinner from 'components/MotionCloudSpinner';
import { LoginForm } from 'modules/LoginForm';
import DeviceImage from 'assets/images/Device.png';
import MotionCloudImage from 'assets/images/motion1.jpg';
import Tema2DImage from 'assets/images/tema2.jpg';
import Tema3DImage from 'assets/images/tema3.jpg';
import NoAccountImage from 'assets/images/noprofile.png';
import { McButton } from 'components/mc';
import { Collapse } from 'antd';
import { Switch } from 'antd';
import { useLightMode } from 'hooks/useLightMode';
import { SunLightModeIcon, MoonDarkModeIcon } from 'assets/icons/svg';

const { Panel } = Collapse;

const FeatureCard: FC<{
	title: string;
	subtitle: string;
	description: string;
	ctaText: string;
	imageSrc: string;
	index: number;
}> = ({ title, subtitle, description, ctaText, imageSrc, index }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<motion.div
			ref={ref}
			className={style.featureCard}
			initial={{ opacity: 0, y: 50 }}
			animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
			transition={{ duration: 0.5, delay: index * 0.2 }}
		>
			<div className={style.featureCardContent}>
				<h4>{subtitle}</h4>
				<h3>{title}</h3>
				<Collapse ghost>
					<Panel header="Read More" key="1" className={style.collapsePanel}>
						<p>{description}</p>
					</Panel>
				</Collapse>
				<Link to="/signup" className={style.ghostButton}>
					{ctaText}
				</Link>
			</div>
			<div className={style.imagePlaceholder}>
				<img src={imageSrc} alt={title} className={style.featureImage} />
			</div>
		</motion.div>
	);
};

const StepCard: FC<{
	step: number;
	title: string;
	description: string;
}> = ({ step, title, description }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<motion.div
			ref={ref}
			className={style.stepCard}
			initial={{ opacity: 0, x: -50 }}
			animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
			transition={{ duration: 0.5, delay: step * 0.2 }}
			whileHover={{ scale: 1.05 }}
		>
			<motion.div
				className={style.stepIcon}
				whileHover={{ rotate: 360 }}
				transition={{ duration: 0.5 }}
			>
				<span>{step}</span>
			</motion.div>
			<h3>{title}</h3>
			<p>{description}</p>
		</motion.div>
	);
};

export const Login: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const loginAsGuestHandler = async () => {
		dispatch(loginAsGuest());
	};

	const { isLoading } = useAuth0();

	const [isLightMode, setIsLightMode] = useLightMode();

	const handleLightModeToggle = () => {
		setIsLightMode(!isLightMode);
	};

	const discoverSectionRef = useRef(null);
	const discoverSectionInView = useInView(discoverSectionRef, { once: true });

	const gettingStartedSectionRef = useRef(null);
	const gettingStartedSectionInView = useInView(gettingStartedSectionRef, {
		once: true,
	});

	return (
		<>
			<div className={style.navBar}>
				<div className={style.logo}>
					<LogoLeft />
				</div>
				<div className={style.exploreFeatures}>
					<div className={style.lightModeSwitch}>
						<Switch
							checked={isLightMode}
							onChange={handleLightModeToggle}
							checkedChildren={<SunLightModeIcon />}
							unCheckedChildren={<MoonDarkModeIcon />}
						/>
					</div>
					<McButton
						onClick={() => {
							navigate('/signup');
						}}
						className={style.registerButton}
					>
						Register
					</McButton>
				</div>
			</div>
			<div className={style.containerWrapper}>
				<div className={style.container}>
					<div
						className={`${style.leftHalfContainer} ${style.deviceImageContainer}`}
					>
						<motion.img
							src={DeviceImage}
							alt="Motion Cloud Device"
							className={style.deviceImage}
							initial={{ opacity: 0, x: -200 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.8, ease: 'easeOut' }}
						/>
					</div>
					<div className={style.rightHalfContainer}>
						<div className={style.signFormWrapper}>
							{isLoading ? (
								<McSpinner />
							) : (
								<>
									<div className={style.signFormContent}>
										<h1 className={style.signFormContentText}>
											Sign In to Motion Cloud
										</h1>
										<h4 className={style.subsignFormContentText}>
											Experience the power of Tema Platform
										</h4>
									</div>

									<LoginForm />

									<div className={style.infoSignup}>
										<div>Don't have an account?</div>
										<Link to="/signup">SIGN UP NOW</Link>
									</div>
									{isDesktop && (
										<p className={style.guestText}>
											Continue as a{' '}
											<span
												className={style.guest}
												onClick={loginAsGuestHandler}
											>
												Guest
											</span>
										</p>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Second section (Discover Tema Platform) */}
			<motion.div
				ref={discoverSectionRef}
				className={`${style.container} ${style.discoverSection}`}
				initial={{ opacity: 0 }}
				animate={discoverSectionInView ? { opacity: 1 } : { opacity: 0 }}
				transition={{ duration: 0.8 }}
			>
				<div className={style.discoverContent}>
					<motion.h2
						className={style.sectionTitle}
						initial={{ opacity: 0, y: -20 }}
						animate={
							discoverSectionInView
								? { opacity: 1, y: 0 }
								: { opacity: 0, y: -20 }
						}
						transition={{ duration: 0.5 }}
					>
						Discover Tema Platform
					</motion.h2>
					<motion.p
						initial={{ opacity: 0, y: -20 }}
						animate={
							discoverSectionInView
								? { opacity: 1, y: 0 }
								: { opacity: 0, y: -20 }
						}
						transition={{ duration: 0.5, delay: 0.2 }}
						className={style.discoverSubtitle}
					>
						Unlock Motion Cloud's potential
					</motion.p>
					<div className={style.featureCards}>
						<FeatureCard
							title="Motion Cloud"
							subtitle="Simplify Your Workflow"
							description="Motion Cloud is your central hub for managing TEMA software and subscriptions. Access tutorials, templates, and advanced features to streamline your video analysis workflow. Elevate your projects with tools designed for precision and ease."
							ctaText="Sign Up Now"
							imageSrc={MotionCloudImage}
							index={0}
						/>

						<FeatureCard
							title="TEMA 2D"
							subtitle="Precision in 2D Tracking"
							description="Track any object in 2D with advanced Point, Outline, and Deformation algorithms."
							ctaText="Join the Revolution"
							imageSrc={Tema2DImage}
							index={1}
						/>

						<FeatureCard
							title="TEMA 3D"
							subtitle="Master 3D Motion"
							description="Unlock 3D Motion Analysis with industry-leading tracking algorithms, including 6DoF."
							ctaText="Start Your Journey"
							imageSrc={Tema3DImage}
							index={2}
						/>
					</div>
				</div>
			</motion.div>

			{/* Third section (I don't have an account) */}
			<div className={style.containerWrapper}>
				<motion.div
					ref={gettingStartedSectionRef}
					className={`${style.container} ${style.gettingStartedSection}`}
					initial={{ opacity: 0 }}
					animate={
						gettingStartedSectionInView ? { opacity: 1 } : { opacity: 0 }
					}
					transition={{ duration: 0.8 }}
				>
					<div className={style.gettingStartedContent}>
						<motion.div
							className={style.gettingStartedText}
							initial={{ opacity: 0, x: -50 }}
							animate={
								gettingStartedSectionInView
									? { opacity: 1, x: 0 }
									: { opacity: 0, x: -50 }
							}
							transition={{ duration: 0.5 }}
						>
							<motion.div
								className={style.noAccountImageWrapper}
								initial={{ opacity: 0, scale: 0.8 }}
								animate={
									gettingStartedSectionInView
										? { opacity: 1, scale: 1 }
										: { opacity: 0, scale: 0.8 }
								}
								transition={{ duration: 0.5, delay: 0.2 }}
							>
								<img
									src={NoAccountImage}
									alt="No Account"
									className={style.noAccountImage}
								/>
							</motion.div>
							<h2 className={style.sectionTitle}>I don't have an account</h2>
							<h3>Getting started with Tema Platform</h3>
						</motion.div>
						<div className={style.gettingStartedContentWrapper}>
							<div className={style.stepCards}>
								<StepCard
									step={1}
									title="Sign Up"
									description="Create your account in just a few clicks."
								/>
								<StepCard
									step={2}
									title="Explore Motion Cloud"
									description="Tailor your experience to fit your needs."
								/>
								<StepCard
									step={3}
									title="Download Desktop App"
									description="Log in and explore Motion Cloud's features."
								/>
								<StepCard
									step={4}
									title="Install and activate Tema Platform"
									description="Invite your users"
								/>
							</div>
							<motion.div
								className={style.signUpPrompt}
								initial={{ opacity: 0, y: 20 }}
								animate={
									gettingStartedSectionInView
										? { opacity: 1, y: 0 }
										: { opacity: 0, y: 20 }
								}
								transition={{ duration: 0.5, delay: 0.8 }}
							>
								<p>Don't have an account?</p>
								<motion.div
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
								>
									<McButton onClick={() => navigate('/signup')}>
										Sign Up Now
									</McButton>
								</motion.div>
							</motion.div>
						</div>
					</div>
				</motion.div>
			</div>
		</>
	);
};
