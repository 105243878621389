import React, { Dispatch, SetStateAction } from 'react';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash } from 'assets/icons/svg';
import { SupportItemDto } from 'api';
import { App } from 'antd';
import { SupportItemForm } from './SupportItemForm';
import { McIconButton } from 'components/mc';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import supportService from 'services/SupportService';

interface ManageOneSupportProps {
	support: SupportItemDto;
	selectedToUpdate: number | null;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
}

export const ManageOneSupport: React.FC<ManageOneSupportProps> = ({
	support,
	selectedToUpdate,
	setSelectedToUpdate,
}) => {
	const { notification, modal } = App.useApp();
	const queryClient = useQueryClient();
	const styles =
		selectedToUpdate === support.id
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const { mutate: deleteSupportItem } = useMutation({
		mutationFn: (id: number) => supportService.deleteSupportItemById(id),
		onSuccess: () => {
			notification.success(deleteSuccessNotification());
			queryClient.invalidateQueries({ queryKey: ['supportItems'] });
		},
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'support item')
			),
	});

	return (
		<>
			<div
				role="button"
				onClick={() =>
					setSelectedToUpdate((c) => (c === support.id ? null : support.id))
				}
			>
				<ItemContainer styles={styles}>
					<div
						className={style.textItem}
						style={{
							flex: '1',
						}}
					>
						{support.question}
					</div>
					<div
						style={{
							borderRight: '0.0625rem var(--text-disabled) solid',
							paddingRight: '1rem',
							marginRight: '1rem',
						}}
					>
						{support.itemType}
					</div>
					<McIconButton
						icon={<IconEdit />}
						onClick={() =>
							setSelectedToUpdate((curr) =>
								curr === support.id ? null : support.id
							)
						}
					/>
					<McIconButton
						icon={<IconTrash />}
						onClick={() => {
							modal.confirm(
								deleteItemModal(() => deleteSupportItem(support.id))
							);
						}}
					/>
				</ItemContainer>
			</div>
			{selectedToUpdate === support.id && (
				<SupportItemForm
					hideForm={() => setSelectedToUpdate(null)}
					supportItem={support}
				/>
			)}
		</>
	);
};
