import { SupportItemType, SupportItemTypes } from './api';

export type ItemtypeSelection = undefined | SupportItemType;

export const itemtypeSelectionValues: ItemtypeSelection[] = [
	undefined,
	'QA',
	'MANUAL',
	'TUTORIAL',
];
export const itemtypeSelectionLabels = ['All', 'Q&A', 'Manual', 'Tutorial'];

export const itemtypeSelectionOptions: {
	label: string;
	value: SupportItemType;
}[] = [
	{ label: 'Q&A', value: SupportItemTypes.QA },
	{ label: 'Manual', value: SupportItemTypes.MANUAL },
	{ label: 'Tutorial', value: SupportItemTypes.TUTORIAL },
];
