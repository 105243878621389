import { useState, FC, useMemo, useEffect, Dispatch } from 'react';
import style from 'assets/styles/editAddElementForm.module.scss';
import { AddLicensedModulesForm } from './Licenses/AddLicensedModulesForm';
import { ManageOneLicense } from './Licenses/ManageOneLicense';
import { App, Spin, Tooltip } from 'antd';
import { EditLicensedModulesForm } from './Licenses/EditLicensedModulesForm';
import { McButton, McDialog } from 'components/mc';
import RedDot from 'components/RedDot';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FullDataLicenseDto, SubscriptionDto } from 'api';
import subscriptionsService from 'services/SubscriptionsService';
import { infoNotification, mcErrorNotification } from 'utils/Notifications';
import AdminLicenseRequestTable from 'modules/AdminLicenseRequestTable';
import { HelpIcon } from 'assets/icons/svg';
import licensesService from 'services/LicenseService';

interface LicensesProps {
	subscription: SubscriptionDto;
	hasOpenRequests?: boolean;
	setIsLoading?: Dispatch<boolean>;
}

export const LicensesBlock: FC<LicensesProps> = ({
	subscription,
	hasOpenRequests,
	setIsLoading,
}: LicensesProps) => {
	const { notification, modal } = App.useApp();

	const {
		data: licenses,
		isLoading,
		error: getLicensesError,
	} = useQuery({
		queryKey: ['subscriptions', subscription.id, 'licenses'],
		queryFn: () =>
			subscriptionsService
				.getAllLicenses(subscription.id)
				.then((res) => res.data),
		enabled: !!subscription,
		initialData: [] as FullDataLicenseDto[],
	});

	useEffect(() => {
		if (!getLicensesError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getLicensesError,
				'fetch',
				'list of licenses for this subscription'
			)
		);
	}, [getLicensesError, notification]);

	const [showAddLicModulesWindow, setShowAddLicModulesWindow] = useState(false);
	const [showEditLicModulesWindow, setShowEditLicModulesWindow] =
		useState(false);
	const [showManageRequestsWindow, setShowManageRequestsWindow] =
		useState(false);
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);

	const selectedLicense = useMemo(
		() => licenses.find((l) => l.id === selectedToUpdate),
		[selectedToUpdate, licenses]
	);

	const { mutate: resetAllExpired, isPending: isResetingExpired } = useMutation(
		{
			mutationFn: () =>
				licensesService.resetAllExpiredLicenses().then((res) => res.data),
			onSuccess: (data) =>
				notification.success(infoNotification(data + ' rows were updated!')),
			onError: (err: unknown) => {
				notification.error(
					mcErrorNotification('Error', err, 'update', 'licenses')
				);
			},
		}
	);

	useEffect(() => {
		setIsLoading?.(isLoading);
	}, [isLoading, setIsLoading]);

	return (
		<>
			<div className={style.blockWrapper}>
				<div className={style.blockHeader}>
					<h3 className={style.blockTitle} style={{ marginRight: 'auto' }}>
						Licenses
					</h3>
					<Tooltip title={'Refresh all expired licenses'}>
						<McButton
							onClick={() => {
								modal.confirm({
									title: 'Refresh expired licenses',
									content: (
										<div style={{ paddingBottom: '16px' }}>
											<p>
												This action updates all expired licenses and sets them
												to available.
											</p>
											<br />
											<b>
												<u>Note</u>
											</b>
											<p>
												This action is usually not required as similar checks
												are performed during license requests. This function is
												also performed once per day during the maintenence
												window (midnight UTC).
											</p>
										</div>
									),
									centered: true,
									maskClosable: true,
									okText: 'Refresh',
									onOk: () => {
										resetAllExpired();
									},
								});
							}}
						>
							{isResetingExpired ? (
								<div>
									<Spin spinning={isResetingExpired} />
								</div>
							) : (
								<HelpIcon />
							)}
						</McButton>
					</Tooltip>
					<McButton
						disabled={!selectedToUpdate}
						onClick={() => setShowEditLicModulesWindow(true)}
					>
						Manual activations
					</McButton>
					<div style={{ position: 'relative' }}>
						<McButton
							onClick={() => {
								setShowManageRequestsWindow((prev) => !prev);
							}}
						>
							Manage requests
							{hasOpenRequests && (
								<RedDot
									additionalStyle={{
										position: 'absolute',
										top: '0',
										right: '0',
									}}
								/>
							)}
						</McButton>
					</div>
					<McButton
						onClick={() => {
							setShowAddLicModulesWindow((prev) => !prev);
						}}
					>
						Add licenses
					</McButton>
				</div>
				<div className={style.blockMainPart}>
					<Spin spinning={isLoading}>
						{licenses
							.sort((a, b) => a.id - b.id)
							.map((license) => {
								return (
									<div key={license.id} className={style.dividerElements}>
										<ManageOneLicense
											subscription={subscription}
											license={license}
											setSelectedToUpdate={setSelectedToUpdate}
											selectedToUpdate={selectedToUpdate}
											showEditLicModulesWindow={showEditLicModulesWindow}
											setShowEditLicModulesWindow={setShowEditLicModulesWindow}
										/>
									</div>
								);
							})}
					</Spin>
				</div>
			</div>
			{showAddLicModulesWindow && (
				<AddLicensedModulesForm
					subscription={subscription}
					setShowAddLicModulesWindow={setShowAddLicModulesWindow}
				/>
			)}
			{showManageRequestsWindow && (
				<McDialog
					modal
					title={'Licensing Requests'}
					footer={
						<McButton
							onClick={(e) => {
								e.stopPropagation();
								setShowManageRequestsWindow(false);
							}}
						>
							Close
						</McButton>
					}
					size={100}
				>
					<AdminLicenseRequestTable subscription={subscription} />
				</McDialog>
			)}
			{showEditLicModulesWindow && selectedLicense && (
				<EditLicensedModulesForm
					subscription={subscription}
					setShowEditLicModulesWindow={setShowEditLicModulesWindow}
					license={selectedLicense}
					setSelectedToUpdate={setSelectedToUpdate}
				/>
			)}
		</>
	);
};
