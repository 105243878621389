import { useAppSelector } from 'hooks/hooks';
import { Preloader } from 'modules/Preloader';
import { Navigate } from 'react-router-dom';
import { getLocalSetting, setLocalSetting } from 'settings';
import { authState } from 'store/slices/auth';

export const AuthRoute = ({ children }: { children: JSX.Element }) => {
	const { isAuth, loading } = useAppSelector(authState);

	if (!loading && isAuth) {
		const redirect = getLocalSetting('redirectAfterLogin') ?? '/';
		setLocalSetting('redirectAfterLogin', null);
		return <Navigate to={redirect} replace={true} />;
	}
	return loading ? <Preloader /> : children;
};
