import { useLightMode } from 'hooks/useLightMode';
import { FC } from 'react';
import { McButton } from './McButton';
import { McTextBox } from './McTextBox';
import { McTable } from './McTable';
import { McDialog } from './McDialog';
import * as svg from 'assets/icons/svg';
import { TooltipBubble } from 'components/TooltipBubble';

export const ComponentsPage: FC = () => {
	const [lightMode, setLightmode] = useLightMode();

	const icons = [
		...Object.entries(svg).map(([variant, icon]) => ({ variant, icon })),
	];

	return (
		<div
			style={{
				padding: '1rem',
				color: 'var(--text-normal)',
				background: 'var(--surface)',
			}}
		>
			<div>
				<button
					style={{
						marginRight: '0.25rem',
						border: '2px solid var(--border-popup)',
						background: lightMode ? 'transparent' : 'var(--button-bg)',
						fontSize: '1.25rem',
					}}
					onClick={() => setLightmode(false)}
				>
					{'🌙'}
				</button>
				<button
					style={{
						marginRight: '0.25rem',
						border: '2px solid var(--border-popup)',
						background: 'transparent',
						fontSize: '1.25rem',
					}}
					onClick={() => setLightmode(null)}
					title="Set to user/OS preference"
				>
					{'👤'}
				</button>
				<button
					style={{
						marginRight: '1rem',
						border: '2px solid var(--border-popup)',
						background: lightMode ? 'var(--button-bg)' : 'transparent',
						fontSize: '1.25rem',
					}}
					onClick={() => setLightmode(true)}
				>
					{'☀️'}
				</button>
				<h2 style={{ display: 'inline-block' }}>
					{lightMode ? 'Cirrus (light)' : 'Nimbus (dark)'}
				</h2>
			</div>
			<Section header="McButton">
				<Example variant="Secondary">
					<McButton>Normal</McButton>
					<button className="mc-button mc-hover">Hover</button>
					<button className="mc-button mc-focused">Focused</button>
					<button className="mc-button mc-disabled">Disabled</button>
				</Example>
				<Example variant="Primary">
					<McButton primary>Label</McButton>
					<button className="mc-button mc-primary mc-hover">Hover</button>
					<button className="mc-button mc-primary mc-focused">Focused</button>
					<button className="mc-button mc-primary mc-disabled">Disabled</button>
				</Example>
				<Example variant="ButtonCol" className="mc-buttonsCol">
					<McButton.Col>
						<McButton primary>Primary</McButton>
						<McButton>Secondary</McButton>
					</McButton.Col>
				</Example>
				<Example variant="ButtonRow" className="mc-buttonsCol">
					<McButton.Row>
						<McButton primary>Short</McButton>
						<McButton>Much longer text</McButton>
					</McButton.Row>
				</Example>
			</Section>
			<Section header="McTextBox">
				<Example variant="Normal">
					<McTextBox label="Empty" />
					<McTextBox label="Placeholder" placeholder="Placeholder" />
					<McTextBox label="Value" defaultValue="Value" />
				</Example>
				<Example variant="Disabled/ReadOnly">
					<McTextBox disabled label="Disabled" defaultValue="Text" />
					<McTextBox readOnly label="ReadOnly" defaultValue="Text" />
					<McTextBox label="Placeholder (for reference)" placeholder="Text" />
				</Example>
				<Example variant="Error">
					<McTextBox
						label="With error"
						defaultValue="one"
						error='"one" is not a number'
					/>
				</Example>
			</Section>
			<Section header="McTable">
				<Example variant="Normal">
					<McTable headers={['One', 'Two', 'Three', 'Four']}>
						<McTable.Row>
							<McTable.Cell>1</McTable.Cell>
							<McTable.Cell>2</McTable.Cell>
							<McTable.Cell>3</McTable.Cell>
							<McTable.Cell>4</McTable.Cell>
						</McTable.Row>
						<McTable.Row>
							<McTable.Cell>I</McTable.Cell>
							<McTable.Cell>II</McTable.Cell>
							<McTable.Cell>III</McTable.Cell>
							<McTable.Cell>IV</McTable.Cell>
						</McTable.Row>
					</McTable>
				</Example>
				<Example variant="Loading">
					<McTable headers={['One', 'Two', 'Three', 'Four']} loading />
				</Example>
				<Example variant="Empty">
					<McTable
						headers={['One', 'Two', 'Three', 'Four']}
						items={[]}
						emptyText="No items 😔"
					/>
				</Example>
			</Section>
			<Section header="McDialog">
				<Example variant="Normal">
					<McDialog title="Dialog">
						<div
							style={{
								border: '1px #80808080 dashed',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flex: 'auto',
								height: '5rem',
							}}
						>
							Content
						</div>
					</McDialog>
				</Example>
			</Section>
			<Section header="TooltipBubble">
				<Example variant="Left">
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							flex: 'auto',
							height: '5rem',
						}}
					>
						<TooltipBubble position="left" tooltiptext="Your tooltip text" />
						<h4>
							<code style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
								&lt;TooltipBubble position="left" tooltiptext="Your tooltip
								text" /&gt;
							</code>
						</h4>
					</div>
				</Example>
				<Example variant="Right">
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							flex: 'auto',
							height: '5rem',
						}}
					>
						<TooltipBubble position="right" tooltiptext="Your tooltip text" />
						<h4>
							<code style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
								&lt;TooltipBubble position="right" tooltiptext="Your tooltip
								text" /&gt;
							</code>
						</h4>
					</div>
				</Example>
			</Section>
			<Section header="Icons">
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						flexWrap: 'wrap',
						gap: '1rem',
						width: '100%',
					}}
				>
					{icons.map((item, index) => (
						<div
							key={index}
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								width: '10rem',
								height: '4rem',
								justifyContent: 'space-evenly',
							}}
							title={item.variant}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '24px',
									height: '24px',
								}}
							>
								{item.icon({})}
							</div>

							<code
								style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}
							>{`<${item.variant} />`}</code>
						</div>
					))}
				</div>
			</Section>
		</div>
	);
};

interface SectionProps extends ChildrenProps {
	header: string;
}
const Section: FC<SectionProps> = (props) => {
	return (
		<section style={{ marginTop: '1rem' }}>
			<details open>
				<summary
					style={{ fontSize: '1.17em', cursor: 'pointer', userSelect: 'none' }}
				>
					{props.header}
				</summary>
				<div
					style={{
						display: 'flex',
						padding: '1rem',
						boxShadow: 'inset 2px 2px 10px #00000030',
						columnGap: '1rem',
						background: 'var(--bg)',
					}}
				>
					{props.children}
				</div>
			</details>
		</section>
	);
};

interface ExampleProps extends ChildrenProps {
	variant: string;
	style?: any;
	className?: any;
}
const Example: FC<ExampleProps> = (props) => {
	return (
		<section>
			<h4>{props.variant}</h4>
			<div
				className={props.className}
				style={{
					...props.style,
					flex: 'auto',
					display: 'flex',
					flexDirection: 'column',
					rowGap: '1rem',
					justifyContent: 'center',
					alignItems: 'stretch',
					border: '1px silver dashed',
					padding: '1rem',
				}}
			>
				{props.children}
			</div>
		</section>
	);
};
