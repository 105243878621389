import style1 from 'assets/styles/components.module.scss';
import style2 from 'assets/styles/applicationReleaseField.module.scss';
import style3 from 'assets/styles/selectField.module.scss';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { EngineDto } from 'api';
import applicationsService from 'services/ApplicationsService';
import { getPublicImageURL } from 'services/ApiService';
import { App, Select, Spin } from 'antd';
import { Option } from 'components/selects';
import { IconTrash, PlusIcon } from 'assets/icons/svg';
import Icon from '@ant-design/icons';
import SelectAppGenerationRelease from 'components/selects/SelectAppGenerationRelease';
import { warningNotification } from 'utils/Notifications';
import enginesService from 'services/EnginesService';
import { AppIcon, TemaGenericAppIcon } from 'assets/icons/apps';
import { useQuery } from '@tanstack/react-query';

// Maps an application id to an application release id (can be undefined to indicate that a new release should be created for the application)
export type AppIdsToReleaseIds = [number, number | undefined];

interface Props {
	selectedApplicationReleases: AppIdsToReleaseIds[];
	setSelectedApplicationReleases: Dispatch<
		SetStateAction<AppIdsToReleaseIds[]>
	>;
	tabIndex?: number;
	existingEngine?: EngineDto;
	setLoading: Dispatch<boolean>;
}

const ApplicationReleaseField = ({
	selectedApplicationReleases,
	setSelectedApplicationReleases,
	tabIndex,
	existingEngine,
	setLoading,
}: Props) => {
	const [initialReleases, setInitialReleases] = useState<{
		[appId: number]: number;
	}>([]);

	const { notification } = App.useApp();

	// Fetches all applications
	const {
		data: applicationList,
		error: applicationListError,
		isLoading: loadingApplications,
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50)
				.then((res) => res.data.content),
		initialData: [],
	});

	// Fetches featured engine
	const {
		data: featuredEngine,
		error: featuredEngineError,
		isLoading: loadingFeaturedEngine,
	} = useQuery({
		queryKey: ['engines', 'featured'],
		queryFn: () =>
			enginesService.getFeaturedEngineItem().then((res) => res.data),
	});

	// Fetches pre-existing releases
	const {
		data: applicationReleases,
		error: existingApplicationReleasesError,
		isLoading: loadingPreExisting,
	} = useQuery({
		queryKey: ['engines', existingEngine?.id, 'releases'],
		queryFn: () =>
			enginesService
				.getApplicationReleasesByEngineId(existingEngine!.id)
				.then((res) => res.data),
		enabled: !!existingEngine,
		initialData: [],
	});

	useEffect(() => {
		if (applicationReleases) {
			setSelectedApplicationReleases(
				applicationReleases.map(
					(release) => [release.applicationId, release.id] as AppIdsToReleaseIds
				)
			);
			setInitialReleases((initialReleases) => {
				applicationReleases.forEach(
					(release) => (initialReleases[release.applicationId!] = release.id)
				);
				return initialReleases;
			});
		}
	}, [applicationReleases, setSelectedApplicationReleases]);

	const loading =
		loadingApplications || loadingFeaturedEngine || loadingPreExisting;

	useEffect(() => {
		setLoading(loading);
	}, [loading, setLoading]);

	useEffect(() => {
		if (applicationListError)
			notification.warning(
				warningNotification('Could not fetch applications!')
			);
	}, [applicationListError, notification]);

	useEffect(() => {
		if (featuredEngineError)
			notification.warning(
				warningNotification('Could not fetch featured engine!')
			);
	}, [featuredEngineError, notification]);

	useEffect(() => {
		if (existingApplicationReleasesError)
			notification.warning(
				warningNotification(
					'Failed to fetch pre-existing application releases!'
				)
			);
	}, [existingApplicationReleasesError, notification]);

	const applicationOptions = useMemo<Option[]>(
		() =>
			(applicationList ?? []).map(
				(application) =>
					({
						value: application.id,
						label: (
							<div className={style3.dropdownItem}>
								<div className={style3.labelIconName}>
									{application.iconImagePath ? (
										<img
											alt=""
											src={getPublicImageURL(application.iconImagePath)}
											width={24}
											height={24}
										/>
									) : (
										<AppIcon app="" size={24} />
									)}
									{application.name}
								</div>
							</div>
						),
						searchValue: application.name.replace(/ /g, ''),
					} as Option)
			),
		[applicationList]
	);

	const handleOnChange = (appId: number) => {
		if (!selectedApplicationReleases.find((element) => element[0] === appId))
			setSelectedApplicationReleases((selectedApplicationReleases) => {
				const elementToAdd: AppIdsToReleaseIds = [
					appId,
					initialReleases[appId],
				];
				return [...selectedApplicationReleases, elementToAdd];
			});
	};

	const removeSelectedApplication = (appId: number) => {
		setSelectedApplicationReleases((selectedApplicationReleases) =>
			selectedApplicationReleases.filter((rel) => rel[0] !== appId)
		);
	};

	return (
		<div
			className={style1.inputFieldWrapper}
			style={{ marginBottom: '1.5rem' }}
		>
			<span className={style1.inputFieldLabel}>Applications</span>
			<div className={style2.fieldWrapper}>
				{loading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							margin: '1rem auto',
						}}
					>
						<Spin spinning={loading} size="large" />
					</div>
				) : (
					<>
						{applicationList.length !== 0 &&
							selectedApplicationReleases
								.map(
									(appToRel) =>
										applicationList.find((app) => app.id === appToRel[0])!
								)
								.map((application) => (
									<div
										className={style2.applicationWrapper}
										key={application.id}
									>
										<div className={style2.itemContainer}>
											{application.iconImagePath ? (
												<img
													alt=""
													src={getPublicImageURL(application.iconImagePath)}
													width={24}
													height={24}
												/>
											) : (
												<TemaGenericAppIcon size={24} />
											)}

											<div className={style2.iconLabel}>{application.name}</div>
										</div>
										<div className={style2.itemContainer}>
											<SelectAppGenerationRelease
												application={application}
												initalReleaseId={initialReleases[application.id]}
												setSelectedApplicationReleases={
													setSelectedApplicationReleases
												}
												featuredReleases={featuredEngine?.applicationReleaseIds}
											/>
											<div
												style={{
													color: 'var(--text-normal)',
													cursor: 'pointer',
													marginLeft: '1rem',
												}}
												onClick={() =>
													removeSelectedApplication(application.id)
												}
											>
												<IconTrash />
											</div>
										</div>
									</div>
								))}
						<Select
							onChange={handleOnChange}
							value={null}
							className={style2.applicationSelect}
							options={applicationOptions}
							variant="borderless"
							placeholder={
								<div className={style2.itemContainer}>
									<Icon component={PlusIcon} />
									<div className={style2.iconLabel}>Add application</div>
								</div>
							}
							optionFilterProp="children"
							tabIndex={tabIndex}
							getPopupContainer={(trigger: any) => trigger.parentNode}
							virtual={false}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ApplicationReleaseField;
