import React from 'react';
import style from 'assets/styles/components.module.scss';

interface Props extends ChildrenProps {
	label?: string;
	error?: string;
}
export const FormItem: React.FC<Props> = ({ children, label, error }) => {
	return (
		<>
			<div className={style.inputFieldWrapper}>
				<span className={style.inputFieldLabel}>{label ?? ''}</span>
				{children}
			</div>
			<div className={style.errorMessage}>{error ?? ''}</div>
		</>
	);
};
