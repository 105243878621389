import { Select } from 'antd';
import style from 'assets/styles/components.module.scss';
import React from 'react';
import { FieldError } from 'react-hook-form';

type SelectProps<V> = React.ComponentPropsWithoutRef<typeof Select<V>>;

export interface OptionType<V> {
	label: React.ReactNode;
	value?: V;
}

export type SelectOpts<V> = OptionType<V>[];

interface Props<V extends string | number | null | undefined>
	extends SelectProps<V> {
	defaultValue?: V;
	value?: V;
	setSelectedField?: React.Dispatch<V>;
	label?: string;
	error?: FieldError | undefined;
	simpleOptions?: string[];
	filterField?: boolean;
}

export const SelectField = <V extends string | number | null | undefined>(
	props: Props<V>
) => {
	const {
		filterField,
		className,
		setSelectedField,
		label,
		error,
		simpleOptions,
		onChange,
		...fwdProps
	} = props;

	const fwdClassName = [
		style.multiSelectField,
		props.filterField ? style.filterField : '',
		props.className ?? '',
	].join(' ');

	const fwdOnChange =
		onChange ??
		(typeof setSelectedField === 'function'
			? (v) => setSelectedField(v)
			: () => {});

	const options =
		props.options ??
		(props.simpleOptions
			? props.simpleOptions.map((o) => ({ label: o, value: o }))
			: undefined);

	const innerContent = (
		<Select
			{...fwdProps}
			className={fwdClassName}
			onChange={fwdOnChange}
			options={options}
			getPopupContainer={(trigger) => trigger.parentNode}
			style={{ border: error ? '1.5px solid var(--add-red)' : '' }}
		/>
	);

	return filterField ? (
		innerContent
	) : (
		<>
			<div className={style.inputFieldWrapper}>
				<span className={style.inputFieldLabel}>{props.label}</span>
				{innerContent}
			</div>
			<div className={style.errorMessage}>{props.error?.message ?? ''}</div>
		</>
	);
};
