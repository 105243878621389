import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface TourInterface {
	open: boolean;
}

const initialState: TourInterface = {
	open: true,
};

const tourSlice = createSlice({
	name: 'tour',
	initialState: initialState,
	reducers: {
		setTourOpen: (state, action) => {
			state.open = action.payload;
		},
	},
});

export const tourState = (state: RootState) => state.tour;

export const { setTourOpen } = tourSlice.actions;

export default tourSlice.reducer;
