import { Spin } from 'antd';
import style from 'assets/styles/supportPage.module.scss';
import { SupportItemDto, TagDto } from 'api';
import { DownloadIcon } from 'assets/icons/svg';
import { FC, useState, useEffect, useMemo } from 'react';
import supportService, { getSupportURL } from 'services/SupportService';
import { Cond } from 'utils/Cond';
import { McMarkdown } from 'components/mc';
import { getPublicImageURL } from 'services/ApiService';

interface Props {
	supportItem: SupportItemDto;
	allTags: TagDto[];
}

export const SupportPanelContent: FC<Props> = ({
	supportItem,
	allTags,
}: Props) => {
	const { tagIds, answer } = supportItem;

	const [download, setDownload] = useState<{ url: string; filename: string }>();
	useEffect(() => {
		if (!supportItem?.downloadLink) return;
		Promise.all([
			getSupportURL('getSupportItemFile', [supportItem.id]),
			supportService.getSupportItemFileName(supportItem.id).then((r) => r.data),
		])
			.then(([url, filename]) => setDownload({ url, filename }))
			.catch(() => {});
	}, [supportItem]);

	const supportItemTags = useMemo(
		() => allTags.filter((tag) => tagIds.includes(tag.id)),
		[allTags, tagIds]
	);

	return (
		<Spin spinning={!supportItem}>
			<div style={{ marginTop: '1.5rem' }}>
				<McMarkdown content={answer} />
			</div>
			<Cond if={supportItem?.fullSizeImagePath}>
				<img
					className={style.supportItemImg}
					src={getPublicImageURL(supportItem?.fullSizeImagePath!)}
					alt=""
				/>
			</Cond>

			<div
				className={style.supportItemTagsRow}
				style={{ marginTop: tagIds && tagIds.length > 0 ? '1rem' : '0' }}
			>
				<div className={style.supportItemTags}>
					{supportItemTags.map((tag) => (
						<span className={style.supportItemTag}>{tag.name}</span>
					))}
				</div>
				<Cond if={supportItem?.downloadLink}>
					<Spin size="small" spinning={!download}>
						<a
							style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
							href={download?.url}
							download={download?.filename}
						>
							Download Attachment
							<DownloadIcon />
						</a>
					</Spin>
				</Cond>
			</div>
		</Spin>
	);
};
