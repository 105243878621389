import { HTMLAttributes, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type DivAttrs = HTMLAttributes<HTMLDivElement>;

export const McDialogContent: React.FC<DivAttrs & ChildrenProps> = (props) => {
	const divProps = { ...props, children: undefined };
	return (
		<div className="mc-dialogBody" {...divProps}>
			{props.children}
		</div>
	);
};

export const McDialogTitle: React.FC<DivAttrs & ChildrenProps> = (props) => {
	const divProps = { ...props, children: undefined };
	return (
		<div className="mc-dialogTitle" {...divProps}>
			{props.children}
		</div>
	);
};

type DialogSize = 'small' | 'large' | number;

interface Props extends ChildrenProps {
	title?: string;
	header?: ReactNode;
	footer?: ReactNode;
	modal?: boolean;
	size?: DialogSize;
}

export const McDialogComponent: React.FC<Props> = (props) => {
	const { modal } = props;
	const header = props.title ? (
		<McDialogTitle>{props.title}</McDialogTitle>
	) : (
		props.header
	);

	const sizeWidth =
		typeof props.size === 'number'
			? props.size
			: props.size === 'large'
			? 70
			: props.size === 'small'
			? 30
			: 70;

	const content = (
		<>
			<div className={`mc-dialogBackdrop${modal ? ' mc-modal' : ''}`}>
				<dialog
					className="mc-dialog"
					aria-modal={modal}
					style={{ width: `${sizeWidth}rem` }}
				>
					<header className="mc-dialogHeader">{header}</header>
					<main className="mc-dialogContent">{props.children}</main>
					<footer className="mc-dialogFooter">{props.footer}</footer>
				</dialog>
			</div>
		</>
	);

	return modal ? createPortal(content, document.body) : content;
};

export const McDialog = McDialogComponent as typeof McDialogComponent & {
	Content: typeof McDialogContent;
	Title: typeof McDialogTitle;
};
(McDialog as any).Content = McDialogContent;
(McDialog as any).Title = McDialogTitle;
