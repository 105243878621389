import React, { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import style from 'assets/styles/manageElements.module.scss';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import { Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { ManageOneNews } from 'modules/ManageNews/ManageOneNews';
import { useDebounce } from 'hooks/useDebounce';
import {
	newsState,
	getNews,
	NewsStatus,
	NewsCategory,
} from 'store/slices/news';
import { oneNewsState } from 'store/slices/oneNews';
import { EditNewsForm } from 'modules/ManageNews/EditNewsForm';
import { FilterField } from 'components/fields';
import { Cond } from 'utils/Cond';
import { getEditRowIndex } from 'utils/layout';
import { McButton, McMarkdown } from 'components/mc';

export const ManageNews: React.FC = () => {
	const dispatch = useAppDispatch();

	const [newsForSearch, setNewsForSearch] = useState('');
	const [selStatus, setSelStatus] = useState<NewsStatus | undefined>();
	const [selCategory, setSelCategory] = useState<NewsCategory | undefined>();
	const [pageSize, setPageSize] = useState(12);
	const [showAddNewsForm, setShowAddNewsForm] = useState(false);
	const [selectedToUpdate, setSelectedToUpdate] = useState<number | null>(null);
	const [indexNewsForEdit, setIndexNewsForEdit] = useState<number | null>(null);
	const [refresh, setRefresh] = useState(false);
	const [searchedValue, setSearchedValue] = useState('');
	const debouncedSearchTerm = useDebounce(newsForSearch, 700);

	const { newsList, loading } = useAppSelector(newsState);

	useEffect(() => {
		if (debouncedSearchTerm) {
			setSearchedValue(debouncedSearchTerm);
		} else {
			setSearchedValue(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm]);

	const { news: newsWithFullSizeImg } = useAppSelector(oneNewsState);

	useEffect(() => {
		dispatch(
			getNews([0, pageSize, searchedValue, selStatus, selCategory, undefined])
		);
	}, [
		dispatch,
		searchedValue,
		refresh,
		setRefresh,
		pageSize,
		selectedToUpdate,
		selStatus,
		selCategory,
	]);

	const [showViewMore, setShowViewMore] = useState(false);

	useEffect(() => {
		if (!newsList) return;
		setShowViewMore(newsList.allElements > pageSize);
	}, [pageSize, newsList]);

	const editRowIndex = useMemo(
		() => getEditRowIndex(3, indexNewsForEdit, newsList?.content?.length),
		[indexNewsForEdit, newsList]
	);

	const newsItems = newsList?.content ?? [];
	const atLeastOneNewsItem = !!newsList && newsList.allElements > 0;

	return (
		<>
			<div className={style.containerManageNews}>
				<h1 className={style.header}>Manage News</h1>
				{!loading &&
				searchedValue === '' &&
				!selStatus &&
				!atLeastOneNewsItem ? (
					<>
						<div
							className={style.noElementsInfo}
							style={{ marginTop: '2rem', marginBottom: '1.5rem' }}
						>
							There are no News to show here
							<AlertCircle />
						</div>
						<AddButton onClick={() => setShowAddNewsForm((prev) => !prev)} />
					</>
				) : (
					<>
						<div className={style.amountInfo}>
							{newsList?.allElements}
							{` News`}
						</div>
						<div className={style.searchAddFields}>
							<div style={{ flex: '1' }} className={style.searchFieldWrapper}>
								<SearchField
									placeholder={'Search'}
									value={newsForSearch}
									onChange={(e) => {
										setNewsForSearch(e.target.value);
									}}
								/>
							</div>
							<div style={{ flex: '0 0 10.3125rem' }}>
								<FilterField
									label={'Category: '}
									selValue={selCategory}
									values={[
										undefined,
										'NEWS',
										'ADMIN_NOTICE',
										'SOFTWARE_RELEASE',
									]}
									labels={['All', 'News', 'Admin notice', 'Software release']}
									admin={true}
									setSelectedField={setSelCategory}
								/>
							</div>
							<div style={{ flex: '0 0 10.3125rem' }}>
								<FilterField
									label={'Status: '}
									selValue={selStatus}
									values={[undefined, 'DRAFT', 'PUBLISHED']}
									labels={['All', 'Drafts', 'Published']}
									admin={true}
									setSelectedField={setSelStatus}
								/>
							</div>
							<AddButton onClick={() => setShowAddNewsForm((prev) => !prev)} />
						</div>
					</>
				)}

				<div style={{ marginTop: '2rem' }}>
					{showAddNewsForm && (
						<EditNewsForm
							onHide={(saved) => {
								setShowAddNewsForm(false);
							}}
							setRefresh={setRefresh}
							newItem={true}
						/>
					)}
				</div>
				{newsList?.allElements !== 0 ? (
					<h3 className={style.listTitle}>News List</h3>
				) : (
					<></>
				)}
				<div>
					<Spin spinning={loading} size="large">
						<Cond
							if={
								!loading &&
								!atLeastOneNewsItem &&
								(searchedValue !== '' || selStatus !== undefined)
							}
						>
							<div className={style.noElementsInfo}>
								There are no News to show here
								<AlertCircle />
							</div>
						</Cond>
						<Cond if={atLeastOneNewsItem}>
							<div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
								{newsItems.map((news, index) => {
									return (
										<React.Fragment key={news.id}>
											<div className={style.cardWrapper}>
												<ManageOneNews
													news={{ ...news }}
													setRefresh={setRefresh}
													selectedToUpdate={selectedToUpdate}
													setSelectedToUpdate={setSelectedToUpdate}
													setIndexNewsForEdit={setIndexNewsForEdit}
													indexNews={index}
												/>
											</div>

											<Cond if={editRowIndex === index}>
												<div className={style.editFormWrapper}>
													{newsWithFullSizeImg &&
														selectedToUpdate === newsWithFullSizeImg.id && (
															<EditNewsForm
																onHide={(saved) => {
																	setSelectedToUpdate(null);
																}}
																setRefresh={setRefresh}
																newItem={false}
															/>
														)}
												</div>
											</Cond>
										</React.Fragment>
									);
								})}
							</div>
						</Cond>
					</Spin>
					<Cond if={atLeastOneNewsItem && showViewMore}>
						<div
							className={style.paginationContainer}
							onClick={() => {
								setPageSize((prev) => prev + 12);
							}}
						>
							<McButton>View more</McButton>
						</div>
					</Cond>
				</div>
			</div>
		</>
	);
};
