import React from 'react';
import style from 'assets/styles/components.module.scss';

interface ButtonProps {
	text?: string;
	tabIndex?: number;
	isDisabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SaveButton: React.FC<ButtonProps> = ({
	text = 'Save',
	tabIndex,
	onClick,
	isDisabled,
}) => {
	const styles = isDisabled
		? {
				cursor: 'not-allowed',
		  }
		: {
				cursor: 'pointer',
		  };

	return (
		<>
			<button
				type={onClick ? 'button' : 'submit'}
				className={style.saveButton}
				tabIndex={tabIndex}
				style={styles}
				onClick={onClick}
				disabled={isDisabled}
			>
				{text}
			</button>
		</>
	);
};
