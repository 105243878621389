import React, { FormEventHandler, useState } from 'react';
import { CancelButton, SaveButton } from 'components/buttons';
import style from 'assets/styles/editAddElementForm.module.scss';
import { PopUpWindow } from 'components/PopUpWindow';
import { SelectModules } from 'components/selects';
import { App } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import { SubscriptionDto } from 'api';

interface AddLicModulesProps {
	subscription: SubscriptionDto;
	setShowAddLicModulesWindow: Dispatcher;
}

export const AddLicensedModulesForm: React.FC<AddLicModulesProps> = ({
	subscription,
	setShowAddLicModulesWindow,
}) => {
	const [licensedModules, setLicensedModules] = useState<number[]>([]);
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const addLicModulesHandler: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const { mutate: createLicense } = useMutation({
		mutationFn: () =>
			subscriptionsService.createLicenses(licensedModules, subscription.id),
		onSuccess: () => {
			notification.success(saveSuccessNotification());
			queryClient.invalidateQueries({
				queryKey: ['subscriptions', subscription.id, 'licenses'],
			});
			setShowAddLicModulesWindow(false);
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'create', 'license')
			),
	});

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setShowAddLicModulesWindow(false);
	};

	return (
		<PopUpWindow title={'Add Licensed Modules'}>
			<form className={style.addFormWrapper} onSubmit={addLicModulesHandler}>
				<div>
					<SelectModules
						label={'Select modules'}
						value={licensedModules}
						onChange={(modules) => setLicensedModules(modules)}
						tabIndex={10}
					/>
				</div>
				<div className={style.buttonsWrapper}>
					<CancelButton tabIndex={30} onClickCancel={cancel} />
					<SaveButton tabIndex={20} onClick={() => createLicense()} />
				</div>
			</form>
		</PopUpWindow>
	);
};
