import { theme } from 'antd';
import { ThemeColors, createTheme } from './common';

export const cirrusColors: ThemeColors = {
	primary: '#0daec9',
	primaryDark: '#068298',
	surface: '#ffffff',
	textNormal: '#424444',
	textSubtitle: '#222528',
	textPrimary: '#01b2d0',
	textDisabled: '#abbbc1',
	bg: '#eaeaea',
	green: '#4bf083',
	blue: '#01b2d0',
	red: '#f04b4b',
	orange: '#e88224',
	borderPopup: '#f7f7f7',
	borderInput: '#424444',
	bgHover: '#203f46',
};
export const cirrusTheme = createTheme(theme.defaultAlgorithm, cirrusColors);
