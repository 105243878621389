import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const T6DAppIcon: React.FC<SvgIconProps> = ({ size = '24' }) => (
	<SvgIcon size={size} actualSize={24}>
		<path
			d="M0 5.35812C0 2.39891 2.39891 0 5.35812 0H18.637C21.5962 0 23.9951 2.39891 23.9951 5.35812V18.637C23.9951 21.5962 21.5962 23.9951 18.637 23.9951H5.35812C2.39891 23.9951 0 21.5962 0 18.637V5.35812Z"
			fill="#8AD3FF"
		/>
		<path
			d="M0.640869 5.7127C0.640869 2.9115 2.91169 0.640686 5.71288 0.640686H18.2826C21.0838 0.640686 23.3547 2.9115 23.3547 5.7127V18.2825C23.3547 21.0837 21.0838 23.3545 18.2826 23.3545H5.71288C2.91169 23.3545 0.640869 21.0837 0.640869 18.2825V5.7127Z"
			fill="#252B2E"
		/>
		<path
			d="M11.9978 22.1457C11.7429 22.1452 11.4884 22.1284 11.2357 22.0952C11.0832 22.0758 10.935 22.0562 10.791 22.0362L15.7013 13.5948L18.9822 19.3428C18.0865 20.1903 17.0485 20.8731 15.9157 21.36C14.6776 21.8885 13.3439 22.156 11.9978 22.1457ZM9.12157 21.7204C7.65322 21.3074 6.30118 20.5583 5.17251 19.5323C4.07567 18.5535 3.22006 17.3342 2.67252 15.9699H12.4285L9.12157 21.7204ZM15.2218 9.62225H21.8554C21.9386 9.97677 22.0066 10.3405 22.0582 10.706C22.2741 12.288 22.0912 13.8989 21.5262 15.3922C21.168 16.3376 20.69 17.2332 20.1039 18.057L15.2218 9.62225ZM2.14032 14.3729C2.05716 14.0188 1.98958 13.655 1.93864 13.2891C1.87892 12.8612 1.84914 12.4295 1.84952 11.9974C1.84306 10.8394 2.04031 9.68931 2.43226 8.59965C2.7811 7.6421 3.27155 6.74226 3.88726 5.93017L8.77377 14.3729H2.14032ZM5.01331 4.65192C5.90934 3.80448 6.94776 3.12163 8.08089 2.63472C9.31859 2.10637 10.652 1.83905 11.9977 1.84948C12.2167 1.84948 12.4718 1.8621 12.7562 1.88694C12.9219 1.90169 13.0794 1.91783 13.2287 1.93537L8.29457 10.4L5.01331 4.65192ZM11.5441 8.02529L14.899 2.27385C16.3645 2.68544 17.713 3.43488 18.8363 4.46201C19.9269 5.44389 20.7777 6.66291 21.3232 8.02527L11.5441 8.02529Z"
			fill="#33393C"
		/>
		<path
			d="M7.79178 16.9871C6.91711 16.9871 6.16674 16.803 5.54066 16.4347C4.91458 16.0572 4.43581 15.514 4.10436 14.8051C3.7729 14.0961 3.60718 13.2214 3.60718 12.181C3.60718 11.0762 3.80513 10.1417 4.20103 9.3775C4.60614 8.61332 5.16317 8.03328 5.87211 7.63737C6.59026 7.24147 7.41429 7.04352 8.3442 7.04352C8.83217 7.04352 9.29713 7.09416 9.73907 7.19543C10.1902 7.29671 10.5723 7.45323 10.8853 7.66499L10.2224 9.01843C9.95543 8.84349 9.66541 8.7238 9.35237 8.65935C9.04854 8.5857 8.72629 8.54887 8.38563 8.54887C7.47414 8.54887 6.75138 8.82968 6.21737 9.39131C5.68337 9.95294 5.41636 10.7816 5.41636 11.8772C5.41636 12.0521 5.42097 12.2593 5.43017 12.4987C5.43938 12.7289 5.4716 12.9636 5.52685 13.203L4.97442 12.5954C5.14015 12.2179 5.36572 11.9048 5.65114 11.6562C5.94577 11.3984 6.29103 11.2051 6.68693 11.0762C7.09204 10.9473 7.52938 10.8829 7.99894 10.8829C8.63422 10.8829 9.20046 11.0071 9.69764 11.2557C10.1948 11.4951 10.5907 11.8404 10.8853 12.2915C11.18 12.7335 11.3273 13.2537 11.3273 13.8521C11.3273 14.4874 11.1708 15.0398 10.8577 15.5094C10.5447 15.979 10.1212 16.3426 9.58715 16.6004C9.05314 16.8582 8.45469 16.9871 7.79178 16.9871ZM7.6951 15.5784C8.06339 15.5784 8.39024 15.514 8.67565 15.3851C8.96107 15.247 9.18204 15.0536 9.33856 14.8051C9.50429 14.5565 9.58715 14.2664 9.58715 13.935C9.58715 13.4286 9.41222 13.0281 9.06235 12.7335C8.71248 12.4388 8.24292 12.2915 7.65367 12.2915C7.26698 12.2915 6.92632 12.3652 6.63169 12.5125C6.34627 12.6506 6.1161 12.844 5.94116 13.0925C5.77544 13.3411 5.69257 13.6266 5.69257 13.9488C5.69257 14.2434 5.77083 14.515 5.92735 14.7636C6.08387 15.0122 6.30944 15.2102 6.60407 15.3575C6.9079 15.5048 7.27158 15.5784 7.6951 15.5784Z"
			fill="#8AD3FF"
		/>
		<path
			d="M12.1451 17.0241L12.9765 15.4792L13.8263 11.4116V8.73793L12.1451 7.19305H16.1024C19.0381 7.19305 21.0426 9.15926 21.0426 12.1086C21.0426 15.0579 19.0381 17.0241 16.1024 17.0241H12.1451ZM12.9765 15.4792H16.0248C18.0423 15.4792 19.3484 14.145 19.3484 12.1086C19.3484 10.0721 18.0423 8.73793 16.0248 8.73793H13.8263V11.4116L12.9765 15.4792Z"
			fill="#E4E4E4"
		/>
		<path
			d="M13.8263 8.73793L12.1451 7.19305L12.1013 11.4116H13.8263V8.73793Z"
			fill="#E4E4E4"
		/>
	</SvgIcon>
);
