import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/signForm.module.scss';
import { FormInput } from 'components/fields';
import { MotionButton } from 'components/MotionButton';
import { isDesktop } from 'desktop';
import { useAppSelector } from 'hooks/hooks';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { authState } from 'store/slices/auth';
import { loginSchema } from 'validations/FormValidation';

interface FormValues {
	email: string;
}

export const LoginForm: FC = () => {
	const { error: apiError } = useAppSelector(authState);
	const [loading, setLoading] = useState(false);

	const [loginError, setLoginError] = useState<string | undefined>(undefined);

	const { error: auth0Error, loginWithRedirect } = useAuth0();

	useEffect(() => {
		if (auth0Error) {
			setLoginError(auth0Error.message);
		} else if (apiError) {
			// prettier-ignore
			setLoginError(
				apiError.code === 'UNAUTHORIZED' ? 'Incorrect email or password' :
				apiError.code === 'ACCOUNT_DISABLED' ? 'Account is inactivated. Contact support@imagesystems.se' :
				'Failed to log in, try again later'
			);
		} else {
			setLoginError(undefined);
		}
	}, [apiError, auth0Error]);

	const { handleSubmit, control } = useForm<FormValues>({
		resolver: yupResolver(loginSchema),
	});

	const loginHandler: SubmitHandler<FormValues> = async (data) => {
		setLoginError(undefined);
		setLoading(true);
		if (isDesktop) {
			// Since the authentication site replaces our app in the window, show the
			// titlebar during the authentication, we'll remove them again later
			const { appWindow } = await import('@tauri-apps/api/window');
			await appWindow.setDecorations(true);
		}
		await loginWithRedirect({
			authorizationParams: {
				login_hint: data.email,
				connection: data.email.endsWith('@imagesystems.se')
					? 'IMSAB-AZOIDC'
					: undefined,
			},
		});
	};

	return (
		<form className={style.form} onSubmit={handleSubmit(loginHandler)}>
			<div className={style.wrapper}>
				<div className={style.errorField}>
					{loginError && <h4 className={style.errorMessage}>{loginError}</h4>}
				</div>
				<Controller
					name="email"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<FormInput
							placeholder={'john.doe@example.com'}
							{...field}
							label={'Email address'}
							error={error?.message}
							type="email"
							autoComplete="username"
						/>
					)}
				/>
			</div>

			<MotionButton
				shape="round"
				size="large"
				htmlType="submit"
				loading={loading}
				width="100%"
				height="3rem"
				fontWeight="700"
			>
				CONTINUE
			</MotionButton>
			<hr className={style.line} />
		</form>
	);
};
