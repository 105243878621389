import { UserDto } from 'api';

export type User = UserDto | Guest;

export interface Guest {
	role: 'GUEST';
}

export const guestUser: Guest = {
	role: 'GUEST',
};

export type Users = User[];

export const isGuest = (user: User | Guest): user is Guest =>
	user.role === 'GUEST';
