import { SVGProps } from 'react';

export const AlertTriangle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_8078_11220)">
			<path
				d="M10.29 3.85923L1.82002 17.9992C1.64539 18.3017 1.55299 18.6445 1.55201 18.9937C1.55103 19.343 1.64151 19.6864 1.81445 19.9898C1.98738 20.2931 2.23675 20.546 2.53773 20.7231C2.83871 20.9002 3.18082 20.9954 3.53002 20.9992H20.47C20.8192 20.9954 21.1613 20.9002 21.4623 20.7231C21.7633 20.546 22.0127 20.2931 22.1856 19.9898C22.3585 19.6864 22.449 19.343 22.448 18.9937C22.4471 18.6445 22.3547 18.3017 22.18 17.9992L13.71 3.85923C13.5318 3.56533 13.2807 3.32235 12.9812 3.15371C12.6817 2.98508 12.3438 2.89648 12 2.89648C11.6563 2.89648 11.3184 2.98508 11.0188 3.15371C10.7193 3.32235 10.4683 3.56533 10.29 3.85923V3.85923Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 17H12.01"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 9V13"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8078_11220">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
