import {
	keepPreviousData,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { App, Spin, Table, TableProps, Tag, Tooltip } from 'antd';
import { LicensingRequestListDto, SubscriptionDto } from 'api';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import subscriptionsService from 'services/SubscriptionsService';
import { capitalize } from 'utils';
import { errorNotification, mcErrorNotification } from 'utils/Notifications';
import licensingStyle from 'assets/styles/licensingPage.module.scss';
import { ipc, isDesktop } from 'desktop';
import { CheckIcon, DownloadIcon, FileTextIcon } from 'assets/icons/svg';
import { McIconButton } from 'components/mc';
import { defaultFileName, fileTypes } from 'desktop/licensing';
import {
	formatDateForUTC,
	getStatusColor,
	TAG_COLOR,
} from './AdminLicenseRequestTable';
import { getDetailedErrorMessage } from './ActivationWizard/ActivationWizardError';

interface Props {
	subscription: SubscriptionDto;
}

const LicenseRequestTable = ({ subscription }: Props) => {
	const { notification } = App.useApp();

	const PAGE_SIZE = 5;

	const [pageNr, setPageNr] = useState<number>(1);

	const {
		data: requestPage,
		isLoading,
		error: getRequestsError,
		isFetching,
	} = useQuery({
		queryKey: [
			'subscriptions',
			subscription.id,
			'requests',
			'paginated',
			pageNr - 1, // -1 due to different indexing in the antd table
		],
		queryFn: () =>
			subscriptionsService
				.getAllLicensingRequests(subscription.id, pageNr - 1, PAGE_SIZE)
				.then((res) => res.data),
		enabled: !!subscription,
		placeholderData: keepPreviousData,
	});

	const requests = useMemo(
		() =>
			!!requestPage
				? requestPage.content.map((request) => {
						return { ...request, key: request.id };
				  })
				: [],
		[requestPage]
	);

	useEffect(() => {
		if (!getRequestsError) return;
		notification.warning(
			mcErrorNotification('Warning', getRequestsError, 'fetch', 'requests')
		);
	}, [getRequestsError, notification]);

	const queryClient = useQueryClient();

	const {
		mutateAsync: completeRequest,
		isPending: isUploadingReceipt,
		variables: uploadingReceiptVariables,
	} = useMutation({
		mutationFn: ({
			subscriptionId,
			licenseRequestId,
			receipt,
		}: {
			subscriptionId: number;
			licenseRequestId: number;
			receipt: Blob;
		}) =>
			subscriptionsService
				.completeRequestForm(subscriptionId, licenseRequestId, receipt)
				.then((res) => res.data),
		onSuccess: () => {
			notification.success({
				message: 'Success!',
				description: `You successfully completed the license request!`,
			});
			queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
		},
		onError: (err: unknown) =>
			notification.error(errorNotification(getDetailedErrorMessage(err, true))),
	});

	const { mutateAsync: downloadResponseFile } = useMutation({
		mutationFn: ({
			subscription,
			request,
		}: {
			subscription: SubscriptionDto;
			request: LicensingRequestListDto;
		}) => {
			const promise = subscriptionsService.downloadRequestFile(
				subscription.id,
				request.id,
				'RESPONSE',
				{ responseType: 'arraybuffer' }
			);
			let fileType = fileTypes['RESPONSE'];
			return ipc.downloadFileToDesktop(
				promise,
				fileType.name,
				fileType.extensions,
				defaultFileName(
					'RESPONSE',
					request.containerSerial ?? `request-${request.id}`
				)
			);
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'download', 'response file')
			),
	});

	const columns: TableProps<LicensingRequestListDto>['columns'] = useMemo(
		() => [
			{
				title: 'Created (UTC)',
				key: 'creationDate',
				dataIndex: 'creationDate',
				render: (_, { creationDate }) => formatDateForUTC(creationDate),
			},
			{
				title: 'Type',
				key: 'requestType',
				dataIndex: 'requestType',
				render: (_, { requestType }) =>
					(capitalize(requestType) ?? '').replaceAll('_', ' '),
			},
			{
				title: 'Modules',
				key: 'selectedModuleNames',
				dataIndex: 'selectedModuleNames',
				render: (_, record) => (
					<>
						{record.selectedModuleNames.map((moduleName) => (
							<Tag color={TAG_COLOR(moduleName)} key={moduleName}>
								{moduleName.toUpperCase()}
							</Tag>
						))}
					</>
				),
			},
			{
				title: 'Expiry (UTC)',
				key: 'activationExpiryDate',
				dataIndex: 'activationExpiryDate',
			},
			{
				title: 'Container ID',
				key: 'containerSerial',
				dataIndex: 'containerSerial',
			},
			{
				title: 'Status',
				key: 'action',
				render: (_, record) => {
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
							<Tooltip title={capitalize(record.licensingRequestStatus ?? '')}>
								<figure
									className={licensingStyle.statusRequestCircle}
									style={{
										background: getStatusColor(record.licensingRequestStatus),
									}}
								/>
							</Tooltip>
							<Tooltip title="Download response file">
								<McIconButton
									icon={<DownloadIcon />}
									disabled={!record.haveResponseFile}
									onClick={async (event) => {
										if (isDesktop) {
											event.preventDefault();
											event.stopPropagation();
											await downloadResponseFile({
												subscription: subscription,
												request: record,
											});
											return;
										} else {
											subscriptionsService
												.downloadRequestFile(
													subscription.id,
													record.id,
													'RESPONSE'
												)
												.then((res) => {
													var data = new Blob([res.data]);
													var requestURL = window.URL.createObjectURL(data);
													const tempLink = document.createElement('a');
													tempLink.href = requestURL;
													tempLink.setAttribute(
														'download',
														`${record.containerSerial}.WibuCmRaU`
													);
													tempLink.click();
												})
												.catch((err) =>
													notification.error(
														mcErrorNotification(
															'Error',
															err,
															'download',
															'response file'
														)
													)
												);
										}
									}}
								/>
							</Tooltip>
							{!!uploadingReceiptVariables &&
							isUploadingReceipt &&
							uploadingReceiptVariables.licenseRequestId === record.id ? (
								<Spin spinning size={'small'} />
							) : record.haveReceiptFile ? (
								<Tooltip title="Receipted">
									<McIconButton disabled icon={<CheckIcon />} />
								</Tooltip>
							) : record.haveResponseFile ? (
								<form
									onChange={async (event: FormEvent) => {
										const target = event.target as HTMLInputElement;
										if (
											target.files?.length &&
											!isUploadingReceipt &&
											!!subscription &&
											!!record
										) {
											try {
												const receipt: Blob = target.files[0];
												await completeRequest({
													subscriptionId: subscription.id,
													licenseRequestId: record.id,
													receipt: receipt,
												});
											} catch (err: any) {
												console.error('Failed request!');
											}
										}
									}}
								>
									<input type="hidden" name="requestId" value={record.id} />
									<label>
										<input type="file" name="file" />
										<Tooltip title="Upload receipt file">
											<div className="mc-iconButton">
												<FileTextIcon />
											</div>
										</Tooltip>
									</label>
								</form>
							) : (
								<McIconButton disabled icon={<FileTextIcon />} />
							)}
						</div>
					);
				},
			},
		],
		[
			completeRequest,
			downloadResponseFile,
			isUploadingReceipt,
			notification,
			subscription,
			uploadingReceiptVariables,
		]
	);
	return (
		<Table
			columns={columns}
			dataSource={requests}
			pagination={{
				position: ['bottomCenter'],
				defaultCurrent: 1,
				total: !!requestPage ? requestPage.allElements : 0,
				size: 'small',
				defaultPageSize: PAGE_SIZE,
				current: pageNr,
				hideOnSinglePage: true,
				onChange: (page, _) => {
					setPageNr(page);
				},
			}}
			loading={isLoading || isFetching}
			size={'small'}
		/>
	);
};

export default LicenseRequestTable;
