import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ApplicationReleaseDto, EngineDto, ErrorDTO } from 'api';
import appReleasesService from 'services/AppReleasesService';
import { enginesService } from 'services/EnginesService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import {
	createAppAsyncThunk,
	setLoadingState,
	setUploadingState,
} from 'utils/rtk';

export interface EngineState {
	engine: EngineDto;
	engineFileName: string | null;
	loading: boolean;
	appsLoading: boolean;
	uploading: boolean | null;
	error: ErrorDTO | null;
	rels: ApplicationReleaseDto[];
}

const initialState: EngineState = {
	engine: {} as EngineDto,
	engineFileName: null,
	loading: true,
	uploading: null,
	error: null,
	appsLoading: false,
	rels: [],
};

export const deleteEngine = createAppAsyncThunk(
	'oneEngine/deleteEngine',
	({ id }: { id: number }, { rejectWithValue }) =>
		enginesService
			.deleteEngineById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteEnginePicture = createAppAsyncThunk(
	'oneEngine/deleteEnginePicture',
	({ id }: { id: number }, { rejectWithValue }) =>
		enginesService
			.deleteEnginesBasicPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteEngineFile = createAppAsyncThunk(
	'oneEngine/deleteEngineFile',
	({ id }: { id: number }, { rejectWithValue }) =>
		enginesService
			.deleteEnginesFile(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type CreateEngineParams = Parameters<typeof enginesService.createEngineForm>;
export const addEngine = createAppAsyncThunk(
	'oneEngine/addEngine',
	(params: CreateEngineParams, { rejectWithValue }) =>
		enginesService
			.createEngineForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type UpdateEngineParams = Parameters<typeof enginesService.updateEngineForm>;
export const updateEngine = createAppAsyncThunk(
	'oneEngine/updateEngine',
	(params: UpdateEngineParams, { rejectWithValue }) =>
		enginesService
			.updateEngineForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOneEngine = createAppAsyncThunk(
	'oneEngine/getOneEngine',
	(id: number, { rejectWithValue }) =>
		enginesService.getEngineById(id).catch(getErrorMessageAnd(rejectWithValue))
);

export const getEngineAppReleases = createAppAsyncThunk(
	'oneEngine/getEngineAppReleases',
	(id: number, { rejectWithValue }) =>
		appReleasesService
			.getApplicationReleasesList(0, 99, undefined, id)
			.then((r) => r.data)
			.catch(getErrorMessageAnd(rejectWithValue))
);

interface EngineParams {
	engineId: number;
}

export const downloadEngine = createAppAsyncThunk(
	'oneEngine/downloadEngine',
	async (p: EngineParams, { rejectWithValue }) =>
		enginesService
			.getEngineFile(p.engineId)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getEngineFileName = createAppAsyncThunk(
	'oneEngine/getEngineFileName',
	async (p: EngineParams, { rejectWithValue }) =>
		enginesService
			.getEnginesFileName(p.engineId)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const oneEngineSlice = createSlice({
	name: 'oneEngine',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addEngine.pending, setUploadingState)
			.addCase(addEngine.fulfilled, (state) => {
				state.uploading = false;
				state.error = null;
			})

			.addCase(updateEngine.pending, setUploadingState)
			.addCase(updateEngine.fulfilled, (state) => {
				state.uploading = false;
				state.error = null;
			})

			.addCase(deleteEngine.pending, setLoadingState)
			.addCase(deleteEngine.fulfilled, (state) => {
				state.loading = false;
				state.engine = {} as EngineDto;
				state.error = null;
			})

			.addCase(deleteEnginePicture.pending, setLoadingState)
			.addCase(deleteEnginePicture.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteEngineFile.pending, setLoadingState)
			.addCase(deleteEngineFile.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getOneEngine.pending, setLoadingState)
			.addCase(getOneEngine.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.engine = payload.data;
			})

			.addCase(getEngineFileName.pending, setLoadingState)
			.addCase(getEngineFileName.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.engineFileName = payload.data;
			})

			.addCase(getEngineAppReleases.pending, (state) => {
				state.appsLoading = true;
				state.rels = [];
			})
			.addCase(getEngineAppReleases.fulfilled, (state, { payload }) => {
				state.appsLoading = false;
				state.rels = payload.content;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(
					addEngine.rejected,
					updateEngine.rejected,
					deleteEngine.rejected,
					deleteEnginePicture.rejected,
					deleteEngineFile.rejected,
					getOneEngine.rejected,
					downloadEngine.rejected,
					getEngineFileName.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
					state.uploading = false;
				}
			);
	},
});

export const oneEngineState = (state: RootState) => state.oneEngine;

export default oneEngineSlice.reducer;
