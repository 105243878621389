import { DownloadAndInstallProgress } from 'desktop/install';

export interface ModalState {
	title: string;
	percent?: number;
	details: string;
	open: boolean;
	done: boolean;
	id?: number;
}

export const modalStateFromProgress = (
	progress: DownloadAndInstallProgress
): ModalState => {
	if (progress.step === 'Downloading') {
		const { step, done, total, id } = progress;
		const percent = (done / total) * 100;
		return {
			title: `${step}...`,
			percent,
			details: `${done} of ${total} bytes`,
			open: true,
			done: false,
			id,
		};
	} else if (progress.step === 'Done') {
		return {
			title: `Done`,
			percent: undefined,
			details: '',
			open: true,
			done: true,
		};
	} else if (progress.step === 'Canceled') {
		return {
			title: `Canceled`,
			percent: undefined,
			details: 'Download was canceled',
			open: true,
			done: true,
		};
	} else if (progress.step === 'Failed') {
		return {
			title: `Failed`,
			percent: undefined,
			details: progress.details,
			open: true,
			done: true,
		};
	} else {
		return {
			title: `${progress.step}...`,
			percent: undefined,
			details: '',
			open: true,
			done: false,
		};
	}
};
