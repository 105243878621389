import React, { forwardRef } from 'react';
import style from 'assets/styles/components.module.scss';
import { DatePicker, Space } from 'antd';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { PickerRef } from 'rc-picker';
import { getTimeRemainingSuffix } from 'utils/date';
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface InputDateProps {
	label?: string;
	value?: string;
	error?: boolean;
	tabIndex?: number;
	errorMessage?: string;
	setDate: React.Dispatch<string>;
	onFocus?: () => void;
	onBlur?: () => void;
	disabled?: boolean;
	max?: string;
	min?: string;
	minToday?: boolean;
	defaultValue?: string;
	isUtc?: boolean;
	timeRemainingSuffix?: boolean;
	timeRemainingIncludeTarget?: boolean;
}

export const dateFormat = 'YYYY-MM-DD';

export const InputDate = forwardRef<PickerRef, InputDateProps>((props, ref) => {
	const onSelectDate: DatePickerProps['onChange'] = (date) => {
		if (date) {
			props.setDate(date.format(dateFormat));
		}
	};
	const suffixAdder: DatePickerProps['format'] = (value) => {
		return `${value.format(dateFormat)} ${
			props.timeRemainingSuffix
				? getTimeRemainingSuffix(
						new Date(value.format(dateFormat)),
						!!props.timeRemainingIncludeTarget
				  )
				: ''
		}`;
	};

	return (
		<>
			<div className={style.inputFieldWrapper}>
				<span className={style.inputFieldLabel}>{props.label}</span>
				<Space direction="vertical">
					<DatePicker
						onChange={onSelectDate}
						onFocus={props.onFocus}
						onBlur={props.onBlur}
						value={
							props.value
								? !!props.isUtc
									? dayjs.utc(props.value)
									: dayjs(props.value)
								: undefined
						}
						defaultValue={
							props.value
								? !!props.isUtc
									? dayjs.utc(props.value)
									: dayjs(props.value)
								: props.defaultValue
								? !!props.isUtc
									? dayjs.utc(props.defaultValue)
									: dayjs(props.defaultValue)
								: undefined
						}
						popupClassName={style.inputDateField}
						className={style.inputField}
						style={{
							border: props.error ? '1.5px solid var(--add-red)' : '',
							width: '100%',
						}}
						getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
						allowClear={false}
						tabIndex={props.tabIndex}
						placeholder="yyyy-mm-dd"
						disabled={props.disabled}
						ref={ref}
						format={suffixAdder}
						maxDate={
							!!props.max
								? !!props.isUtc
									? dayjs.utc(props.max)
									: dayjs(props.max)
								: undefined
						}
						minDate={
							!!props.minToday
								? !!props.isUtc
									? dayjs.utc()
									: dayjs()
								: props.min !== undefined
								? !!props.isUtc
									? dayjs.utc(props.min)
									: dayjs(props.min)
								: undefined
						}
					/>
				</Space>
			</div>
			<div className={style.errorMessage}>{props.errorMessage}</div>
		</>
	);
});
