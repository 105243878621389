import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	FC,
	BaseHTMLAttributes,
} from 'react';
import { Link, LinkProps as RouteLinkProps } from 'react-router-dom';

type ButtonAttrs = ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
type DivAttrs = BaseHTMLAttributes<HTMLDivElement>;

interface Props {
	primary?: boolean;
	danger?: boolean;
	centered?: boolean;
	nowrap?: boolean;
}

const clearProps: { [K in keyof Props]-?: any } = {
	primary: undefined,
	danger: undefined,
	centered: undefined,
	nowrap: undefined,
};

const classesFromProps = (props: Props) => [
	'mc-button',
	props.primary ? 'mc-primary' : props.danger ? 'mc-danger' : 'mc-secondary',
	props.centered ? 'mc-centered' : '',
	props.nowrap ? 'mc-nowrap' : '',
];

interface ButtonProps extends ButtonAttrs, Props {}
const McButtonComponent: FC<ButtonProps> = (props) => {
	const cssClasses = classesFromProps(props).join(' ');
	const buttonProps = { ...props, ...clearProps };

	return (
		<button {...buttonProps} className={cssClasses + ' ' + props.className}>
			{props.children}
		</button>
	);
};

interface LinkProps extends AnchorAttrs, Props {
	disabled?: boolean;
}
export const McLinkButton: FC<LinkProps> = (props) => {
	const cssClasses = [
		...classesFromProps(props),
		props.disabled ? 'mc-disabled' : '',
	].join(' ');
	const anchorProps = { ...props, ...clearProps, disabled: undefined };

	return (
		<a {...anchorProps} className={cssClasses}>
			{props.children}
		</a>
	);
};

interface ButtonWrapperProps extends ChildrenProps, DivAttrs {}

export const McButtonCol: FC<ButtonWrapperProps> = (props) => {
	const divProps = { ...props, children: undefined };
	const extraClasses = props.className ? ' ' + props.className : '';
	return (
		<div {...divProps} className={`mc-buttonsCol${extraClasses}`}>
			{props.children}
		</div>
	);
};
export const McButtonRow: FC<ButtonWrapperProps> = (props) => {
	const divProps = { ...props, children: undefined };
	const extraClasses = props.className ? ' ' + props.className : '';
	return (
		<div {...divProps} className={`mc-buttonsRow${extraClasses}`}>
			{props.children}
		</div>
	);
};

interface RouteProps extends RouteLinkProps, Props {
	disabled?: boolean;
}
export const McRouteButton: FC<RouteProps> = (props) => {
	const cssClasses = [
		...classesFromProps(props),
		props.disabled ? 'mc-disabled' : '',
	].join(' ');
	const linkProps = { ...props, ...clearProps, disabled: undefined };

	return (
		<Link {...linkProps} className={cssClasses}>
			{props.children}
		</Link>
	);
};

export const McButton = McButtonComponent as typeof McButtonComponent & {
	Link: typeof McLinkButton;
	Col: typeof McButtonCol;
	Row: typeof McButtonRow;
	Route: typeof McRouteButton;
};
(McButton as any).Link = McLinkButton;
(McButton as any).Col = McButtonCol;
(McButton as any).Row = McButtonRow;
(McButton as any).Route = McRouteButton;
