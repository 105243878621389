import React, { Dispatch, SetStateAction } from 'react';

import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash } from 'assets/icons/svg';
import { EditAppForm } from './EditAppForm';
import { ItemContainer } from 'components/ItemContainer';
import { ApplicationDto } from 'api';
import { getPublicImageURL } from 'services/ApiService';
import { App } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { TemaGenericAppIcon } from 'assets/icons/apps';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';

interface Props {
	application: ApplicationDto;
	selectedToUpdate: number | null;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
}

export const ManageOneApplication: React.FC<Props> = ({
	application,
	selectedToUpdate,
	setSelectedToUpdate,
}) => {
	const { notification, modal } = App.useApp();
	const queryClient = useQueryClient();

	const styles =
		selectedToUpdate === application.id
			? {
					border: '2px solid var(--bg-active)',
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();

		if (selectedToUpdate === null) {
			setSelectedToUpdate(application.id);
		} else {
			if (selectedToUpdate === application.id) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(application.id);
			}
		}
	};

	const { mutate: deleteApplication } = useMutation({
		mutationFn: (id: number) => applicationsService.deleteApplication(id),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['applications'] });
			notification.success(deleteSuccessNotification(application.name));
		},
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'application')
			),
	});

	const moduleCount = application.modulesIds?.length ?? 0;

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.profileItem} style={{ width: '45%' }}>
					{!application.iconImagePath ? (
						<div className={style.appAvatar}>
							<TemaGenericAppIcon size={32} />
						</div>
					) : (
						<img
							className={style.appAvatar}
							alt=""
							src={getPublicImageURL(application.iconImagePath)}
							width={32}
							height={32}
						/>
					)}
					<div className={style.textItem}>
						<span>{application.name}</span>
					</div>
				</div>
				<div className={style.textItem} style={{ width: '45%' }}>
					{moduleCount === 1
						? 'One module'
						: `${moduleCount === 0 ? 'No' : moduleCount} modules`}
				</div>
				<div className={style.buttonsItem} style={{ width: '10%' }}>
					<div style={{ cursor: 'pointer' }} onClick={clickEditBtnHandler}>
						<IconEdit />
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(
									() => deleteApplication(application.id),
									application.name
								)
							);
						}}
					>
						<IconTrash />
					</div>
				</div>
			</ItemContainer>
			{selectedToUpdate === application.id && (
				<EditAppForm
					application={application}
					setSelectedToUpdate={setSelectedToUpdate}
				/>
			)}
		</div>
	);
};
