import { SvgIcon, SvgIconProps } from './SvgIcon';

interface Props extends SvgIconProps {
	color?: string;
}
export const AlertCircle: React.FC<Props> = ({
	size = '24',
	zoomResize,
	color = '#6C7B84',
}) => (
	<SvgIcon size={size} actualSize={24} zoomResize={zoomResize}>
		<path
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 8V12"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 16H12.01"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
