import React, { forwardRef, useMemo, useEffect } from 'react';
import type { BaseSelectRef } from 'rc-select';
import style from 'assets/styles/components.module.scss';
import { Select, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { getModules, modulesState } from 'store/slices/modules';

interface OptionType {
	label: string;
	value: number;
}

interface SelectModulesProps {
	label?: string;
	value: number[];
	tabIndex?: number;
	onChange?:
		| ((value: number[], option: OptionType | OptionType[]) => void)
		| undefined;
	error?: boolean;
	errorMessage?: string;
}

export type SelectModulesRef = React.Ref<BaseSelectRef> | undefined | null;

export const SelectModules: React.FC<SelectModulesProps> = forwardRef<
	SelectModulesRef,
	SelectModulesProps
>((props, ref) => {
	const dispatch = useAppDispatch();

	const size = 50;
	const page = 0;

	useEffect(() => {
		dispatch(getModules({ page, size, searchedValue: '' }));
	}, [dispatch]);

	const { modules, loading } = useAppSelector(modulesState);

	const options: OptionType[] = useMemo(
		() =>
			loading || !modules
				? []
				: modules.content.map((m) => ({
						label: `${m.name} (${m.productCode})`,
						value: m.id,
				  })),
		[loading, modules]
	);

	const notFound = (
		<div
			style={{
				color: 'var(--add-red)',
				fontWeight: '600',
				padding: '0.5rem 1rem',
			}}
		>
			No such Module
		</div>
	);

	return (
		<>
			<div
				className={style.inputFieldWrapper}
				style={{ marginBottom: '1.5rem' }}
			>
				<span className={style.inputFieldLabel}>{props.label}</span>
				<Select
					mode="multiple"
					tabIndex={props.tabIndex}
					value={props.value}
					tagRender={(props) => (
						<Tag
							closable={props.closable}
							onClose={props.onClose}
							className={style.tagMultiSelectField}
						>
							{props.label}
						</Tag>
					)}
					notFoundContent={notFound}
					getPopupContainer={(trigger) => trigger.parentNode}
					placeholder={'Please select'}
					onChange={props.onChange}
					options={options}
					allowClear={true}
					className={style.multiSelectField}
					style={{
						border: props.error ? '1.5px solid var(--add-red)' : '',
						marginBottom: '-24px',
					}}
					optionFilterProp="label"
					// ref={ref}
				/>
			</div>
			<div className={style.errorMessage}>{props.errorMessage}</div>
		</>
	);
});
