import { Dispatch, useEffect, useMemo, useState } from 'react';
import style from 'assets/styles/selectField.module.scss';
import { WebCrmOrganizationDto } from 'api';
import webCrmService from 'services/WebCrmService';
import { useDebounce } from 'hooks/useDebounce';
import SelectEntity, { Option } from './SelectEntity';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { App } from 'antd';
import { errorNotification, warningNotification } from 'utils/Notifications';
const { getName } = require('country-list');

interface Props {
	selectedId: number | undefined;
	setSelectedId: Dispatch<number | undefined>;
	tabIndex?: number;
}

const SelectWebCrmOrganization = ({
	selectedId,
	setSelectedId,
	tabIndex,
}: Props) => {
	// Currently fetched webcrmorganisation.
	const { notification } = App.useApp();
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce(searchText, 750);
	const PAGE_SIZE = 300;
	const [initialId, setInitialId] = useState<number | undefined>(selectedId);

	useEffect(() => {
		if (
			selectedId !== undefined &&
			selectedId !== null &&
			initialId === undefined
		)
			setInitialId(selectedId);
	}, [selectedId, initialId]);

	// If an id was provided as an initial value, fetch the corresponding webcrm organization
	const {
		data: initialOrg,
		error: getInitialOrgError,
		isLoading: isLoadingInitialOrg,
	} = useQuery({
		queryKey: ['webcrm', 'organizations', initialId],
		queryFn: () =>
			webCrmService
				.getWebCrmOrganizationById(initialId!)
				.then((res) => res.data),
		enabled: initialId !== undefined && initialId !== null,
		initialData: undefined,
		retry: 1,
	});

	// Fetch webcrm organizations paginated infinite query
	const {
		data: webCrmOrganizationPages,
		error: getWebCrmOrganizationsError,
		isLoading: isLoadingWebCrmOrganizations,
		isFetching: isFetchingWebCrmOrganizations,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['webcrm', 'organizations', 'infinite', debouncedSearch],
		queryFn: ({ pageParam: pageNr }) =>
			webCrmService
				.getWebCrmOrganizations(pageNr, PAGE_SIZE, debouncedSearch)
				.then((res) => res.data),
		initialPageParam: 1, // webcrm api starts at 1 for pages
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.number! + 1;
			return nextPageNr <= lastPage.totalPages! ? nextPageNr : undefined;
		},
		retry: 1, // Retries a single time since there is a limit for api calls to webcrm
	});

	const webCrmOptions = useMemo(() => {
		const mapOption = (org: WebCrmOrganizationDto) => {
			return {
				label: (
					<div className={style.dropdownItem}>
						<div className={style.labelIconName}>
							<div>
								{org.name}, {getName(org.country)} (#{org.id})
							</div>
						</div>
					</div>
				),
				value: org.id,
				searchValue: (
					org.name! +
					org.country! +
					org.id +
					org.compareName
				).replace(/ /g, ''),
			};
		};

		const webCrmOrgs = !!webCrmOrganizationPages
			? webCrmOrganizationPages.pages.reduce(
					(acc, page) => [
						...acc,
						...(page.content ?? []).map((webCrmOrg) => mapOption(webCrmOrg)),
					],
					[] as Option[]
			  )
			: [];

		if (
			initialOrg !== undefined &&
			!webCrmOrgs.find((org) => org.value === initialOrg.id)
		)
			return [mapOption(initialOrg), ...webCrmOrgs];
		return webCrmOrgs;
	}, [initialOrg, webCrmOrganizationPages]);

	useEffect(() => {
		if (!getInitialOrgError) return;
		notification.warning(
			warningNotification(
				'Failed to fetch the provided webcrm organization with the specified id!'
			)
		);
	}, [getInitialOrgError, notification]);

	useEffect(() => {
		if (!getWebCrmOrganizationsError) return;
		notification.error(
			errorNotification('Failed to fetch webcrm organizations!')
		);
	}, [getWebCrmOrganizationsError, notification]);

	return (
		<SelectEntity
			selectedId={selectedId}
			setSelectedId={setSelectedId}
			fetchMoreEntities={fetchNextPage}
			loading={
				isLoadingWebCrmOrganizations ||
				isFetchingWebCrmOrganizations ||
				isLoadingInitialOrg
			}
			setSearchText={setSearchText}
			placeholder="Your WebCRM Organization"
			label="WebCRM organization"
			entityName="WebCRM organization"
			tabIndex={tabIndex}
			includeEmptyOption={true}
			options={webCrmOptions}
		/>
	);
};

export default SelectWebCrmOrganization;
