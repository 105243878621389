import { FilterCategory } from './fields/FilterTagField';
import { IconClose } from 'assets/icons/svg';
import style from 'assets/styles/filterTagField.module.scss';

interface Props {
	filterCategories: FilterCategory[];
}

const FilterTagContainer = ({ filterCategories }: Props) => {
	return (
		<div className={style.filterTagContainer} style={{ marginTop: '1rem' }}>
			{filterCategories.map((filterCategory, idx1) =>
				filterCategory.filterItems.map(
					(filterItem, idx2) =>
						filterItem.enabled && (
							<div
								className={style.filterTag}
								onClick={() => {
									filterItem.toggleEnabled();
								}}
								key={idx1 + '-' + idx2}
							>
								{!!filterCategory.categoryName &&
								filterCategory.categoryName.length > 0
									? `${filterCategory.categoryName}: `
									: ''}
								{filterItem.label}
								<div
									style={{
										display: 'inline-block',
										height: '24px',
										width: '24px',
									}}
								>
									<IconClose />
								</div>
							</div>
						)
				)
			)}
		</div>
	);
};

export default FilterTagContainer;
