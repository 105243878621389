import { useState, FC, useMemo, useEffect } from 'react';
import { ManageOneSupport } from 'modules/ManageSupport/ManageOneSupport';
import { SearchField } from 'components/fields';
import { AddButton } from 'components/buttons';
import { SupportItemForm } from 'modules/ManageSupport/SupportItemForm';
import style from 'assets/styles/manageElements.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { App, Spin } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { FilterField } from 'components/fields';
import {
	ItemtypeSelection,
	itemtypeSelectionLabels,
	itemtypeSelectionValues,
} from 'types/item-type-selection';
import { McButton } from 'components/mc';
import { useInfiniteQuery } from '@tanstack/react-query';
import supportService from 'services/SupportService';
import { SupportItemDto } from 'api';
import { mcErrorNotification } from 'utils/Notifications';
export const ManageSupport: FC = () => {
	const [itemType, setItemType] = useState<ItemtypeSelection>();
	const [showAddSupportForm, setShowAddSupportForm] = useState(false);
	const [searchedValue, setSearchedValue] = useState('');
	const [selectedToUpdate, setSelectedForUpdate] = useState<number | null>(
		null
	);
	const debouncedSearchTerm = useDebounce(searchedValue, 700);
	const PAGE_SIZE = 10;
	const { notification } = App.useApp();

	const {
		data: supportItemPages,
		error: getSupportItemsError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['supportItems', 'infinite', debouncedSearchTerm, itemType],
		queryFn: ({ pageParam: pageNr }) =>
			supportService
				.supportItemsList(pageNr, PAGE_SIZE, debouncedSearchTerm, itemType)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const supportItems = useMemo(
		() =>
			!!supportItemPages
				? supportItemPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as SupportItemDto[]
				  )
				: [],
		[supportItemPages]
	);

	const supportItemsCount =
		!!supportItemPages && supportItemPages.pages.length > 0
			? supportItemPages.pages[0].allElements
			: 0;

	useEffect(() => {
		if (!getSupportItemsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getSupportItemsError,
				'fetch',
				'support item list'
			)
		);
	}, [getSupportItemsError, notification]);

	return (
		<>
			<div className={style.container}>
				<h1 className={style.header}>Manage Support Items</h1>
				<>
					<div className={style.amountInfo}>
						{`Displaying ${supportItems.length} out of ${supportItemsCount} support items`}
					</div>
					<div className={style.searchAddFields}>
						<div style={{ flex: 'auto' }} className={style.searchFieldWrapper}>
							<SearchField
								placeholder={'Search'}
								value={searchedValue}
								onChange={(e) => {
									setSearchedValue(e.target.value);
								}}
							/>
						</div>

						<div style={{ flex: '0 0 12.3125rem' }}>
							<FilterField
								label={'Item type: '}
								selValue={itemType}
								values={itemtypeSelectionValues}
								labels={itemtypeSelectionLabels}
								admin={true}
								setSelectedField={setItemType}
							/>
						</div>
						<AddButton onClick={() => setShowAddSupportForm((prev) => !prev)} />
					</div>
				</>

				<div style={{ marginTop: '2rem' }}>
					{showAddSupportForm && (
						<SupportItemForm
							supportItem="new"
							hideForm={() => setShowAddSupportForm(false)}
						/>
					)}
				</div>
				{supportItemsCount !== 0 && (
					<h3 className={style.listTitle}>Support List</h3>
				)}
				<div>
					<Spin spinning={isLoading} size="large">
						{!isLoading &&
							supportItemsCount === 0 &&
							(searchedValue !== '' || itemType !== undefined) && (
								<div className={style.noElementsInfo}>
									Unfortunately the list of Support Items is empty
									<AlertCircle />
								</div>
							)}
						{supportItemsCount === 0 ? (
							<>There are currently no Support Items to show here</>
						) : (
							<div className={style.itemsContainer}>
								{supportItems.map((supportItem) => {
									return (
										<div key={supportItem.id}>
											<ManageOneSupport
												support={supportItem}
												selectedToUpdate={selectedToUpdate}
												setSelectedToUpdate={setSelectedForUpdate}
											/>
										</div>
									);
								})}
							</div>
						)}
					</Spin>
				</div>
				{supportItemsCount !== 0 && hasNextPage && (
					<div
						className={style.paginationContainer}
						onClick={() => fetchNextPage()}
					>
						<McButton disabled={isLoading || isFetching}>View more</McButton>
					</div>
				)}
			</div>
		</>
	);
};
