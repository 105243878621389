import { createAction, createSlice } from '@reduxjs/toolkit';
import {
	SubscriptionDto,
	FullDataLicenseDto,
	ContainerDto,
	LicensingRequestListDto,
	ApplicationDto,
	ModuleItemDto,
} from 'api';
import { AxiosResponse } from 'axios';
import appsService from 'services/ApplicationsService';
import modulesService from 'services/ModulesService';
import subscriptionsService from 'services/SubscriptionsService';
import { AppDispatch, RootState } from 'store';
import { PagedRequestParams } from 'types';
import { PageDto } from 'types/api';
import { getErrorMessageAnd } from 'utils/errors';
import {
	StateWithLoading,
	createAppAsyncThunk,
	getLoadingStateReducers,
} from 'utils/rtk';

type Loadables =
	| 'subscriptions'
	| 'licenses'
	| 'containers'
	| 'requests'
	| 'applications'
	| 'modules'
	| 'saveRequest';

export interface LicensingState extends StateWithLoading<Loadables> {
	subscriptions: SubscriptionDto[];
	selectedSubscription: SubscriptionDto | null;
	selectedSubscriptionId: number | null;
	licenses: FullDataLicenseDto[];
	containers: ContainerDto[];
	requests: LicensingRequestListDto[];
	applications: ApplicationDto[];
	modules: ModuleItemDto[];
}

const initialState: LicensingState = {
	subscriptions: [],
	selectedSubscription: null,
	selectedSubscriptionId: null,
	loadingStates: {
		subscriptions: {},
		licenses: {},
		containers: {},
		requests: {},
		applications: {},
		modules: {},
		saveRequest: {},
	},
	licenses: [],
	containers: [],
	requests: [],
	applications: [],
	modules: [],
};

// Helper to inject state into action payload creator
const withState = <T>(
	getState: () => unknown,
	cb: (state: LicensingState) => T
): T => cb((getState() as RootState).licensing);

const singleToPaged = <T>(
	res: AxiosResponse<T>
): AxiosResponse<PageDto<T>> => ({
	...res,
	data: {
		allElements: 1,
		allPages: 1,
		content: [res.data],
		offset: 0,
		pageNumber: 1,
		pageSize: 1,
	},
});

// == Subscriptions
export const getSubscriptions = createAppAsyncThunk(
	'licensing/getSubscriptions',
	(params: PagedRequestParams | { id: number }, { rejectWithValue }) => {
		if ('id' in params) {
			return subscriptionsService
				.getSubscriptionById(params.id)
				.then(singleToPaged)
				.catch(getErrorMessageAnd(rejectWithValue));
		} else {
			const { page, searchedValue, size } = params;
			return subscriptionsService
				.getPaginatedSubscriptions(page, size, searchedValue)
				.catch(getErrorMessageAnd(rejectWithValue));
		}
	}
);
const getSubscriptionsReducers = getLoadingStateReducers('subscriptions');

// == Containers
export const getContainers = createAppAsyncThunk(
	'licensing/getContainers',
	(_, { rejectWithValue, getState }) =>
		withState(getState, (state) =>
			state.selectedSubscriptionId == null
				? Promise.resolve([])
				: subscriptionsService
						.getAllContainers(state.selectedSubscriptionId)
						.then((r) => r.data)
						.catch(getErrorMessageAnd(rejectWithValue))
		)
);
const containersReducers = getLoadingStateReducers('containers');

// == Licenses
export const getLicenses = createAppAsyncThunk(
	'licensing/getLicenses',
	(_, { rejectWithValue, getState }) =>
		withState(getState, (state) =>
			state.selectedSubscriptionId == null
				? Promise.resolve([])
				: subscriptionsService
						.getAllLicenses(state.selectedSubscriptionId)
						.then((r) => r.data)
						.catch(getErrorMessageAnd(rejectWithValue))
		)
);
const licensesReducers = getLoadingStateReducers('licenses');

// == Applications
export const getApplications = createAppAsyncThunk(
	'licensing/getApplications',
	({ page, searchedValue, size }: PagedRequestParams, { rejectWithValue }) =>
		appsService
			.getApplicationsList(page, size, searchedValue)
			.then((r) => r.data.content)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const applicationsReducers = getLoadingStateReducers('applications');

// == Modules
export const getModules = createAppAsyncThunk(
	'licensing/getModules',
	({ page, searchedValue, size }: PagedRequestParams, { rejectWithValue }) =>
		modulesService
			.getModulesList(page, size, searchedValue)
			.then((r) => r.data.content)
			.catch(getErrorMessageAnd(rejectWithValue))
);
const modulesReducers = getLoadingStateReducers('modules');

export const setSelectedSubscription = createAction<number | null>(
	'licensing/setSelectedSubscription'
);

export const selectSubscription =
	(subscriptionId: number | null) => (dispatch: AppDispatch) => {
		dispatch(setSelectedSubscription(subscriptionId));
		dispatch(getLicenses());
		dispatch(getContainers());
	};

const licensingSlice = createSlice({
	name: 'licensing',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSubscriptions.pending, getSubscriptionsReducers.pending)
			.addCase(getSubscriptions.rejected, getSubscriptionsReducers.rejected)
			.addCase(getSubscriptions.fulfilled, (state, { payload }) => {
				getSubscriptionsReducers.fulfilled(state);

				state.subscriptions = payload.data.content;
			})

			.addCase(getContainers.pending, containersReducers.pending)
			.addCase(getContainers.rejected, containersReducers.rejected)
			.addCase(getContainers.fulfilled, (state, { payload }) => {
				containersReducers.fulfilled(state);

				state.containers = payload;
			})

			.addCase(getLicenses.pending, licensesReducers.pending)
			.addCase(getLicenses.rejected, licensesReducers.rejected)
			.addCase(getLicenses.fulfilled, (state, { payload }) => {
				licensesReducers.fulfilled(state);

				state.licenses = payload;
			})

			.addCase(getApplications.pending, applicationsReducers.pending)
			.addCase(getApplications.rejected, applicationsReducers.rejected)
			.addCase(getApplications.fulfilled, (state, { payload }) => {
				applicationsReducers.fulfilled(state);

				state.applications = payload;
			})

			.addCase(getModules.pending, modulesReducers.pending)
			.addCase(getModules.rejected, modulesReducers.rejected)
			.addCase(getModules.fulfilled, (state, { payload }) => {
				modulesReducers.fulfilled(state);

				state.modules = payload;
			})

			.addCase(setSelectedSubscription, (state, { payload }) => {
				state.selectedSubscriptionId = payload;

				state.selectedSubscription =
					payload === null
						? null
						: (state.subscriptions.find(
								(s) => s.id === payload
						  ) as SubscriptionDto) ?? null;
			});
	},
});

export const licensingReducer = licensingSlice.reducer;
export const licensingState = (state: RootState) => state.licensing;
