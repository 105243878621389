import { useMemo } from 'react';
import { useLocalSettings } from 'settings';

export const useLightMode: () => [
	boolean,
	(lightMode: boolean | null) => void
] = () => {
	const [lightModeSetting, setIsLightMode] = useLocalSettings('lightMode');

	// Initialize isLightMode based on the light mode preference
	const isLightMode = useMemo(
		() =>
			lightModeSetting ?? // User preference...
			// matchMedia?.('(prefers-color-scheme: light)')?.matches ?? // ...or browser preference...
			false, // ...or finally just "no"
		[lightModeSetting]
	);

	return [isLightMode, setIsLightMode];
};
