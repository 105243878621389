import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const T3DAppIcon: React.FC<SvgIconProps> = ({ size = '24' }) => (
	<SvgIcon size={size} actualSize={24}>
		<path
			d="M0 5.35759C0 2.39867 2.39867 0 5.35759 0H18.6351C21.594 0 23.9927 2.39867 23.9927 5.35759V18.6351C23.9927 21.594 21.594 23.9927 18.6351 23.9927H5.35759C2.39867 23.9927 0 21.594 0 18.6351V5.35759Z"
			fill="#FDFD5E"
		/>
		<path
			d="M0.640869 5.71219C0.640869 2.91128 2.91146 0.640686 5.71237 0.640686H18.2809C21.0818 0.640686 23.3524 2.91127 23.3524 5.71219V18.2807C23.3524 21.0816 21.0818 23.3522 18.2809 23.3522H5.71237C2.91146 23.3522 0.640869 21.0816 0.640869 18.2807V5.71219Z"
			fill="#252B2E"
		/>
		<path
			d="M11.9967 22.1437C11.7419 22.1432 11.4874 22.1263 11.2347 22.0931C11.0823 22.0738 10.9341 22.0541 10.7901 22.0342L15.6999 13.5936L18.9805 19.3411C18.0849 20.1884 17.0469 20.8712 15.9142 21.3581C14.6763 21.8865 13.3427 22.154 11.9967 22.1437ZM9.12084 21.7184C7.65264 21.3055 6.30074 20.5564 5.17218 19.5305C4.07545 18.5518 3.21992 17.3327 2.67244 15.9685H12.4274L9.12084 21.7184ZM15.2204 9.62148H21.8534C21.9366 9.97596 22.0046 10.3397 22.0561 10.7051C22.272 12.287 22.0892 13.8977 21.5242 15.3908C21.1661 16.3362 20.6882 17.2317 20.1021 18.0553L15.2204 9.62148ZM2.14029 14.3716C2.05714 14.0176 1.98956 13.6538 1.93863 13.288C1.87891 12.8601 1.84914 12.4285 1.84952 11.9964C1.84306 10.8385 2.04029 9.68852 2.4322 8.59898C2.781 7.64152 3.27141 6.74178 3.88706 5.92976L8.77308 14.3716H2.14029ZM5.013 4.65164C5.90894 3.80428 6.94725 3.1215 8.08027 2.63464C9.31784 2.10634 10.6511 1.83905 11.9967 1.84948C12.2156 1.84948 12.4707 1.8621 12.7552 1.88694C12.9208 1.90169 13.0783 1.91782 13.2275 1.93536L8.29393 10.3991L5.013 4.65164ZM11.5431 8.02467L14.8977 2.27381C16.363 2.68535 17.7114 3.43472 18.8346 4.46175C19.9251 5.44353 20.7758 6.66243 21.3213 8.02465L11.5431 8.02467Z"
			fill="#33393C"
		/>
		<path
			d="M6.89976 16.8988C6.21843 16.8988 5.55092 16.8021 4.89722 16.6087C4.25273 16.4062 3.70952 16.13 3.26758 15.7801L4.04097 14.3852C4.39084 14.6707 4.81436 14.9008 5.31154 15.0758C5.80872 15.2507 6.32892 15.3382 6.87214 15.3382C7.51663 15.3382 8.01841 15.2093 8.37749 14.9515C8.73656 14.6845 8.9161 14.3254 8.9161 13.8742C8.9161 13.4323 8.75037 13.0824 8.41892 12.8246C8.08746 12.5668 7.55346 12.4379 6.81689 12.4379H5.93302V11.2088L8.69513 7.9219L8.92991 8.59861H3.73714V7.09326H10.3248V8.29478L7.56266 11.5817L6.62354 11.0293H7.16216C8.34987 11.0293 9.23834 11.2963 9.8276 11.8303C10.4261 12.3551 10.7253 13.0318 10.7253 13.8604C10.7253 14.4037 10.5872 14.9054 10.311 15.3658C10.0348 15.8261 9.61123 16.199 9.04039 16.4844C8.47876 16.7607 7.76522 16.8988 6.89976 16.8988Z"
			fill="#FDFD5E"
		/>
		<path
			d="M11.8743 16.9357L12.7057 15.3908L13.5555 11.3232V8.64955L11.8743 7.10468H15.8316C18.7673 7.10468 20.7718 9.07088 20.7718 12.0202C20.7718 14.9695 18.7673 16.9357 15.8316 16.9357H11.8743ZM12.7057 15.3908H15.7541C17.7715 15.3908 19.0777 14.0566 19.0777 12.0202C19.0777 9.98376 17.7715 8.64955 15.7541 8.64955H13.5555V11.3232L12.7057 15.3908Z"
			fill="#E4E4E4"
		/>
		<path
			d="M13.5555 8.64955L11.8743 7.10468L11.8306 11.3232H13.5555V8.64955Z"
			fill="#E4E4E4"
		/>
	</SvgIcon>
);
