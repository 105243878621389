import React, { useState, Dispatch, SetStateAction } from 'react';
import { useAppSelector } from 'hooks/hooks';
import style from 'assets/styles/selectEngine.module.scss';
import { IconTickDown } from 'assets/icons/svg';
import useOutsideClick from 'hooks/useOutsideClick';
import { enginesState } from 'store/slices/engines';

interface FilterProps {
	label: string;
	setSelEngineID: Dispatch<SetStateAction<number | undefined>>;
}

export const SelectEngineForTemplates: React.FC<FilterProps> = ({
	label,
	setSelEngineID,
}) => {
	const [showDropMenu, setShowDropMenu] = useState(false);
	const [selected, setSelected] = useState(0);
	const ref = useOutsideClick(() => {
		setShowDropMenu(false);
	});

	const { publishedEnginesForTemplates } = useAppSelector(enginesState);

	const values = [
		{
			id: undefined,
			name: 'Show all',
		},
		...publishedEnginesForTemplates,
	];

	const [value, setValue] = useState(values[0].name);

	return (
		<div className={style.wrapper} ref={ref}>
			<div
				className={style.filterField}
				onClick={(e) => {
					e.preventDefault();
					setShowDropMenu((prev) => !prev);
				}}
			>
				<span style={{ color: 'var(--text-normal)' }}>{label}</span>
				<div style={{ color: 'var(--text-primary)' }}>{value}</div>
				{showDropMenu ? (
					<div style={{ transform: 'rotate(180deg)' }}>
						<IconTickDown />
					</div>
				) : (
					<div>
						<IconTickDown />
					</div>
				)}
			</div>
			{showDropMenu && (
				<div className={style.filterFieldDropMenu}>
					{values.map((val, idx) => {
						return (
							<div
								className={
									selected === idx ? style.selected : style.filterFieldDropItem
								}
								key={idx}
								onClick={() => {
									setValue(val.name);
									setShowDropMenu(false);
									setSelected(idx);
									setSelEngineID(val.id);
								}}
							>
								{val.name}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
