import { FC, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { LogoLeft } from 'assets/icons/svg';
import style from 'assets/styles/sign.module.scss';
import { useNavigate, Link } from 'react-router-dom';
import { SignupForm } from 'modules/SignupForm';
import DeviceImage from 'assets/images/Device.png';
import MotionCloudImage from 'assets/images/motion1.jpg';
import Tema2DImage from 'assets/images/tema2.jpg';
import Tema3DImage from 'assets/images/tema3.jpg';
import { McButton } from 'components/mc';
import { Collapse } from 'antd';
import { Switch } from 'antd';
import { useLightMode } from 'hooks/useLightMode';
import { SunLightModeIcon, MoonDarkModeIcon } from 'assets/icons/svg';

const { Panel } = Collapse;

const FeatureCard: FC<{
	title: string;
	subtitle: string;
	description: string;
	ctaText: string;
	imageSrc: string;
	index: number;
}> = ({ title, subtitle, description, ctaText, imageSrc, index }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<motion.div
			ref={ref}
			className={style.featureCard}
			initial={{ opacity: 0, y: 50 }}
			animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
			transition={{ duration: 0.5, delay: index * 0.2 }}
		>
			<div className={style.featureCardContent}>
				<h4>{subtitle}</h4>
				<h3>{title}</h3>
				<Collapse ghost>
					<Panel header="Read More" key="1" className={style.collapsePanel}>
						<p>{description}</p>
					</Panel>
				</Collapse>
				<Link to="/signup" className={style.ghostButton}>
					{ctaText}
				</Link>
			</div>
			<div className={style.imagePlaceholder}>
				<img src={imageSrc} alt={title} className={style.featureImage} />
			</div>
		</motion.div>
	);
};

export const Signup: FC = () => {
	const navigate = useNavigate();

	const [isLightMode, setIsLightMode] = useLightMode();

	const handleLightModeToggle = () => {
		setIsLightMode(!isLightMode);
	};

	const discoverSectionRef = useRef(null);
	const discoverSectionInView = useInView(discoverSectionRef, { once: true });

	return (
		<div>
			<div className={style.navBar}>
				<div className={style.logo}>
					<LogoLeft />
				</div>
				<div className={style.exploreFeatures}>
					<div className={style.lightModeSwitch}>
						<Switch
							checked={isLightMode}
							onChange={handleLightModeToggle}
							checkedChildren={<SunLightModeIcon />}
							unCheckedChildren={<MoonDarkModeIcon />}
						/>
					</div>
					<McButton
						onClick={() => {
							navigate('/login');
						}}
						className={style.signInButton}
					>
						Sign In
					</McButton>
				</div>
			</div>
			<div className={style.containerWrapper}>
				<div className={style.container}>
					<div
						className={`${style.leftHalfContainer} ${style.deviceImageContainer}`}
					>
						<motion.img
							src={DeviceImage}
							alt="Motion Cloud Device"
							className={style.deviceImage}
							initial={{ opacity: 0, x: -200 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.8, ease: 'easeOut' }}
						/>
					</div>
					<div className={style.rightHalfContainer}>
						<div className={style.signFormWrapper}>
							<div className={style.signFormContent}>
								<h1 className={style.signFormContentText}>
									Sign Up for Motion Cloud
								</h1>
								<h4 className={style.subsignFormContentText}>
									Experience the power of Tema Platform
								</h4>
							</div>

							{/* Signup form */}
							<SignupForm />

							<motion.div
								className={style.signUpPrompt}
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: 0.8 }}
							>
								<p>Already have an account?</p>
								<motion.div
									whileHover={{ scale: 1.05 }}
									whileTap={{ scale: 0.95 }}
								>
									<Link to="/login">SIGN IN NOW</Link>
								</motion.div>
							</motion.div>
						</div>
					</div>
				</div>
			</div>

			{/* Discover Tema Platform section */}
			<motion.div
				ref={discoverSectionRef}
				className={`${style.container} ${style.discoverSection}`}
				initial={{ opacity: 0 }}
				animate={discoverSectionInView ? { opacity: 1 } : { opacity: 0 }}
				transition={{ duration: 0.8 }}
			>
				<div className={style.discoverContent}>
					<motion.h2
						className={style.sectionTitle}
						initial={{ opacity: 0, y: -20 }}
						animate={
							discoverSectionInView
								? { opacity: 1, y: 0 }
								: { opacity: 0, y: -20 }
						}
						transition={{ duration: 0.5 }}
					>
						Discover Tema Platform
					</motion.h2>
					<motion.p
						initial={{ opacity: 0, y: -20 }}
						animate={
							discoverSectionInView
								? { opacity: 1, y: 0 }
								: { opacity: 0, y: -20 }
						}
						transition={{ duration: 0.5, delay: 0.2 }}
						className={style.discoverSubtitle}
					>
						Unlock Motion Cloud's potential
					</motion.p>
					<div className={style.featureCards}>
						<FeatureCard
							title="Motion Cloud"
							subtitle="Simplify Your Workflow"
							description="Motion Cloud is your central hub for managing TEMA software and subscriptions. Access tutorials, templates, and advanced features to streamline your video analysis workflow. Elevate your projects with tools designed for precision and ease."
							ctaText="Sign Up Now"
							imageSrc={MotionCloudImage}
							index={0}
						/>

						<FeatureCard
							title="TEMA 2D"
							subtitle="Precision in 2D Tracking"
							description="Track any object in 2D with advanced Point, Outline, and Deformation algorithms."
							ctaText="Join the Revolution"
							imageSrc={Tema2DImage}
							index={1}
						/>

						<FeatureCard
							title="TEMA 3D"
							subtitle="Master 3D Motion"
							description="Unlock 3D Motion Analysis with industry-leading tracking algorithms, including 6DoF."
							ctaText="Start Your Journey"
							imageSrc={Tema3DImage}
							index={2}
						/>
					</div>
				</div>
			</motion.div>
		</div>
	);
};
