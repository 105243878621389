import { SvgIcon, SvgIconProps } from './SvgIcon';

export const IconEmptyCube: React.FC<SvgIconProps> = ({ size = '40' }) => (
	<SvgIcon size={size} actualSize={40}>
		<path
			d="M21.4831 2.41659L34.8164 9.08326C35.3718 9.35922 35.8391 9.78463 36.166 10.3117C36.4928 10.8387 36.6661 11.4465 36.6664 12.0666V27.9499C36.6661 28.5701 36.4928 29.1778 36.166 29.7049C35.8391 30.2319 35.3718 30.6573 34.8164 30.9333L21.4831 37.5999C21.02 37.8317 20.5093 37.9523 19.9914 37.9523C19.4736 37.9523 18.9629 37.8317 18.4998 37.5999L5.16642 30.9333C4.61164 30.6537 4.14601 30.2247 3.82207 29.6946C3.49813 29.1645 3.32877 28.5545 3.33309 27.9333V12.0666C3.33342 11.4465 3.50674 10.8387 3.83355 10.3117C4.16037 9.78463 4.62773 9.35922 5.18309 9.08326L18.5164 2.41659C18.9773 2.18755 19.4851 2.06836 19.9998 2.06836C20.5145 2.06836 21.0222 2.18755 21.4831 2.41659V2.41659Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.86621 10.2666L19.9995 18.3333L36.1329 10.2666"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20 37.9335V18.3335"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
