export interface SvgIconProps {
	size?: string | number;
	zoomResize?: boolean;
}

interface Props extends React.PropsWithChildren<SvgIconProps> {
	actualSize: string | number;
}

export const SvgIcon: React.FC<Props> = ({
	actualSize,
	size,
	children,
	zoomResize,
}) => (
	<svg
		width={size}
		height={size}
		viewBox={`0 0 ${actualSize} ${actualSize}`}
		className={`${zoomResize ? 'zoom-resize' : ''}`}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		{children}
	</svg>
);
