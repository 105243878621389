import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import style from 'assets/styles/selectField.module.scss';
import { SelectField } from './SelectField';
import { appsState, getAllApps } from 'store/slices/applications';
import { getPublicImageURL } from 'services/ApiService';
import { TemaGenericAppIcon } from 'assets/icons/apps';

type Props = React.ComponentPropsWithoutRef<typeof SelectField<number>>;

export const SelectApplication: React.FC<Props> = (props) => {
	const dispatch = useAppDispatch();

	const { apps, loading } = useAppSelector(appsState);

	useEffect(() => {
		if (apps) return;
		dispatch(getAllApps());
	}, [apps, dispatch]);

	const options = useMemo(
		() =>
			(apps?.content ?? []).map((app) => ({
				value: app.id,
				label: (
					<div className={style.dropdownItem}>
						<div className={style.labelIconName}>
							{!app.iconImagePath ? (
								<TemaGenericAppIcon size={32} />
							) : (
								<img
									alt=""
									src={getPublicImageURL(app.iconImagePath)}
									width={32}
									height={32}
								/>
							)}

							<div>{app.name}</div>
						</div>
					</div>
				),
			})),
		[apps]
	);

	return (
		<SelectField
			{...props}
			placeholder={props.placeholder ?? 'Select an application'}
			options={options}
			loading={loading}
			error={props.error}
		/>
	);
};
