import React, { Dispatch, SetStateAction } from 'react';
import { AlertCircle, CodesandboxIcon } from 'assets/icons/svg';
import style from 'assets/styles/licensingPage.module.scss';
import comStyle from 'assets/styles/components.module.scss';
import { McCheckBox } from 'components/mc';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SubscriptionDto } from 'api';

dayjs.extend(utc);
dayjs.extend(timezone);

interface ActivatedModulesProps {
	subscription: SubscriptionDto;
	license: License;
	setSelectedToUpdate: Dispatch<SetStateAction<number[]>>;
	selectedToUpdate: number[];
	currentContainerID: string | undefined;
	setCurrentContainerID: Dispatch<string | undefined>;
	modActiveEnabled: boolean;
	modAvailableEnabled: boolean;
}

export const SubscriptionLicense: React.FC<ActivatedModulesProps> = ({
	subscription,
	license,
	setSelectedToUpdate,
	selectedToUpdate,
	currentContainerID,
	setCurrentContainerID,
	modActiveEnabled,
	modAvailableEnabled,
}) => {
	const selected = selectedToUpdate.includes(license.id);

	const toggleSelected = () => {
		if (selectedToUpdate.includes(license.id)) {
			setSelectedToUpdate((list) => list.filter((el) => el !== license.id));
		} else {
			setCurrentContainerID(license.containerName);
			setSelectedToUpdate((list) => [...list, license.id]);
		}
	};

	const activated =
		!!license.expiryDate &&
		license.expiryDate.length > 0 &&
		license.licenseStatus === 'ACTIVATED' &&
		(dayjs.utc().isBefore(dayjs.utc(license.expiryDate), 'day') ||
			dayjs.utc().isSame(dayjs.utc(license.expiryDate), 'day')); //!!license.expiryDate; // || license.licenseStatus === FullDataLicenseDtoLicenseStatusEnum.ACTIVATED;
	const disabled =
		(!activated && modActiveEnabled) ||
		(activated && modAvailableEnabled) ||
		(currentContainerID !== undefined &&
			license.containerName !== currentContainerID) ||
		subscription.status === 'INACTIVE';

	return (
		<div
			className={comStyle.subscriptionItem}
			style={{ height: '56px' }}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (!disabled) {
					toggleSelected();
				}
			}}
		>
			<div className={style.textItem} style={{ width: '35%' }}>
				<div
					className={style.statusRequestCircle}
					style={{
						background: activated ? 'var(--add-green)' : 'var(--add-orange)',
					}}
				></div>
				<CodesandboxIcon />
				<div>{license.moduleName}</div>
				<div
					style={{ color: 'var(--text-disabled)' }}
				>{`(id: ${license.id})`}</div>
			</div>
			<div className={style.textItem} style={{ width: '25%' }}>
				{activated && !!license.containerName && (
					<>
						<span title={`Activated in container ${license.containerName}`}>
							<AlertCircle color="var(--add-green)" />
						</span>
						{license.containerName}
					</>
				)}
			</div>
			<div className={style.textItem} style={{ width: '30%' }}>
				{!!license.expiryDate && activated && (
					<>Activated until: {license.expiryDate}</>
				)}
			</div>
			<div className={style.actionsItem} style={{ width: '10%' }}>
				<McCheckBox checked={selected} disabled={disabled} />
			</div>
		</div>
	);
};
