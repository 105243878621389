/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApplicationReleaseSortOrder } from '../model';
import { ErrorDTO } from '../model';
import { GlobalSearchDto } from '../model';
import { PageDtoApplicationReleaseDto } from '../model';
/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get and download desktop application file for Home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/home/desktop/application/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This controller provides global searching by key value 
         * @param {string} searchedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSearchResult: async (searchedValue: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchedValue' is not null or undefined
            if (searchedValue === null || searchedValue === undefined) {
                throw new RequiredError('searchedValue','Required parameter searchedValue was null or undefined when calling getGlobalSearchResult.');
            }
            const localVarPath = `/api/home/home/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of published application releases for Home page
         * @param {number} page 
         * @param {number} size 
         * @param {number} [enginesId] 
         * @param {ApplicationReleaseSortOrder} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedApplicationReleases: async (page: number, size: number, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getPublishedApplicationReleases.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getPublishedApplicationReleases.');
            }
            const localVarPath = `/api/home/applications/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (enginesId !== undefined) {
                localVarQueryParameter['enginesId'] = enginesId;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get and download desktop application file for Home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            const localVarAxiosArgs = await HomeApiAxiosParamCreator(configuration).getFile(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary This controller provides global searching by key value 
         * @param {string} searchedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalSearchResult(searchedValue: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<GlobalSearchDto>>> {
            const localVarAxiosArgs = await HomeApiAxiosParamCreator(configuration).getGlobalSearchResult(searchedValue, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of published application releases for Home page
         * @param {number} page 
         * @param {number} size 
         * @param {number} [enginesId] 
         * @param {ApplicationReleaseSortOrder} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedApplicationReleases(page: number, size: number, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoApplicationReleaseDto>> {
            const localVarAxiosArgs = await HomeApiAxiosParamCreator(configuration).getPublishedApplicationReleases(page, size, enginesId, sortOrder, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get and download desktop application file for Home page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(options?: AxiosRequestConfig): Promise<AxiosResponse<Blob>> {
            return HomeApiFp(configuration).getFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This controller provides global searching by key value 
         * @param {string} searchedValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalSearchResult(searchedValue: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<GlobalSearchDto>>> {
            return HomeApiFp(configuration).getGlobalSearchResult(searchedValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of published application releases for Home page
         * @param {number} page 
         * @param {number} size 
         * @param {number} [enginesId] 
         * @param {ApplicationReleaseSortOrder} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedApplicationReleases(page: number, size: number, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoApplicationReleaseDto>> {
            return HomeApiFp(configuration).getPublishedApplicationReleases(page, size, enginesId, sortOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * 
     * @summary Get and download desktop application file for Home page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public async getFile(options?: AxiosRequestConfig) : Promise<AxiosResponse<Blob>> {
        return HomeApiFp(this.configuration).getFile(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary This controller provides global searching by key value 
     * @param {string} searchedValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public async getGlobalSearchResult(searchedValue: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<GlobalSearchDto>>> {
        return HomeApiFp(this.configuration).getGlobalSearchResult(searchedValue, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of published application releases for Home page
     * @param {number} page 
     * @param {number} size 
     * @param {number} [enginesId] 
     * @param {ApplicationReleaseSortOrder} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public async getPublishedApplicationReleases(page: number, size: number, enginesId?: number, sortOrder?: ApplicationReleaseSortOrder, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoApplicationReleaseDto>> {
        return HomeApiFp(this.configuration).getPublishedApplicationReleases(page, size, enginesId, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }
}
