import 'assets/styles/Card.scss';
import { FC, useEffect } from 'react';
import { McButton, McMarkdown } from 'components/mc';
import { getPublicImageURL } from 'services/ApiService';
import { TemaGenericAppIcon } from 'assets/icons/apps';
import { useQuery } from '@tanstack/react-query';
import { ApplicationReleaseDto } from 'api';
import applicationsService from 'services/ApplicationsService';
import { App, Spin } from 'antd';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { mcErrorNotification } from 'utils/Notifications';
import { Cond } from 'utils/Cond';

interface CardProps {
	release: ApplicationReleaseDto;
}

export const AppsCard: FC<CardProps> = ({ release }) => {
	const { user } = useAppSelector(authState);
	const isGuest = user.role === 'GUEST';
	const { notification } = App.useApp();
	const {
		data: app,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['applications', release.applicationId],
		queryFn: () =>
			applicationsService
				.getApplication(release.applicationId!)
				.then((res) => res.data),
		enabled: !!release && release.applicationId !== undefined && !isGuest,
	});

	useEffect(() => {
		if (!error) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				error,
				'fetch',
				'application data for apps to try'
			)
		);
	}, [error, notification]);

	return (
		<div className="card-container">
			<Spin spinning={isLoading} size={'large'}>
				<Cond if={!!app && !!release}>
					<div className="card-content">
						<div className="card-body">
							<div className="title-container">
								<div className="icon-container">
									{!app?.iconImagePath ? (
										<TemaGenericAppIcon size={24} />
									) : (
										<img
											alt="app icon"
											src={getPublicImageURL(app.iconImagePath)}
										/>
									)}
								</div>
								<h4 className="title">{app?.name}</h4>
							</div>
							<div className="description-container">
								<div className="description">
									<McMarkdown content={release.description} />
								</div>
							</div>
						</div>
						<div className="btn-container">
							<McButton.Route primary to={`/apps/${release.id}`}>
								Discover More
							</McButton.Route>
						</div>
					</div>
				</Cond>
			</Spin>
		</div>
	);
};
