import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, CloudLargeIcon, DownloadIcon } from 'assets/icons/svg';
import style from 'assets/styles/oneElementPage.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import {
	getEngineAppReleases,
	getOneEngine,
	oneEngineState,
} from 'store/slices/oneEngine';
import { Spin } from 'antd';
import { getDownloadEngineUrl, getPublicImageURL } from 'services/ApiService';
import { getApps, appsState } from 'store/slices/applications';
import { McMarkdown } from 'components/mc';
import { ApplicationDto, ApplicationReleaseDto } from 'api';
import { Cond } from 'utils/Cond';

export const OneEngine = () => {
	let navigate = useNavigate();

	const { id: paramId } = useParams();
	const id = Number(paramId);
	const dispatch = useAppDispatch();
	const { engine, loading, rels, appsLoading } = useAppSelector(oneEngineState);
	const { apps } = useAppSelector(appsState);

	const handleBackBtn = () => {
		navigate(-1);
	};

	useEffect(() => {
		dispatch(getApps({ page: 0, size: 99 }));
	}, [dispatch]);

	useEffect(() => {
		if (!paramId) return;
		if (!Number.isInteger(id)) return;
		dispatch(getOneEngine(id));
		dispatch(getEngineAppReleases(id));
	}, [id, paramId, dispatch]);

	const appRels = useMemo(
		() =>
			rels.map(
				(rel) =>
					[
						rel,
						(apps?.content || []).find((a) => a.id === rel.applicationId),
					] as [ApplicationReleaseDto, ApplicationDto | undefined]
			),
		[apps, rels]
	);

	return (
		<Spin spinning={loading}>
			<div className={style.container}>
				<div className={style.header}>
					<CloudLargeIcon />
					<h1 className={style.title}>{engine.name}</h1>
				</div>
				<Cond if={engine.fullSizeImagePath}>
					<div className={style.imgContainer}>
						<img alt="" src={getPublicImageURL(engine.fullSizeImagePath!)} />
					</div>
				</Cond>
				<div className="flex between wrap" style={{ gap: '2rem' }}>
					<Spin spinning={appsLoading} style={{ flex: 1 }}>
						<div className={style.appLogos} style={{ gap: '2rem' }}>
							{appRels.map(([rel, app]) => (
								<div key={rel.id} className={style.appLogo}>
									<img
										alt={`${app?.name} icon`}
										src={getPublicImageURL(app?.iconImagePath as string)}
									/>
									{app?.name}
								</div>
							))}
						</div>
					</Spin>
					<a
						style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
						href={getDownloadEngineUrl(id)}
					>
						Download Engine
						<DownloadIcon />
					</a>
				</div>
				<div className={style.descriptionContainer}>
					{engine.description && (
						<h2 className={style.descriptionTitle}>Description</h2>
					)}

					<McMarkdown content={engine.description} />
				</div>
				{engine.releaseNotes && (
					<div className={style.releaseNotesContainer}>
						<div className="flex align-center" style={{ gap: '0.75rem' }}>
							<h2 className={style.releaseNotesTitle}>Release Notes</h2>
							<h2>•</h2>
							<h3>{engine.releaseDate}</h3>
						</div>
						<div className={style.markdownContent}>
							<McMarkdown content={engine.releaseNotes} />
						</div>
					</div>
				)}
				<h4 className={style.backButton} onClick={handleBackBtn}>
					<ArrowLeftIcon />
					<span>Back</span>
				</h4>
			</div>
		</Spin>
	);
};
