import React, { Dispatch, useEffect } from 'react';
import style from 'assets/styles/manageElements.module.scss';
import { ItemContainer } from 'components/ItemContainer';
import { IconEdit, IconTrash, WebCrmIcon } from 'assets/icons/svg';
import { App, Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import EditOrganizationForm from './EditOrganizationForm';
import { OrganizationDto } from 'api';
import { webCrmOrganizationUrl } from 'utils/urls';
import organizationService from 'services/OrganizationService';
import {
	deleteItemModal,
	deleteSuccessNotification,
	errorNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface Props {
	organization: OrganizationDto;
	open: boolean;
	setSelected: Dispatch<OrganizationDto | undefined>;
	setRefresh: Dispatcher;
	refresh: boolean;
}

const ManageOneOrganization = ({ organization, open, setSelected }: Props) => {
	const { notification, modal } = App.useApp();

	const styles = open
		? {
				backgroundColor: 'var(--bg-active)',
				color: 'var(--button-text)',
		  }
		: {
				color: 'var(--text-normal)',
		  };

	const queryClient = useQueryClient();

	// Delete organization
	const { mutate: handleRemoveOrganization } = useMutation({
		mutationFn: () => organizationService.removeOrganization(organization.id),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['organizations'],
			});
			notification.success(deleteSuccessNotification(organization.name));
		},
		onError: (error: unknown) => {
			notification.error(
				mcErrorNotification('Error', error, 'remove', 'organization')
			);
		},
	});

	// Fetch the amount of users in the organization
	const { data: userAmount, error: getUserAmountError } = useQuery({
		queryKey: ['organizations', organization.id, 'users', 'count'],
		queryFn: () =>
			organizationService
				.getAmountOfUsersInOrganization(organization.id)
				.then((res) => res.data),
		initialData: 0,
		enabled: !!organization,
	});

	useEffect(() => {
		if (!getUserAmountError) return;
		notification.warning(errorNotification('Organization not found!'));
	}, [getUserAmountError, notification]);

	return (
		<div>
			<ItemContainer styles={styles}>
				<Row style={{ width: '100%', display: 'flex' }}>
					<Col span={8}>
						<div className={style.textItem}>{organization.name}</div>
					</Col>
					<Col span={3}>
						<div
							className={style.textItem}
							style={{
								paddingLeft: '1rem',
							}}
						>
							{organization.webCrmId && (
								<a
									href={webCrmOrganizationUrl(organization.webCrmId)}
									target="_blank"
									rel="noreferrer"
								>
									<Icon component={WebCrmIcon} />
								</a>
							)}
						</div>
					</Col>
					<Col span={3}>
						<div className={style.textItem}>{userAmount} users</div>
					</Col>
					<Col span={8}>
						<div className={style.textItem}>{organization.email}</div>
					</Col>
				</Row>
				<div className={style.buttonsItem}>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() =>
							open ? setSelected(undefined) : setSelected(organization)
						}
					>
						<IconEdit />
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(
									() => handleRemoveOrganization(),
									organization.name
								)
							);
						}}
					>
						<IconTrash />
					</div>
				</div>
			</ItemContainer>
			{open && (
				<EditOrganizationForm
					organization={organization}
					setSelected={setSelected}
				/>
			)}
		</div>
	);
};

export default ManageOneOrganization;
