import React from 'react';
import style from 'assets/styles/filterField.module.scss';
import { DatePicker, Space } from 'antd';
import type { DatePickerProps } from 'antd';

interface DateProps {
	label?: string;
	setSelectedField: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const FilterReleaseYear: React.FC<DateProps> = ({
	label,
	setSelectedField,
}) => {
	const onSelectDate: DatePickerProps['onChange'] = (date, dateString) => {
		setSelectedField(date?.year());
	};

	return (
		<div
			className={style.filterField}
			style={{ flex: '0 0 15.313rem', padding: '0 1rem' }}
		>
			<span
				style={{
					color: 'var(--text-normal)',
					fontSize: '1.125rem',
					fontWeight: '600',
					whiteSpace: 'nowrap',
				}}
			>
				{label}
			</span>
			<Space direction="vertical">
				<DatePicker
					onChange={onSelectDate}
					variant="borderless"
					placeholder={'...'}
					popupClassName={style.filterYearPopup}
					className={style.filterYear}
					getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
					placement={'bottomRight'}
					allowClear
					picker="year"
				/>
			</Space>
		</div>
	);
};
