import { App, Modal } from 'antd';
import { ISMA_FIRM } from 'App';
import { McLogoIcon } from 'assets/icons/svg';
import { ipc, isDesktop } from 'desktop';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
	codemeterState,
	setImportLifModalDismissed,
} from 'store/slices/Codemeter';
import { errorNotification, infoNotification } from 'utils/Notifications';
import style from 'assets/styles/terms.module.scss';
import { authState } from 'store/slices/auth';
import { useMutation } from '@tanstack/react-query';

const LifTemplateModal = () => {
	const [importLifModalOpen, setImportLifModalOpen] = useState<boolean>(false);
	const { importLifModalDismissed } = useAppSelector(codemeterState);
	const { isAuth } = useAppSelector(authState);
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();

	const { mutate: importLif, isPending: isPendingLifImport } = useMutation({
		mutationFn: () => ipc.importLifTemplate(),
		onError: () => {
			notification.error(
				errorNotification('Failed to import standalone license container!')
			);
		},
		onSuccess: () => {
			notification.success(
				infoNotification('Successfully imported standalone license container!')
			);
			setImportLifModalOpen(false);
			dispatch(setImportLifModalDismissed(true));
		},
	});

	const hasContainer: () => Promise<boolean> = useCallback(async () => {
		const res = await Promise.allSettled([
			ipc.getCodemeterContainers(ISMA_FIRM, 'Act'),
			ipc.getCodemeterContainers(ISMA_FIRM, 'Dongle'),
		]);

		for (const result of res) {
			if (result.status === 'fulfilled' && result.value.length > 0) {
				return true;
			}
		}
		return false;
	}, []);

	useEffect(() => {
		if (isDesktop && !importLifModalDismissed) {
			hasContainer()
				.then((hasCmContainer) => {
					if (!hasCmContainer) {
						setImportLifModalOpen(true);
					} else {
						dispatch(setImportLifModalDismissed(true));
					}
				})
				.catch(() =>
					console.error('Failed to check containers for LIF import!')
				);
		}
	}, [dispatch, hasContainer, importLifModalDismissed]);

	return (
		<Modal
			open={
				isDesktop && isAuth && importLifModalOpen && !importLifModalDismissed
			}
			title={
				<div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
					<McLogoIcon />
					<div>Import License Container</div>
				</div>
			}
			okText="Import Container"
			cancelText={'Ignore'}
			centered
			cancelButtonProps={{ disabled: isPendingLifImport }}
			okButtonProps={{
				disabled: isPendingLifImport || !isDesktop,
				loading: isPendingLifImport,
			}}
			onOk={() => {
				if (
					isDesktop &&
					!isPendingLifImport &&
					importLifModalOpen &&
					!importLifModalDismissed
				) {
					importLif();
				}
			}}
			className={style.modal}
			maskClosable={false}
			onCancel={() => {
				if (!isPendingLifImport) {
					setImportLifModalOpen(false);
					dispatch(setImportLifModalDismissed(true));
				}
			}}
		>
			In order to use TEMA products, at least one license container is required.
			Motion Cloud failed to detect any such container for your system.
			<br />
			<br />
			Do you wish to import an empty standalone license container that will be
			tied to your current computer?
		</Modal>
	);
};

export default LifTemplateModal;
