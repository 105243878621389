import React, {
	useState,
	useEffect,
	Dispatch,
	SetStateAction,
	useMemo,
} from 'react';
import style from 'assets/styles/selectField.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { UserDto } from 'api';
import usersService from 'services/UsersService';
import SelectEntity, { Option } from './SelectEntity';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { App, Tooltip } from 'antd';
import { mcErrorNotification } from 'utils/Notifications';

interface SelectCustomerProps {
	userId: number | undefined;
	setUserId: Dispatch<SetStateAction<number | undefined>>;
	tabIndex?: number;
}

export const SelectCustomer: React.FC<SelectCustomerProps> = ({
	userId,
	setUserId,
	tabIndex,
}) => {
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce(searchText, 200);
	const PAGE_SIZE = 100;
	const { notification } = App.useApp();

	const [initialId, setInitialId] = useState<number | undefined>(userId);

	useEffect(() => {
		if (userId !== undefined && userId !== null && initialId === undefined)
			setInitialId(userId);
	}, [initialId, userId]);

	const {
		data: initialUser,
		isLoading: isLoadingInitialUser,
		error: getInitialUserError,
	} = useQuery({
		queryKey: ['users', initialId],
		queryFn: () => usersService.getUserById(initialId!).then((res) => res.data),
		enabled: initialId !== undefined,
	});

	const {
		data: userPages,
		isLoading: isLoadingUsers,
		error: getUsersError,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['users', 'infinite', debouncedSearch],
		queryFn: ({ pageParam: pageNr }) =>
			usersService
				.getUsersList(pageNr, PAGE_SIZE, undefined, undefined, debouncedSearch)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const userOptions: Option[] = useMemo(() => {
		// Mapping option function
		const mapOption = (user: UserDto) => {
			return {
				label: (
					<Tooltip
						placement="rightTop"
						title={user.email}
						overlayInnerStyle={{ textWrap: 'nowrap' }}
						overlayStyle={{ maxWidth: '500px' }}
						fresh
					>
						<div className={style.dropdownItem}>
							<div className={style.labelIconName}>
								<div>
									{user.firstName} {user.lastName}
								</div>
							</div>
							<div className={style.dropdownItemCompany}>({user.company})</div>
						</div>
					</Tooltip>
				),
				value: user.id,
				searchValue: (
					user.firstName! +
					user.lastName! +
					user.company +
					user.email +
					(user.department ?? '')
				).replace(/ /g, ''),
			};
		};

		// Options retrieved through pagination
		const options = !!userPages
			? userPages.pages.reduce(
					(acc, page) => [
						...acc,
						...page.content.map((user) => mapOption(user)),
					],
					[] as Option[]
			  )
			: [];

		// If we have an initially selected entity and it isn't already an option, add it as one.
		if (
			initialUser !== undefined &&
			!options.find((option) => option.value === initialUser.id)
		)
			return [mapOption(initialUser), ...options];
		return options;
	}, [initialUser, userPages]);

	useEffect(() => {
		if (!getUsersError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getUsersError,
				'fetch',
				'user list for select field'
			)
		);
	}, [getUsersError, notification]);

	useEffect(() => {
		if (!getInitialUserError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				getInitialUserError,
				'fetch',
				'initial users for select field'
			)
		);
	}, [getInitialUserError, notification]);

	return (
		<SelectEntity
			setSearchText={setSearchText}
			placeholder="Select a customer"
			label="Customer"
			entityName="customer"
			tabIndex={tabIndex}
			loading={isLoadingUsers || isLoadingInitialUser}
			setSelectedId={setUserId}
			selectedId={userId}
			includeEmptyOption={false}
			fetchMoreEntities={fetchNextPage}
			options={userOptions}
		/>
	);
};
