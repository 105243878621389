import { SvgIcon, SvgIconProps } from './SvgIcon';

export const LinkIcon: React.FC<SvgIconProps> = ({ size = '24' }) => (
	<SvgIcon actualSize={24} size={size}>
		<path
			d="M10 13C10.4295 13.5741 10.9774 14.0491 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9403 15.7513 14.6897C16.4231 14.4392 17.0331 14.047 17.54 13.54L20.54 10.54C21.4508 9.59695 21.9548 8.33394 21.9434 7.02296C21.932 5.71198 21.4061 4.45791 20.4791 3.53087C19.5521 2.60383 18.298 2.07799 16.987 2.0666C15.676 2.0552 14.413 2.55918 13.47 3.46997L11.75 5.17997"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14 10.9992C13.5705 10.4251 13.0226 9.95007 12.3934 9.60631C11.7642 9.26255 11.0684 9.05813 10.3533 9.00691C9.63816 8.9557 8.92037 9.05888 8.24861 9.30947C7.57685 9.56005 6.96684 9.95218 6.45996 10.4592L3.45996 13.4592C2.54917 14.4023 2.04519 15.6653 2.05659 16.9763C2.06798 18.2872 2.59382 19.5413 3.52086 20.4683C4.4479 21.3954 5.70197 21.9212 7.01295 21.9326C8.32393 21.944 9.58694 21.44 10.53 20.5292L12.24 18.8192"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
