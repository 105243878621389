import { CSSProperties, FC, ReactNode, forwardRef, Ref } from 'react';
import 'assets/styles/Section.scss';

interface SectionProps {
	children?: ReactNode;
	title?: string;
	row?: boolean;
	disabled?: boolean;
	style?: CSSProperties;
	ref?: Ref<HTMLDivElement>;
	customTitle?: ReactNode;
}

export const Section: FC<SectionProps & { ref?: Ref<HTMLDivElement> }> =
	forwardRef<HTMLDivElement, SectionProps>(
		({ children, title, row, disabled, style, customTitle }, ref) => {
			return (
				<div
					className="section-container"
					style={disabled ? { opacity: '0.4', ...style } : { ...style }}
					ref={ref}
				>
					{!!customTitle ? (
						customTitle
					) : (
						<h3 className="section-title">{title}</h3>
					)}
					<div className="section-content">{children}</div>
				</div>
			);
		}
	);
