import { Dispatch, useState } from 'react';
import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';
import { UploadIcon } from 'assets/icons/svg';
import { CmContainer, ipc } from 'desktop';
import { ActivationWizardState } from '../ActivationWizard';
import { useMutation } from '@tanstack/react-query';
import { Spin } from 'antd';

interface Props {
	changeDisplayPage: Dispatch<ActivationWizardState>;
	selectedContainer: CmContainer;
}

const ExportContextPage = ({ changeDisplayPage, selectedContainer }: Props) => {
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);

	const [isExporting, setIsExporting] = useState<boolean>(false);

	const exportContextFile = async (container: CmContainer) => {
		setErrorMessage(undefined);
		setIsExporting(true);
		try {
			const context = await createContext(container);
			const blobText = await context.text();
			const fileName: string = `Cm-${container.container_type}-${container.mask}-${container.serial}.WibuCmRaC`;
			var url = window.URL.createObjectURL(context);
			const tempLink = document.createElement('a');
			tempLink.href = url;
			tempLink.setAttribute('download', fileName);
			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(url);
			await ipc.saveFile(fileName, blobText);
		} catch (err) {
			setErrorMessage('Failed to export generated context file!');
		} finally {
			setIsExporting(false);
		}
	};

	const { mutateAsync: createContext, isPending } = useMutation({
		mutationFn: (container: CmContainer) =>
			ipc
				.createContextFile(container.mask, container.serial)
				.then((res) => new Blob([String.fromCharCode.apply(String, res)])),
		onError: (err: any) => {
			setErrorMessage(
				'Failed to generate context file for the selected container!'
			);
		},
	});

	const disableButtons: boolean = isPending || isExporting;

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Export Context</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					Export the context file for the currently selected container and
					transfer it to the online computer.
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '24px',
					gap: '12px',
					alignItems: 'center',
				}}
			>
				<McButton
					primary
					disabled={disableButtons}
					onClick={() => {
						if (!disableButtons) exportContextFile(selectedContainer);
					}}
				>
					<UploadIcon />
					Export context file
				</McButton>
				{disableButtons && <Spin spinning={disableButtons} />}
				{!!errorMessage && (
					<div
						style={{
							color: 'var(--add-red)',
							fontSize: '13px',
							fontWeight: '500',
						}}
					>
						{errorMessage}
					</div>
				)}
			</div>
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton
					onClick={() => {
						changeDisplayPage('OfflineActivation');
					}}
					disabled={disableButtons}
				>
					Back
				</McButton>

				<McButton
					primary
					onClick={() => {
						changeDisplayPage('ImportUpdate');
					}}
					disabled={disableButtons}
				>
					Next
				</McButton>
			</div>
		</div>
	);
};

export default ExportContextPage;
