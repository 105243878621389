import React, { forwardRef } from 'react';
import style from 'assets/styles/components.module.scss';

interface InputProps {
	placeholder: string;
	value: string;
	label?: string;
	tabIndex?: number;
	height?: string;
	error?: boolean;
	errorMessage?: string;
	onFocus?: () => void;
	onBlur?: () => void;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export type InputTextAreaRef = HTMLTextAreaElement;

export const InputTextarea: React.FC<InputProps> = forwardRef<
	InputTextAreaRef,
	InputProps
>((props, ref) => {
	return (
		<>
			<div className={style.inputFieldWrapper}>
				<span className={style.inputFieldLabel}>{props.label}</span>
				<div
					className={style.inputTextareaWrapper}
					style={{ border: props.error ? '1.5px solid var(--add-red)' : '' }}
				>
					<textarea
						className={style.inputTextarea}
						style={{ height: props.height }}
						placeholder={props.placeholder}
						ref={ref}
						value={props.value}
						tabIndex={props.tabIndex}
						onChange={props.onChange}
						onFocus={props.onFocus}
						onBlur={props.onBlur}
					></textarea>
				</div>
			</div>
			<div className={style.errorMessage}>{props.errorMessage}</div>
		</>
	);
});
