import React from 'react';
import style from '../assets/styles/RedDot.module.scss';

interface Props {
	additionalStyle: React.CSSProperties;
}

const RedDot = ({ additionalStyle }: Props) => {
	return <div className={style.redDot} style={additionalStyle}></div>;
};

export default RedDot;
