import React from 'react';
import { IconClose, ImageSystemsLogo, McLogoIcon } from 'assets/icons/svg';
import version from 'version.json';

export const AboutPopoverContent = ({
	setVisible,
}: {
	setVisible: (value: boolean) => void;
}) => {
	const handleClose = () => {
		setVisible(false);
	};

	return (
		<div className="AboutPopover popoverSettings-content">
			<div className="closePopover" onClick={handleClose}>
				<IconClose />
			</div>
			<div className="about-logo-wrapper flex">
				<div>
					<McLogoIcon />
				</div>
				<div className="flex-col">
					<h5 className="logo-text">Motion Cloud</h5>
					<p className="version-text">Version {version.fullVersion}</p>
				</div>
			</div>
			<p>
				Lorem ipsum dolor sit amet consectetur. Nibh porttitor risus sagittis
				pretium in praesent hendrerit. Id faucibus ipsum nunc pellentesque
				ultrices sollicitudin cursus. Diam vulputate purus feugiat ut euismod id
				sagittis sapien interdum. Eget gravida id faucibus nisi etiam nunc quam
				sit ullamcorper. Diam ut in dolor velit. Ornare tempor at neque pretium.
				Elementum lorem risus quam iaculis lacus risus tempus. Neque urna
				sodales ornare sit arcu. Lectus porttitor suspendisse ultricies urna in
				integer elit.
			</p>
			<div
				style={{
					borderTop: '1px solid var(--border-popup-inside)',
					paddingTop: '1.5rem',
					marginTop: '1.5rem',
				}}
			>
				<p>
					&copy; 2023 Image Systems. All rights reserved. Image Systems, the
					Image Systems logo and motion Cloud are either registered trademarks
					or trademarks of Image Systems in Sweden and/or other countries.
				</p>
			</div>

			<div
				className="flex center"
				style={{
					borderTop: '1px solid var(--border-popup-inside)',
					paddingTop: '1.5rem',
					marginTop: '1.5rem',
				}}
			>
				<ImageSystemsLogo />
			</div>
		</div>
	);
};
