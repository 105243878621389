import React from 'react';
import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';

const ExportUpdatePage = () => {
	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Import Context</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					On the remote computer, export the context file and transfer it to
					this computer. Then import the context file by pressing "Import
					context file".
				</div>
			</div>
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton onClick={() => {}}>Back</McButton>

				<McButton primary onClick={() => {}}>
					Next
				</McButton>
			</div>
		</div>
	);
};

export default ExportUpdatePage;
