import { useEffect, useState } from 'react';

export const useAsync = <T>(
	promise: () => Promise<T>,
	initial?: T
): [T | undefined, () => void] => {
	const [state, setState] = useState(initial);
	const updateState = () => void promise().then((v) => setState(v));

	useEffect(updateState, [promise]);

	return [state, updateState];
};
