export const enableHackyZoomSupport = () => {
	let currZoom = 1.0;
	let zoomScroll = false;
	const maxZoom = 1.5;
	const minZoom = 0.7;

	const zoomIn = () => updateZoom(Math.min(currZoom + 0.1, maxZoom));
	const zoomOut = () => updateZoom(Math.max(currZoom - 0.1, minZoom));
	const updateZoom = (newZoom: number) => {
		currZoom = newZoom;
		document
			.querySelector<HTMLElement>(':root')
			?.style.setProperty('--zoom', currZoom.toString());
	};

	document.addEventListener('keydown', (e) => {
		zoomScroll = e.ctrlKey;
		if (!e.ctrlKey) return;
		switch (e.key) {
			case '0':
				updateZoom(1);
				break;
			case '+':
			case '=':
				zoomIn();
				break;
			case '-':
				zoomOut();
				break;
			default:
		}
	});
	document.addEventListener('keyup', (e) => {
		if (zoomScroll === e.ctrlKey) return;
		zoomScroll = e.ctrlKey;
	});

	window.addEventListener('blur', () => {
		zoomScroll = false;
	});

	document.addEventListener('wheel', (e) => {
		if (!zoomScroll) return;
		if (e.deltaY > 0) {
			zoomOut();
		} else if (e.deltaY < 0) {
			zoomIn();
		}
	});
};
