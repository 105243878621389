import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { supportService } from 'services/SupportService';
import { SupportsResponse } from 'types/response/SupportsResponse';

import { RootState } from 'store';

import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';
import tagService from 'services/TagsService';
import { ErrorDTO, SupportItemType } from 'api';

export interface SupportsState {
	supports: SupportsResponse;
	loading: boolean;
	error: ErrorDTO | null;
	tags: Record<number, string>;
}

const initialState: SupportsState = {
	supports: {} as SupportsResponse,
	loading: false,
	error: null,
	tags: {},
};

interface GetSupportsParams {
	page: number;
	searchedValue: string;
	size: number;
	supportItemType: SupportItemType | undefined;
}

export const getSupports = createAppAsyncThunk(
	'supports/getSupports',
	(
		{ page, searchedValue, size, supportItemType }: GetSupportsParams,
		{ rejectWithValue }
	) =>
		supportService
			.supportItemsList(page, size, searchedValue, supportItemType)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getTags = createAppAsyncThunk(
	'supports/getTags',
	(_, { rejectWithValue }) =>
		tagService.getTagsList().catch(getErrorMessageAnd(rejectWithValue))
);

const supportsSlice = createSlice({
	name: 'supports',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getSupports.pending, setLoadingState)
			.addCase(getSupports.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.supports = payload.data;
			})

			.addCase(getTags.fulfilled, (state, { payload }) => {
				state.tags = Object.fromEntries(
					payload.data.map((tag) => [tag.id, tag.name])
				);
			})

			.addMatcher(
				isAnyOf(getSupports.rejected, getTags.rejected),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
					console.error('[Support] Error: %o', state.error);
				}
			);
	},
});

export const supportsState = (state: RootState) => state.supports;

export default supportsSlice.reducer;

const setLoadingState = (state: SupportsState) => {
	state.loading = true;
	state.error = null;
};
