import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { newsService } from 'services/NewsService';
import { NewsResponse } from 'types/response/NewsResponse';

import { RootState } from 'store';
import { NewsCategory, NewsStatus } from 'types/api';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';
import { ErrorDTO } from 'api';
import { SortOrder } from 'pages';

export type { NewsCategory, EntityStatus as NewsStatus } from 'api';

export interface NewsListState {
	newsList?: NewsResponse;
	publishedNewsForHome: NewsResponse;
	publishedNewsForStore: NewsResponse;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: NewsListState = {
	newsList: undefined,
	publishedNewsForHome: {} as NewsResponse,
	publishedNewsForStore: {} as NewsResponse,
	loading: false,
	error: null,
};

type NewsRequestParamsLoose = Parameters<typeof newsService.getNewsList>;
type NewsRequestParams = [
	NewsRequestParamsLoose[0], // Page
	NewsRequestParamsLoose[1], // Size
	NewsRequestParamsLoose[2], // Filter Query
	NewsStatus | undefined,
	NewsCategory | undefined,
	SortOrder | undefined
];

export const getNews = createAppAsyncThunk(
	'news/getNews',
	(params: NewsRequestParams, { rejectWithValue }) =>
		newsService
			.getNewsList(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getPublishedNewsForHome = createAppAsyncThunk(
	'news/getPublishedNewsForHome',
	(params: NewsRequestParams, { rejectWithValue }) =>
		newsService
			.getNewsList(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getPublishedNewsForStore = createAppAsyncThunk(
	'news/getPublishedNewsForStore',
	(params: NewsRequestParams, { rejectWithValue }) =>
		newsService
			.getNewsList(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const newsSlice = createSlice({
	name: 'news',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getNews.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.newsList = payload.data;
			})
			.addCase(getPublishedNewsForHome.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.publishedNewsForHome = payload.data;
			})
			.addCase(getPublishedNewsForStore.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.publishedNewsForStore = payload.data;
			})
			.addCase(resetState, () => {
				return initialState;
			})
			.addMatcher(
				isAnyOf(
					getNews.pending,
					getPublishedNewsForHome.pending,
					getPublishedNewsForStore.pending
				),
				setLoadingState
			)
			.addMatcher(
				isAnyOf(
					getNews.rejected,
					getPublishedNewsForHome.rejected,
					getPublishedNewsForStore.rejected
				),
				(state, action) => {
					console.error('[NEWS] %o error: %o', action.type, action.payload);
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const newsState = (state: RootState) => state.news;

export default newsSlice.reducer;
