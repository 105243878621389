import React, {
	useState,
	Dispatch,
	MouseEventHandler,
	useCallback,
	useEffect,
} from 'react';
import { EditSubscriptionForm } from './EditSubscriptionForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import {
	IconEdit,
	IconTrash,
	HelpIcon,
	FloatingSubscriptionIcon,
	StandaloneSubscriptionIcon,
} from 'assets/icons/svg';
import { AdminSubscriptionDto } from 'api';
import { capitalize } from 'utils';
import { McIconButton } from 'components/mc';
import subscriptionsService from 'services/SubscriptionsService';
import { App, Popover, Spin, Tooltip } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface Props {
	subscription: AdminSubscriptionDto;
	setSelectedToUpdate: Dispatch<number | null>;
	selectedToUpdate: number | null;
}

type CopyState = 'READY' | 'PENDING' | 'COPIED' | 'FAILED';

export const ManageOneSubscription: React.FC<Props> = ({
	subscription,
	setSelectedToUpdate,
	selectedToUpdate,
}) => {
	const [copyState, setCopyState] = useState<CopyState>('READY');
	const [summaryText, setSummaryText] = useState<string>();

	const { notification, modal } = App.useApp();
	const queryClient = useQueryClient();

	const styles =
		selectedToUpdate === subscription.id
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
					columnGap: '2rem',
			  }
			: {
					color: 'var(--text-normal)',
					columnGap: '2rem',
			  };

	const clickEditBtnHandler: MouseEventHandler = (e) => {
		e.preventDefault();

		if (selectedToUpdate === null) {
			setSelectedToUpdate(subscription.id);
		} else {
			if (selectedToUpdate === subscription.id) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(subscription.id);
			}
		}
	};

	const handleCopyButtonClick: MouseEventHandler = useCallback(
		(e) => {
			e.preventDefault();
			if (copyState === 'READY') {
				setCopyState('PENDING');
				subscriptionsService
					.getSubscriptionData(subscription.id)
					.then((res) => {
						copySummary(res.data);
					});
			} else {
				copySummary(summaryText ?? '');
			}
		},
		[subscription.id, copyState, summaryText]
	);

	const copySummary = (summary: string) => {
		setSummaryText(summary);
		try {
			navigator.clipboard.writeText(summary);
			setCopyState('COPIED');
		} catch (x) {
			setCopyState('FAILED');
		}
	};

	const { mutate: deleteSubscription } = useMutation({
		mutationFn: () =>
			subscriptionsService.deleteSubscriptionById(subscription.id),
		onSuccess: () => {
			notification.success(
				deleteSuccessNotification(subscription.subscriptionID)
			);
			queryClient.invalidateQueries({
				queryKey: ['subscriptions'],
			});
		},
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'subscription')
			),
	});

	const selectedSubscription = subscription as Subscription;

	const { data: hasOpenRequests, error: hasOpenRequestsError } = useQuery({
		queryKey: ['subscriptions', subscription.id, 'licenses', 'requests'],
		queryFn: () =>
			subscriptionsService
				.hasOpenRequests(subscription.id)
				.then((res) => res.data),
	});

	useEffect(() => {
		if (!hasOpenRequestsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				hasOpenRequestsError,
				'check whether',
				'subscription has an open request'
			)
		);
	}, [hasOpenRequestsError, notification]);

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.textItem} style={{ flex: '3', gap: '16px' }}>
					<Tooltip
						title={
							!subscription.status || subscription.status === 'INACTIVE'
								? capitalize(subscription.status)
								: subscription.hasExpired
								? 'Expired'
								: capitalize(subscription.status)
						}
					>
						<div
							className={style.statusCircle}
							style={{
								background:
									!subscription.status || subscription.status === 'INACTIVE'
										? 'var(--add-red)'
										: subscription.hasExpired
										? 'var(--add-orange)'
										: !!subscription.status && subscription.status === 'ACTIVE'
										? 'var(--add-green)'
										: 'var(--add-red)',
							}}
						/>
					</Tooltip>
					<Tooltip
						title={
							subscription.type === 'NETWORK'
								? 'Floating subscription'
								: 'Standalone subscription'
						}
					>
						<div
							style={{
								display: 'inline-block',
								height: '24px',
								width: '24px',
							}}
						>
							{subscription.type === 'NETWORK' ? (
								<FloatingSubscriptionIcon />
							) : (
								<StandaloneSubscriptionIcon />
							)}
						</div>
					</Tooltip>

					<div>{subscription.subscriptionID}</div>
				</div>
				<div className={style.textItem} style={{ flex: '4' }}>
					{!!subscription.ownerInfo
						? `${subscription.ownerInfo.firstName} ${subscription.ownerInfo.lastName} (${subscription.ownerInfo.company})`
						: 'No Customer'}
				</div>
				<div className={style.buttonsItem} style={{ flexGrow: 0, gap: 0 }}>
					<Popover
						placement="left"
						overlayClassName="primary-popover"
						content={
							copyState === 'COPIED' ? (
								<>Subscription summary was copied to clipboard!</>
							) : copyState === 'FAILED' ? (
								<>Failed to copy summary. Click to try again!</>
							) : copyState === 'READY' ? (
								<>Click to copy subscription summary</>
							) : (
								<Spin spinning />
							)
						}
					>
						<McIconButton onClick={handleCopyButtonClick} icon={<HelpIcon />} />
					</Popover>

					<McIconButton icon={<IconEdit />} onClick={clickEditBtnHandler} />
					<McIconButton
						icon={<IconTrash />}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(
									() => deleteSubscription(),
									subscription.subscriptionID
								)
							);
						}}
					/>
				</div>
			</ItemContainer>
			{selectedToUpdate === subscription.id && (
				<EditSubscriptionForm
					subscription={selectedSubscription}
					hasOpenRequests={hasOpenRequests}
					hideForm={() => setSelectedToUpdate(null)}
				/>
			)}
		</div>
	);
};
