import {
	FullDataLicenseDto,
	LicenseRequestProgressDto,
	RequestType,
	SubscriptionDto,
} from 'api';
import { Dispatch, useLayoutEffect, useState } from 'react';
import style from 'assets/styles/activationWizard.module.scss';
import { IconActivationWizard, IconClose } from 'assets/icons/svg';
import SelectContainerPage from './ActivationPages/SelectContainerPage';
import DefineActivationPage from './ActivationPages/DefineActivationPage';
import { CmContainer, isDesktop } from 'desktop';
import OfflineActivationSelect from './ActivationPages/OfflineActivationSelect';
import { App } from 'antd';
import { useAppSelector } from 'hooks/hooks';
import { authState } from 'store/slices/auth';
import { errorNotification } from 'utils/Notifications';
import ExportContextPage from './ActivationPages/ExportContextPage';
import ImportContextPage from './ActivationPages/ImportContextPage';
import ExportUpdatePage from './ActivationPages/ExportUpdatePage';
import ImportUpdatePage from './ActivationPages/ImportUpdatePage';
import ExportReceiptPage from './ActivationPages/ExportReceiptPage';
import SendReceiptPage from './ActivationPages/SendReceiptPage';

interface Props {
	displayActivationWizard: boolean;
	setDisplayActivationWizard: Dispatch<boolean>;
	subscription?: SubscriptionDto;
	licenses?: FullDataLicenseDto[];
	requestType?: RequestType;
	deselectAllLicenses?: () => void;
}

export type ActivationWizardState =
	| 'SelectContainer'
	| 'DefineActivation'
	| 'OfflineActivation'
	| 'ExportContext'
	| 'ImportContext'
	| 'ExportUpdate'
	| 'ImportUpdate'
	| 'ExportReceipt'
	| 'SendReceipt';

export type OfflineActivationOption = 'OnlineComputer' | 'OfflineComputer';

/**
 * The following functional component is the motion cloud license activation wizard.
 * All pages that can be shown are sibiling components with one or more condition for it to be shown.
 * These components can change the state of the main activation wizard through props and also
 * request a change of page through the changeState function.
 */
const ActivationWizard = ({
	displayActivationWizard,
	setDisplayActivationWizard,
	subscription,
	licenses: propLicenses,
	requestType,
	deselectAllLicenses,
}: Props) => {
	const { notification, modal } = App.useApp();
	const { user } = useAppSelector(authState);
	const isGuest = user.role === 'GUEST';

	const [preSelectedContainerId, setPreSelecedContainerId] = useState<
		string | undefined
	>(undefined);

	const [licenses] = useState<FullDataLicenseDto[] | undefined>(propLicenses);

	useLayoutEffect(() => {
		if (
			(requestType === 'DEACTIVATION' || requestType === 'EXTEND_ACTIVATION') &&
			!!licenses &&
			licenses.length > 0
		) {
			const containerIds = licenses.map((license) => license.containerName);
			if (containerIds.includes(undefined)) {
				setDisplayActivationWizard(false);
				return;
			}
			containerIds.every((id) => containerIds.at(0)! === id!); // FIXME: maybe problem with this?
			setPreSelecedContainerId(containerIds.at(0));
		}
	}, [licenses, requestType, setDisplayActivationWizard]);

	// The main function used to transition between states.
	function changeState(desiredState: ActivationWizardState) {
		switch (desiredState) {
			case 'SelectContainer':
				setOfflineActivationOption(undefined);
				setCurrentState('SelectContainer');
				break;
			case 'DefineActivation':
				if (
					!isGuest &&
					!!selectedContainer &&
					!!subscription &&
					!!licenses &&
					licenses.length > 0 &&
					!!requestType
				)
					setCurrentState('DefineActivation');
				else {
					notification.error(
						errorNotification('Not all prerequisites met to access this page!')
					);
				}
				break;
			case 'OfflineActivation':
				setCurrentState('OfflineActivation');
				break;
			case 'ImportContext':
				setCurrentState('ImportContext');
				break;
			case 'ExportContext':
				setCurrentState('ExportContext');
				break;
			case 'ImportUpdate':
				setCurrentState('ImportUpdate');
				break;
			case 'ExportReceipt':
				setCurrentState('ExportReceipt');
				break;
			case 'SendReceipt':
				setCurrentState('SendReceipt');
				break;
		}
	}

	// OBS! The set function for this state is not meant to be manipulated outside of the changeDisplayPage function
	// =============================================================================================================
	const [currentState, setCurrentState] =
		useState<ActivationWizardState>('SelectContainer');

	const [selectedContainer, setSelectedContainer] = useState<
		CmContainer | undefined
	>(undefined);

	const [offlineActivationOption, setOfflineActivationOption] = useState<
		OfflineActivationOption | undefined
	>(undefined);

	const [importedContextFile, setImportedContextFile] = useState<
		Blob | undefined
	>(undefined);

	const [manualLicenseProgressDto, setManualLicenseProgressDto] = useState<
		LicenseRequestProgressDto | undefined
	>(undefined);
	// =============================================================================================================

	return (
		<>
			{displayActivationWizard && (
				<div className={style.container}>
					<div className={style.modal}>
						<div className={style.titleBar}>
							<div className={style.titleContainer}>
								<IconActivationWizard />
								<div className={style.titleText}>License Activation Wizard</div>
							</div>
							<div
								className={style.closeButton}
								onClick={() => {
									if (currentState === 'SelectContainer') {
										setDisplayActivationWizard(false);
									} else {
										modal.confirm({
											title:
												'Are you sure you want to close an active license activation? All progress will be lost!',
											centered: true,
											onOk: () => {
												setDisplayActivationWizard(false);
											},
										});
									}
								}}
							>
								<IconClose size={20} />
							</div>
						</div>
						<div className={style.contentBox}>
							{currentState === 'SelectContainer' && (
								<SelectContainerPage
									subscription={subscription}
									setDisplayActivationWizard={setDisplayActivationWizard}
									selectedContainer={selectedContainer}
									setSelectedContainer={setSelectedContainer}
									changeDisplayPage={changeState}
									preSelectedContainerId={preSelectedContainerId}
								/>
							)}
							{currentState === 'DefineActivation' &&
								!isGuest &&
								!!selectedContainer &&
								!!subscription &&
								!!licenses &&
								licenses.length > 0 &&
								!!requestType && (
									<DefineActivationPage
										container={selectedContainer!}
										changeDisplayPage={changeState}
										licenses={licenses}
										subscription={subscription}
										setDisplayActivationWizard={setDisplayActivationWizard}
										requestType={requestType}
										deselectAllLicenses={deselectAllLicenses}
										offlineActivationOption={offlineActivationOption}
										importedContextFile={importedContextFile}
										setLicenseRequestProgressDto={setManualLicenseProgressDto}
									/>
								)}
							{currentState === 'OfflineActivation' && (
								<OfflineActivationSelect
									container={selectedContainer}
									changeDisplayPage={changeState}
									setOfflineActivationOption={setOfflineActivationOption}
									offlineActivationOption={offlineActivationOption}
								/>
							)}
							{currentState === 'ExportContext' &&
								isDesktop &&
								selectedContainer !== undefined && (
									<ExportContextPage
										changeDisplayPage={changeState}
										selectedContainer={selectedContainer}
									/>
								)}
							{currentState === 'ImportContext' && (
								<ImportContextPage
									changeDisplayPage={changeState}
									setSelectedContainer={setSelectedContainer}
									selectedContainer={selectedContainer}
									contextFile={importedContextFile}
									setContextFile={setImportedContextFile}
								/>
							)}
							{currentState === 'ExportUpdate' && <ExportUpdatePage />}
							{currentState === 'ImportUpdate' && !!selectedContainer && (
								<ImportUpdatePage
									changeDisplayPage={changeState}
									selectedContainer={selectedContainer}
								/>
							)}
							{currentState === 'ExportReceipt' &&
								!!selectedContainer &&
								isDesktop && (
									<ExportReceiptPage
										selectedContainer={selectedContainer}
										changeDisplayPage={changeState}
										setDisplayActivationWizard={setDisplayActivationWizard}
									/>
								)}
							{currentState === 'SendReceipt' &&
								!!selectedContainer &&
								!!subscription &&
								!!manualLicenseProgressDto && (
									<SendReceiptPage
										progressDto={manualLicenseProgressDto}
										subscription={subscription}
										setDisplayActivationWizard={setDisplayActivationWizard}
									/>
								)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ActivationWizard;
