import { FC } from 'react';
import { DisableableIconProps } from './disableable';
import { SvgIcon } from '../SvgIcon';

export const CheckboxDefaultIcon: FC<DisableableIconProps> = (props) => {
	const actualSize = 24;
	const borderColor = props.disabled
		? 'var(--text-disabled)'
		: 'var(--text-normal)';
	return (
		<SvgIcon size={props.size ?? actualSize} actualSize={actualSize}>
			<rect
				x="1"
				y="1"
				width="22"
				height="22"
				rx="1"
				stroke={borderColor}
				strokeWidth="2"
			/>
		</SvgIcon>
	);
};

export const CheckboxCheckedIcon: FC<DisableableIconProps> = (props) => {
	const actualSize = 24;
	const fillColor = props.disabled
		? 'var(--button-secondary)'
		: 'var(--button-bg)';
	return (
		<SvgIcon size={props.size ?? actualSize} actualSize={actualSize}>
			<rect width="24" height="24" rx="2" fill={fillColor} />
			<path
				d="M17.9902 9.00008L16.5802 7.58008L9.99023 14.1701L7.41023 11.6001L5.99023 13.0101L9.99023 17.0001L17.9902 9.00008Z"
				fill="var(--text-subtitle)"
			/>
		</SvgIcon>
	);
};
