import { FeatureFlagDto, UpdateFeatureFlagDto } from 'api';
import { McButton } from 'components/mc';
import React from 'react';
import style from 'assets/styles/editAddElementForm.module.scss';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from 'components/fields';
import { featureFlagSchema } from 'validations/FormValidation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import featureFlagService from 'services/FeatureFlagService';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { App } from 'antd';

interface Props {
	featureFlag: FeatureFlagDto | 'new';
	hideForm: () => void;
}

interface FormValues {
	key: string;
	description: string;
}

const EditFeatureFlagForm = ({ hideForm, featureFlag }: Props) => {
	const isNew = featureFlag === 'new';
	const queryClient = useQueryClient();
	const { notification } = App.useApp();
	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		hideForm();
	};

	const {
		formState: { errors },
		control,
		handleSubmit,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(featureFlagSchema),
		defaultValues: {
			key: isNew ? undefined : featureFlag.key,
			description: isNew ? undefined : featureFlag.description,
		},
	});

	const { mutate: updateFeatureFlag } = useMutation({
		mutationFn: ({
			id,
			updateDto,
		}: {
			id: number;
			updateDto: UpdateFeatureFlagDto;
		}) =>
			featureFlagService
				.updateFeatureFlag(updateDto, id)
				.then((res) => res.data),
		onSuccess: (_, data) => {
			notification.success(saveSuccessNotification(data.updateDto.key));
			queryClient.invalidateQueries({ queryKey: ['feature-flags'] });
			hideForm();
		},
		onError: (error: unknown) => {
			notification.error(
				mcErrorNotification('Error', error, 'update', 'feature flag')
			);
		},
	});

	const updateModuleHandler: SubmitHandler<FormValues> = (data) => {
		if (isNew) return;

		if (
			data.key === featureFlag.key &&
			data.description === featureFlag.description
		) {
			hideForm();
			return;
		}

		const featureFlagDto: UpdateFeatureFlagDto = {
			key: data.key.trim(),
			description: data.description.trim(),
		};

		updateFeatureFlag({ id: featureFlag.id, updateDto: featureFlagDto });
	};

	const { mutate: addFeatureFlag } = useMutation({
		mutationFn: (data: FormValues) =>
			featureFlagService
				.addFeatureFlag({
					key: data.key.trim(),
					description: !!data.description ? data.description.trim() : undefined,
				})
				.then((res) => res.data),
		onSuccess: (_, data) => {
			notification.success(saveSuccessNotification(data.key));
			queryClient.invalidateQueries({ queryKey: ['feature-flags'] });
			hideForm();
		},
		onError: (error: unknown) => {
			notification.error(
				mcErrorNotification('Error', error, 'create', 'feature flag')
			);
		},
	});

	return (
		<div className={isNew ? style.addFormWrapper : style.editFormWrapper}>
			<form
				onSubmit={handleSubmit((data) => {
					isNew ? addFeatureFlag(data) : updateModuleHandler(data);
				})}
			>
				<div className={style.editForm} style={{ flexDirection: 'column' }}>
					<div className={style.row}>
						<div className={style.column} style={{ flex: 3 }}>
							<Controller
								name="key"
								control={control}
								render={({ field }) => (
									<InputField
										placeholder={'key'}
										{...field}
										label={'Feature Flag Key'}
										error={!!errors.key}
										errorMessage={errors.key?.message}
									/>
								)}
							/>
						</div>
						<div className={style.column} style={{ flex: 7 }}>
							<Controller
								name="description"
								control={control}
								render={({ field }) => (
									<InputField
										{...field}
										placeholder={'Description'}
										label={'Description'}
										error={!!errors.description}
										errorMessage={errors.description?.message}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<McButton.Row
					style={{ justifyContent: 'flex-end', marginBottom: '1.5rem' }}
				>
					<McButton onClick={cancel}>Cancel</McButton>
					<McButton primary type="submit">
						{isNew ? 'Create' : 'Update'}
					</McButton>
				</McButton.Row>
			</form>
		</div>
	);
};

export default EditFeatureFlagForm;
