export const getEditRowIndex = (
	columns: number,
	editIndex: number | null,
	itemCount: number | undefined
): number | undefined => {
	if (editIndex === null || typeof itemCount === 'undefined') return undefined;

	const editRow = Math.floor(editIndex / columns);
	const lastRowCols = itemCount % columns;
	const lastColIndex =
		(editIndex < itemCount - lastRowCols ? columns : lastRowCols) - 1;

	const rowIndex = editRow * columns + lastColIndex;

	return rowIndex;
};
