import { Select, Spin } from 'antd';
import { Dispatch, ReactNode, useMemo } from 'react';
import style from 'assets/styles/selectField.module.scss';
import {
	FetchNextPageOptions,
	InfiniteData,
	InfiniteQueryObserverResult,
} from '@tanstack/react-query';

export interface Option {
	label: ReactNode;
	value: number | undefined | null;
	searchValue: string;
}

interface Props {
	placeholder?: string;
	label?: string;
	entityName?: string;
	setSearchText?: Dispatch<string>;
	loading?: boolean;
	clearOnSelect?: boolean;
	tabIndex?: number;
	includeEmptyOption?: boolean;
	selectedId?: number | undefined;
	setSelectedId?: Dispatch<number | undefined>;
	onChange?: (newValue: number | undefined) => void;
	options: Option[];
	fetchMoreEntities?: (
		options?: FetchNextPageOptions | undefined
	) => Promise<InfiniteQueryObserverResult<InfiniteData<any, unknown>, Error>>;
}

const SelectEntity = (props: Props) => {
	const onScroll = (e: any) => {
		e.persist();
		const target = e.target;
		if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
			props.setSearchText?.('');
			props.fetchMoreEntities?.();
		}
	};

	const notFound = (
		<div
			style={{
				color: 'var(--add-red)',
				fontWeight: '600',
				justifyContent: 'center',
				textAlign: 'center',
				margin: '1rem auto',
			}}
		>
			No such {props.entityName ?? 'entity'}
		</div>
	);

	const options = useMemo(
		() =>
			!!props.includeEmptyOption
				? [
						{
							label: (
								<div className={style.dropdownItem}>
									<div className={style.labelIconName}>
										<div
											style={{ color: 'var(--add-orange)', fontWeight: 'bold' }}
										>
											No {props.entityName ?? 'entity'}
										</div>
									</div>
								</div>
							),
							value: null,
							searchValue: '',
						},
						...props.options,
				  ]
				: [...props.options],
		[props.entityName, props.includeEmptyOption, props.options]
	);

	const handleOnChange = (newValue: number | undefined) => {
		props.onChange?.(newValue);
		if (!props.clearOnSelect) props.setSelectedId?.(newValue);
	};

	return (
		<div className={style.inputFieldWrapper} style={{ marginBottom: '1.5rem' }}>
			<span className={style.inputFieldLabel}>{props.label ?? 'Entity'}</span>
			<Select
				className={style.multiSelectField}
				placeholder={props.placeholder ?? 'Select entity'}
				showSearch
				value={
					props.clearOnSelect
						? null
						: props.selectedId !== null
						? props.selectedId
						: undefined
				}
				onChange={handleOnChange}
				variant="borderless"
				optionFilterProp="children"
				options={options}
				tabIndex={props.tabIndex}
				filterOption={(input: any, option: any) =>
					(option?.searchValue ?? '')
						.toLowerCase()
						.includes(input.toLowerCase().replace(/ /g, ''))
				}
				getPopupContainer={(trigger: any) => trigger.parentNode}
				onPopupScroll={onScroll}
				onSearch={(input) => {
					props.setSearchText?.(input);
				}}
				dropdownRender={(menu) => (
					<>
						<div
							style={{
								display: props.loading ? 'flex' : 'none',
								justifyContent: 'center',
								margin: '1rem auto',
							}}
						>
							<Spin spinning={props.loading} />
						</div>
						{menu}
					</>
				)}
				notFoundContent={notFound}
				loading={props.loading}
			/>
		</div>
	);
};

export default SelectEntity;
