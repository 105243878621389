import { LicensingFileType } from './types';
import { dialog } from '@tauri-apps/api';

export const fileTypes: Record<LicensingFileType, dialog.DialogFilter> = {
	REQUEST: { name: 'License Request Files', extensions: ['WibuCmRaC'] },
	RESPONSE: { name: 'License Response Files', extensions: ['WibuCmRaU'] },
	RECEIPT: { name: 'License Receipt Files', extensions: ['WibuCmRaR'] },
};

export const defaultFileName = (
	fileType: LicensingFileType,
	containerName: string
) => `${containerName}.${fileTypes[fileType]?.extensions?.[0] ?? 'txt'}`;
