const DIVISIONS = [
	{ amount: 60, name: 'seconds' },
	{ amount: 60, name: 'minutes' },
	{ amount: 24, name: 'hours' },
	{ amount: 7, name: 'days' },
	{ amount: 4.34524, name: 'weeks' },
	{ amount: 12, name: 'months' },
	{ amount: Number.POSITIVE_INFINITY, name: 'years' },
] satisfies { amount: number; name: Intl.RelativeTimeFormatUnit }[];

export function timeAgo(date: Date): string;
export function timeAgo(date_str: string): string;
export function timeAgo(date_or_str: string | Date): string {
	const formatter = new Intl.RelativeTimeFormat('en', { style: 'long' });
	const date =
		typeof date_or_str === 'object' ? date_or_str : new Date(date_or_str);

	let duration = (date.getTime() - new Date().getTime()) / 1000;
	for (let i = 0; i < DIVISIONS.length; i++) {
		const division = DIVISIONS[i];
		if (Math.abs(duration) < division.amount) {
			return formatter.format(Math.round(duration), division.name);
		}
		duration /= division.amount;
	}

	return 'Lightyears';
}

export function getTimeRemainingSuffix(
	targetDate: Date,
	includeTarget?: boolean
): string {
	const now = new Date();
	if (includeTarget) {
		targetDate.setUTCHours(23);
		targetDate.setUTCMinutes(59);
		targetDate.setUTCSeconds(59);
	}
	const timeDifference = targetDate.getTime() - now.getTime();

	const seconds = Math.floor(timeDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	if (seconds <= 0) {
		return `(Expired)`;
	}

	if (hours <= 0) {
		return `(Remaining: < 1 hour)`;
	}

	if (days <= 0) {
		return `(Remaining: ${hours} hours)`;
	}

	return `(Remaining: ${days} days)`;
}
