import React from 'react';
import {
	AddressIcon,
	EnvalopeIcon,
	IconClose,
	ImageSystemsLogo,
	TelIcon,
	WebsiteIcon,
} from 'assets/icons/svg';

interface ContactPopoverContentProps {
	setVisible: (value: boolean) => void;
}

export const ContactPopoverContent = ({
	setVisible,
}: ContactPopoverContentProps) => {
	const handleClose = () => {
		setVisible(false);
	};

	return (
		<div className="contactPopover popoverSettings-content">
			<div className="closePopover" onClick={handleClose}>
				<IconClose />
			</div>
			<ul>
				<li>
					<EnvalopeIcon />
					<a href="mailto:support@imagesystems.se">support@imagesystems.se</a>
				</li>
				<li>
					<TelIcon />
					<span>+46 13 200 100</span>
				</li>
				<li>
					<AddressIcon />
					<span>
						Image Systems AB <br></br>Snickaregatan 40<br></br>S - 582 26
						Linköping - Sweden
					</span>
				</li>
				<li>
					<WebsiteIcon />
					<a
						href="http://www.imagesystems.se"
						target="_blank"
						rel="noopener noreferrer"
					>
						www.imagesystems.se
					</a>
				</li>
			</ul>
			<div
				className="flex center"
				style={{
					borderTop: '1px solid var(--border-popup-inside)',
					paddingTop: '1.5rem',
					marginTop: '1.5rem',
				}}
			>
				<ImageSystemsLogo />
			</div>
		</div>
	);
};
