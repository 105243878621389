import style from 'assets/styles/manageStatistics.module.scss';

import React, { FC } from 'react';

export const ManageStatistics: FC = () => {
	return (
		<div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
			<h1 className={style.header}>Statistics</h1>
			<div style={{ display: 'flex', height: '100%' }}>
				<iframe
					style={{ border: 0, flex: 1 }}
					title="MCStats"
					src="https://mcstats-dev.azurewebsites.net/public-dashboards/e60f2059e4294378afb59e353f36ac1c?orgId=1"
				></iframe>
			</div>
		</div>
	);
};

export default ManageStatistics;
