import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import usersReducer from './slices/users';
import oneUserReducer from './slices/oneUser';
import appReleasesReducer from './slices/releases';
import selectedAppsReducer from './slices/selectedApplications';
import publishedAppsReducer from './slices/publishedApplications';
import oneEngineReducer from './slices/oneEngine';
import enginesReducer from './slices/engines';
import newsReducer from './slices/news';
import oneNewsReducer from './slices/oneNews';
import templatesReducer from './slices/templates';
import publishedTemplatesReducer from './slices/publishedTemplates';
import oneTemplateReducer from './slices/oneTemplate';
import onePublishedNewsReducer from './slices/onePublishedNews';
import allAppReleasesShortInfoReducer from './slices/allAppReleasesShortInfo';
import modulesReducer from './slices/modules';
import oneModuleReducer from './slices/oneModule';
import applicationsReducer from './slices/applications';
import oneAppReducer from './slices/oneApplication';
import oneSupportReducer from './slices/oneSupport';
import supportsReducer from './slices/supports';
import tagsReducer from './slices/tags';
import { subscriptionsReducer } from './slices/subscriptions';
import { licensingReducer } from './slices/licensing';
import { notificationsReducer } from './slices/notifications';
import organizationsReducer from './slices/organizations';
import tourReducer from './slices/tour';
import codemeterReducer from './slices/Codemeter';

const reducers = combineReducers({
	auth: authReducer,
	users: usersReducer,
	oneUser: oneUserReducer,
	appReleases: appReleasesReducer,
	selectedApplications: selectedAppsReducer,
	publishedApplications: publishedAppsReducer,
	engines: enginesReducer,
	oneEngine: oneEngineReducer,
	news: newsReducer,
	oneNews: oneNewsReducer,
	onePublishedNews: onePublishedNewsReducer,
	templates: templatesReducer,
	publishedTemplates: publishedTemplatesReducer,
	oneTemplate: oneTemplateReducer,
	allAppReleasesShortInfo: allAppReleasesShortInfoReducer,
	modules: modulesReducer,
	oneModule: oneModuleReducer,
	applications: applicationsReducer,
	oneApp: oneAppReducer,
	supports: supportsReducer,
	oneSupport: oneSupportReducer,
	tags: tagsReducer,
	subscriptions: subscriptionsReducer,
	licensing: licensingReducer,
	notifications: notificationsReducer,
	organizations: organizationsReducer,
	tour: tourReducer,
	codemeter: codemeterReducer,
});

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
