import { Modal } from 'antd';
import { AlertCircle } from 'assets/icons/svg';
import { FC, useEffect, useState } from 'react';
import 'assets/styles/OfflineModal.scss';
import { useAppDispatch } from 'hooks/hooks';
import { loginAsGuest } from 'store/slices/auth';
interface OfflineModalProps {
	show: boolean;
}

export const OfflineModal: FC<OfflineModalProps> = ({ show }) => {
	const dispatch = useAppDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isRetry, setIsRetry] = useState(false);

	useEffect(() => {
		show ? setIsModalOpen(() => true) : setIsModalOpen(() => false);
	}, [show]);

	const handleOk = () => {
		dispatch(loginAsGuest());
	};

	const handleRetry = async () => {
		setIsRetry(true);
		setTimeout(() => {
			setIsRetry(false);
		}, 1000);
	};
	return (
		<Modal
			wrapClassName="offline-modal"
			width={494}
			title={
				<>
					<p>Unable to reach Motion Cloud servers.</p>
					<AlertCircle />
				</>
			}
			open={isModalOpen}
			onOk={handleRetry}
			onCancel={handleOk}
			confirmLoading={isRetry}
			maskClosable={false}
			keyboard={false}
			centered
			closable={false}
			okType={'default'}
			okButtonProps={{ tabIndex: 0, disabled: isRetry }}
			cancelButtonProps={{ type: 'primary', tabIndex: 0 }}
			okText={'Retry'}
			cancelText={'Proceed without login'}
		>
			<p>
				Please check your internet settings and try again, or proceed to Motion
				Cloud with desktop features only.
			</p>
		</Modal>
	);
};
