import {
	SupportItemsApiAxiosParamCreator,
	SupportItemsApiFp,
} from 'api/client/support-items-api';
import { apiAxios, apiConfig } from './ApiService';
import { BASE_PATH } from 'api/base';

export const supportService = SupportItemsApiFp(apiAxios, apiConfig);
export default supportService;

type ParamCreator = ReturnType<typeof SupportItemsApiAxiosParamCreator>;

export const getSupportURL = async <K extends keyof ParamCreator>(
	key: K,
	params: Parameters<ParamCreator[K]>
) => {
	return SupportItemsApiAxiosParamCreator()
		[key](
			/* @ts-ignore */
			...params
		)
		.then((ra) => BASE_PATH + ra.url);
};
