import React, { forwardRef } from 'react';
import style from 'assets/styles/components.module.scss';

interface CommonInputProps {
	icon?: JSX.Element;
	placeholder?: string;
	label?: string;
	tabIndex?: number;
	error?: boolean;
	readOnly?: boolean;
	required?: boolean;
	errorMessage?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	disabled?: boolean;
}
interface TextInputProps {
	value: string;
	defaultValue?: string;
	type?: 'text' | undefined;
}
interface NumberInputProps {
	value: number | '';
	defaultValue?: number;
	type: 'number';
}
interface FileInputProps {
	value: string;
	defaultValue?: undefined;
	type: 'file';
}
export type InputFieldRef = HTMLInputElement;

type InputProps = CommonInputProps &
	(TextInputProps | NumberInputProps | FileInputProps);

export const InputField: React.FC<InputProps> = forwardRef<
	InputFieldRef,
	InputProps
>((props, ref) => {
	return (
		<>
			<div className={style.inputFieldWrapper}>
				<span
					className={style.inputFieldLabel}
					style={props.disabled ? { color: 'var(--border-popup)' } : {}}
				>
					{props.label}
				</span>
				<div className={style.inputFieldIconWrapper}>
					<input
						tabIndex={props.tabIndex}
						autoFocus={props.tabIndex === 1}
						className={style.inputField}
						style={
							props.disabled
								? {
										borderColor: 'var(--border-popup)',
										color: 'var(--border-popup)',
								  }
								: { border: props.error ? '1.5px solid var(--add-red)' : '' }
						}
						type={props.type ?? 'text'}
						value={props.value}
						defaultValue={props.defaultValue}
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						onChange={props.onChange}
						onFocus={props.onFocus}
						onBlur={props.onBlur}
						required={props.required}
						ref={ref}
						disabled={props.disabled}
					/>
					{props.icon && (
						<div className={style.inputFieldIcon}>{props.icon}</div>
					)}
				</div>
			</div>
			<div className={style.errorMessage}>{props.errorMessage}</div>
		</>
	);
});
