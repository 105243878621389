import { Popover } from 'antd';
import { MoreVertical } from 'assets/icons/MoreVertical';
import { FC, useState } from 'react';
import { McIconButton } from './McIconButton';
import { TooltipPlacement } from 'antd/es/tooltip';

interface ForwardProps {
	placement?: TooltipPlacement;
}
interface Props extends ChildrenProps, ForwardProps {}
export const McMoreMenu: FC<Props> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const forwardProps: ForwardProps = { placement: props.placement };
	return props.children ? (
		<Popover
			{...forwardProps}
			afterOpenChange={(open) => setIsOpen(open)}
			onOpenChange={(open) => setIsOpen(open)}
			open={isOpen}
			trigger="click"
			arrow={false}
			rootClassName="mc-moreMenuWrapper"
			content={
				<menu className="mc-moreMenu" onClick={() => setIsOpen(false)}>
					{props.children}
				</menu>
			}
		>
			<div className={`mc-moreMenuTrigger ${isOpen ? 'mc-x-open' : ''}`}>
				<McIconButton icon={<MoreVertical />} />
			</div>
		</Popover>
	) : (
		<></>
	);
};
