import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ErrorDTO, SupportItemDto } from 'api';
import { AxiosResponse } from 'axios';
import { supportService } from 'services/SupportService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import {
	createAppAsyncThunk,
	setLoadingState,
	setUploadingState,
} from 'utils/rtk';

export interface SupportItemState {
	support: SupportItemDto | null;
	supportFile: Blob;
	supportFileName: string | null;
	loading: boolean;
	uploading: boolean | null;
	error: ErrorDTO | null;
}

const initialState: SupportItemState = {
	support: {} as SupportItemDto,
	supportFile: {} as Blob,
	supportFileName: null,
	loading: true,
	uploading: false,
	error: null,
};

export const deleteSupport = createAppAsyncThunk(
	'oneSupport/deleteSupport',
	({ id }: { id: number }, { rejectWithValue }) =>
		supportService
			.deleteSupportItemById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteSupportPicture = createAppAsyncThunk(
	'oneSupport/deleteSupportPicture',
	({ id }: { id: number }, { rejectWithValue }) =>
		supportService
			.deleteSupportItemBasicPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type CreateSupportParams = Parameters<
	typeof supportService.createSupportItemForm
>;
export const addSupport = createAppAsyncThunk(
	'oneSupport/addSupport',
	(params: CreateSupportParams, { rejectWithValue }) =>
		supportService
			.createSupportItemForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type UpdateSupportParams = Parameters<
	typeof supportService.updateSupportItemForm
>;
export const updateSupport = createAppAsyncThunk(
	'oneSupport/updateSupport',
	(params: UpdateSupportParams, { rejectWithValue }) =>
		supportService
			.updateSupportItemForm(...params)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getOneSupport = createAppAsyncThunk(
	'oneSupport/getOneSupport',
	({ id }: { id: number }, { rejectWithValue }) =>
		supportService
			.getSupportItemById(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

interface SupportParams {
	id: number;
}

export const downloadSupport = createAppAsyncThunk(
	'oneSupport/downloadSupport',
	async (p: SupportParams, { rejectWithValue }) =>
		supportService
			.getSupportItemFile(p.id, { responseType: 'blob' })
			.catch(getErrorMessageAnd(rejectWithValue)) as Promise<
			AxiosResponse<Blob>
		>
);

export const getSupportFileName = createAppAsyncThunk(
	'oneSupport/getSupportFileName',
	async (p: SupportParams, { rejectWithValue }) =>
		supportService
			.getSupportItemFileName(p.id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const oneSupportSlice = createSlice({
	name: 'oneSupport',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addSupport.pending, setUploadingState)
			.addCase(addSupport.fulfilled, (state) => {
				state.uploading = false;
				state.error = null;
			})

			.addCase(updateSupport.pending, setUploadingState)
			.addCase(updateSupport.fulfilled, (state) => {
				state.uploading = false;
				state.error = null;
			})

			.addCase(deleteSupport.pending, setLoadingState)
			.addCase(deleteSupport.fulfilled, (state) => {
				state.loading = false;
				state.support = {} as SupportItemDto;
				state.error = null;
			})

			.addCase(deleteSupportPicture.pending, setLoadingState)
			.addCase(deleteSupportPicture.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(getOneSupport.pending, (state) => {
				state.support = null;
				setLoadingState(state);
			})
			.addCase(getOneSupport.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.support = payload.data;
			})

			.addCase(downloadSupport.pending, setLoadingState)
			.addCase(downloadSupport.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.supportFile = payload.data;
			})

			.addCase(getSupportFileName.pending, setLoadingState)
			.addCase(getSupportFileName.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.supportFileName = payload.data;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(
					addSupport.rejected,
					updateSupport.rejected,
					deleteSupport.rejected,
					deleteSupportPicture.rejected,
					getOneSupport.rejected,
					downloadSupport.rejected,
					getSupportFileName.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
					state.uploading = false;
				}
			);
	},
});

export const oneSupportState = (state: RootState) => state.oneSupport;

export default oneSupportSlice.reducer;
