import React, { useState, Dispatch, SetStateAction } from 'react';
import style from 'assets/styles/filterField.module.scss';
import { IconTickDown } from 'assets/icons/svg';
import useOutsideClick from 'hooks/useOutsideClick';

interface FilterProps<V> {
	label?: string;
	selValue: V;
	values: V[];
	labels: string[];
	font?: string;
	admin?: boolean;
	notifications?: boolean;
	setSelectedField: Dispatch<SetStateAction<V>>;
}

export const FilterField = <V,>(props: FilterProps<V>) => {
	const {
		label,
		labels,
		selValue,
		values,
		font,
		admin,
		notifications,
		setSelectedField,
	} = props;
	const [showDropMenu, setShowDropMenu] = useState(false);
	const [selected, setSelected] = useState(0);
	const ref = useOutsideClick(() => {
		setShowDropMenu(false);
	});

	return (
		<div
			className={style.wrapper}
			ref={ref}
			style={{ marginLeft: notifications ? '7.813rem' : '' }}
		>
			<div style={{ position: 'relative', width: '100%' }}>
				<div
					className={style.filterField}
					style={{
						background: admin ? 'var(--bg-dark)' : 'transparent',
						padding: admin ? '0.7rem 1rem' : '0 0 0 1rem',
						height: admin ? '2.875rem' : '',
						justifyContent: notifications ? 'flex-end' : '',
					}}
					onClick={(e) => {
						e.preventDefault();
						setShowDropMenu((prev) => !prev);
					}}
				>
					<span style={{ color: 'var(--text-normal)', fontSize: font }}>
						{label}
					</span>
					<div
						style={{
							color: 'var(--text-primary)',
							fontSize: font,
							whiteSpace: 'nowrap',
						}}
					>
						{labels[values.indexOf(selValue)]}
					</div>
					<div className={showDropMenu ? 'rotate180' : 'rotate0'}>
						<IconTickDown />
					</div>
				</div>
				{showDropMenu && (
					<div
						className={style.filterFieldDropMenu}
						style={{ marginTop: admin ? '0.7rem' : '0.4rem' }}
					>
						{values.map((value, idx) => {
							return (
								<div
									className={
										selected === idx
											? style.selected
											: style.filterFieldDropItem
									}
									key={idx}
									onClick={() => {
										setSelectedField(value);
										setShowDropMenu(false);
										setSelected(idx);
									}}
									style={{
										fontSize: font,
										padding: notifications
											? '0.375rem 0.75rem 0.375rem 1rem'
											: '',
									}}
								>
									{labels[idx]}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
