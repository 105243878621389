/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApplicationDto } from '../model';
import { ApplicationReleaseDto } from '../model';
import { DownloadTicketDTO } from '../model';
import { EditEngineDto } from '../model';
import { EngineAddedDto } from '../model';
import { EngineDto } from '../model';
import { EntityStatus } from '../model';
import { ErrorDTO } from '../model';
import { PageDtoEngineDto } from '../model';
/**
 * EnginesApi - axios parameter creator
 * @export
 */
export const EnginesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create engine
         * @param {EngineAddedDto} [engineDto] 
         * @param {Blob} [image] 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEngineForm: async (engineDto?: EngineAddedDto, image?: Blob, enginesFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/engines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (engineDto !== undefined) {
                const engineDtoValue = typeof engineDto === 'object' && !(engineDto instanceof Blob) ? JSON.stringify(engineDto) : engineDto;
                localVarFormParams.append('engineDto', engineDtoValue as any);
            }

            if (image !== undefined) {
                const imageValue = typeof image === 'object' && !(image instanceof Blob) ? JSON.stringify(image) : image;
                localVarFormParams.append('image', imageValue as any);
            }

            if (enginesFile !== undefined) {
                const enginesFileValue = typeof enginesFile === 'object' && !(enginesFile instanceof Blob) ? JSON.stringify(enginesFile) : enginesFile;
                localVarFormParams.append('enginesFile', enginesFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEngineById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEngineById.');
            }
            const localVarPath = `/api/engines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete engines basic picture by engine id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnginesBasicPicture: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEnginesBasicPicture.');
            }
            const localVarPath = `/api/engines/{id}/picture`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnginesFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEnginesFile.');
            }
            const localVarPath = `/api/engines/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get application releases for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationReleasesByEngineId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplicationReleasesByEngineId.');
            }
            const localVarPath = `/api/engines/{id}/releases`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get applications for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationsByEngineId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getApplicationsByEngineId.');
            }
            const localVarPath = `/api/engines/{id}/applications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngineById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEngineById.');
            }
            const localVarPath = `/api/engines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngineFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEngineFile.');
            }
            const localVarPath = `/api/engines/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of engines with pagination and filtering by year and status
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {number} [year] 
         * @param {EntityStatus} [entityStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEngines: async (page: number, size: number, searchedValue?: string, year?: number, entityStatus?: EntityStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            if (page === null || page === undefined) {
                throw new RequiredError('page','Required parameter page was null or undefined when calling getEngines.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getEngines.');
            }
            const localVarPath = `/api/engines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (searchedValue !== undefined) {
                localVarQueryParameter['searchedValue'] = searchedValue;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (entityStatus !== undefined) {
                localVarQueryParameter['entityStatus'] = entityStatus;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get engines file name by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnginesFileName: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEnginesFileName.');
            }
            const localVarPath = `/api/engines/{id}/file/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get featured engines item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedEngineItem: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/engines/featured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of published engines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedEngines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/engines/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update engine by id
         * @param {number} id 
         * @param {EditEngineDto} [engineDto] 
         * @param {Blob} [basicPic] 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEngineForm: async (id: number, engineDto?: EditEngineDto, basicPic?: Blob, enginesFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEngineForm.');
            }
            const localVarPath = `/api/engines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (engineDto !== undefined) {
                const engineDtoValue = typeof engineDto === 'object' && !(engineDto instanceof Blob) ? JSON.stringify(engineDto) : engineDto;
                localVarFormParams.append('engineDto', engineDtoValue as any);
            }

            if (basicPic !== undefined) {
                const basicPicValue = typeof basicPic === 'object' && !(basicPic instanceof Blob) ? JSON.stringify(basicPic) : basicPic;
                localVarFormParams.append('basicPic', basicPicValue as any);
            }

            if (enginesFile !== undefined) {
                const enginesFileValue = typeof enginesFile === 'object' && !(enginesFile instanceof Blob) ? JSON.stringify(enginesFile) : enginesFile;
                localVarFormParams.append('enginesFile', enginesFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload engines file by id
         * @param {number} id 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEnginesFileForm: async (id: number, enginesFile?: Blob, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadEnginesFileForm.');
            }
            const localVarPath = `/api/engines/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("Auth0", ["openid", "profile", "email", "read:users", "write:users", "write:news", "write:support-items", "write:applications", "write:licenses", "write:templates", "write:organizations"])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


            if (enginesFile !== undefined) {
                const enginesFileValue = typeof enginesFile === 'object' && !(enginesFile instanceof Blob) ? JSON.stringify(enginesFile) : enginesFile;
                localVarFormParams.append('enginesFile', enginesFileValue as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnginesApi - functional programming interface
 * @export
 */
export const EnginesApiFp = function(axiosInstance: Promise<AxiosInstance>, configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create engine
         * @param {EngineAddedDto} [engineDto] 
         * @param {Blob} [image] 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEngineForm(engineDto?: EngineAddedDto, image?: Blob, enginesFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).createEngineForm(engineDto, image, enginesFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEngineById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).deleteEngineById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete engines basic picture by engine id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnginesBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).deleteEnginesBasicPicture(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Delete engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnginesFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).deleteEnginesFile(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get application releases for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getApplicationReleasesByEngineId(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get applications for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationsByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationDto>>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getApplicationsByEngineId(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getEngineById(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadTicketDTO>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getEngineFile(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of engines with pagination and filtering by year and status
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {number} [year] 
         * @param {EntityStatus} [entityStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngines(page: number, size: number, searchedValue?: string, year?: number, entityStatus?: EntityStatus, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoEngineDto>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getEngines(page, size, searchedValue, year, entityStatus, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get engines file name by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnginesFileName(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getEnginesFileName(id, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get featured engines item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturedEngineItem(options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getFeaturedEngineItem(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Get list of published engines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedEngines(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EngineDto>>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).getPublishedEngines(options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Update engine by id
         * @param {number} id 
         * @param {EditEngineDto} [engineDto] 
         * @param {Blob} [basicPic] 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEngineForm(id: number, engineDto?: EditEngineDto, basicPic?: Blob, enginesFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).updateEngineForm(id, engineDto, basicPic, enginesFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
        /**
         * 
         * @summary Upload engines file by id
         * @param {number} id 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadEnginesFileForm(id: number, enginesFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            const localVarAxiosArgs = await EnginesApiAxiosParamCreator(configuration).uploadEnginesFileForm(id, enginesFile, options);
            const axios = await axiosInstance;
            const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: BASE_PATH + localVarAxiosArgs.url};
            return axios.request(axiosRequestArgs);
        },
    }
};

/**
 * EnginesApi - factory interface
 * @export
 */
export const EnginesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create engine
         * @param {EngineAddedDto} [engineDto] 
         * @param {Blob} [image] 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEngineForm(engineDto?: EngineAddedDto, image?: Blob, enginesFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EnginesApiFp(configuration).createEngineForm(engineDto, image, enginesFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEngineById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EnginesApiFp(configuration).deleteEngineById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete engines basic picture by engine id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnginesBasicPicture(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EnginesApiFp(configuration).deleteEnginesBasicPicture(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEnginesFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EnginesApiFp(configuration).deleteEnginesFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get application releases for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationReleasesByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
            return EnginesApiFp(configuration).getApplicationReleasesByEngineId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get applications for a specific engine
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationsByEngineId(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ApplicationDto>>> {
            return EnginesApiFp(configuration).getApplicationsByEngineId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get engine by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineById(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            return EnginesApiFp(configuration).getEngineById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get engines file by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngineFile(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<DownloadTicketDTO>> {
            return EnginesApiFp(configuration).getEngineFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of engines with pagination and filtering by year and status
         * @param {number} page 
         * @param {number} size 
         * @param {string} [searchedValue] 
         * @param {number} [year] 
         * @param {EntityStatus} [entityStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEngines(page: number, size: number, searchedValue?: string, year?: number, entityStatus?: EntityStatus, options?: AxiosRequestConfig): Promise<AxiosResponse<PageDtoEngineDto>> {
            return EnginesApiFp(configuration).getEngines(page, size, searchedValue, year, entityStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get engines file name by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnginesFileName(id: number, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return EnginesApiFp(configuration).getEnginesFileName(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get featured engines item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturedEngineItem(options?: AxiosRequestConfig): Promise<AxiosResponse<EngineDto>> {
            return EnginesApiFp(configuration).getFeaturedEngineItem(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of published engines
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedEngines(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EngineDto>>> {
            return EnginesApiFp(configuration).getPublishedEngines(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update engine by id
         * @param {number} id 
         * @param {EditEngineDto} [engineDto] 
         * @param {Blob} [basicPic] 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEngineForm(id: number, engineDto?: EditEngineDto, basicPic?: Blob, enginesFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EnginesApiFp(configuration).updateEngineForm(id, engineDto, basicPic, enginesFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload engines file by id
         * @param {number} id 
         * @param {Blob} [enginesFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadEnginesFileForm(id: number, enginesFile?: Blob, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EnginesApiFp(configuration).uploadEnginesFileForm(id, enginesFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnginesApi - object-oriented interface
 * @export
 * @class EnginesApi
 * @extends {BaseAPI}
 */
export class EnginesApi extends BaseAPI {
    /**
     * 
     * @summary Create engine
     * @param {EngineAddedDto} [engineDto] 
     * @param {Blob} [image] 
     * @param {Blob} [enginesFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async createEngineForm(engineDto?: EngineAddedDto, image?: Blob, enginesFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EnginesApiFp(this.configuration).createEngineForm(engineDto, image, enginesFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete engine by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async deleteEngineById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EnginesApiFp(this.configuration).deleteEngineById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete engines basic picture by engine id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async deleteEnginesBasicPicture(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EnginesApiFp(this.configuration).deleteEnginesBasicPicture(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete engines file by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async deleteEnginesFile(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EnginesApiFp(this.configuration).deleteEnginesFile(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get application releases for a specific engine
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getApplicationReleasesByEngineId(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationReleaseDto>>> {
        return EnginesApiFp(this.configuration).getApplicationReleasesByEngineId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get applications for a specific engine
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getApplicationsByEngineId(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ApplicationDto>>> {
        return EnginesApiFp(this.configuration).getApplicationsByEngineId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get engine by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getEngineById(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<EngineDto>> {
        return EnginesApiFp(this.configuration).getEngineById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get engines file by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getEngineFile(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<DownloadTicketDTO>> {
        return EnginesApiFp(this.configuration).getEngineFile(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of engines with pagination and filtering by year and status
     * @param {number} page 
     * @param {number} size 
     * @param {string} [searchedValue] 
     * @param {number} [year] 
     * @param {EntityStatus} [entityStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getEngines(page: number, size: number, searchedValue?: string, year?: number, entityStatus?: EntityStatus, options?: AxiosRequestConfig) : Promise<AxiosResponse<PageDtoEngineDto>> {
        return EnginesApiFp(this.configuration).getEngines(page, size, searchedValue, year, entityStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get engines file name by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getEnginesFileName(id: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return EnginesApiFp(this.configuration).getEnginesFileName(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get featured engines item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getFeaturedEngineItem(options?: AxiosRequestConfig) : Promise<AxiosResponse<EngineDto>> {
        return EnginesApiFp(this.configuration).getFeaturedEngineItem(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get list of published engines
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async getPublishedEngines(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<EngineDto>>> {
        return EnginesApiFp(this.configuration).getPublishedEngines(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update engine by id
     * @param {number} id 
     * @param {EditEngineDto} [engineDto] 
     * @param {Blob} [basicPic] 
     * @param {Blob} [enginesFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async updateEngineForm(id: number, engineDto?: EditEngineDto, basicPic?: Blob, enginesFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EnginesApiFp(this.configuration).updateEngineForm(id, engineDto, basicPic, enginesFile, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Upload engines file by id
     * @param {number} id 
     * @param {Blob} [enginesFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnginesApi
     */
    public async uploadEnginesFileForm(id: number, enginesFile?: Blob, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EnginesApiFp(this.configuration).uploadEnginesFileForm(id, enginesFile, options).then((request) => request(this.axios, this.basePath));
    }
}
