import { App, Spin } from 'antd';
import { TrashIcon2, UploadIcon } from 'assets/icons/svg';
import style from 'assets/styles/editAddElementForm.module.scss';
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Cond } from 'utils/Cond';
import { infoNotification } from 'utils/Notifications';

interface FileProps {
	text: string;
	fileUploadName: string | undefined;
	setFileUploadName: Dispatch<SetStateAction<string | undefined>>;
	setFileUpload: Dispatch<SetStateAction<Blob | undefined>>;
	showLoader: boolean | null;
}

export const UploadFileField: FC<FileProps> = ({
	text,
	fileUploadName,
	setFileUploadName,
	setFileUpload,
	showLoader,
}) => {
	const { notification } = App.useApp();
	const upLoadHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files![0];

		if (file.size / 1024 / 1024 / 1024 > 1) {
			notification.info(infoNotification('File must be smaller than 1GB!'));
		} else {
			setFileUploadName(file.name);
			setFileUpload(file);
		}
		(event.target as HTMLInputElement).value = '';
	};

	return (
		<div className={style.uploadContainer}>
			<label style={{ cursor: 'pointer' }}>
				<input
					type="file"
					onChange={(event) => {
						upLoadHandler(event);
					}}
				/>
				<Cond except={fileUploadName}>
					<span className={style.uploadText}>
						{text}
						<UploadIcon />
					</span>
				</Cond>
			</label>
			<Cond if={fileUploadName}>
				<span className={style.uploadText}>
					{fileUploadName}
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => setFileUploadName(undefined)}
					>
						<TrashIcon2 />
					</span>
				</span>
			</Cond>
			{showLoader && <Spin />}
		</div>
	);
};
