import { FC, useEffect, useState } from 'react';
import { AppsCard } from 'components/cards';
import { FeaturedCard } from 'components/cards';
import { FilterField } from 'components/fields';
import { App, Spin } from 'antd';
import style from 'assets/styles/storePage.module.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import newsService from 'services/NewsService';
import appReleasesService from 'services/AppReleasesService';
import { mcErrorNotification } from 'utils/Notifications';
import applicationsService from 'services/ApplicationsService';

const SortingModes = {
	BY_CREATED_AT_DESC: 'Newest first',
	BY_CREATED_AT_ASC: 'Oldest first',
	BY_ALPHABET_ASC: 'Alphabetical order',
	BY_ALPHABET_DESC: 'Reverse alphabetical order',
};
const sortingKeys = Object.keys(SortingModes) as (keyof typeof SortingModes)[];
const sortingLabels = Object.values(SortingModes);

export const StorePage: FC = () => {
	const [sortOrder, setSortOrder] =
		useState<keyof typeof SortingModes>('BY_CREATED_AT_DESC');

	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const {
		data: releasesForStore,
		isLoading: isLoadingReleases,
		error: getReleasesError,
	} = useQuery({
		queryKey: ['applications', 'releases', 'store'],
		queryFn: () =>
			appReleasesService
				.getApplicationReleasesForStore()
				.then((res) => res.data),
		initialData: [],
	});

	const {
		data: latestNewsItem,
		isLoading: isLoadingNewsItem,
		error: getNewsItemError,
	} = useQuery({
		queryKey: ['news', 'store'],
		queryFn: () =>
			newsService.getNewestPublishedNewItem().then((res) => res.data),
	});

	useEffect(() => {
		if (!getNewsItemError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getNewsItemError,
				'fetch',
				'store news item'
			)
		);
	}, [getNewsItemError, notification]);

	useEffect(() => {
		if (!getReleasesError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getReleasesError,
				'fetch',
				'featured application releases'
			)
		);
	}, [getReleasesError, notification]);

	return (
		<>
			<Spin spinning={isLoadingNewsItem} size="large">
				{!!latestNewsItem && (
					<div
						onMouseEnter={() =>
							queryClient.prefetchQuery({
								queryKey: ['news', latestNewsItem.id],
								queryFn: () =>
									!!latestNewsItem.id
										? newsService
												.getNewsItemById(latestNewsItem.id)
												.then((res) => res.data)
										: undefined,
							})
						}
					>
						<FeaturedCard
							title={latestNewsItem.title ?? ''}
							description={
								latestNewsItem.description ? [latestNewsItem.description] : []
							}
							imageSrcPath={latestNewsItem.thumbnailImagePath}
							btnText="Read more"
							btnLinkTo={`/news/${latestNewsItem.id}`}
						/>
					</div>
				)}
			</Spin>
			<div className={style.appsSection}>
				<div className={style.titleRow}>
					<h3 className={style.title}>Apps</h3>
					<div>
						<FilterField
							label={'Sort by: '}
							selValue={sortOrder}
							font={'1rem'}
							values={sortingKeys}
							labels={sortingLabels}
							setSelectedField={setSortOrder}
						/>
					</div>
				</div>
				<div>
					<Spin spinning={isLoadingReleases} size="large">
						<div className={style.allAppsContainer}>
							{releasesForStore.map((release) => (
								<div
									className={style.cardWrapper}
									key={release.id}
									onMouseEnter={() => {
										queryClient.prefetchQuery({
											queryKey: ['applications'],
											queryFn: () =>
												applicationsService
													.getApplicationsList(0, 50, undefined)
													.then((res) => res.data.content),
											initialData: [],
										});
										queryClient.prefetchQuery({
											queryKey: ['applications', 'releases', release.id],
											queryFn: () =>
												appReleasesService
													.getApplicationReleaseById(release.id)
													.then((res) => res.data),
										});
										queryClient.prefetchQuery({
											queryKey: ['engines', 'releases', 'latest', release.id],
											queryFn: () =>
												appReleasesService
													.getLatestEngineForApplicationRelease(release.id)
													.then((res) => res.data),
										});
									}}
								>
									<AppsCard release={release} />
								</div>
							))}
						</div>
					</Spin>
				</div>
			</div>
		</>
	);
};
