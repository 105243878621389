import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { CancelButton } from 'components/buttons';
import { SaveButton } from 'components/buttons';
import { InputField, InputTextarea, PictureField } from 'components/fields';
import { SelectField } from 'components/selects';
import { Controller, useForm } from 'react-hook-form';
import { appReleaseSchema } from 'validations/FormValidation';
import {
	ApplicationReleaseDto,
	ApplicationReleaseSaveDto,
	EntityStatus,
} from 'api';
import { EntityStatuses } from 'types/api';
import { SelectApplication } from 'components/selects/SelectApplication';
import { App } from 'antd';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import appReleasesService from 'services/AppReleasesService';
import { getRequestError } from 'utils/errors';

interface Props {
	applicationRelease: ApplicationReleaseDto | 'new';
	hideForm: () => void;
}

interface FormValues {
	version: string;
	description: string;
	applicationId: number | undefined;
}

export const AppReleaseForm: FC<Props> = ({ applicationRelease, hideForm }) => {
	const {
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(appReleaseSchema),
	});

	const isNew = applicationRelease === 'new';

	useEffect(() => {
		if (!!applicationRelease && !isNew) {
			setValue('version', isNew ? '' : applicationRelease.version ?? '', {
				shouldValidate: !isNew,
				shouldDirty: false,
			});
			setValue(
				'description',
				isNew ? '' : applicationRelease.description ?? '',
				{
					shouldValidate: !isNew,
					shouldDirty: false,
				}
			);
			setValue(
				'applicationId',
				isNew ? undefined : applicationRelease.applicationId,
				{
					shouldValidate: !isNew,
					shouldDirty: false,
				}
			);

			setSelectedImage(undefined);
		}
	}, [applicationRelease, setValue, isNew]);

	const [status, setStatus] = useState<EntityStatus>(
		isNew ? 'DRAFT' : applicationRelease.entityStatus
	);

	const [, setCompressedFile] = useState<Blob | undefined>();
	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const { mutate: addApplicationRelease } = useMutation({
		mutationFn: (data: FormValues) => {
			const applicationReleaseDto: ApplicationReleaseSaveDto = {
				description: data.description ? data.description : '',
				version: data.version.trim(),
				basicPicture: '',
				avatarPicture: '',
				entityStatus: status,
				applicationId: data.applicationId,
			};

			return appReleasesService.createApplicationReleaseForm(
				applicationReleaseDto,
				undefined,
				selectedImage
			);
		},
		onSuccess: () => {
			notification.success(saveSuccessNotification());
			queryClient.invalidateQueries({ queryKey: ['applications', 'releases'] });
			hideForm();
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT')
				setFormError('version', {
					type: 'custom',
					message: 'The version is already in use for this application!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'create', 'application release')
			);
		},
	});

	const { mutate: deleteApplicationReleaseImage } = useMutation({
		mutationFn: (id: number) =>
			appReleasesService.deleteApplicationReleasesBasicPicture(id),
		onSuccess: () => setIsImageDeleted(false),
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification(
					'Error',
					error,
					'delete',
					'application release image'
				)
			),
	});

	const { mutate: updateApplicationRelease } = useMutation({
		mutationFn: ({
			id,
			applicationReleaseDto,
		}: {
			id: number;
			applicationReleaseDto: ApplicationReleaseSaveDto;
		}) =>
			appReleasesService.updateApplicationReleaseForm(
				id,
				applicationReleaseDto,
				undefined,
				selectedImage
			),
		onSuccess: (_, data) => {
			notification.success(saveSuccessNotification());
			queryClient.invalidateQueries({ queryKey: ['applications', 'releases'] });

			if (isImageDeleted) {
				deleteApplicationReleaseImage(data.id);
			}
			hideForm();
		},
		onError: (error: unknown) => {
			const errorDto = getRequestError(error);
			if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT')
				setFormError('version', {
					type: 'custom',
					message: 'The version is already in use for this application!',
				});
			notification.error(
				mcErrorNotification('Error', error, 'update', 'application release')
			);
		},
	});

	const updateApplicationReleaseHandler = (data: FormValues) => {
		if (isNew) return;

		const versionEqual = data.version === applicationRelease.version;
		const descEqual =
			(data.description !== null &&
				data.description === applicationRelease.description) ||
			(data.description === null && applicationRelease.description === '');
		const statusEqual = status === applicationRelease.entityStatus;
		const appIdEqual = data.applicationId === applicationRelease.applicationId;
		const imgChanged = selectedImage instanceof File;

		if (
			versionEqual &&
			descEqual &&
			statusEqual &&
			appIdEqual &&
			!imgChanged &&
			!isImageDeleted
		) {
			hideForm();
			return;
		}

		const applicationReleaseDto = {
			version: versionEqual ? undefined : data.version.trim(),
			description: descEqual ? undefined : data.description.trim(),
			entityStatus: statusEqual ? undefined : status,
			applicationId: appIdEqual ? undefined : data.applicationId,
		};

		updateApplicationRelease({
			id: applicationRelease.id,
			applicationReleaseDto,
		});
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		hideForm();
	};

	return (
		<form
			className={isNew ? style.addFormWrapper : style.editFormWrapper}
			onSubmit={handleSubmit((data) =>
				isNew
					? addApplicationRelease(data)
					: updateApplicationReleaseHandler(data)
			)}
		>
			<PictureField
				selectedImage={selectedImage}
				setSelectedImage={setSelectedImage}
				setCompressedFile={setCompressedFile}
				needToCompress={false}
				existingImagePath={
					typeof applicationRelease !== 'string'
						? applicationRelease.fullSizeImagePath
						: ''
				}
				isImageDeleted={isImageDeleted}
				setIsImageDeleted={setIsImageDeleted}
				isAvatar={false}
			/>
			<div className={style.editForm} style={{ marginTop: '1rem' }}>
				<div className={style.column}>
					<Controller
						name="applicationId"
						control={control}
						render={({ field }) => (
							<SelectApplication
								{...field}
								error={errors.applicationId}
								label="Application"
							/>
						)}
					/>
				</div>
				<div className={style.column}>
					<Controller
						name="version"
						control={control}
						render={({ field }) => (
							<InputField
								placeholder={'Application Version'}
								tabIndex={20}
								{...field}
								label={'Version'}
								error={!!errors.version}
								errorMessage={errors.version?.message}
							/>
						)}
					/>
				</div>
			</div>
			<SelectField
				defaultValue={status}
				options={[
					{ label: 'Published', value: EntityStatuses.PUBLISHED },
					{ label: 'Draft', value: EntityStatuses.DRAFT },
				]}
				label={'Status'}
				tabIndex={40}
				setSelectedField={setStatus}
			/>
			<Controller
				name="description"
				control={control}
				render={({ field }) => (
					<InputTextarea
						{...field}
						placeholder={'Description'}
						tabIndex={30}
						label={'Description'}
						error={errors.description === undefined ? false : true}
						errorMessage={errors.description?.message}
					/>
				)}
			/>
			<div className={style.buttonsWrapper}>
				<CancelButton tabIndex={60} onClickCancel={cancel} />
				<SaveButton tabIndex={50} />
			</div>
		</form>
	);
};
