import { App, Select } from 'antd';
import { ApplicationDto, ApplicationReleaseDto } from 'api';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Option } from './SelectEntity';
import style1 from 'assets/styles/selectGeneration.module.scss';
import style2 from 'assets/styles/selectField.module.scss';
import style3 from 'assets/styles/manageElements.module.scss';
import applicationsService from 'services/ApplicationsService';
import { AppIdsToReleaseIds } from 'components/fields/ApplicationReleaseField';
import { useQuery } from '@tanstack/react-query';
import { warningNotification } from 'utils/Notifications';

interface Props {
	application: ApplicationDto;
	initalReleaseId?: number;
	setSelectedApplicationReleases: Dispatch<
		SetStateAction<AppIdsToReleaseIds[]>
	>;
	featuredReleases?: Array<number>;
}

const defaultOption = () => {
	return {
		value: null,
		label: (
			<div className={style2.dropdownItem}>
				<div className={style2.labelIconName}>
					<div
						className={style3.statusCircle}
						style={{ backgroundColor: 'var(--add-green)' }}
					/>
					Create new generation
				</div>
			</div>
		),
		searchValue: 'Create new generation'.replace(/ /g, ''),
	};
};

const ItemLabel: React.FC<{ isFeatured: boolean; version: string }> = ({
	isFeatured,
	version,
}) => (
	<div className={style2.dropdownItem}>
		<div className={style2.labelIconName}>
			<div
				className={style3.statusCircle}
				style={
					isFeatured
						? { backgroundColor: 'var(--primary)' }
						: { backgroundColor: 'var(--add-orange)' }
				}
			/>
			{version} {isFeatured ? '(current)' : ''}
		</div>
	</div>
);

const SelectAppGenerationRelease = ({
	application,
	initalReleaseId,
	setSelectedApplicationReleases,
	featuredReleases,
}: Props) => {
	const { notification } = App.useApp();

	// Fetches application releases of the certain application type
	const {
		data: applicationReleases,
		error: applicationReleaseError,
		isLoading: loadingOptions,
	} = useQuery({
		queryKey: ['applications', application.id, 'releases'],
		queryFn: () =>
			applicationsService
				.getApplicationReleasesByApplicationId(application.id, false)
				.then((res) => res.data),
		initialData: [],
		enabled: !!application,
	});

	const generationOptions = useMemo<Option[]>(() => {
		const featured: ApplicationReleaseDto[] = [];
		let ordinary: ApplicationReleaseDto[] = [];

		if (featuredReleases)
			applicationReleases.forEach((release) =>
				featuredReleases.includes(release.id)
					? featured.push(release)
					: ordinary.push(release)
			);
		else ordinary = applicationReleases;

		const orderedList = [...featured, ...ordinary];

		return [
			defaultOption(),
			...orderedList.map((release) => {
				const isFeaturedRelease = featuredReleases
					? featuredReleases.includes(release.id)
					: false;

				return {
					value: release.id,
					label: ItemLabel({
						isFeatured: isFeaturedRelease,
						version: release.version ?? '',
					}),
					searchValue: (
						release.version + (isFeaturedRelease ? '(current) featured' : '') ??
						''
					).replace(/ /g, ''),
				};
			}),
		];
	}, [applicationReleases, featuredReleases]);

	useEffect(() => {
		if (applicationReleaseError)
			notification.warning(
				warningNotification(
					'Could not fetch application releases for application'
				)
			);
	}, [applicationReleaseError, notification]);

	const handleOnChange = (newlySelectedValue: number | null) => {
		setSelectedApplicationReleases((selectedApplicationReleases) =>
			selectedApplicationReleases.map((release) =>
				release[0] === application.id
					? [
							release[0],
							newlySelectedValue === null ? undefined : newlySelectedValue,
					  ]
					: [release[0], release[1]]
			)
		);
	};

	return (
		<Select
			showSearch
			options={generationOptions}
			onChange={handleOnChange}
			defaultValue={initalReleaseId !== undefined ? initalReleaseId : null}
			className={`${style2.multiSelectField} ${style1.generationSelect}`}
			variant="borderless"
			filterOption={(input: any, option: any) =>
				(option?.searchValue ?? '')
					.toLowerCase()
					.includes(input.toLowerCase().replace(/ /g, ''))
			}
			optionFilterProp="children"
			getPopupContainer={(trigger: any) => trigger.parentNode}
			virtual={false}
			loading={loadingOptions}
			disabled={loadingOptions}
		/>
	);
};

export default SelectAppGenerationRelease;
