import React, { Dispatch, SetStateAction } from 'react';

import { EditModuleForm } from './EditModuleForm';
import { ItemContainer } from 'components/ItemContainer';
import style from 'assets/styles/manageElements.module.scss';
import { IconEdit, IconTrash } from 'assets/icons/svg';
import { ModuleItemDto } from 'api';
import { App } from 'antd';
import {
	deleteItemModal,
	deleteSuccessNotification,
	mcErrorNotification,
} from 'utils/Notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import modulesService from 'services/ModulesService';

interface ManageOneModuleProps {
	module: ModuleItemDto;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	selectedToUpdate: number | null;
}

export const ManageOneModule: React.FC<ManageOneModuleProps> = ({
	module,
	setSelectedToUpdate,
	selectedToUpdate,
}) => {
	const { modal, notification } = App.useApp();

	const styles =
		selectedToUpdate === module.id
			? {
					backgroundColor: 'var(--bg-active)',
					color: 'var(--button-text)',
			  }
			: {
					color: 'var(--text-normal)',
			  };

	const clickEditBtnHandler = (
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();

		if (selectedToUpdate === null) {
			setSelectedToUpdate(module.id);
		} else {
			if (selectedToUpdate === module.id) {
				setSelectedToUpdate(null);
			} else {
				setSelectedToUpdate(module.id);
			}
		}
	};

	const queryClient = useQueryClient();

	const { mutate: deleteModuleHandler } = useMutation({
		mutationFn: () => modulesService.deleteModuleItemById(module.id),
		onSuccess: () => {
			notification.success(deleteSuccessNotification(module.name));
			queryClient.invalidateQueries({ queryKey: ['modules'] });
		},
		onError: (error: unknown) =>
			notification.error(
				mcErrorNotification('Error', error, 'delete', 'selected module')
			),
	});

	return (
		<div>
			<ItemContainer styles={styles}>
				<div className={style.textItem} style={{ width: '50%' }}>
					<div>{module.name}</div>
				</div>
				<div className={style.textItem} style={{ width: '40%' }}>
					{module.productCode}
				</div>
				<div className={style.buttonsItem} style={{ width: '10%' }}>
					<div style={{ cursor: 'pointer' }} onClick={clickEditBtnHandler}>
						<IconEdit />
					</div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
							e.preventDefault();
							modal.confirm(
								deleteItemModal(() => deleteModuleHandler(), module.name)
							);
						}}
					>
						<IconTrash />
					</div>
				</div>
			</ItemContainer>
			{selectedToUpdate === module.id && (
				<EditModuleForm
					module={module}
					setSelectedToUpdate={setSelectedToUpdate}
				/>
			)}
		</div>
	);
};
