import { UserDto } from 'api';
import style from 'assets/styles/selectField.module.scss';
import { useDebounce } from 'hooks/useDebounce';
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import SelectEntity from './SelectEntity';
import { useInfiniteQuery } from '@tanstack/react-query';
import usersService from 'services/UsersService';
import { App } from 'antd';
import { warningNotification } from 'utils/Notifications';

interface Props {
	usersToAdd: UserDto[];
	setUsersToAdd: Dispatch<UserDto[]>;
	tabIndex?: number;
	existingUsersInOrganization: UserDto[];
}

const SelectAddUser = ({
	tabIndex,
	usersToAdd,
	setUsersToAdd,
	existingUsersInOrganization,
}: Props) => {
	const [searchText, setSearchText] = useState('');
	const debouncedSearch = useDebounce(searchText, 200);
	const { notification } = App.useApp();
	const PAGE_SIZE = 20;

	const {
		data: userPages,
		error: getUsersError,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: ['users', 'infinite', debouncedSearch],
		queryFn: ({ pageParam: pageNr }) =>
			usersService
				.getUsersList(pageNr, PAGE_SIZE, undefined, undefined, debouncedSearch)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const users: UserDto[] = useMemo(
		() =>
			!!userPages
				? userPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as UserDto[]
				  )
				: [],
		[userPages]
	);

	const mapOption = useCallback((user: UserDto) => {
		return {
			label: (
				<div className={style.dropdownItem}>
					<div className={style.labelIconName}>
						<div>
							{user.firstName} {user.lastName}
						</div>
					</div>
					<div className={style.dropdownItemCompany}>({user.company})</div>
					{user.organizationId && (
						<div className={style.dropdownItemCompany}>
							(User already in an organization!)
						</div>
					)}
				</div>
			),
			value: user.id,
			searchValue: (
				user.firstName! +
				user.lastName! +
				user.company +
				user.email +
				(user.department ?? '')
			).replace(/ /g, ''),
		};
	}, []);

	const options = useMemo(
		() => users.map((user) => mapOption(user)),
		[mapOption, users]
	);

	useEffect(() => {
		if (!getUsersError) return;
		notification.warning(warningNotification('Failed to fetch user list!'));
	}, [getUsersError, notification]);

	const onChange = (newValue: number | undefined) => {
		if (newValue === undefined) return;

		const selectedUser = users.find((user) => user.id === newValue);

		if (
			selectedUser !== undefined &&
			!usersToAdd.some((user) => user.id === selectedUser.id) &&
			!existingUsersInOrganization.some(
				(user) => user.id === selectedUser.id
			) &&
			!selectedUser.organizationId
		) {
			setUsersToAdd([selectedUser, ...usersToAdd]);
		}
	};

	return (
		<SelectEntity
			options={options}
			onChange={onChange}
			clearOnSelect={true}
			loading={isLoading || isFetching}
			setSearchText={setSearchText}
			fetchMoreEntities={fetchNextPage}
			label="Users"
			placeholder="Add a user"
			entityName="user"
			tabIndex={tabIndex}
			includeEmptyOption={false}
		/>
	);
};

export default SelectAddUser;
