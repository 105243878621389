import { ReactNode, useState } from 'react';

import style from 'assets/styles/filterTagField.module.scss';
import { CheckIcon, FilterIcon, IconTickDown } from 'assets/icons/svg';
import useOutsideClick from 'hooks/useOutsideClick';

export interface FilterCategory {
	categoryName?: string;
	filterItems: FilterItem[];
}

export interface FilterItem {
	enabled: boolean; // If the filter is active
	toggleEnabled: () => void; // set function for the filter
	label: string; // Label for the option
	isOptionDisabled?: boolean; // Disables the filter option
	icon?: ReactNode; // Icon appearing left of the label
}

interface Props {
	filterCategories: FilterCategory[];
}

const FilterTagField = ({ filterCategories }: Props) => {
	const [showDropMenu, setShowDropMenu] = useState(false);
	const ref = useOutsideClick(() => {
		setShowDropMenu(false);
	});

	return (
		<div
			className={style.wrapper}
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				setShowDropMenu((prev) => !prev);
			}}
		>
			<div className={style.filterField}>
				<div
					style={{
						display: 'inline-block',
						height: '24px',
						width: '24px',
					}}
				>
					<FilterIcon />
				</div>
				<div
					style={{
						color: 'var(--text-normal)',
						userSelect: 'none',
						display: 'inline-block',
					}}
				>
					Filter
				</div>
				<div className={showDropMenu ? 'rotate0' : 'rotate180'}>
					<IconTickDown />
				</div>
			</div>
			<div style={{ position: 'relative', width: '100%' }}>
				<div
					className={style.filterFieldDropDown}
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
					}}
					style={!showDropMenu ? { display: 'none' } : {}}
				>
					{filterCategories.map((category, idx) => (
						<div className={style.filterFieldCategory} key={idx}>
							<div className={style.filterFieldCategoryLabel}>
								{category.categoryName?.toUpperCase()}
							</div>
							{category.filterItems.map((filterItem, idx) => (
								<div
									className={
										!!filterItem.isOptionDisabled
											? style.filterFieldDropItemDisabled
											: style.filterFieldDropItem
									}
									onClick={() => {
										if (!filterItem.isOptionDisabled)
											filterItem.toggleEnabled();
									}}
									key={idx}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											gap: '6px',
										}}
									>
										{!!filterItem.icon && (
											<div
												style={{
													display: 'inline-block',
													height: '24px',
													width: '24px',
												}}
											>
												{filterItem.icon}
											</div>
										)}
										<div>{filterItem.label}</div>
									</div>
									{filterItem.enabled ? (
										<div
											style={
												!!filterItem.isOptionDisabled
													? { color: 'var(--text-disabled)' }
													: {}
											}
										>
											<CheckIcon />
										</div>
									) : (
										<></>
									)}
								</div>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default FilterTagField;
