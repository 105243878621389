import { getRequestError } from 'utils/errors';

export const getDetailedErrorMessage = (
	anyError: unknown,
	includeCode?: boolean
): string => {
	const errorDto = getRequestError(anyError);
	let errMessage: string = 'Unspecified error!';

	if (!!errorDto.details && errorDto.details.length > 0) {
		errMessage = errorDto.details.reduce((a, b) => a + '\n' + b.error, '');
	}

	return errMessage + (!!includeCode ? ` (${errorDto.code})` : '');
};
