import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';
import { Dispatch, useRef, useState } from 'react';
import { CheckIcon, DownloadIcon, IconTrash } from 'assets/icons/svg';
import { ActivationWizardState } from '../ActivationWizard';
import { CmContainer, ContainerType, isContainerType } from 'desktop';
import { InputField } from 'components/fields';
import manualStyle from './../Components/FileButton.module.scss';

interface Props {
	changeDisplayPage: Dispatch<ActivationWizardState>;
	setSelectedContainer: Dispatch<CmContainer | undefined>;
	selectedContainer: CmContainer | undefined;
	setContextFile: Dispatch<Blob | undefined>;
	contextFile: Blob | undefined;
}

const ImportContextPage = ({
	changeDisplayPage,
	setSelectedContainer,
	selectedContainer,
	setContextFile,
	contextFile,
}: Props) => {
	const isFileLoaded = !!contextFile;
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const onImport = (file: File) => {
		const fileName: string = file.name;
		setErrorMessage(undefined);
		try {
			if (!fileName.endsWith('.WibuCmRaC')) {
				setErrorMessage('Wrong filetype provided!');
				return;
			}

			const nameParts: string[] = fileName.split('-');
			if (
				nameParts.length !== 4 ||
				nameParts[0] !== 'Cm' ||
				!isContainerType(nameParts[1])
			) {
				setErrorMessage(
					'File name is missing information!\nPlease enter container serial and type manually:'
				);
				return;
			}
			const mask: number = Number(nameParts[2]);

			const match = nameParts[3].match(/^\d+/);

			if (match === null || match.length === 0) {
				setErrorMessage('File name is missing information!\n');
				return;
			}

			const serial: number = Number(match[0]);

			if (isNaN(serial) || isNaN(mask)) {
				setErrorMessage('File name is missing information!\n');
				return;
			}

			const container: CmContainer = {
				mask: mask,
				serial: serial,
				container_type: nameParts[1] as ContainerType,
				identifier: `${mask}-${serial}`,
			};
			setContextFile(file);
			setSelectedContainer(container);
		} catch (err) {}
	};

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Import Context</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					On the remote computer, export the context file and transfer it to
					this computer. Then import the context file by pressing "Import
					context file".
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '24px',
					gap: '8px',
					alignItems: 'center',
				}}
			>
				<div className={manualStyle.fileButtonContainer}>
					<input
						className={manualStyle.fileButtonInput}
						type="file"
						id="file-input"
						style={{ display: 'none' }}
						ref={fileInputRef}
						onChange={(event) => {
							try {
								onImport(event.target.files![0]);
							} catch (e) {
								console.error('Failed to import file!');
							}
						}}
					/>
					<McButton
						primary
						onClick={() => {
							if (fileInputRef !== null && fileInputRef.current !== null)
								fileInputRef.current.click();
						}}
					>
						<DownloadIcon />
						Import context file
					</McButton>
				</div>
				{isFileLoaded && (
					<>
						<CheckIcon />
						<div
							onClick={() => {
								setContextFile(undefined);
								setSelectedContainer(undefined);
								setErrorMessage(undefined);
							}}
						>
							<IconTrash />
						</div>
					</>
				)}
				{!!errorMessage && (
					<div
						style={{
							color: 'var(--add-red)',
							fontSize: '13px',
							fontWeight: '500',
							whiteSpace: 'pre-line',
						}}
					>
						{errorMessage}
					</div>
				)}
			</div>
			{!!contextFile && !!selectedContainer && (
				<>
					<InputField
						value={!!selectedContainer ? selectedContainer.identifier : ''}
						label={'Container Serial'}
					/>
					<InputField
						value={!!selectedContainer ? selectedContainer.container_type : ''}
						readOnly
						label={'Container Type'}
					/>
				</>
			)}
			<div
				style={{
					justifyContent: 'space-between',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton
					onClick={() => {
						setContextFile(undefined);
						//setSelectedContainer(undefined); // TODO: fix candidate 1
						changeDisplayPage('OfflineActivation');
					}}
				>
					Back
				</McButton>

				<McButton
					primary
					onClick={() => {
						changeDisplayPage('DefineActivation');
					}}
					disabled={!isFileLoaded || !selectedContainer}
				>
					Next
				</McButton>
			</div>
		</div>
	);
};

export default ImportContextPage;
