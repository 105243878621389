import { ModalFuncProps } from 'antd';
import { ArgsProps as NotificationProps } from 'antd/es/notification';
import { getRequestError } from './errors';

/**
 * Contains functional props for modals and notifications.
 */

export type McErrorType = 'Error' | 'Warning';

// Notification arguments ----------------------------------------------------
export const genericNotification = (
	title: string,
	description: string
): NotificationProps => ({
	message: title,
	description: description,
});

export const infoNotification = (message: string): NotificationProps => {
	return {
		message: 'Info!',
		description: message,
	} as NotificationProps;
};

export const warningNotification = (message: string): NotificationProps => {
	return {
		message: 'Warning!',
		description: message,
	} as NotificationProps;
};

export const errorNotification = (errorMessage: string): NotificationProps => {
	return {
		message: 'Error!',
		description: errorMessage,
		duration: null,
	} as NotificationProps;
};

export const mcErrorNotification = (
	errorType: McErrorType,
	error: unknown,
	predicate: string,
	target: string
): NotificationProps => {
	const errorDto = getRequestError(error);

	const description: string =
		!!errorDto.details && errorDto.details.length > 0
			? errorDto.details.reduce((a, b) => a + '\n' + b.error, '') +
			  ` (${errorDto.code})`
			: `Failed to ${predicate} ${target}! (${errorDto.code})`;

	const config: NotificationProps = {
		message: `${errorType}!`,
		description: description,
	};

	if (errorType === 'Error') config.duration = null;

	return config;
};

export const fileUploadedNotification = (): NotificationProps => {
	return {
		message: 'Uploaded!',
		description: 'The file was successfully uploaded!',
	} as NotificationProps;
};

export const fileDownloadedNotification = (): NotificationProps => {
	return {
		message: 'Downloaded!',
		description: 'The file was successfully downloaded!',
	} as NotificationProps;
};

export const abandonRequestNotification = (): NotificationProps => {
	return {
		message: 'Request abandoned!',
		description: 'The selected request was abandoned!',
	} as NotificationProps;
};

export const confirmRequestNotification = (): NotificationProps => {
	return {
		message: 'Request confirmed!',
		description: 'The selected request was confirmed!',
	} as NotificationProps;
};

export const createdRequestNotification = (): NotificationProps => {
	return {
		message: 'Request created!',
		description: 'Your request has been sent!',
	} as NotificationProps;
};

export const saveSuccessNotification = (
	itemName?: string
): NotificationProps => {
	return {
		message: 'Saved!',
		description: `You successfully saved ${itemName ?? 'the item'}!`,
	} as NotificationProps;
};

export const deleteSuccessNotification = (
	itemName?: string
): NotificationProps => {
	return {
		message: 'Deleted!',
		description: `You successfully deleted ${itemName ?? 'the item'}!`,
	} as NotificationProps;
};

// Modal arguments --------------------------------------------------------
export const deleteItemModal = (onOk: () => void, itemName?: string) => {
	return {
		title: 'Confirm Deletion',
		content: `Are you sure you want to delete ${
			itemName ?? 'this item'
		}? This action is irreversible!`,
		onOk: onOk,
		okType: 'danger',
		okText: 'Delete',
		maskClosable: true,
		centered: true,
	} as ModalFuncProps;
};

export const errorModal = (errorMessage: string) => {
	return {
		title: 'Error!',
		content: errorMessage,
		maskClosable: true,
		centered: true,
	} as ModalFuncProps;
};
