export const transformLinkTarget = (href: string | undefined) => {
	if (!href || href.startsWith('http')) {
		return '_blank';
	}
	return '_top';
};

export const webCrmOrganizationUrl = (id: number) =>
	'https://app.webcrm.com/MainMenu/Organisations/OrganisationCard/OrganisationCard.aspx?orgId=' +
	id;
