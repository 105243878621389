import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ApplicationReleaseSortOrder } from 'api';
import { ErrorDTO, PageDtoApplicationReleaseDto } from 'api';
import { homeService } from 'services/HomeService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk } from 'utils/rtk';

export interface PublishedApplicationsState {
	publishedApplicationsForHome: PageDtoApplicationReleaseDto;
	publishedApplicationsForStore: PageDtoApplicationReleaseDto;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: PublishedApplicationsState = {
	publishedApplicationsForHome: {} as PageDtoApplicationReleaseDto,
	publishedApplicationsForStore: {} as PageDtoApplicationReleaseDto,
	loading: false,
	error: null,
};

interface HomeAppsParams {
	sortOrder?: ApplicationReleaseSortOrder;
	page: number;
	size: number;
	enginesId?: number;
}

export const getPublishedApplicationsForHome = createAppAsyncThunk(
	'publishedApplications/getAppsForHome',
	({ sortOrder, page, size, enginesId }: HomeAppsParams, { rejectWithValue }) =>
		homeService
			.getPublishedApplicationReleases(page, size, enginesId, sortOrder)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const getPublishedApplicationsForStore = createAppAsyncThunk(
	'publishedApplications/getAppsForStore',
	(
		{ sortOrder: applicationCriteria, page, size, enginesId }: HomeAppsParams,
		{ rejectWithValue }
	) =>
		homeService
			.getPublishedApplicationReleases(
				page,
				size,
				enginesId,
				applicationCriteria
			)
			.catch(getErrorMessageAnd(rejectWithValue))
);

const publishedApplicationsSlice = createSlice({
	name: 'publishedApplications',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPublishedApplicationsForHome.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(
				getPublishedApplicationsForHome.fulfilled,
				(state, { payload }) => {
					state.loading = false;
					state.publishedApplicationsForHome = payload.data;
				}
			)

			.addCase(getPublishedApplicationsForStore.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(
				getPublishedApplicationsForStore.fulfilled,
				(state, { payload }) => {
					state.loading = false;
					state.publishedApplicationsForStore = payload.data;
				}
			)

			.addMatcher(
				isAnyOf(
					getPublishedApplicationsForHome.rejected,
					getPublishedApplicationsForStore.rejected
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const publishedApplicationsState = (state: RootState) =>
	state.publishedApplications;

export default publishedApplicationsSlice.reducer;
