import { useEffect, useState } from 'react';

import { CancelButton } from 'components/buttons';
import { SaveButton } from 'components/buttons';
import { InputField, InputTextarea, PictureField } from 'components/fields';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

import { yupResolver } from '@hookform/resolvers/yup';
import style from 'assets/styles/editAddElementForm.module.scss';
import { DisabledScreen } from 'components/DisabledScreen';
import { UploadFileField } from 'components/fields';
import { SelectAppRelease, SelectField } from 'components/selects';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { addTemplate, oneTemplateState } from 'store/slices/oneTemplate';
import { addTemplateSchema } from 'validations/FormValidation';
import { EntityStatuses } from 'types/api';
import { generateDisplayErrorMessage, getRequestError } from 'utils/errors';
import { App } from 'antd';
import {
	errorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';

interface AddTemplateFormProps {
	setShowAddTemplateForm: Dispatcher;
	setRefresh: Dispatcher;
}

interface FormValues {
	name: string;
	description: string;
	application: number | undefined;
}

export const AddTemplateForm: React.FC<AddTemplateFormProps> = ({
	setShowAddTemplateForm,
	setRefresh,
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		setError: setFormError,
	} = useForm<FormValues>({
		mode: 'onBlur',
		resolver: yupResolver(addTemplateSchema),
	});

	const dispatch = useAppDispatch();
	const [status, setStatus] = useState(EntityStatuses.DRAFT);
	const [appReleaseId, setAppReleaseId] = useState<number>();
	const [compressedFile, setCompressedFile] = useState<Blob | undefined>();
	const [selectedImage, setSelectedImage] = useState<Blob | undefined>();
	const [fileUploadName, setFileUploadName] = useState<string>();
	const [fileUpload, setFileUpload] = useState<Blob>();
	const { uploading } = useAppSelector(oneTemplateState);
	const [showLoader, setShowLoader] = useState(uploading);
	const [startUploading, setStartUploading] = useState(false);

	useEffect(() => {
		setShowLoader(uploading && startUploading);
	}, [uploading, startUploading]);

	useEffect(() => {
		setValue('application', appReleaseId, {
			shouldValidate: Boolean(appReleaseId),
			shouldDirty: true,
		});
	}, [appReleaseId, setValue]);

	useEffect(() => {
		if (typeof appReleaseId === 'undefined' || appReleaseId === undefined) {
			setStatus(EntityStatuses.DRAFT);
		}
	}, [appReleaseId, setAppReleaseId, status, setStatus]);

	const [isImageDeleted, setIsImageDeleted] = useState(false);
	const { notification } = App.useApp();

	const addTemplateHandler: SubmitHandler<FormValues> = (data) => {
		setStartUploading(true);

		const templateDto = {
			title: data.name.trim(),
			description: data.description.trim(),
			entityStatus: status,
			applicationReleaseId: appReleaseId,
		};

		dispatch(
			addTemplate([
				templateDto,
				selectedImage,
				compressedFile,
				fileUpload,
				!!fileUpload,
			])
		)
			.unwrap()
			.then(() => {
				setShowAddTemplateForm(false);
				setRefresh((prev) => !prev);
				notification.success(saveSuccessNotification(templateDto.title));
			})
			.catch((error: unknown) => {
				const errorDto = getRequestError(error);
				if (errorDto.code === 'ENTITY_UNIQUE_CONFLICT') {
					setFormError('name', {
						type: 'custom',
						message:
							'Another template with such a name and application already exist!',
					});
					setFormError('application', {
						type: 'custom',
						message:
							'Another template with such a name and application already exist!',
					});
				}
				setStartUploading(false);
				notification.error(
					errorNotification(generateDisplayErrorMessage(errorDto))
				);
			});
	};

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setShowAddTemplateForm(false);
		setStartUploading(false);
	};

	return (
		<>
			<form
				className={style.addFormWrapper}
				onSubmit={handleSubmit(addTemplateHandler)}
			>
				<PictureField
					selectedImage={selectedImage}
					setSelectedImage={setSelectedImage}
					setCompressedFile={setCompressedFile}
					needToCompress={true}
					isImageDeleted={isImageDeleted}
					setIsImageDeleted={setIsImageDeleted}
					isAvatar={false}
				/>
				<UploadFileField
					text={'Upload template'}
					fileUploadName={fileUploadName}
					setFileUploadName={setFileUploadName}
					setFileUpload={setFileUpload}
					showLoader={showLoader}
				/>
				<div className={style.editForm} style={{ marginTop: '1.5rem' }}>
					<div className={style.column}>
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<InputField
									placeholder={'Template name'}
									tabIndex={1}
									{...field}
									label={'Template Name'}
									error={!!errors.name}
									errorMessage={errors.name?.message}
								/>
							)}
						/>
						<Controller
							name="description"
							control={control}
							render={({ field }) => (
								<InputTextarea
									{...field}
									placeholder={'Description'}
									tabIndex={2}
									label={'Description'}
									error={errors.description === undefined ? false : true}
									errorMessage={errors.description?.message}
								/>
							)}
						/>
					</div>
					<div className={style.column}>
						<Controller
							name="application"
							control={control}
							render={() => (
								<>
									<SelectAppRelease
										appReleaseId={appReleaseId}
										setAppReleaseId={setAppReleaseId}
										tabIndex={3}
										label={'Application Release'}
										error={!!errors.application}
										errorMessage={errors.application?.message}
									/>
									<input type="hidden" {...register('application')} />
								</>
							)}
						/>

						<SelectField
							defaultValue={status}
							options={[
								{ label: 'Draft', value: EntityStatuses.DRAFT },
								{ label: 'Published', value: EntityStatuses.PUBLISHED },
							]}
							label={'Status'}
							tabIndex={4}
							setSelectedField={setStatus}
						/>
					</div>
				</div>
				<div className={style.buttonsWrapper}>
					<CancelButton tabIndex={6} onClickCancel={cancel} />
					<SaveButton tabIndex={5} />
				</div>
			</form>
			{showLoader && <DisabledScreen />}
		</>
	);
};
