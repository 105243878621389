import { SvgIcon, SvgIconProps } from '../SvgIcon';

export const WinMaximizeIcon: React.FC<SvgIconProps> = ({ size = '10' }) => (
	<SvgIcon size={size} actualSize={10}>
		<rect
			x="0.5"
			y="0.5"
			width="9"
			height="9"
			stroke="currentColor"
			strokeWidth="1"
		/>
	</SvgIcon>
);
