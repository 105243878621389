import { ChangeEvent, FC, FocusEvent, forwardRef, ReactNode } from 'react';

import { Input, InputRef } from 'antd';

import 'assets/styles/FormInput.scss';

interface Props {
	icon?: ReactNode;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	value?: string;
	error?: string;
	type?: string;
	defaultValue?: string;
	autoComplete?: string;
	onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput: FC<Props> = forwardRef<InputRef, Props>(
	(
		{
			icon,
			label,
			placeholder,
			disabled,
			value,
			type,
			defaultValue,
			onChange,
			onBlur,
			error,
			autoComplete,
		},
		ref
	) => {
		return (
			<div className="formInput-container">
				{label && (
					<label htmlFor={label} className="formInput-label">
						{label}
					</label>
				)}
				{type === 'password' ? (
					<Input.Password
						className="input-password"
						size="large"
						prefix={icon}
						placeholder={placeholder}
						id={label}
						ref={ref}
						disabled={disabled}
						value={value}
						style={{ border: error ? '1.5px solid var(--add-red)' : '' }}
						onBlur={onBlur}
						onChange={onChange}
						autoComplete={autoComplete}
						defaultValue={defaultValue}
					/>
				) : (
					<Input
						size="large"
						prefix={icon}
						placeholder={placeholder}
						id={label}
						ref={ref}
						disabled={disabled}
						value={value}
						style={{ border: error ? '1.5px solid var(--add-red)' : '' }}
						onBlur={onBlur}
						onChange={onChange}
						autoComplete={autoComplete}
						defaultValue={defaultValue}
					/>
				)}
				<div className="errorMessage">{error}</div>
			</div>
		);
	}
);
