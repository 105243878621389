import { createSlice, createAction, isAnyOf } from '@reduxjs/toolkit';
import { ApplicationDto, ErrorDTO } from 'api';
import { AxiosResponse } from 'axios';
import applicationsService from 'services/ApplicationsService';

import { RootState } from 'store';
import { getErrorMessageAnd, rejectionError } from 'utils/errors';
import { createAppAsyncThunk, setLoadingState } from 'utils/rtk';

export interface AppState {
	app: ApplicationDto;
	loading: boolean;
	error: ErrorDTO | null;
}

const initialState: AppState = {
	app: {} as ApplicationDto,
	loading: true,
	error: null,
};

export const deleteApp = createAppAsyncThunk(
	'oneApp/deleteApp',
	({ id }: { id: number }, { rejectWithValue }) =>
		applicationsService
			.deleteApplication(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const deleteAppAvatar = createAppAsyncThunk(
	'oneApp/deleteAppIcon',
	({ id }: { id: number }, { rejectWithValue }) =>
		applicationsService
			.deleteApplicationIconPicture(id)
			.catch(getErrorMessageAnd(rejectWithValue))
);

type AddAppArgs = Parameters<typeof applicationsService.createApplicationForm>;

export const addApp = createAppAsyncThunk(
	'oneApp/addApp',
	(args: AddAppArgs, { rejectWithValue }) =>
		applicationsService
			.createApplicationForm(...args)
			.catch(getErrorMessageAnd(rejectWithValue))
);

export const updateApp = createAppAsyncThunk<
	AxiosResponse<void>,
	{ applicationDto: ApplicationDto; basicPic?: Blob }
>('oneApp/updateApp', (args, { rejectWithValue }) =>
	applicationsService
		.updateApplicationForm(args.applicationDto, args.basicPic)
		.catch(getErrorMessageAnd(rejectWithValue))
);

export const resetState = createAction('RESET');

const oneAppSlice = createSlice({
	name: 'oneApp',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(addApp.pending, setLoadingState)
			.addCase(addApp.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(updateApp.pending, setLoadingState)
			.addCase(updateApp.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(deleteApp.pending, setLoadingState)
			.addCase(deleteApp.fulfilled, (state) => {
				state.loading = false;
				state.app = {} as ApplicationDto;
				state.error = null;
			})

			.addCase(deleteAppAvatar.pending, setLoadingState)
			.addCase(deleteAppAvatar.fulfilled, (state) => {
				state.loading = false;
				state.error = null;
			})

			.addCase(resetState, () => {
				return initialState;
			})

			.addMatcher(
				isAnyOf(
					addApp.pending,
					updateApp.pending,
					deleteApp.pending,
					deleteAppAvatar.pending
				),
				(state, action) => {
					state.error = action.payload ?? rejectionError;
					state.loading = false;
				}
			);
	},
});

export const oneAppState = (state: RootState) => state.oneApp;

export default oneAppSlice.reducer;
