import React, { useState } from 'react';
import styles from '../../assets/styles/releaseNotes.module.scss';
import { IconTickDown } from 'assets/icons/svg';
import { McMarkdown } from 'components/mc';

interface ReleaseNoteCardProps {
	markdown: string;
	monthYear: string;
	fullDate: string;
	index: number;
	name: string;
}

const ReleaseNoteCard: React.FC<ReleaseNoteCardProps> = ({
	markdown,
	monthYear,
	fullDate,
	index,
	name,
}) => {
	const [isCollapsed, setIsCollapsed] = useState(index !== 0);

	const handleToggleCollapse = () => {
		setIsCollapsed((prevState) => !prevState);
	};

	const collapsibleHeaderClass = `${styles.collapsibleHeader} ${
		isCollapsed ? styles.collapsedHeader : styles.expandedHeader
	}`;

	return (
		<div className={styles.container}>
			<div className={collapsibleHeaderClass} onClick={handleToggleCollapse}>
				Release Notes: Motion Cloud {name} - {monthYear}
				<div className={styles.rightHeader}>
					<span className={styles.dateHeader}>{fullDate}</span>
					<span
						className={`${styles.iconTickDown} ${
							isCollapsed ? 'rotate0' : 'rotate180'
						}`}
					>
						<IconTickDown />
					</span>
				</div>
			</div>
			<div
				className={`${styles.itemsContainer} ${
					!isCollapsed ? styles.expanded : ''
				}`}
			>
				<div className={styles.innerContent}>
					<McMarkdown content={markdown} />
				</div>
			</div>
		</div>
	);
};

export default ReleaseNoteCard;
