import { dialog, fs } from '@tauri-apps/api';
import { AxiosResponse } from 'axios';

export const downloadFile = async (
	downloadResponse: Promise<AxiosResponse>,
	fileTypeName?: string,
	extensions?: string[],
	defaultFileName?: string
) => {
	try {
		const res = await downloadResponse;

		if (typeof res.headers.get !== 'function') return;
		const fileName =
			res.headers.get('content-disposition', /filename="(.+)"/)?.[1] ??
			defaultFileName ??
			'file';

		const filePath = await dialog.save({
			defaultPath: fileName,
			filters: [{ name: fileTypeName ?? '', extensions: extensions ?? [''] }],
		});
		if (!filePath) return;

		await fs.writeBinaryFile(filePath, res.data as unknown as ArrayBuffer);
	} catch (x: unknown) {
		const error =
			typeof x === 'string' ? x : x instanceof Error ? x.message : `${x}`;
		dialog.message(`An error occurred while saving the file:\n${error}`, {
			title: 'Failed to save file',
			type: 'error',
		});
	}
};
