import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react';
import style from 'assets/styles/editAddElementForm.module.scss';
import { FormItem, InputDate } from 'components/fields';
import { SelectField } from 'components/selects';
import { McButton, McDialog } from 'components/mc';
import { App, AutoComplete, Input } from 'antd';
import { LicenseStatus } from 'types/api';
import { SubscriptionDto, UpdateLicenseDto } from 'api';
import {
	mcErrorNotification,
	saveSuccessNotification,
} from 'utils/Notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import licensesService from 'services/LicenseService';
import modulesService from 'services/ModulesService';
import subscriptionsService from 'services/SubscriptionsService';

interface EditLicModulesProps {
	subscription: SubscriptionDto;
	license: License;
	setSelectedToUpdate: Dispatch<SetStateAction<number | null>>;
	setShowEditLicModulesWindow: Dispatcher;
}

export const EditLicensedModulesForm: React.FC<EditLicModulesProps> = ({
	subscription,
	license,
	setSelectedToUpdate,
	setShowEditLicModulesWindow,
}) => {
	const [moduleName, setModuleName] = useState(license.moduleName);
	const [containerName, setContainerName] = useState(license.containerName);
	const [status, setStatus] = useState(license.licenseStatus);
	const queryClient = useQueryClient();

	const [expiryDate, setExpiryDate] = useState(
		license.expiryDate ?? subscription.renewalDate ?? ''
	);
	const { notification } = App.useApp();

	const statusOptions: { label: string; value: LicenseStatus }[] = [
		{ label: 'Activated', value: 'ACTIVATED' },
		{ label: 'Available', value: 'AVAILABLE' },
	];

	const { data: modules, error: getModulesError } = useQuery({
		queryKey: ['modules'],
		queryFn: () =>
			modulesService
				.getModulesList(0, 50, undefined) // Pagination not needed since there won't be that many
				.then((res) => res.data.content),
		initialData: [],
	});

	const { data: containers, error: getContainersError } = useQuery({
		queryKey: ['subscriptions', subscription.id, 'containers'],
		queryFn: () =>
			subscriptionsService
				.getAllContainers(subscription.id)
				.then((res) => res.data),
		initialData: [],
		enabled: !!subscription,
	});

	const moduleOptions = useMemo(
		() => modules.map((m) => ({ label: m.name, value: m.name })),
		[modules]
	);

	const containerOptions = useMemo(
		() =>
			containers.map((c) => ({
				label: `${c.name} (${c.containerType})`,
				value: c.name,
			})),
		[containers]
	);

	const { mutate: updateLicenseModules } = useMutation({
		mutationFn: (dto: UpdateLicenseDto) =>
			licensesService.updateLicense(dto, license.id),
		onSuccess: () => {
			notification.success(saveSuccessNotification());
			setSelectedToUpdate(null);
			queryClient.invalidateQueries({
				queryKey: ['subscriptions', subscription.id, 'licenses'],
			});
		},
		onError: (err: unknown) =>
			notification.error(
				mcErrorNotification('Error', err, 'update', 'license modules')
			),
	});

	const updateLicModulesHandler = () => {
		const statusChanged = status !== license.licenseStatus;
		const containerChanged = containerName !== license.containerName;
		const expiryChanged = expiryDate !== license.expiryDate;
		if (!statusChanged && !containerChanged && !expiryChanged) {
			setSelectedToUpdate(null);
		} else {
			const dto: UpdateLicenseDto = {
				licenseStatus: statusChanged ? status : undefined,
				containerName: containerChanged ? containerName : undefined,
				expiryDate: expiryChanged ? expiryDate : undefined,
			};

			updateLicenseModules(dto);
		}
	};

	useEffect(() => {
		if (!getContainersError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getContainersError,
				'fetch',
				'available containers'
			)
		);
	}, [getContainersError, notification]);

	useEffect(() => {
		if (!getModulesError) return;
		notification.warning(
			mcErrorNotification('Warning', getModulesError, 'fetch', 'modules list')
		);
	}, [getModulesError, notification]);

	const cancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSelectedToUpdate(null);
		setShowEditLicModulesWindow(false);
	};

	const saveEnabled =
		(status === 'ACTIVATED' && expiryDate && containerName) ||
		(status === 'AVAILABLE' && license.licenseStatus !== status);

	return (
		<McDialog
			modal
			title={'Manual Activation Status Update'}
			footer={
				<McButton.Row>
					<McButton onClick={cancel}>Cancel</McButton>

					<McButton
						disabled={!saveEnabled}
						onClick={updateLicModulesHandler}
						primary
						type="submit"
					>
						Save
					</McButton>
				</McButton.Row>
			}
		>
			<form
				className={style.addFormWrapper}
				style={{ borderRadius: 0, borderLeft: 0, borderRight: 0 }}
				onSubmit={updateLicModulesHandler}
			>
				<div className={style.editForm}>
					<div className={style.column}>
						<SelectField
							defaultValue={moduleName}
							options={moduleOptions}
							label={'Module name'}
							setSelectedField={setModuleName}
							disabled
						/>
						<SelectField
							defaultValue={status}
							options={statusOptions}
							label={'Status'}
							setSelectedField={setStatus}
						/>
					</div>
					<div className={style.column}>
						<FormItem label={'Container'}>
							<AutoComplete
								value={containerName}
								options={containerOptions}
								disabled={status === 'AVAILABLE'}
								onChange={setContainerName}
							>
								<Input.Search
									onChange={(e) => setContainerName(e.target.value)}
									size="large"
									disabled={status === 'AVAILABLE'}
								/>
							</AutoComplete>
						</FormItem>

						<InputDate
							label={'Expiry date (UTC)'}
							value={expiryDate}
							setDate={setExpiryDate}
							disabled={status === 'AVAILABLE'}
							defaultValue={subscription.renewalDate}
							isUtc
							timeRemainingSuffix
							timeRemainingIncludeTarget
						/>
					</div>
				</div>
			</form>
		</McDialog>
	);
};
