/* tslint:disable */
/* eslint-disable */
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export const ApplicationReleaseSortOrderVals = {
    CREATEDATDESC: 'BY_CREATED_AT_DESC',
    CREATEDATASC: 'BY_CREATED_AT_ASC',
    ALPHABETDESC: 'BY_ALPHABET_DESC',
    ALPHABETASC: 'BY_ALPHABET_ASC'
} as const;

export type ApplicationReleaseSortOrder = typeof ApplicationReleaseSortOrderVals[keyof typeof ApplicationReleaseSortOrderVals];

