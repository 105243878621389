import React, { Dispatch } from 'react';
import {
	FloatingSubscriptionIcon,
	IconTickDown,
	StandaloneSubscriptionIcon,
} from 'assets/icons/svg';
import { OpenedCustomerSubscription } from './OpenedCustomerSubscription';
import style from 'assets/styles/licensingPage.module.scss';
import { Cond } from 'utils/Cond';
import { SubscriptionDto } from 'api';
import { getTimeRemainingSuffix } from 'utils/date';
import { Tooltip } from 'antd';

interface CustomerSubscriptionProps {
	subscription: SubscriptionDto;
	selectedSubscriptionId: number | null;
	setSelectedSubscriptionId: Dispatch<number | null>;
}

export const OneCustomerSubscription: React.FC<CustomerSubscriptionProps> = ({
	subscription,
	selectedSubscriptionId,
	setSelectedSubscriptionId,
}) => {
	const openSubscription = selectedSubscriptionId === subscription.id;

	const styles = openSubscription
		? {
				color: 'var(--text-subtle)',
		  }
		: {
				color: 'var(--text-normal)',
		  };

	return (
		<div>
			<div
				className={style.subscriptionItem}
				style={styles}
				onClick={() => {
					setSelectedSubscriptionId(
						selectedSubscriptionId === subscription.id ? null : subscription.id
					);
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
					<div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
						<Tooltip
							title={
								subscription.type === 'NETWORK'
									? 'Floating subscription'
									: 'Standalone subscription'
							}
						>
							<div
								style={{
									display: 'inline-block',
									height: '24px',
									width: '24px',
								}}
							>
								{subscription.type === 'NETWORK' ? (
									<FloatingSubscriptionIcon />
								) : (
									<StandaloneSubscriptionIcon />
								)}
							</div>
						</Tooltip>
						<h4
							className={style.titleItem}
							style={{ alignItems: 'center', ...styles }}
						>
							Subscription ID: {subscription.subscriptionID}
							<div style={{ color: 'var(--add-red)' }}>
								{subscription.status === 'INACTIVE' ? '(Disabled)' : ''}
							</div>
						</h4>
					</div>
					<div className={style.textItem}>
						{`• Renewal: ${subscription.renewalDate} ${
							subscription.renewalDate !== undefined
								? getTimeRemainingSuffix(
										new Date(subscription.renewalDate),
										true
								  )
								: ''
						}`}
						{` • ${
							subscription.type === 'NETWORK' ? 'Floating' : 'Standalone'
						} license `}
						{subscription.type === 'NETWORK'
							? '• ' +
							  (!!subscription.seats ? subscription.seats : 0) +
							  ' seats'
							: ''}
					</div>
				</div>
				<div className={style.buttonsItem}>
					{openSubscription ? (
						<div style={{ transform: 'rotate(180deg)' }}>
							<IconTickDown />
						</div>
					) : (
						<IconTickDown />
					)}
				</div>
			</div>
			<Cond if={openSubscription}>
				<OpenedCustomerSubscription subscription={subscription} />
			</Cond>
		</div>
	);
};
