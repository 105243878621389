import { Layout } from 'antd';
import { ReactNode } from 'react';
import { CustomFooter } from './CustomFooter';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import 'assets/styles/layout.scss';

const NonAuthLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
	return (
		<Layout className="layout-wrapper">
			<div className="content-wrapper">
				<Content>{children}</Content>
				<div className="footer-wrapper" style={{ paddingBottom: '3rem' }}>
					<CustomFooter />
				</div>
			</div>
		</Layout>
	);
};

export default NonAuthLayout;
