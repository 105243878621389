import React, { FC, useState } from 'react';
import 'assets/styles/footer.scss';
import { MenuOutlined } from '@ant-design/icons';
import {
	EnvelopeIcon,
	FacebookIcon,
	ImageSystemsLogo,
	LinkedinIcon,
	YoutubeIcon,
} from 'assets/icons/svg';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { McIconButton } from 'components/mc';
import { Terms } from 'components/Terms';

export const CustomFooter: FC = () => {
	const navigate = useNavigate();
	const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

	const footerMenuItems = [
		{ label: 'Website', key: 'https://imagesystems.se' },
		{ label: 'About', key: 'https://imagesystems.se/about/' },
		{ label: 'Terms', key: 'terms' },
		{ label: 'Contact', key: 'https://imagesystems.se/contact/' },
	];

	const handleMenuClick = (key: string) => {
		if (key === 'terms') {
			setIsTermsModalOpen(true);
		} else {
			window.open(key, '_blank');
		}
	};

	return (
		<div className="footer-container">
			<div className="footer-content flex-col-mobile">
				<div className="logo-container" onClick={() => navigate('/')}>
					<ImageSystemsLogo />
				</div>
				<Menu
					mode="horizontal"
					items={footerMenuItems}
					selectedKeys={[]}
					overflowedIndicator={<MenuOutlined />}
					onSelect={({ key }) => handleMenuClick(key)}
					className="footerMenu"
				/>
				<div className="social-icons">
					<McIconButton.Link
						icon={<FacebookIcon />}
						href="https://www.facebook.com/Imagesyste"
						target="_blank"
					/>
					<McIconButton.Link
						icon={<LinkedinIcon />}
						href="https://www.linkedin.com/company/image-systems-motion-analysis"
						target="_blank"
					/>
					<McIconButton.Link
						icon={<EnvelopeIcon />}
						href="mailto:info@imagesystems.se"
						target="_blank"
					/>
					<McIconButton.Link
						icon={<YoutubeIcon />}
						href="https://www.youtube.com/channel/UC5edwrL3WIc54GVPIYEUMpg"
						target="_blank"
					/>
				</div>
			</div>
			<Terms
				isOpen={isTermsModalOpen}
				onClose={() => setIsTermsModalOpen(false)}
			/>
		</div>
	);
};
