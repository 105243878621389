import { McButton } from 'components/mc/McButton';
import pageStyle from './ActivationWizardPages.module.scss';
import { Dispatch, useRef, useState } from 'react';
import { CheckIcon, UploadIcon } from 'assets/icons/svg';
import manualStyle from './../Components/FileButton.module.scss';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import { App, Spin } from 'antd';
import { errorNotification } from 'utils/Notifications';
import { LicenseRequestProgressDto, SubscriptionDto } from 'api';
import { getDetailedErrorMessage } from '../ActivationWizardError';

interface Props {
	subscription: SubscriptionDto;
	setDisplayActivationWizard: Dispatch<boolean>;
	progressDto: LicenseRequestProgressDto;
}

const SendReceiptPage = ({
	setDisplayActivationWizard,
	subscription,
	progressDto,
}: Props) => {
	const [isReceiptSuccessful, setIsReceiptSuccessful] = useState<
		boolean | undefined
	>(undefined);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const { notification } = App.useApp();
	const queryClient = useQueryClient();

	const { mutateAsync: completeRequest, isPending: isUploadingReceipt } =
		useMutation({
			mutationFn: ({
				subscriptionId,
				licenseRequestId,
				receipt,
			}: {
				subscriptionId: number;
				licenseRequestId: number;
				receipt: Blob;
			}) =>
				subscriptionsService
					.completeRequestForm(subscriptionId, licenseRequestId, receipt)
					.then((res) => res.data),
			onMutate: () => setIsReceiptSuccessful(undefined),
			onSuccess: () => {
				notification.success({
					message: 'Success!',
					description: `You successfully completed the license request!`,
				});
				queryClient.invalidateQueries({ queryKey: ['subscriptions'] });
				setIsReceiptSuccessful(true);
			},
			onError: (err: unknown) => {
				setErrorMessage(getDetailedErrorMessage(err));
				setIsReceiptSuccessful(false);
			},
		});

	return (
		<div className={pageStyle.pageContainer}>
			<div className={pageStyle.pageContent}>
				<div className={pageStyle.pageTitle}>Upload receipt</div>
				<div
					style={{
						marginBottom: '8px',
						marginTop: '12px',
					}}
					className={pageStyle.divider}
				/>
				<div
					style={{
						width: '100%',
						marginBottom: '24px',
					}}
					className={pageStyle.pageText}
				>
					Select and send the receipt file you exported from the offline
					computer after the import of the update file.
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '24px',
					gap: '8px',
					alignItems: 'center',
				}}
			>
				<div className={manualStyle.fileButtonContainer}>
					<input
						className={manualStyle.fileButtonInput}
						type="file"
						id="file-input"
						style={{ display: 'none' }}
						ref={fileInputRef}
						onChange={(event) => {
							try {
								const receipt: Blob = event.target.files![0];
								completeRequest({
									subscriptionId: subscription.id,
									licenseRequestId: progressDto.licenseRequestId,
									receipt: receipt,
								});
							} catch (e) {
								notification.error(errorNotification('Failed to import file!'));
								event.target.value = '';
								return;
							}
						}}
					/>
					<McButton
						primary
						disabled={!!isReceiptSuccessful || isUploadingReceipt}
						onClick={() => {
							if (fileInputRef !== null && fileInputRef.current !== null)
								fileInputRef.current.click();
						}}
					>
						<UploadIcon />
						Send receipt
					</McButton>
				</div>
				{isUploadingReceipt && <Spin spinning={isUploadingReceipt} />}
				{isReceiptSuccessful !== undefined &&
					(isReceiptSuccessful ? (
						<CheckIcon />
					) : (
						<div
							style={{
								color: 'var(--add-red)',
								fontSize: '13px',
								fontWeight: '500',
								whiteSpace: 'pre-line',
							}}
						>
							{errorMessage}
						</div>
					))}
			</div>
			<div
				style={{
					justifyContent: 'end',
				}}
				className={pageStyle.buttonBar}
			>
				<McButton
					primary
					onClick={() => {
						setDisplayActivationWizard(false);
					}}
					disabled={!isReceiptSuccessful}
				>
					Finish
				</McButton>
			</div>
		</div>
	);
};

export default SendReceiptPage;
